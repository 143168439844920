<template>
  <div class="container is-fluid content-container">
    <div class="columns is-vcentered is-centered">
      <div class="column is-full scroll-overflow">
        <vuejs-heatmap
            v-bind:selector="heatmapSelector"
            v-bind:tooltip-unit="tooltipUnit"
            v-bind:entries="heatmapEntries"
            v-bind:color-range="heatmapColorRange"
            v-bind:tooltip-enabled="showTooltip"
            v-bind:locale="heatmapLocale"
        ></vuejs-heatmap>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  props: ['heatmapSelector', 'heatmapColorRange','heatmapEntries'],
  components: {},
  data() {
    return {
      showTooltip: true,
      tooltipUnit: 'email',
      heatmapLocale: {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        No: 'No',
        on: 'on',
        Less: 'Less',
        More: 'More'
      }
    }
  },
  watch: {},
  mounted() {
    let vm = this;
    vm.$nextTick(function () {

    });
  }
}
</script>

<style scoped>
.scroll-overflow{
  overflow: scroll;
}
</style>