<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-four-fifths-desktop is-four-fifths-widescreen is-four-fifths-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.name.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.interactionEnabled.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.greetingAction.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.salesOfferInteractionType.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.keywords.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.whitelist.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.blacklist.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Header"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.headerType.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.headerContent.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.headerCaption.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Body"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.bodyContent.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Footer"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.footerContent.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Options"></div>
        <whatsAppInteractiveToolsSection
            v-bind:boxFor="'salesofferinteractive'"
            v-bind:interactionType="interactionType"
            v-bind:boxData="formFieldsData.actionObject.binds.initial"
            v-on:boxEvent="boxEvent"></whatsAppInteractiveToolsSection>
      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import selectField from "./elements/selectField";
import checkRadioField from "./elements/checkRadioField";
import textAreaField from "./elements/textAreaField";
import whatsAppInteractiveToolsSection from "./elements/whatsAppInteractiveToolsSection";
import deletionForm from "./elements/deletionForm";

export default {
  name: "salesofferinteractiveForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField,
    selectField,
    deletionForm,
    checkRadioField,
    textAreaField,
    whatsAppInteractiveToolsSection
  },
  data() {
    return {interactionType: ''}
  },
  props: ['formsData', 'formActionButton'],
  watch: {
    formLoading: {
      handler: function (newVal) {
        let vm = this;
        if (!newVal) {
          vm.interactionType = vm.formFieldsData.salesOfferInteractionType.binds.initial;
        }
      },
      deep: true
    }
  },
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
