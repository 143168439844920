<template>
  <component
      v-bind="formProperties"
      v-bind:is="formSelect"
      v-on:dataModal="showDataModal"
      v-on:closeModal="closeDataModal"
      v-on:refreshTable="refreshModalTable"
      v-on:missingFormData="missingFormData"
      v-bind:formsData="formsData"
      v-bind:formActionButton="formActionButton"
  ></component>
</template>

<script>
import contactsForm from '../forms/contactsForm.vue';
import groupsForm from '../forms/groupsForm.vue';
import approveForm from '../forms/approveForm.vue';
import accountusersForm from '../forms/accountusersForm.vue';
import datacollectorseriesForm from '../forms/datacollectorseriesForm.vue';
import datacollectorquestionsForm from '../forms/datacollectorquestionsForm.vue';
import proxymanagertablesForm from '../forms/proxymanagertablesForm.vue';
import proxymanageractionsForm from '../forms/proxymanageractionsForm.vue';
import templatesmsForm from '../forms/templatesmsForm.vue';
import linkproxyfiernodesForm from '../forms/linkproxyfiernodesForm.vue';
import linkproxyfierconfigForm from '../forms/linkproxyfierconfigForm.vue';
import proxymanagerblacklistForm from '../forms/proxymanagerblacklistForm.vue';
import mpesamanagernumbersForm from '../forms/mpesamanagernumbersForm.vue';
import mpesamanageractionsForm from '../forms/mpesamanageractionsForm.vue';
import accountmanageraccountsForm from '../forms/accountmanageraccountsForm.vue';
import accountmanagerusersForm from '../forms/accountmanagerusersForm.vue';
import buyMoreSmsForm from '../forms/buyMoreSmsForm.vue';
import nodesmanagercontrollersForm from '../forms/nodesmanagercontrollersForm.vue';
import nodesmanagerproviesForm from '../forms/nodesmanagerproxiesForm.vue';
import email2smsconfigurationForm from '../forms/email2smsconfigurationForm.vue';
import proxymanagerreportingForm from '../forms/proxymanagerreportingForm.vue';
import saleofferinteractiveForm from '../forms/saleofferinteractiveForm.vue';
import saleofferactionForm from '../forms/saleofferactionForm.vue';
import saleofferitemForm from '../forms/saleofferitemForm.vue';
import saleoffersubscriberForm from '../forms/saleoffersubscriberForm.vue';
import reportconfigurationForm from '../forms/reportconfigurationForm.vue';
import emailusersForm from '../forms/emailusersForm.vue';
import emailcategoriesForm from '../forms/emailcategoriesForm.vue';
import subscribersForm from '../forms/subscribersForm.vue';
import subscribergroupsForm from '../forms/subscribergroupsForm.vue';
import emailtagsForm from '../forms/emailtagsForm.vue';


export default {
  name: "modalsForm",
  data() {
    return {}
  },
  props: ['formsData', 'formActionButton'],
  //mixins: [dataFetcher],
  components: {
    contacts: contactsForm,
    groups: groupsForm,
    approve: approveForm,
    datacollectorseries: datacollectorseriesForm,
    datacollectorquestions: datacollectorquestionsForm,
    proxymanagertables: proxymanagertablesForm,
    proxymanageractions: proxymanageractionsForm,
    proxymanagerblacklist: proxymanagerblacklistForm,
    accountusers: accountusersForm,
    mpesamanagernumbers: mpesamanagernumbersForm,
    mpesamanageractions: mpesamanageractionsForm,
    accountmanageraccounts: accountmanageraccountsForm,
    accountmanagerusers: accountmanagerusersForm,
    templates: templatesmsForm,
    buymoresms: buyMoreSmsForm,
    nodesmanagercontrollers: nodesmanagercontrollersForm,
    nodesmanagerproxies: nodesmanagerproviesForm,
    email2smsconfiguration: email2smsconfigurationForm,
    proxymanagerreporting: proxymanagerreportingForm,
    linkproxyfiernodes: linkproxyfiernodesForm,
    linkproxyfierconfig: linkproxyfierconfigForm,
    saleofferinteractive: saleofferinteractiveForm,
    saleofferaction: saleofferactionForm,
    saleofferitem: saleofferitemForm,
    saleoffersubscriber: saleoffersubscriberForm,
    reportconfiguration: reportconfigurationForm,
    emailusers: emailusersForm,
    emailcategories: emailcategoriesForm,
    subscribers: subscribersForm,
    subscribergroups: subscribergroupsForm,
    emailtags: emailtagsForm,
  },
  computed: {
    formSelect: function () {
      try {
        let vm = this;
        return vm.formsData.data.formname;
      } catch (error) {
        return '';
      }
    },
    formProperties: function () {
      try {
        let vm = this;
        return {formAction: vm.formsData.data.action};
      } catch (error) {
        return '';
      }
    }
  },
  methods: {
    showDataModal: function (emittedData) {
      let vm = this;
      vm.$emit('dataModal', emittedData);
    },
    closeDataModal: function (emittedData) {
      let vm = this;
      vm.$emit('closeModal', emittedData);
    },
    refreshModalTable: function (emittedData) {
      let vm = this;
      vm.$emit('refreshTable', emittedData);
    },
    missingFormData: function (emittedData) {
      let vm = this;
      vm.$emit('missingFormData', emittedData);
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
    });
  },
  watch: {}
}
</script>

<style scoped>

</style>