<template>
  <form>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="field-label is-normal has-text-left form-padded-labels">
            <label class="label">SMS: <span class="help is-danger bigger-text" v-if="$v.SmsMsg.$invalid">The SMS message cannot be empty</span></label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile is-multiline has-text-left">
                <div class="column is-full">
                  <p class="control" v-bind:class="{ 'has-icons-right' : $v.SmsMsg.$invalid }">
                                        <textarea class="textarea is-primary is-rounded" placeholder="SMS Message"
                                                  ref="smsmsg" rows="3"
                                                  v-bind:class="{ 'is-danger' : $v.SmsMsg.$invalid }"
                                                  v-model.trim="SmsMsg" v-on:input="textareaResize"
                                                  id="smsTextArea"></textarea>
                    <span class="icon is-small is-right redColored" v-if="$v.SmsMsg.$invalid">
											<font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
										</span>
                  </p>
                </div>
                <div class="column is-full has-text-right" v-html="SmsMessageCount">
                </div>
                <!--                                <div class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">-->
                <!--                                    <p class="control">-->
                <!--                                        <a class="button is-fullwidth is-primary" v-on:click="getTemplates">-->
                <!--											<span class="icon is-small">-->
                <!--												<font-awesome-icon v-bind:icon="['fas', 'file-alt']"/>-->
                <!--											</span>-->
                <!--                                            <span>-->
                <!--												Template-->
                <!--											</span>-->
                <!--                                        </a>-->
                <!--                                    </p>-->
                <!--                                </div>-->
                <!--                                <div class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">-->
                <!--                                    <p class="control">-->

                <!--                                    </p>-->
                <!--                                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--        <div class="columns is-centered is-vcentered">-->
    <!--            <div class="column">-->
    <!--                <div class="field">-->
    <!--                    <div class="field-body">-->
    <!--                        <div class="field">-->
    <!--                            <div class="columns is-mobile is-multiline has-text-left is-vcentered">-->
    <!--                                <div class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">-->
    <!--                                    <p class="control">-->
    <!--                                        <a class="button is-fullwidth is-primary" v-on:click="getSignatures">-->
    <!--											<span class="icon is-small">-->
    <!--												<font-awesome-icon v-bind:icon="['fas', 'signature']"/>-->
    <!--											</span>-->
    <!--                                            <span>Signature</span>-->
    <!--                                        </a>-->
    <!--                                    </p>-->
    <!--                                </div>-->
    <!--                                <div class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">-->
    <!--                                    {{ setSignatureText }}-->
    <!--                                </div>-->
    <!--                            </div>-->

    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <!--<div class="field-label is-normal has-text-left form-padded-labels">
              <label class="label">To:</label>
          </div>-->
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile is-multiline has-text-left is-vcentered">
                <div
                    class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                  <p class="control">
                    <a class="button is-fullwidth is-primary" v-on:click="getGroups">
											<span class="icon is-small">
												<font-awesome-icon v-bind:icon="['fas', 'users']"/>
											</span>
                      <span>To</span>
                    </a>
                  </p>
                </div>
                <div
                    class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                  {{ setReceiverGroups }}
                  <p class="help is-danger bigger-text" v-if="$v.receiverArrJoined.$invalid">Please
                    select the receiver(s)</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="field-label is-normal has-text-left form-padded-labels">
            <div class="is-divider is " data-content="SMS Scope"></div>
            <label class="label">
                            <span class="help is-danger bigger-text" v-if="$v.sendScope.$invalid">
                                Please select the sms sending scope
                            </span>
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile has-text-left">
                <div class="column is-full">
                  <div class="columns is-mobile is-multiline">
                    <div class="column">
                      <input id="toall" name="sendscope" type="radio" v-model="sendScope"
                             value="all"/>
                      <label class="choice-label" for="toall">All</label>
                    </div>
                    <div class="column">
                      <input id="tosample" name="sendscope" type="radio" v-model="sendScope"
                             value="sample"/>
                      <label class="choice-label" for="tosample">Sample</label>
                    </div>
                    <div class="column is-full no-wrapping"
                         v-show="sampleTypePickerVisible">
                      <div class="columns is-multiline">
                        <div class="column">
                          <input class="input is-primary is-rounded" type="number"
                                 v-bind:class="{ 'is-danger' : $v.sampleSize.$invalid }"
                                 v-model="sampleSize">
                          <p class="help is-danger bigger-text" v-if="$v.sampleSize.$invalid">
                            Please specify a sample size</p>
                        </div>
                        <div class="column">
                          <select class="input is-primary is-rounded"
                                  v-bind:class="{ 'is-danger' : $v.sampleLogic.$invalid }"
                                  v-model="sampleLogic">
                            <option value="random">Random</option>
                            <option value="first">First</option>
                            <option value="last">Last</option>
                          </select>
                          <p class="help is-danger bigger-text"
                             v-if="$v.sampleLogic.$invalid">Please specify a sample logic</p>
                        </div>
                        <div class="column">
                          <input id="pergroupsample" name="pergroupsample" type="checkbox"
                                 v-model="perGroupSample.perGroup"
                                 value="true"/>
                          <label class="choice-label" for="pergroupsample">Per Group</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="is-divider" data-content="Spam checking by"></div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile has-text-left">
                <div class="column">
                  <input id="spamcheckNumber" name="spamcheck" type="radio"
                         v-model="spamcheck" value="number"/>
                  <label class="choice-label" for="spamcheckNumber">By Number</label>
                </div>
                <div class="column">
                  <input id="spamcheckContent" name="spamcheck" type="radio"
                         v-model="spamcheck" value="content"/>
                  <label class="choice-label" for="spamcheckContent">By Content</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="is-divider" data-content="Spam checking scope"></div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile is-multiline has-text-left">
                <div class="column">
                  <input id="spamcheckthisorder" name="spamcheckscope" type="radio"
                         v-model="spamcheckScope" value="thisorder"/>
                  <label class="choice-label" for="spamcheckthisorder">This order</label>
                </div>
                <div class="column">
                  <input id="spamcheckhistory" name="spamcheckscope" type="radio"
                         v-model="spamcheckScope" value="historicalorders"/>
                  <label class="choice-label" for="spamcheckhistory">Historical</label>
                </div>
                <div class="column is-full"
                     v-show="historicalSpamChecking">
                  <div class="columns">
                    <div class="column is-full">
                      <select class="input is-primary is-rounded"
                              v-bind:class="{ 'is-danger' : $v.historicalSpam.$invalid }"
                              v-model="historicalSpam">
                        <option value="5d">5 days</option>
                        <option value="4d">4 days</option>
                        <option value="3d">3 days</option>
                        <option value="3d">2 days</option>
                        <option value="1d">1 day</option>
                      </select>
                      <p class="help is-danger bigger-text" v-if="$v.historicalSpam.$invalid">
                        Please specify a historical cutoff</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="is-divider" data-content="SMS Type"></div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile has-text-left">
                <div class="column is-full">
                  <div class="columns is-mobile">
                    <div class="column">
                      <input id="trans" name="transbroad" type="radio" v-model="transbroad"
                             value="trans"/>
                      <label class="choice-label" for="trans">Transactional</label>
                    </div>
                    <div class="column">
                      <input id="broad" name="transbroad" type="radio" v-model="transbroad"
                             value="broad"/>
                      <label class="choice-label" for="broad">Broadcast</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="is-divider" data-content="SMS Options"></div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile has-text-left">
                <div class="column is-full">
                  <div class="columns">
                    <div class="column">
                      <input id="preloadSms" name="preloadSms" type="checkbox"
                             v-model="preloadSms.preload"
                             value="true"/>
                      <label class="choice-label" for="preloadSms">Preload Text</label>
                    </div>
                    <div class="column">
                      <input id="overnightSms" name="overnightSms" type="checkbox"
                             v-model="overnightSms.overnight"
                             value="true"/>
                      <label class="choice-label" for="overnightSms">Overnight SMS</label>
                    </div>
                    <div class="column">
                      <input id="continueTomorrow" name="continueTomorrow" type="checkbox"
                             v-model="continueTomorrow.continue"
                             value="true"/>
                      <label class="choice-label" for="continueTomorrow">Continue Tomorrow</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column">
        <div class="field">
          <div class="is-divider" data-content="Send Time"></div>
          <div class="field-label is-normal has-text-left form-padded-labels">
            <label class="label">
                            <span class="help is-danger bigger-text" v-if="$v.sendTime.$invalid">
                                Please select when the sms should be sent
                            </span>
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile has-text-left">
                <div class="column is-full">
                  <div class="columns is-mobile is-multiline">
                    <div class="column">
                      <input id="sendnow" name="sendtime" type="radio" v-model="sendTime"
                             value="now"/>
                      <label class="choice-label" for="sendnow">Now</label>
                    </div>
                    <div class="column">
                      <input id="sendlater" name="sendtime" type="radio" v-model="sendTime"
                             value="later"/>
                      <label class="choice-label" for="sendlater">Later</label>
                    </div>
                    <div class="column is-full"
                         v-show="dateTimePickerVisible">
                      <flat-pickr
                          class="input is-primary"
                          name="laterdatetime"
                          placeholder="Select date and time"
                          v-bind:class="{ 'is-danger' : $v.laterDateTime.$invalid  }"
                          v-bind:config="configDatePicker"
                          v-model="laterDateTime"
                      >
                      </flat-pickr>
                      <p class="help is-danger bigger-text" v-if="$v.laterDateTime.$invalid">
                        Please specify a date and time</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-vcentered">
      <div class="column is-centered is-vcentered">
        <div class="field">
          <div class="field-label is-normal has-text-left form-padded-labels">
            <label class="label">
              <vs-divider v-if="previewTextSet">Text Message preview</vs-divider>
              <span v-html="SmsPreviewTextDisplay"></span>
              <vs-divider v-if="previewTextSet"></vs-divider>
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-centered is-vcentered">
                  <div class="field is-grouped">
                    <p class="control">
                      <a class="button is-link" v-bind:disabled="disableSendButton"
                         v-on:click="checkForm">Order SMS</a>
                    </p>
                    <p class="control">
                      <a class="button is-danger" v-on:click="clearComposeForm">
                        Clear Form
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import api from "./../../../api/api.js";
import flatPickr from 'vue-flatpickr-component';
//import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import {required} from 'vuelidate/lib/validators';


export default {
  name: "composeSms",
  data() {
    let vm = this;
    return {
      SmsMsg: '',
      SmsMsgCount: '0 Characters (0 SMS)',
      totalCharacterCount: 0,
      smsInvalid: false,
      laterDateTime: '',//new Date(),
      configDatePicker: {
        inline: true,
        minDate: "today",
        // wrap: true,
        dateFormat: 'Y-m-d at H:i',
        time_24hr: true,
        enableTime: true,
        // plugins: [new confirmDatePlugin({
        //     confirmText: "OK ",
        //     showAlways: true,
        //     theme: "light"
        // })]
      },
      receiverString: '',
      selectedSignature: '',
      sendButtonDisable: false,
      dateTimePickerVisible: false,
      sampleTypePickerVisible: false,
      perGroupSample: {perGroup: ''},
      preloadSms: {preload: ''},
      overnightSms: {overnight: ''},
      continueTomorrow: {continue: true},
      historicalSpamChecking: false,
      transbroad: 'broad',
      sendTime: '',
      sendScope: '',
      selectedSignatureString: '',
      spamcheck: 'number',
      spamcheckScope: 'thisorder',
      receiverArrJoined: '',
      myself: {},
      fullMessage: '',
      previewTextSet: false,
      dataSelectedInternal: vm.dataSelected,
      sampleLogic: 'random',
      historicalSpam: '5d',
      sampleSize: 0
    }
  },
  validations() {
    let vm = this,
        valudationObj = {
          SmsMsg: {required},
          receiverArrJoined: {required},
          sendTime: {required},
          sendScope: {required}
        };

    if (vm.$_.trim(vm.sendTime) === 'later') {
      valudationObj = vm.$_.assign({}, valudationObj, {laterDateTime: {required}});
    } else {
      valudationObj = vm.$_.assign({}, valudationObj, {laterDateTime: {}});
    }

    if (vm.$_.trim(vm.sendScope) === 'sample') {
      valudationObj = vm.$_.assign({}, valudationObj, {sampleLogic: {required}, sampleSize: {required}});
    } else {
      valudationObj = vm.$_.assign({}, valudationObj, {sampleLogic: {}, sampleSize: {}});
    }

    if (vm.$_.trim(vm.spamcheck) === 'historical') {
      valudationObj = vm.$_.assign({}, valudationObj, {historicalSpam: {required}});
    } else {
      valudationObj = vm.$_.assign({}, valudationObj, {historicalSpam: {}});
    }

    return valudationObj;
  },
  props: ['dataSelected'],
  components: {
    flatPickr
  },
  computed: {
    setReceiverGroups: function () {
      let vm = this,
          dataFrom;
      if ((typeof vm.dataSelectedInternal !== 'undefined') && (typeof vm.dataSelectedInternal.from !== 'undefined')) {
        dataFrom = vm.$_.trim(vm.dataSelectedInternal.from);
        switch (dataFrom) {
          case 'get-sms-contact-groups-nocount':
            vm.receiverArrJoined = vm.$_.join(vm.dataSelectedInternal.selection, ', ');
            break;
          case 'templates-subsection':
            vm.SmsMsg = vm.dataSelectedInternal.rowData.s;
            vm.$scrollTo('#compose-subsection', 500, vm.scrollOptions);
            break;
        }
      }
      return vm.receiverArrJoined;
    },
    setSignatureText: function () {
      let vm = this,
          finalSignatureText = '';
      if ((typeof vm.dataSelectedInternal !== 'undefined') && (typeof vm.dataSelectedInternal.from !== 'undefined')) {
        if (vm.$_.trim(vm.dataSelectedInternal.from) === 'get-sms-signatures') {
          vm.selectedSignatureString = vm.$_.join(vm.dataSelectedInternal.selection, ', ');
        }
      }
      if (vm.$_.trim(vm.selectedSignatureString) === '') {
        if (vm.$_.trim(vm.myself.sg) === '') {
          finalSignatureText = vm.selectedSignatureString;
        } else {
          finalSignatureText = vm.$_.trim(vm.myself.sg) + ' (' + (vm.$_.trim(vm.myself.sg).length + 1) + ' Characters)';
        }
      } else {
        finalSignatureText = vm.selectedSignatureString + ' (' + (vm.$_.trim(vm.selectedSignatureString).length + 1) + ' Characters)';
      }
      vm.selectedSignatureString = finalSignatureText;
      return vm.selectedSignatureString;
    },
    disableSendButton: function () {
      let vm = this;
      return vm.$v.$invalid;
    },
    fullMessageExists: function () {
      let vm = this;
      return vm.$_.trim(vm.fullMessage) !== '';
    },
    /**
     * @return {boolean}
     */
    SmsMessageCount: function () {
      let vm = this;
      vm.SmsMsgCount = vm.totalCharacterCount + ' Characters (' + Math.ceil(parseFloat(vm.totalCharacterCount) / parseFloat(160.0)) + ' SMS)';
      return vm.SmsMsgCount;
    },
    SmsPreviewTextDisplay: function () {
      let vm = this,
          returnText = '';
      if (vm.$_.trim(vm.fullMessage) !== '' &&
          vm.$_.trim(vm.setReceiverGroups) !== '' &&
          vm.$_.trim(vm.sendScope) !== '' &&
          vm.$_.trim(vm.sendTime) !== '') {
        returnText = `<strong>Message:</strong> ${vm.fullMessage}`;
        if (vm.$_.trim(vm.sendScope) === 'sample') {
          returnText += `<br><strong>To:</strong> ${vm.setReceiverGroups} (${vm.$_.trim(vm.sampleLogic)} ${vm.$_.trim(vm.sampleSize)})`
        } else {
          returnText += `<br><strong>To:</strong> ${vm.setReceiverGroups} (${vm.$_.trim(vm.sendScope)})`;
        }
        switch (vm.$_.trim(vm.sendTime).toString().toLowerCase()) {
          case 'now':
            returnText += `<br><strong>When:</strong> Now`;
            break;
          case 'later':
            if (vm.$_.trim(vm.laterDateTime) !== '') {
              returnText += `<br><strong>When:</strong> ${vm.laterDateTime}`;
            } else {
              returnText += `<br><strong>When:</strong> Date and time not set`;
            }
            break;
          default:
            returnText += `<br><strong>When:</strong> Not set`;
        }

        console.log(vm.preloadSms);
        if (vm.$_.trim(vm.preloadSms.preload) !== '') {
          if (vm.$_.trim(vm.preloadSms.preload) === 'true') {
            returnText += `<br><strong>Preload SMS:</strong> Yes`;
          } else {
            returnText += `<br><strong>Preload SMS:</strong> No`;
          }
        } else {
          returnText += `<br><strong>Preload SMS:</strong> No`;
        }

        console.log(vm.overnightSms);
        if (vm.$_.trim(vm.overnightSms.overnight) !== '') {
          if (vm.$_.trim(vm.overnightSms.overnight) === 'true') {
            returnText += `<br><strong>Overnight SMS:</strong> Yes`;
          } else {
            returnText += `<br><strong>Overnight SMS:</strong> No`;
          }
        } else {
          returnText += `<br><strong>Overnight SMS:</strong> No`;
        }

        console.log(vm.continueTomorrow);
        if (vm.$_.trim(vm.continueTomorrow.continue) !== '') {
          if (vm.$_.trim(vm.continueTomorrow.continue) === 'true') {
            returnText += `<br><strong>Continue Tomorrow:</strong> Yes`;
          } else {
            returnText += `<br><strong>Continue Tomorrow:</strong> No`;
          }
        } else {
          returnText += `<br><strong>Continue Tomorrow:</strong> No`;
        }

        vm.previewTextSet = true;
      } else {
        vm.previewTextSet = false;
      }
      return returnText;
    }
  },
  methods: {
    checkForm: function (event) {
      event.preventDefault();
      let vm = this;
      if (!vm.$v.$invalid) {
        vm.sendButtonDisable = true;
        vm.orderSms();
      } else {
        vm.sendButtonDisable = false;
        vm.$notify({
          group: 'warning-notification',
          title: 'SMS data missing',
          text: 'Please ensure all the required fields have been filled',
          duration: 3000,
          max: 1,
          position: 'top center'
        });
      }
    },
    orderSms: function () {
      let vm = this;
      vm.smsOrderedInvalid = false;
      let authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let reqHeader = {
        headers: {
          authorization: authorizationToken,
        }
      };
      let orderData = {
            text: vm.SmsMsg,
            signature: vm.selectedSignatureString,
            receivers: vm.setReceiverGroups,
            sendtime: vm.sendTime,
            sendscope: vm.sendScope,
            samplesize: vm.sampleSize,
            samplelogic: vm.sampleLogic,
            samplepergroup: vm.perGroupSample,
            preloadSms: vm.preloadSms,
            overnightSms: vm.overnightSms,
            continueTomorrow: vm.continueTomorrow,
            datetime: vm.laterDateTime,
            spamcheckInd: vm.spamcheck,
            spamcheckScope: vm.spamcheckScope,
            historicalSpamScope: vm.historicalSpam,
            smsType: vm.transbroad,
            session: vm.$session.id(),
            mandatorysignature: vm.$_.trim(vm.myself.sg)
          },
          orderSummary;
      api.operation('orderfesms').post(orderData, reqHeader)
          .then(({data}) => {
            vm.sendButtonDisable = false;
            if (data.success) {
              vm.smsOrderedInvalid = false;
              orderSummary = 'Text: ' + orderData.text + '<br>Receivers: ' + orderData.receivers + '<br>Time: ' + vm.$moment().format("YYYY-MM-DD HH:mm:ss");
              vm.clearComposeForm();
              vm.$notify({
                group: 'success-notification',
                title: data.returnData,
                text: orderSummary,
                duration: -1,
                max: 1,
                position: 'bottom left'
              });

            } else {
              if (data.msg === "Authentication failed.") {
                vm.smsOrderedInvalid = true;
              }
            }
          });
    },
    clearComposeForm: function () {
      let vm = this;
      vm.dataSelectedInternal = {};
      vm.SmsMsg = '';
      vm.receiverArrJoined = '';
      vm.selectedSignatureString = '';
      vm.spamcheck = 'number';
      vm.spamcheckScope = 'thisorder';
      vm.transbroad = 'broad';
      vm.sendTime = '';
      vm.fullMessage = '';
      vm.totalCharacterCount = 0;
      vm.sendScope = '';
      vm.sampleLogic = 'random';
      vm.sampleSize = 0;
      vm.perGroupSample = {perGroup: ''};
      vm.preloadSms = {preload: ''};
      vm.overnightSms = {overnight: ''};
      vm.continueTomorrow = {continue: true};
      vm.historicalSpam = '5d';
    },
    getTemplates: function () {
      let vm = this;
      vm.$emit('dataModal', {
        dataHead: 'SMS Template',
        dataReq: 'get-sms-templates',
        from: 'composesmsform',
        type: 'radio',
        key: vm.$moment().valueOf()
      });
    },
    getSignatures: function () {
      let vm = this;
      if (vm.$_.trim(vm.myself.sg) === '') {
        vm.$emit('dataModal', {
          dataHead: 'SMS Signature',
          dataReq: 'get-sms-signatures',
          from: 'composesmsform',
          type: 'checkbox',
          key: vm.$moment().valueOf(),
          items: {}
        });

      } else {
        vm.$emit('dataModal', {
          dataHead: 'SMS Signature',
          dataReq: 'get-sms-signatures',
          from: 'composesmsform',
          type: 'radio',
          key: vm.$moment().valueOf(),
          items: {}
        });
      }
    },
    getGroups: function () {
      let vm = this;
      vm.$emit('dataModal', {
        dataHead: 'SMS To',
        dataReq: 'get-sms-contact-groups-nocount',
        from: 'composesmsform',
        type: 'checkbox',
        key: vm.$moment().valueOf(),
        items: {}
      });
    },
    submitform: function () {
      let vm = this;
      vm.smsInvalid = false;
      let authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let reqHeader = {
        headers: {
          authorization: authorizationToken,
        }
      };
      api.operation('sendsms').post({
        sms: vm.SmsMsg
      }, reqHeader)
          .then(({data}) => {
            if (data.success) {
              vm.smsInvalid = false;
            } else {
              if (data.msg === "Authentication failed.") {
                vm.smsInvalid = true;
              }
            }
          });
    },
    textareaResize() {
      let vm = this,
          charsC = vm.$_.trim(vm.SmsMsg).length,
          selectedsignatureC = vm.$_.trim(vm.selectedSignatureString).length,
          mandatorysignatureC = vm.$_.trim(vm.myself.sg).length;
      if (selectedsignatureC > 0) {
        vm.totalCharacterCount = charsC + selectedsignatureC + 1;
        vm.fullMessage = vm.cleanSmsSpaces(vm.$_.trim(vm.SmsMsg) + "\n" + vm.$_.trim(vm.selectedSignatureString));
      } else {
        if (mandatorysignatureC > 0) {
          vm.totalCharacterCount = charsC + mandatorysignatureC + 1;
          vm.fullMessage = vm.cleanSmsSpaces(vm.$_.trim(vm.SmsMsg) + "\n" + vm.$_.trim(vm.myself.sg));
        } else {
          vm.totalCharacterCount = charsC;
          vm.fullMessage = vm.cleanSmsSpaces(vm.$_.trim(vm.SmsMsg));
        }
      }
      this.$refs.smsmsg.style.minHeight = this.$refs.smsmsg.scrollHeight + 'px';
    },
    cleanSmsSpaces(rawSms) {
      let cleanSMS = rawSms;
      cleanSMS = cleanSMS.replace(/ +/gm, " ");
      cleanSMS = cleanSMS.replace(/\n+/gm, "\n");
      cleanSMS = cleanSMS.replace(/\t+/gm, "\t");
      cleanSMS = cleanSMS.replace(/ \n/gm, "\n");
      cleanSMS = cleanSMS.replace(/\n /gm, "\n");
      cleanSMS = cleanSMS.replace(/\n/gm, "<br>");
      return cleanSMS;
    }
  },
  watch: {
    sendTime: {
      handler: function () {
        let vm = this;
        vm.dateTimePickerVisible = vm.$_.trim(vm.sendTime) === 'later';
        if (!vm.dateTimePickerVisible) {
          vm.laterDateTime = '';
        }
      },
      deep: true
    },
    sendScope: {
      handler: function () {
        let vm = this;
        vm.sampleTypePickerVisible = vm.$_.trim(vm.sendScope) === 'sample';
        if (!vm.sampleTypePickerVisible) {
          vm.samplesize = 0;
        }
      },
      deep: true
    },
    spamcheckScope: {
      handler: function () {
        let vm = this;
        vm.historicalSpamChecking = vm.$_.trim(vm.spamcheckScope) === 'historicalorders';
        if (!vm.historicalSpamChecking) {
          vm.historicalSpam = '5d';
        }
      },
      deep: true
    },
    selectedSignatureString: {
      handler: function () {
        let vm = this;
        vm.textareaResize();
      },
      deep: true
    },
    dataSelected: {
      handler: function () {
        let vm = this;
        vm.dataSelectedInternal = vm.dataSelected;
      },
      deep: true
    }
  },
  mounted: function () {
    let vm = this;
    vm.myself = vm.$session.get('myself');
    vm.sendTime = '';
  }
}
</script>

<style scoped>

.is-divider, .is-divider-vertical {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.redColored {
  color: red !important;
}

.black-text {
  color: black !important;
}

.underlined_text {
  text-decoration: underline !important;
}

.bigger-text {
  font-size: larger;
}

.bolder-text {
  font-weight: bold;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.choice-label {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 10px;
  line-height: 40px;
  cursor: pointer;
}

.choice-label::before {
  content: " ";
  position: absolute;
  top: 6px;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  /*border: 2px solid #8e44ad;*/
  border: 2px solid hsl(171, 100%, 41%);
  border-radius: 4px;
  z-index: -1;
}

input[type="radio"] + .choice-label::before {
  border-radius: 18px;
}

/* Checked */
input[type="checkbox"]:checked + .choice-label,
input[type="radio"]:checked + .choice-label {
  padding-left: 10px;
  color: #fff;
}

input[type="checkbox"]:checked + .choice-label::before,
input[type="radio"]:checked + .choice-label::before {
  top: 0;
  width: 100%;
  height: 100%;
  /*background: #8e44ad;*/
  background: hsl(171, 100%, 41%);
}

/* Transition */
.choice-label, .choice-label::before {
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}

.flatpickr-confirm {
  background: hsl(171, 100%, 41%) !important;
}

.no-wrapping {
  white-space: nowrap !important;
}
</style>