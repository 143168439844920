module.exports = [
    'A good laugh heals a lot of hurts. — Madeleine L’Engle',
    'A good laugh is sunshine in the house. — William Thackeray',
    'A smile is a curve that sets everything straight. — Phyllis Diller',
    'A good laugh is sunshine in the house. — William Thackeray',
    'A smile is a curve that sets everything straight. — Phyllis Diller',
    'As soap is to the body, so laughter is to the soul. — A Jewish Proverb',
    'quotes about laughterAs soon as you have made a thought, laugh at it. — Lao Tsu',
    'Earth laughs in flowers. — Ralph Waldo Emerson',
    'From there to here, from here to there, funny things are everywhere. — Dr. Seuss',
    'He deserves Paradise who makes his companions laugh. — Koran',
    'He that is of a merry heart has a continual feast. — Proverbs 15:15',
    'He who laughs, lasts! — Mary Pettibone Poole',
    'Humor is a prelude to faith and laughter is the beginning of prayer. — Reinhold Niebuhr',
    'Humor is laughing at what you haven’t got when you ought to have it. — James Langston Hughes',
    'I commend mirth. — Ecclesiastes 8:15',
    'If you would not be laughed at, be the first to laugh at yourself. — Benjamin Franklin',
    'It is bad to suppress laughter. It goes back down and spreads to your hips. — Fred Allen',
    'Laugh at yourself first, before anyone else can. — Elsa Maxwell',
    'Laugh my friend, for laughter ignites a fire within the pit of your belly and awakens your being. —Stella & Blake',
    'Laughter has no foreign accent. — Paul Lowney',
    'Laughter is a sense of proportion and a power of seeing yourself from the outside. — Zero Mostel',
    'Laughter is God’s hand on the shoulder of a troubled world. — Bettenell Huntznicker',
    'Laughter is the corrective force which prevents us from becoming cranks. — Henri Bergson',
    'Laughter is the foundation of reconciliation. — St. Francis de Sales',
    'Laughter is the shortest distance between two people. — Victor Borge',
    'Laughter is the sun that drives winter from the human face. — Victor Hugo',
    'Let your heart by merry. — Judges 19:6',
    'Life is a great big canvas; throw all the paint on it you can. — Danny Kaye',
    'Most people would rather be certain they’re miserable than risk being happy. — Robert Newton Anthony',
    'No matter what your heartache may be, laughing helps you forget it for a few seconds. — Red Skelton',
    'Prepare for mirth, for mirth becomes a feast. — William Shakespeare',
    'Remember this: very little is needed to make a happy life. — Marcus Aurelius',
    'Smiles are the soul’s kisses. — Minna Thomas Antrim',
    'Spring unlocks the flowers to paint the laughing soil. — Reginald Heber',
    'The art of medicine consists of amusing the patient while nature cures the disease. — Voltaire',
    'The beauty of the world has two edges; one of laughter, one of anguish, cutting the heart asunder. — Virginia Woolf',
    'The best way to cheer yourself is to try to cheer someone else up. Mark Twain',
    'The comic and the tragic lie inseparably close, like light and shadow. — Socrates',
    'The deeper that sorrow carves into your being, the more joy you can contain. — Kahlil Gibran',
    'The greatest prayer you could ever pray is to laugh every day. — Ramtha',
    'The human race has one really effective weapon, and that is laughter. — Mark Twain',
    'The most wasted of all days is one without laughter. — E E Cummings',
    'The person who can bring the spirit of laughter into a room is indeed blessed. — Bennett Cerf',
    'The young man who has not wept is a savage, and the old man who will not laugh is a fool. — George Santayana',
    'Think of all the beauty still left around you and be happy. — Anne Frank',
    'Those who do not know how to weep with their whole heart don’t know how to laugh either. — Golda Meir',
    'To truly laugh, you must be able to take your pain and play with it. — Charlie Chaplin',
    'Total absence of humor renders life impossible. — Colette',
    'Trouble knocked at the door, but, hearing laughter, hurried away. ― Benjamin Franklin',
    'True humor springs more from the heart than from the head; it is not contempt, its essence is love. — Thomas Carlyle',
    'We are all here for a spell. Get all the good laughs you can. — Will Rogers',
    'We cannot really love anybody with whom we never laugh. — Agnes Repplier',
    'We don’t laugh because we’re happy, we are happy because we laugh. — William James',
    'When humor goes, there goes civilization. — Erma Bombeck',
    'When you laugh, you get a glimpse of God. — Merrily Belgum',
    'When you realize how perfect everything is you will tilt your head back and laugh at the sky. — Buddha',
    'Wit is the key, I think, to anybody’s heart, because who doesn’t like to laugh? — Julia Roberts',
    'With mirth and laughter let old wrinkles come. — William Shakespeare',
    'With the fearful strain that is on me night and day, if I did not laugh I should die. — Abraham Lincoln',
    'Wrinkles should merely indicate where the smiles have been. — Mark Twain',
    'You don’t stop laughing because you grow old. You grow old because you stop laughing. — Michael Pritchard',
    'You grow up the day you have your first real laugh — at yourself. — Ethel Barrymore'
];