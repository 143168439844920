<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.name.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.categoryActive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.enforceOptOut.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.confidentialityStatement.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField.vue";
import textAreaField from "./elements/textAreaField.vue";
import checkRadioField from "./elements/checkRadioField.vue";
import deletionForm from "./elements/deletionForm.vue";

export default {
  name: "emailusersForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    deletionForm,
    checkRadioField,
    inputField,
    textAreaField
  },
  data() {
    // let vm = this;
    return {}
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
