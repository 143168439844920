<template>
  <div>
    <div v-if="dateFieldType==='basic'">
      <p class="element-label" v-html="labelText"></p>
      <div class="field"
           v-show="inputData.inlineDate">
        <div class="control has-icons-left">
          <flat-pickr
              v-model.trim="inputValue"
              v-bind:config="dateTimePickerConfig"
              class="input is-primary is-rounded"
              v-bind:placeholder="placeholderText"
              name="dateInput"
              v-bind:class="{ 'is-danger' : dateInputInvalid }"
          >
          </flat-pickr>
          <span class="icon is-small is-left">
          <font-awesome-icon v-bind:icon="['fas', inputIcon]"/>
        </span>
        </div>
      </div>
      <div class="field has-addons"
           v-show="!inputData.inlineDate">
        <div class="control has-icons-left">
          <flat-pickr
              v-model.trim="inputValue"
              v-bind:config="dateTimePickerConfig"
              class="input is-primary is-rounded"
              v-bind:placeholder="placeholderText"
              name="dateInput"
              v-bind:class="{ 'is-danger' : dateInputInvalid }"
          >
          </flat-pickr>
          <span class="icon is-small is-left">
                <font-awesome-icon v-bind:icon="[inputIconCategory, inputIcon]"/>
            </span>
        </div>
        <div class="control">
				<span class="button" v-on:click="clearInputValue()">
					<font-awesome-icon v-bind:icon="['fas', 'times']"/>
				</span>
        </div>
      </div>
      <p class="help is-danger bigger-text bolder-text" v-if="dateInputInvalid">{{ dateInputInvalidText }}</p>
    </div>
    <div v-if="dateFieldType==='complex'">
      <div class="columns no-padding no-margin is-mobile">
        <div class="column is-half-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd no-padding">
          <p class="control">
           <span class="select is-fullwidth">
            <select v-model="dateMode">
              <option value="single">Single</option>
              <option value="range">Range</option>
              <option value="multiple">Multiple</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </span>
          </p>
        </div>
        <div
            class="column is-one-quarter-mobile is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd no-padding">
          <p class="control">
            <button class="button is-success is-fullwidth" v-on:click="setDateData">
            <span class="icon is-small">
              <font-awesome-icon v-bind:icon="['fas', 'check']"/>
            </span>
              <span>Go</span>
            </button>
          </p>
        </div>
        <div
            class="column is-one-quarter-mobile is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd no-padding">
          <p class="control">
            <button class="button is-danger is-outlined is-fullwidth" v-on:click="clearDateData">
              <span>Clear</span>
              <span class="icon is-small">
              <font-awesome-icon v-bind:icon="['fas', 'times']"/>
            </span>
            </button>
          </p>
        </div>
      </div>
      <div class="columns no-padding no-margin is-centered">
        <div class="column is-full is-centered no-padding">
          <div class="field has-addons has-addons-centered">
            <p class="control"
               v-bind:class="{ 'is-expanded' : isExpanded }">
              <flatPickr
                  v-model.trim="inputValue"
                  v-bind:config="configDatePicker.chartConfig"
                  class="input is-primary auto-margin"
                  v-bind:placeholder="complexPlaceholder"
                  name="rangeDates"
                  v-bind:key="configDatePicker.timeStamp"
              >
              </flatPickr>
            </p>
          </div>
        </div>
      </div>

    </div>
    <div v-if="dateFieldType==='dashboard'">
      <div class="columns no-padding no-margin is-centered">
        <div class="column is-full is-centered no-padding is-hidden-touch">
          <div class="field has-addons has-addons-centered">
            <div class="control">
           <span class="select is-fullwidth is-small">
            <select v-model="dateMode">
              <option value="single">Single</option>
              <option value="range">Range</option>
              <option value="multiple">Multiple</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </span>
            </div>
            <div class="control"
                 v-bind:class="{ 'is-expanded' : isExpanded }">
              <flatPickr
                  v-model.trim="inputValue"
                  v-bind:config="configDatePicker.chartConfig"
                  class="input is-primary auto-margin is-small"
                  v-bind:placeholder="complexPlaceholder"
                  name="rangeDates"
                  v-bind:key="configDatePicker.timeStamp"
              >
              </flatPickr>
            </div>
            <div class="control">
              <button class="button is-success is-fullwidth is-small" v-on:click="setDateData">
            <span class="icon is-small">
              <font-awesome-icon v-bind:icon="['fas', 'check']"/>
            </span>
                <span>Go</span>
              </button>
            </div>
            <div class="control">
              <button class="button is-danger is-outlined is-fullwidth is-small" v-on:click="clearDateData">
                <span>Clear</span>
                <span class="icon is-small">
              <font-awesome-icon v-bind:icon="['fas', 'times']"/>
            </span>
              </button>
            </div>
          </div>
        </div>
        <div class="column is-full is-centered no-padding is-hidden-desktop">
          <div class="field">
            <div class="control is-expanded">
           <span class="select is-fullwidth is-small">
            <select v-model="dateMode">
              <option value="single">Single</option>
              <option value="range">Range</option>
              <option value="multiple">Multiple</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </span>
            </div>
          </div>
          <div class="field">
            <div class="control"
                 v-bind:class="{ 'is-expanded' : isExpanded }">
              <flatPickr
                  v-model.trim="inputValue"
                  v-bind:config="configDatePicker.chartConfig"
                  class="input is-primary auto-margin is-small"
                  v-bind:placeholder="complexPlaceholder"
                  name="rangeDates"
                  v-bind:key="configDatePicker.timeStamp"
              >
              </flatPickr>
            </div>
          </div>
          <div class="field is-grouped is-grouped-centered">
            <div class="control">
              <button class="button is-success is-fullwidth is-small" v-on:click="setDateData">
            <span class="icon is-small">
              <font-awesome-icon v-bind:icon="['fas', 'check']"/>
            </span>
                <span>Go</span>
              </button>
            </div>
            <div class="control">
              <button class="button is-danger is-outlined is-fullwidth is-small" v-on:click="clearDateData">
                <span>Clear</span>
                <span class="icon is-small">
              <font-awesome-icon v-bind:icon="['fas', 'times']"/>
            </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../../api/mixins/formFunctions";
import flatPickr from 'vue-flatpickr-component';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect.js';
import 'flatpickr/dist/plugins/monthSelect/style.css';

export default {
  name: "dateInput",
  data() {
    let vm = this,
        dataObject = {},
        maxDateValue = '',
        maxDateValueParts,
        maxDateValueObj = {};

    // console.log(`maxDate: ${vm.inputData.maxDate}`);

    if (vm.$_.trim(vm.inputData.maxDate) !== '') {
      if (vm.$_.trim(vm.inputData.maxDate) === 'today') {
        maxDateValue = 'today';
      } else {
        maxDateValueParts = vm.$_.split(vm.inputData.maxDate, '|');
        // plus|{months:12}
        console.log(maxDateValueParts);
        console.log(maxDateValueParts[0]);
        console.log(maxDateValueParts[1]);
        console.log(maxDateValueParts[2]);
        maxDateValueObj[maxDateValueParts[1]] = maxDateValueParts[2];
        console.log(maxDateValueObj);
        switch (maxDateValueParts[0]) {
          case 'plus':
            maxDateValue = vm.$DateTime.local().setZone("UTC").plus(maxDateValueObj).toISO();
            break;
          case 'minus':
            maxDateValue = vm.$DateTime.local().setZone("UTC").minus(maxDateValueObj).toISO();
            break;
          default:
            console.log(`Unknown operation: ${maxDateValueParts[0]}`)
            break;
        }
      }

    }
    // console.log(maxDateValue);
    if (vm.dateFieldType === 'basic') {
      dataObject = {
        inputValue: vm.inputData.initial,
        inputName: vm.inputData.elementName,
        inputType: vm.inputData.type,
        placeholderText: vm.inputData.placeholderText,
        dateInputInvalidText: vm.inputData.invalidText,
        inputIcon: vm.inputData.icon,
        inputIconCategory: vm.inputData.iconCategory,
        initialValue: vm.inputData.initial,
        isMandatory: vm.inputData.mandatory,
        validationRules: vm.inputData.validationRules,
        labelText: vm.inputData.labelText,
        disabledInput: vm.inputData.disabled,
        mustBeWordInputInvalidText: '',
        mustBeEmailInputInvalidText: '',
        minValueInputInvalidText: '',
        dateTimePickerConfig: {
          maxDate: maxDateValue,
          minDate: vm.inputData.minDate,
          inline: vm.inputData.inlineDate,
          wrap: true,
          allowInput: true,
          position: "auto center",
          dateFormat: vm.inputData.dateFormat,
          enableTime: vm.inputData.enableTime,
          plugins: [
            new confirmDatePlugin({
              confirmText: "OK ",
              showAlways: vm.inputData.showConfirmDate,
              theme: "light"
            })
          ]
        }
      }
    }
    if (vm.dateFieldType === 'complex') {
      dataObject = {
        dateMode: 'week',
        inputValue: '',
        inputName: vm.inputData.elementName,
        dateData: '',
        complexPlaceholder: 'Select date Range'
      }
    }
    if (vm.dateFieldType === 'dashboard') {
      dataObject = {
        dateMode: 'week',
        inputValue: '',
        inputName: vm.inputData.elementName,
        dateData: '',
        complexPlaceholder: 'Select date Range'
      }
    }
    return dataObject;
  },
  methods: {
    clearDateData: function () {
      let vm = this;
      vm.inputValue = '';
      vm.dateMode = 'week';
      vm.dateData = '';
    },
    setDateData: function () {
      let vm = this;
      vm.$emit('complexDateSet', {
        inputValue: vm.inputValue,
        dateData: vm.dateData,
        dateMode: vm.dateMode,
      });
    }
  },
  computed: {
    configDatePicker: function () {
      let vm = this,
          timeStamp = vm.$moment().valueOf(),
          chartConfig = {},
          returnObj = {};
      vm.dateData = '';
      switch (vm.dateMode) {
        case 'single':
          chartConfig = {
            inline: (typeof vm.inputData.inlineElement !== 'undefined') ? vm.inputData.inlineElement : true,
            maxDate: "today",
            position: "auto center",
            dateFormat: 'Y-m-d',
            enableTime: false,
            weekNumbers: true,
            mode: vm.dateMode,
          };
          break;
        case 'range':
          chartConfig = {
            inline: (typeof vm.inputData.inlineElement !== 'undefined') ? vm.inputData.inlineElement : true,
            maxDate: "today",
            position: "auto center",
            dateFormat: 'Y-m-d',
            enableTime: false,
            weekNumbers: true,
            mode: vm.dateMode,
          };
          break;
        case 'multiple':
          chartConfig = {
            inline: (typeof vm.inputData.inlineElement !== 'undefined') ? vm.inputData.inlineElement : true,
            maxDate: "today",
            position: "auto center",
            dateFormat: 'Y-m-d',
            enableTime: false,
            weekNumbers: true,
            mode: vm.dateMode,
          };
          break;
        case 'week':
          chartConfig = {
            inline: (typeof vm.inputData.inlineElement !== 'undefined') ? vm.inputData.inlineElement : true,
            maxDate: "today",
            position: "auto center",
            // dateFormat: 'Y-m-d',
            // enableTime: false,
            weekNumbers: true,
            // mode: 'single',
            locale: {
              firstDayOfWeek: 1 //start on Monday
            },
            plugins: [new weekSelectPlugin({})],
            onChange: [
              function () {
                let vmb = this,
                    weekSelection = '';
                if (vmb.selectedDates[0]) {
                  let momentDate = vm.$moment(vmb.selectedDates[0]),
                      momentDateIsoweek = momentDate.isoWeek(),
                      momentDateIsoWeekYear = momentDate.isoWeekYear();
                  weekSelection = `week ${momentDateIsoweek} of ${momentDateIsoWeekYear}`;
                }
                vm.dateData = weekSelection;
                // console.log(weekSelection);
              }
            ]
          };
          break;
        case 'month':
          chartConfig = {
            inline: (typeof vm.inputData.inlineElement !== 'undefined') ? vm.inputData.inlineElement : true,
            // maxDate: "today",
            position: "auto center",
            // //dateFormat: 'Y-m-d',
            // enableTime: false,
            // //weekNumbers: true,
            // mode: 'single',
            plugins: [
              new monthSelectPlugin({
                shorthand: true, //defaults to false
                // dateFormat: "m.y", //defaults to "F Y"
                // altFormat: "F Y", //defaults to "F Y"
                theme: "light" // defaults to "light"
              })
            ]
          };
          break;
      }

      returnObj = {
        chartConfig: chartConfig,
        timeStamp: timeStamp
      }
      return returnObj;
    }
  },
  props: ['inputData', 'dateFieldType', 'isExpanded'],
  watch: {
    inputData: {
      handler: function () {
        let vm = this;
        vm.inputValue = vm.inputData.initial;
        vm.inputName = vm.inputData.elementName;
        vm.inputType = vm.inputData.type;
        vm.placeholderText = vm.inputData.placeholderText;
        vm.dateInputInvalidText = vm.inputData.invalidText;
        vm.inputIcon = vm.inputData.icon;
        vm.isMandatory = vm.inputData.mandatory;
        vm.validationRules = vm.inputData.validationRules;
        vm.labelText = vm.inputData.labelText;
        vm.disabledInput = vm.inputData.disabled;
        vm.dateTimePickerConfig = {
          maxDate: vm.inputData.maxDate,
          wrap: true,
          allowInput: true,
          dateFormat: vm.inputData.dateFormat,
          enableTime: vm.inputData.enableTime,
          plugins: [
            new confirmDatePlugin({
              confirmText: "OK ",
              showAlways: true,
              theme: "light"
            })
          ]
        }
      },
      deep: true
    }
  },
  mixins: [formFunctions],
  components: {
    flatPickr
  }
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.auto-margin {
  margin: auto !important;
}
</style>



