<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered" v-if="!formLoading">
          <div class="column is-full">
            <div class="notification is-success padded-3-px">
              <ul>
                <li>{{ formFieldsData.smsRate.binds.labelText }} {{ formFieldsData.smsRate.binds.initial }}</li>
                <li>{{ formFieldsData.vatPercentage.binds.labelText }}
                  {{ formFieldsData.vatPercentage.binds.initial }}%
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.payingAmount.binds
                  v-on:dataEvent="dataEventTracked"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.payingNumber.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.payerEmail.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="table-container">
              <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                <tr class="is-selected">
                  <th>SMS Payment</th>
                  <th>SMS Units</th>
                  <th>VAT Amount</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>KES {{ smsCreditWorth }}</td>
                  <td>{{ smsUnits }} @ KES {{ formFieldsData.smsRate.binds.initial }}</td>
                  <td>{{ taxAmount }}</td>
                  <th>KES {{totalPayment }}</th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              On clicking "<strong>Pay</strong>", you will receive a prompt for Mpesa payment on the number you have
              provided above. <strong>Enter
              your Mpesa PIN on the phone</strong> to complete the payment.<br>
              Payment Status will be communicated to you through Email and SMS.<br>
              <strong>Please unlock your phone and wait for the Mpesa PIN prompt to pop up right after clicking "Pay"</strong>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";

export default {
  name: "contactsForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField
  },
  data() {
    return {
      smsCreditWorth: 0,
      smsUnits: 0,
      taxAmount: 0,
      totalPayment: 0
    }
  },
  props: ['formsData', 'formActionButton'],
  methods: {},
  watch: {
    inputValue: {
      handler: function () {
        let vm = this;
        console.error(vm.inputValue);
      },
      deep: true
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
