<template>
  <component
      v-bind="formProperties"
      v-bind:is="formSelect"
      v-on:dataModal="showDataModal"
      v-on:closeModal="closeDataModal"
      v-on:refreshTable="refreshModalTable"
      v-bind:dataSelected="dataSelected"
  ></component>
</template>

<script>
import loginCredentialsForm from '../forms/loginCredentialsForm.vue';
import forgotCredentialsForm from '../forms/forgotCredentialsForm.vue';
import resetCredentialsForm from '../forms/resetCredentialsForm.vue';
import activateAccountForm from '../forms/activateAccountForm.vue';
import SubmitOTPForm from '../forms/SubmitOTPForm.vue';
import contactForm from '../forms/contactUsForm.vue';
import composesmsForm from '../forms/composesmsForm.vue';
import composemailForm from '../forms/composeemailForm.vue';


export default {
  name: "sectionsForm",
  data() {
    return {}
  },
  props: ['formsData', 'dataSelected'],
  components: {
    loginCredentials: loginCredentialsForm,
    forgotCredentials: forgotCredentialsForm,
    resetCredentials: resetCredentialsForm,
    activateAccount: activateAccountForm,
    SubmitOTP: SubmitOTPForm,
    composesms: composesmsForm,
    composeemail: composemailForm,
    contact: contactForm
  },
  computed: {
    formSelect: function () {
      try {
        let vm = this;
        return vm.formsData.subsection.formname;
      } catch (error) {
        return '';
      }
    },
    formProperties: function () {
      try {
        let vm = this;
        return {formAction: vm.formsData.subsection.action};
      } catch (error) {
        return '';
      }
    }
  },
  methods: {
    showDataModal: function (emittedData) {
      let vm = this;
      vm.$emit('dataModal', emittedData);
    },
    closeDataModal: function (emittedData) {
      let vm = this;
      vm.$emit('closeModal', emittedData);
    },
    refreshModalTable: function (emittedData) {
      let vm = this;
      vm.$emit('refreshTable', emittedData);
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
    });
  },
  watch: {}
}
</script>

<style scoped>

</style>