<template>
	<div>
		<div class="buttons is-centered">
			<a class="button is-info is-fullwidth" v-on:click="$emit('download-item')" v-if="downloadAble">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'file-download']"/>
					</span>
				<span>Download</span>
			</a>
			<a class="button is-primary is-fullwidth" v-on:click="$emit('import-item')" v-if="importAble">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'file-import']"/>
					</span>
				<span>Import</span>
			</a>
			<a class="button is-danger is-fullwidth" v-on:click="$emit('delete-item')" v-if="deleteAble">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'trash-alt']"/>
					</span>
				<span>Delete</span>
			</a>
		</div>
	</div>
</template>

<script>
    export default {
        name: "listItemButtons",
        data() {
            return {}
        },
        props: ['downloadAble','importAble','deleteAble'],
        components: {},
        methods: {}
    }
</script>

<style scoped>

</style>