<template>
  <div>
    <p class="element-label" v-html="labelText"></p>
    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.inputValue.$invalid }">
            <textarea
                class="textarea is-primary is-rounded text-area-with-icon"
                v-bind:placeholder="placeholderText"
                v-model.trim="inputValue"
                v-on:input="textareaResize"
                ref="textArea"
                rows="3"
                v-bind:class="{ 'is-danger' : $v.inputValue.$invalid }"></textarea>
      <span class="icon is-small is-left">
                <font-awesome-icon v-bind:icon="[inputIconCategory, inputIcon]"/>
            </span>
      <span class="icon is-small is-right redColored" v-if="$v.inputValue.$invalid">
				<font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
			</span>
    </p>
    <p class="help is-danger bigger-text bolder-text" v-if="$v.inputValue.$invalid">{{
        mandatoryInputInvalidText
      }}</p>
  </div>
</template>

<script>
import formFunctions from "./../../../../api/mixins/formFunctions";

export default {
  name: "textAreaField",
  mixins: [formFunctions],
  data() {
    let vm = this;
    return {
      inputValue: vm.inputData.initial,
      inputName: vm.inputData.elementName,
      inputType: vm.inputData.type,
      placeholderText: vm.inputData.placeholderText,
      mandatoryInputInvalidText: vm.inputData.invalidText,
      inputIconCategory: vm.inputData.iconCategory,
      inputIcon: vm.inputData.icon,
      isMandatory: vm.inputData.mandatory,
      validationRules: vm.inputData.validationRules,
      labelText: vm.inputData.labelText,
      disabledInput: vm.inputData.disabled,
      mustBeWordInputInvalidText: '',
      mustBeEmailInputInvalidText: '',
      minValueInputInvalidText: ''
    }
  },
  props: ['inputData'],
  watch: {
    inputData: {
      handler: function () {
        let vm = this;
        vm.inputValue = vm.inputData.initial;
        vm.inputName = vm.inputData.elementName;
        vm.inputType = vm.inputData.type;
        vm.placeholderText = vm.inputData.placeholderText;
        vm.mandatoryInputInvalidText = vm.inputData.invalidText;
        vm.inputIcon = vm.inputData.icon;
        vm.isMandatory = vm.inputData.mandatory;
        vm.validationRules = vm.inputData.validationRules;
        vm.labelText = vm.inputData.labelText;
        vm.disabledInput = vm.inputData.disabled;
      },
      deep: true
    }
  },
  methods: {
    textareaResize: function () {
      this.$refs.textArea.style.minHeight = this.$refs.textArea.scrollHeight + 'px';
    }
  }
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}

.text-area-with-icon {
  padding-left: 30px !important;
}
</style>