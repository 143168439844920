import { render, staticRenderFns } from "./sectionsForm.vue?vue&type=template&id=44997e5b&scoped=true&"
import script from "./sectionsForm.vue?vue&type=script&lang=js&"
export * from "./sectionsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44997e5b",
  null
  
)

export default component.exports