<template>
  <div>
    <vs-divider position="center">
      Action
    </vs-divider>
    <div class="buttons is-centered">
      <span class="button is-normal" v-on:click="$emit('edit-record')" v-if="editAble">Edit</span>
      <span class="button is-danger" v-on:click="$emit('delete-record')" v-if="deleteAble">Delete</span>
      <span class="button is-success" v-on:click="$emit('approve-record')" v-if="approveAble">Approve</span>
      <span class="button is-danger" v-on:click="$emit('reject-record')" v-if="rejectAble">Reject</span>
      <span class="button is-info" v-on:click="$emit('details-record')" v-if="detailsAble">Details</span>
      <span class="button is-info" v-on:click="$emit('cancel-record')" v-if="cancelAble">Cancel</span>
      <span class="button is-success" v-on:click="$emit('use-record')" v-if="useAble">Use</span>
      <span class="button is-warning" v-on:click="$emit('update-record')" v-if="updateAble">Update</span>
      <span class="button is-warning" v-on:click="$emit('toggle-record')" v-if="toggleAble">switch</span>
      <span class="button is-warning" v-on:click="$emit('startstop-record')" v-if="startstopAble">Start/Stop</span>
      <span class="button is-info" v-on:click="$emit('showmapping-record')" v-if="shawmappingAble">Mapping</span>
      <span class="button is-primary" v-on:click="$emit('send-record')" v-if="sendAble">Send</span>
      <span class="button is-link" v-on:click="$emit('view-record')" v-if="viewAble">View</span>
      <span class="button is-info" v-on:click="$emit('void-record')" v-if="voidAble">Void</span>
      <span class="button is-warning" v-on:click="$emit('view-as')" v-if="viewasAble">View As</span>
      <span class="button is-warning" v-on:click="$emit('load-record')" v-if="loadAble">load</span>
      <span class="button is-info" v-on:click="$emit('view-messages')" v-if="hasMessagesContent">View Communication</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "accordionButtons",
  data() {
    return {}
  },
  props: ['editAble', 'deleteAble', 'approveAble', 'rejectAble', 'detailsAble', 'cancelAble', 'useAble', 'updateAble', 'sendAble', 'viewAble', 'voidAble', 'toggleAble', 'viewasAble', 'startstopAble', 'shawmappingAble', 'loadAble', 'hasMessagesContent'],
  components: {},
  methods: {}
}
</script>

<style scoped>

</style>