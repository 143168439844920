<template>
	<sectionCollapseAccordion
			v-if="tableStructureReady"
			v-bind:subsection="tableData.subsection"
			v-bind:subkey="tableData.subkey"
			v-bind:myRole="tableData.myRole"
			v-bind:refreshMe="tableData.refreshMe"
	/>
</template>

<script>

    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import sectionCollapseAccordion from './collapseAccordion.vue';

    export default {
        name: "modalsTableData",
        mixins: [dataFetcher],
        data() {
            return {
                tableStructureReady:false
            }
        },
        props: ['datasData'],
        components: {
            sectionCollapseAccordion,
        },
        computed: {},
        methods: {},
        watch: {},
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.loadModalTableData();
            });
        }
    }
</script>

<style scoped>
</style>