<template>
    <div class="columns is-centered">
        <div class="column is-full" v-if="!elementsLoaded">
            {{ formLoadingText }}
        </div>
        <div class="column is-full" v-if="displayingForm">
            <form>
                <div class="columns is-centered" v-if="formLoading">
                    <div class="column is-full">
                        <div class="notification is-info padded-3-px">
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.name.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.mobile.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.email.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.idnum.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <dateField
                                    v-bind:inputData=formFieldsData.birthdayDate.binds
                                    v-bind:dateFieldType="'basic'"
                                    v-bind:isExpanded="false"
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered is-vcentered">
                    <div class="column">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.contactGender.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered is-vcentered">
                    <div class="column">
                        <div class="field">
                            <checkRadioGroup
                                    v-bind:inputData=formFieldsData.contactGroups.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-full" v-if="notDisplayingForm">
            <deletionForm
                    v-bind:formFieldsVal="formFieldsData.formActions.delete"
                    v-bind:deletionAction="formsData.data.action"
                    v-on:dataEvent="dataEvent"
            />
        </div>
    </div>
</template>

<script>

    import formFunctions from "./../../../api/mixins/formFunctions";
    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import inputField from "./elements/inputField";
    import checkRadioField from "./elements/checkRadioField";
    import dateField from "./elements/dateField";
    import checkRadioGroup from "./elements/checkRadioGroup";
    import deletionForm from "./elements/deletionForm";

    export default {
        name: "contactsForm",
        mixins: [dataFetcher, formFunctions],
        components: {
            inputField,
            dateField,
            checkRadioField,
            checkRadioGroup,
            deletionForm
        },
        data() {
            return {}
        },
        props: ['formsData', 'formActionButton'],
        methods: {},
        watch: {},
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.getThisFormData();
            });
        }
    }
</script>

<style scoped>
    .padded-3-px {
        padding: 3px !important;
    }
</style>
