<template>
    <form>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left">
                        <input class="input is-primary is-rounded" type="text" placeholder="Name">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'user']"/>
                        </span>
                    </p>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left">
                        <input class="input is-primary is-rounded" type="text" placeholder="Mobile Number">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'mobile-alt']"/>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <div class="field">
                    <p class="control has-icons-left">
                        <input class="input is-primary is-rounded" type="email" placeholder="Email">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'envelope']"/>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <div class="field">
                    <p class="control has-icons-left">
                        <input class="input is-primary is-rounded" type="text" placeholder="Subject">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'pen-nib']"/>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <div class="field">
                    <div class="control">
                        <textarea class="textarea is-primary is-rounded" placeholder="Message"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-two-thirds">
                <div class="columns is-centered is-mobile">
                    <div class="column is-narrow">
                        <div class="field">
                            <a id="contact_us_btn" href="#" class="button form-button-submit">Send Message</a>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <div class="field">
                            <a id="contact_us_clear" href="#" class="button button-alt form-button-reset">Clear Form</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
	export default {
		name: "contactUsForm"
	}
</script>

<style scoped>

</style>