<template>
  <div class="container email-editor-component" id="emailcompose-subsection-component" v-bind:key="stepWizardKey">
    <form-wizard
        v-on:onNextStep="nextStep"
        v-on:onPreviousStep="previousStep"
        v-on:onComplete="submitForm"
        class="form-wizard-class">
      <tab-content title="Compose Email" v-bind:selected="true">
        <EmailEditor
            ref="emailEditor"
            v-on:load="editorLoaded"
            v-on:ready="editorReady"
            v-bind:tools="toolsInit"
            v-bind:editorId="'emailEditor'"
            class="email-composer-editor"
        />
        <div class="columns is-mobile is-multiline is-centered pad-top-20" v-if="composerAutoSave">
          <div class="column is-full">
            <div class="field">
              Changed at {{ composerAutoSave }}
            </div>
          </div>
        </div>
        <div class="columns is-mobile is-multiline pad-top-20">
          <div class="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <div class="field-label"></div>
              <div class="field-body is-pulled-right">
                <div class="field is-expanded">
                  <div class="field has-addons">

                    <p class="control">
<!--                      <a class="button is-info" v-on:click="saveEmail" v-if="updateSavedDesign==='update'">Update</a>-->
<!--                      <a class="button is-info" v-on:click="saveEmail" v-if="updateSavedDesign==='save'">Save</a>-->
                      <a class="button is-info" v-on:click="saveEmail">Save</a>
                    </p>
                    <p class="control">
                      <checkRadioField
                          v-bind:key="editorChecksBindsKey"
                          v-bind:inputData=editorChecksBinds
                          v-on:dataEvent="editorChecksSelection"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Transition name="slide-fade">
            <div class="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd"
                 v-if="saveEditorType.forTemplate">
              <div class="field">
                <inputField
                    v-bind:key="emailDesignNameBindsKey"
                    v-bind:inputData=emailDesignNameBinds
                    v-on:dataEvent="inputFieldDataEntered"
                />
              </div>
            </div>
          </Transition>
        </div>
        <div class="columns is-centered is-mobile is-multiline" v-if="!designNameEntered && saveEditorType.forTemplate">
          <div class="column is-full" v-if="!designNameEntered">
            <div class="field">
              <p class="help is-danger bigger-text bolder-text">
                Please enter the template name
              </p>
            </div>
          </div>
        </div>
        <div class="columns is-centered" v-if="hasError('emailHtml')">
          <div class="column is-full">
            <div class="field">
              <div>
                <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.emailHtml.required">
                  Please save before proceeding.
                </p>
              </div>
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="Email Properties">
        <div class="email-composer-tabs-editor email-composer-editor-padded">
          <div class="columns is-centered is-vcentered">
            <form class="container">
              <div class="columns is-centered is-mobile is-multiline is-vcentered">
                <div
                    class="column is-full">
                  <div class="field">
                    <inputField
                        v-bind:key="emailSubjectBindsKey"
                        v-bind:inputData=emailSubjectBinds
                        v-on:dataEvent="inputFieldDataEntered"
                    />
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-mobile is-multiline is-vcentered">
                <div
                    class="column is-full-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                  <div class="field">
                    <p class="control">
                      <a class="button is-fullwidth is-primary" v-on:click="getLists">
											<span class="icon is-small">
												<font-awesome-icon v-bind:icon="['fas', 'users']"/>
											</span>
                        <span>To</span>
                      </a>
                    </p>
                  </div>
                </div>
                <div
                    class="column is-full-mobile is-half-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                  <div class="field">
                    <p class="control">
                      {{ setReceiverGroups }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-mobile is-multiline is-vcentered">
                <div
                    class="column is-full">
                  <div class="field">
                    <selectField
                        v-bind:key="emailFromBindsKey"
                        v-bind:inputData=emailFromBinds
                        v-on:dataEvent="inputFieldDataEntered"
                    />
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-mobile is-multiline is-vcentered">
                <div
                    class="column is-full">
                  <div class="field">
                    <selectField
                        v-bind:key="emailReplyToBindsKey"
                        v-bind:inputData=emailReplyToBinds
                        v-on:dataEvent="inputFieldDataEntered"
                    />
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-vcentered">
                <div
                    class="column is-full">
                  <div class="field">
                    <selectField
                        v-bind:key="emailCategoryBindsKey"
                        v-bind:inputData=emailCategoryBinds
                        v-on:dataEvent="inputFieldDataEntered"
                    />
                  </div>
                </div>
              </div>
              <div class="columns is-mobile is-multiline is-vcentered">
                <div class="column">
                  <input id="sendnow" name="sendtime" type="radio" v-model="formData.sendTime"
                         value="now"/>
                  <label class="choice-label" for="sendnow">Now</label>
                </div>
                <div class="column">
                  <input id="sendlater" name="sendtime" type="radio" v-model="formData.sendTime"
                         value="later"/>
                  <label class="choice-label" for="sendlater">Later</label>
                </div>
                <div class="column is-full"
                     v-show="dateTimePickerVisible">
                  <flat-pickr
                      class="input is-primary"
                      name="laterdatetime"
                      placeholder="Select date and time"
                      v-bind:config="configDatePicker"
                      v-model="formData.laterDateTime"
                  >
                  </flat-pickr>
                  <p class="help is-danger bigger-text" v-if="hasError('laterDateTime')">
                    Please specify a date and time</p>
                </div>
              </div>
              <div class="columns is-centered is-vcentered">
                <div class="column is-full">
                  <div class="field">
                    <textAreaField
                        v-bind:key="anyOtherInformationBindsKey"
                        v-bind:inputData=anyOtherInformationBinds
                        v-on:dataEvent="inputFieldDataEntered"
                    />
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-vcentered">
                <div class="column is-full">
                  <div class="field">
                    <tagsInputField
                        v-bind:key="emailTagsBindsKey"
                        v-bind:inputData=emailTagsBinds
                        v-on:dataEvent="inputFieldDataEntered"
                    />
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-vcentered">
                <div class="column is-full">
                  <div class="field">
                    <p class="element-label has-text-weight-bold">Attachments</p>
                    <v-uploader
                        v-on:done="uploadDone"
                        v-bind:multiple="true"
                        v-bind:language="'en'"
                        v-bind:button-text="'Select File'"
                        v-bind:file-type-exts="'*'"
                        v-bind:upload-file-obj-name="'datafiles'"
                        v-bind:file-size-limit="'10MB'"
                        v-bind:upload-headers="uploadFileHeader"
                        v-bind:upload-file-url="uploadFileUrlWithAuthorization"
                        v-bind:delete-file-url="deleteFileUrlWithAuthorization"
                    ></v-uploader>
                  </div>
                </div>
              </div>
              <div class="columns is-centered is-vcentered is-multiline is-mobile" v-if="formData.attachments.length>0">
                <div v-for="(attachment, key) in formData.attachments"
                     class="column is-full"
                     v-bind:key="key">
                  <div class="buttons has-addons">
                    <a class="button"
                       v-on:click="downloadAttachment(attachment.fileName,attachment.fileHumanName)">{{ attachment.fileHumanName }}</a>
                    <a class="button" v-on:click="removeAttachment(attachment.fileQquuid)">Remove</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="columns is-centered"
               v-if="hasError('emailSubject') || hasError('emailTo') || hasError('emailFrom') || hasError('emailReplyTo') || hasError('emailCategory') || hasError('sendTime') || hasError('laterDateTime')">
            <div class="column is-full">
              <div class="field">
                <div>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.emailSubject.required">
                    Please provide the email subject
                  </p>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.emailTo.required">
                    Please provide the email receiver(s)
                  </p>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.emailFrom.required">
                    Please provide the email source address
                  </p>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.emailReplyTo.required">
                    Please provide the email reply-to address
                  </p>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.emailCategory.required">
                    Please provide the email category
                  </p>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.sendTime.required">
                    Please choose the send time
                  </p>
                  <p class="help is-danger bigger-text bolder-text" v-if="!$v.formData.laterDateTime.required">
                    Please choose the sending date and time
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="Preview">
        <div class="email-composer-tabs-editor">
          <div class="columns is-vcentered">
            <div class="column is-one-fifth is-size-4 has-text-right has-text-weight-bold">Subject:</div>
            <div class="column is-four-fifths is-size-5 has-text-left" v-html="formData.emailSubject"></div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-one-fifth is-size-4 has-text-right has-text-weight-bold">To:</div>
            <div class="column is-four-fifths is-size-5 has-text-left" v-html="formData.emailTo"></div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-one-fifth is-size-4 has-text-right has-text-weight-bold">From:</div>
            <div class="column is-four-fifths is-size-5 has-text-left" v-html="formData.emailFrom"></div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-one-fifth is-size-4 has-text-right has-text-weight-bold">Reply To:</div>
            <div class="column is-four-fifths is-size-5 has-text-left" v-html="formData.emailReplyTo"></div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-full" v-html="formData.emailHtml"></div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-full is-size-4 has-text-weight-bold">
              Attachments
            </div>
          </div>
          <div class="columns is-centered is-vcentered is-multiline is-mobile" v-if="formData.attachments.length>0">
            <div v-for="(attachment, key) in formData.attachments"
                 class="column is-full"
                 v-bind:key="key">
              <div class="buttons has-addons">
                <a class="button"
                   v-on:click="downloadAttachment(attachment.fileName,attachment.fileHumanName)">{{ attachment.fileHumanName }}</a>
                <a class="button" v-on:click="removeAttachment(attachment.fileQquuid)">Remove</a>
              </div>
            </div>
          </div>

        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {EmailEditor} from 'vue-email-editor';
import {ValidationHelper} from "vue-step-wizard";
import {required, requiredIf} from "vuelidate/lib/validators";
import api from "./../../../api/api.js";
import checkRadioField from "./elements/checkRadioField";
import selectField from "./elements/selectField.vue";
import textAreaField from "./elements/textAreaField.vue";
import tagsInputField from "./elements/tagsInputField.vue";
import inputField from "./elements/inputField.vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import logoUrl from "@/tumatext/themes/chadash/assets/img/Logo Full.png";

export default {
  name: 'composeEmail',
  mixins: [ValidationHelper],
  data() {
    let vm = this;
    return {
      draftsRequestId: 1,
      toolsInit: {
        image: {
          properties: {
            src: {
              value: {
                url: `https://${window.location.hostname}/apiv3/placeholders/emailImage`,
                width: 500,
                height: 100
              }
            }
          }
        }
      },
      composerLoaded: false,
      composerAutoSave: '',
      composerSavedDesign: '',
      dataSelectedInternal: vm.dataSelected,
      receiverArrJoined: '',
      formData: {
        emailDraftId: '',
        emailHtml: '',
        laterDateTime: '',
        sendTime: '',
        emailDesignName: '',
        emailSubject: '',
        emailTo: '',
        emailFrom: '',
        emailReplyTo: '',
        emailCategory: '',
        anyOtherInformation: '',
        emailTags: [],
        attachments: []
      },
      configDatePicker: {
        inline: true,
        minDate: "today",
        dateFormat: 'Y-m-d at H:i',
        time_24hr: true,
        enableTime: true
      },
      stepTime: '',

      stepWizardKey: `stepwizard_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      editorChecksBindsKey: `editorChecks_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      editorChecksBinds: {
        "element": "defaultcheckradio",
        "elementKey": "",
        "elementName": "saveTypes",
        "initial": {},
        "pickItemsArray": [
          {
            "type": "checkbox",
            "name": "forTemplate",
            "id": "forTemplate",
            "model": "forTemplate",
            "value": true,
            "label": "Template"
          }
        ],
        "labelText": "",
        "placeholderText": "",
        "invalidText": "Please select save type",
        "iconCategory": "fas",
        "icon": "check",
        "type": "checkbox",
        "mandatory": "no",
        "validationRules": "",
        "disabled": false,
      },

      emailDesignNameBindsKey: `emailDesignName_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      emailDesignNameBinds: {
        "element": "input",
        "elementKey": "",
        "elementName": "emailDesignName",
        "initial": '', //vm.formData.emailDesignName
        "labelText": "",
        "placeholderText": "Template Name",
        "invalidText": "Please enter the template name",
        "iconCategory": "fas",
        "icon": "hashtag",
        "type": "text",
        "mandatory": "no",
        "validationRules": "",
        "disabled": false
      },

      emailSubjectBindsKey: `emailSubject_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      emailSubjectBinds: {
        "element": "input",
        "elementKey": "",
        "elementName": "emailSubject",
        "initial": '', //vm.formData.emailSubject,
        "labelText": "Email Subject",
        "placeholderText": "Subject",
        "invalidText": "Please enter the email subject",
        "iconCategory": "fas",
        "icon": "hashtag",
        "type": "text",
        "mandatory": "yes",
        "validationRules": "",
        "disabled": false
      },

      emailFromBindsKey: `emailFrom_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      emailFromBinds: {
        "element": "select",
        "elementKey": "",
        "elementName": "emailFrom",
        "initial": "",
        "labelText": "Email From",
        "placeholderText": "Email From",
        "invalidText": "Please select the email from",
        "iconCategory": "fas",
        "icon": "umbrella",
        "type": "datafetchingafresh",
        "showDataProvided": [],
        "dataJson": "get-email-from",
        "dataRequest": "composeemailfrom-subsection",
        "dataHeader": "From",
        "mandatory": "yes",
        "canCreateNew": false,
        "canSelectNone": false,
        "canSelectAll": false,
        "validationRules": "",
        "disabled": false
      },

      emailReplyToBindsKey: `emailReplyTo_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      emailReplyToBinds: {
        "element": "select",
        "elementKey": "",
        "elementName": "emailReplyTo",
        "initial": "",
        "labelText": "Reply To",
        "placeholderText": "Reply To",
        "invalidText": "Please select the reply to",
        "iconCategory": "fas",
        "icon": "umbrella",
        "type": "datafetchingafresh",
        "showDataProvided": [],
        "dataJson": "get-email-from",
        "dataRequest": "composeemailfrom-subsection",
        "dataHeader": "Reply To",
        "mandatory": "yes",
        "canCreateNew": false,
        "canSelectNone": false,
        "canSelectAll": false,
        "validationRules": "",
        "disabled": false
      },

      emailCategoryBindsKey: `emailCategory_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      emailCategoryBinds: {
        "element": "select",
        "elementKey": "",
        "elementName": "emailCategory",
        "initial": "",
        "labelText": "Email Category",
        "placeholderText": "Email Category",
        "invalidText": "Please select the email category",
        "iconCategory": "fas",
        "icon": "umbrella",
        "type": "datafetchingafresh",
        "showDataProvided": [],
        "dataJson": "get-email-categories",
        "dataRequest": "composeemailcategories-subsection",
        "dataHeader": "Category",
        "mandatory": "yes",
        "canCreateNew": false,
        "canSelectNone": false,
        "canSelectAll": false,
        "validationRules": "",
        "disabled": false
      },

      anyOtherInformationBindsKey: `anyOtherInformation_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      anyOtherInformationBinds: {
        "element": "textarea",
        "elementKey": "",
        "elementName": "anyOtherInformation",
        "initial": '', //vm.formData.anyOtherInformation,
        "labelText": "Any other information. (This is internal)",
        "placeholderText": "Any other information that should be noted about this email.",
        "invalidText": "Please enter any other information",
        "iconCategory": "fas",
        "icon": "signature",
        "type": "text",
        "mandatory": "no",
        "validationRules": "",
        "disabled": false
      },

      emailTagsBindsKey: `emailTags_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`,
      emailTagsBinds: {
        "element": "tagsinput",
        "elementKey": "",
        "elementName": "emailTags",
        "initial": [],
        "labelText": "Email Tags",
        "placeholderText": "Click here to add tags for opt-out page",
        "invalidText": "Please enter any the email tags",
        "iconCategory": "fas",
        "icon": "signature",
        "type": "text",
        "mandatory": "no",
        "validationRules": "",
        "disabled": false
      },

      saveEditorType: {forTemplate: false},
      emailEditorState: {},
      validationRules: [
        {
          emailHtml: {required},
          emailDesignName: {
            required: requiredIf(function () {
              return vm.saveEditorType.forTemplate
            })
          }
        },
        {
          emailSubject: {required},
          emailTo: {required},
          // receiverArrJoined: {required},
          emailFrom: {required},
          emailReplyTo: {required},
          emailCategory: {required},
          sendTime: {required},
          laterDateTime: {
            required: requiredIf(function () {
              return vm.$_.trim(vm.formData.sendTime) === 'later'
            })
          }
        }
      ],
      emailEditorDesign: {}
    }
  },
  props: ['formsData', 'formActionButton', 'dataSelected'],
  components: {
    inputField,
    textAreaField,
    selectField,
    EmailEditor,
    checkRadioField,
    flatPickr,
    tagsInputField
  },
  computed: {
    designNameEntered: function () {
      let vm = this;
      console.log(vm.formData.emailDesignName);
      return !(vm.$_.trim(vm.formData.emailDesignName) === '');
    },
    dateTimePickerVisible() {
      let vm = this;
      return vm.$_.trim(vm.formData.sendTime) === 'later';
    },
    setReceiverGroups: function () {
      let vm = this,
          dataFrom;
      console.log(vm.dataSelectedInternal);
      if ((typeof vm.dataSelectedInternal !== 'undefined') && (typeof vm.dataSelectedInternal.from !== 'undefined')) {
        dataFrom = vm.$_.trim(vm.dataSelectedInternal.from);
        switch (dataFrom) {
          case 'get-email-contact-groups-nocount':
            vm.formData.emailTo = vm.receiverArrJoined = vm.$_.join(vm.dataSelectedInternal.selection, ', ');
            break;
        }
      }
      return vm.receiverArrJoined;
    },
    uploadFileHeader: function () {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let headers = {
        authorization: authorizationToken,
        filesource: `https://${window.location.hostname}`
      };
      console.log(headers);
      return headers;
    },
    updateSavedDesign: function () {
      let vm = this;
      if (vm.$session.has('emailEditorDesignSaved') && vm.$session.has('emailEditorDesignAutosave')) {
        console.log(`composerAutoSave: ${vm.$DateTime.fromFormat(vm.composerAutoSave, 'yyyy-LL-dd HH:mm:ss').toMillis()}`);
        console.log(`composerSavedDesign: ${vm.$DateTime.fromFormat(vm.composerSavedDesign, 'yyyy-LL-dd HH:mm:ss').toMillis()}`);
        if (vm.$DateTime.fromFormat(vm.composerAutoSave, 'yyyy-LL-dd HH:mm:ss') > vm.$DateTime.fromFormat(vm.composerSavedDesign, 'yyyy-LL-dd HH:mm:ss')) {
          return 'update';
        } else {
          return '';
        }
      } else {
        return 'save';
      }
    },
    uploadFileUrlWithAuthorization: function () {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      console.log('XXX');
      return `${api.operation('addemailattachment').returnUrl()}?auth=${authorizationToken}&logo=${logoUrl}&filesource=https://${window.location.hostname}&emailId=${vm.formData.emailDraftId}`;
    },
    deleteFileUrlWithAuthorization: function () {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      console.log('XXX');
      return `${api.operation('deleteemailattachment').returnUrl()}?auth=${authorizationToken}&logo=${logoUrl}&filesource=https://${window.location.hostname}&emailId=${vm.formData.emailDraftId}`;
    }
  },
  methods: {
    getLists: function () {
      let vm = this;
      vm.$emit('dataModal', {
        dataHead: 'Email To',
        dataReq: 'get-email-contact-groups-nocount',
        from: 'composeemailform',
        type: 'checkbox',
        key: vm.$moment().valueOf(),
        items: {}
      });
    },
    // called when the editor is created
    editorLoaded() {
      console.log('editorLoaded');
      // Pass the template JSON here
      // this.$refs.emailEditor.editor.loadDesign({});
    },
    // called when the editor has finished loading
    editorReady() {
      let vm = this;
      console.log('editorReady');
      console.log('registerCallback for image');
      vm.composerLoaded = true;

      if (vm.$session.has('emailEditorDesign')) {
        vm.$refs.emailEditor.editor.loadDesign(vm.$session.get('emailEditorDesign'));
      } else {
        vm.$refs.emailEditor.editor.loadDesign(vm.emailEditorDesign);
      }

      vm.$refs.emailEditor.editor.registerCallback('image', function (file, done) {
        console.log(file);
        console.log(done);
        let data = new FormData();
        data.append('file', file.attachments[0]);
        let authorizationToken;
        if (vm.$session.has('jwt')) {
          authorizationToken = vm.$session.get('jwt');
        } else {
          authorizationToken = '';
        }
        let reqHeader = {
          headers: {
            authorization: authorizationToken,
            filesource: `https://${window.location.hostname}`
          }
        };

        api.operation('emailingfiles').post(data, reqHeader)
            .then(({data}) => {
              console.log(data);
              if (data.success) {
                done({progress: 100, url: `https://${window.location.hostname}/apiv3/emailingfile/${data.url}`})
              } else {
                if (data.msg === "Authentication failed.") {
                  done({progress: 0, url: ''})
                }
              }
            });
      });
      vm.$refs.emailEditor.editor.addEventListener('design:updated', function () {
        vm.$refs.emailEditor.editor.saveDesign((design) => {
          vm.$session.set('emailEditorDesign', design);
          vm.composerAutoSave = vm.$DateTime.local().setZone("Africa/Nairobi").toFormat('yyyy-LL-dd HH:mm:ss');
          vm.$session.set('emailEditorDesignAutosave', vm.composerAutoSave);
        });
      });
      vm.$refs.emailEditor.editor.addEventListener('design:loaded', function (data) {
        // Design is loaded
        let json = data.design; // design json
        console.log(json);
      });
    },
    saveDesign() {
      let vm = this,
          deferredFn = vm.$Q.defer();
      vm.$refs.emailEditor.editor.saveDesign((design) => {
        console.log('saveDesign');
        console.log(design);
        vm.emailEditorState = design;
        deferredFn.resolve('done');
      });
      return deferredFn.promise;
    },
    exportHtml() {
      let vm = this,
          deferredFn = vm.$Q.defer();
      vm.$refs.emailEditor.editor.exportHtml((data) => {
        console.log('exportHtml');
        console.log(data);
        console.log('exportHtml', data.html);
        vm.formData.emailHtml = data.html;
        deferredFn.resolve('done');

        let authorizationToken;
        if (vm.$session.has('jwt')) {
          authorizationToken = vm.$session.get('jwt');
        } else {
          authorizationToken = '';
        }
        let reqHeader = {
          headers: {
            authorization: authorizationToken,
            filesource: `https://${window.location.hostname}`
          }
        };


        let emailDraftData = {
          formData: vm.formData,
          session: vm.$session.id(),
          requestid: vm.draftsRequestId++,
        };

        api.operation('emailingdrafts').post(emailDraftData, reqHeader)
            .then(({data}) => {
              console.log(data);
              if (data.success) {
                console.log(data);
                vm.formData.emailDraftId = data.draftID;

                vm.composerSavedDesign = vm.$DateTime.local().setZone("Africa/Nairobi").toFormat('yyyy-LL-dd HH:mm:ss');
                vm.$session.set('emailEditorDesignSaved', vm.composerSavedDesign);

              } else {
                if (data.msg === "Authentication failed.") {
                  console.log(data);
                }
              }
            });


      });
      return deferredFn.promise;
    },
    saveEmail() {
      let vm = this;
      console.log(vm.saveEditorType);
      vm.exportHtml()
          .then(function () {
            if (typeof vm.saveEditorType.forTemplate !== 'undefined') {
              if (vm.saveEditorType.forTemplate) {
                vm.saveDesign()
                    .then(function () {
                      // vm.showSaveForError.forTemplate = true;
                    });
              } else {
                // vm.showSaveForError.forTemplate = false;
              }
            }
          });

    },
    nextStep() {
      let vm = this,
          parentSection = document.querySelector("#emailcompose-subsection");
      vm.$scrollTo(parentSection);
      vm.stepTime = vm.$DateTime.local().setZone("UTC").valueOf();
    },
    previousStep() {
      let vm = this,
          parentSection = document.querySelector("#emailcompose-subsection");
      vm.$scrollTo(parentSection);
      vm.stepTime = vm.$DateTime.local().setZone("UTC").valueOf();
    },
    resetEmailComposeForm() {
      let vm = this;
      vm.stepWizardKey = `stepwizard_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;
      vm.composerAutoSave = '';
      vm.composerSavedDesign = '';
      vm.saveEditorType = {forTemplate: false};
      vm.editorChecksBinds.initial = {forTemplate: false};
      vm.editorChecksBindsKey = `editorChecks_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;
      vm.emailDesignNameBinds.initial = '';
      vm.emailSubjectBinds.initial = '';
      vm.receiverArrJoined = '';
      vm.dataSelectedInternal = '';
      vm.emailFromBinds.initial = '';
      vm.emailReplyToBinds.initial = '';
      vm.emailCategoryBinds.initial = '';
      vm.anyOtherInformationBinds.initial = '';
      vm.emailTagsBinds.initial = [];
      vm.$session.set('emailEditorDesignSaved', '');
      vm.$session.set('emailEditorDesign', {});
      vm.$session.set('emailEditorDesignAutosave', '');
      vm.$session.set('emailFormData', {});
      vm.formData = {
        emailDraftId: '',
        emailHtml: '',
        laterDateTime: '',
        sendTime: '',
        emailDesignName: '',
        emailSubject: '',
        emailTo: '',
        emailFrom: '',
        emailReplyTo: '',
        emailCategory: '',
        anyOtherInformation: '',
        emailTags: [],
        attachments: []
      };
    },
    submitForm() {
      let vm = this;
      console.log(vm.formData);
      let authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let reqHeader = {
        headers: {
          authorization: authorizationToken,
          filesource: `https://${window.location.hostname}`
        }
      };
      let emailSendData = {
        formData: vm.formData,
        session: vm.$session.id(),
        requestid: vm.draftsRequestId++,
      };
      api.operation('emailingsubmit').post(emailSendData, reqHeader)
          .then(({data}) => {
            console.log(data);
            if (data.success) {
              console.log(data);
              if (data.orderID === vm.formData.emailDraftId) {
                vm.resetEmailComposeForm();
              } else {
                console.log(`There's a mismatch somewhere`);
              }
            } else {
              if (data.msg === "Authentication failed.") {
                console.log(data);
              }
            }
          });
    },
    isArrayEqual(arrayA, arrayB) {
      let vm = this;
      return vm.$_(arrayA).differenceWith(arrayB, vm.$_.isEqual).isEmpty();
    },
    editorChecksSelection(emittedData) {
      let vm = this,
          inputEnteredData = vm.$_.split(emittedData.inputEntered, '==');
      console.log(emittedData);
      vm.saveEditorType = JSON.parse(inputEnteredData[1]);
    },
    inputFieldDataEntered(emittedData) {
      let vm = this,
          inputEnteredData = vm.$_.split(emittedData.inputEntered, '==');
      console.log(emittedData);
      switch (inputEnteredData[0]) {
        case 'saveTypes':
          vm.saveEditorType = JSON.parse(inputEnteredData[1]);
          vm.editorChecksBinds.initial = JSON.parse(inputEnteredData[1]);
          vm.editorChecksBindsKey = `editorChecks_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;
          break;
        case 'emailDesignName':
          vm.formData.emailDesignName = vm.$_.trim(inputEnteredData[1]);
          vm.emailDesignNameBinds.initial = vm.formData.emailDesignName;
          break;
        case 'emailSubject':
          vm.formData.emailSubject = vm.$_.trim(inputEnteredData[1]);
          vm.emailSubjectBinds.initial = vm.formData.emailSubject;
          break;
        case 'emailFrom':
          vm.formData.emailFrom = vm.$_.trim(inputEnteredData[1]);
          vm.emailFromBinds.initial = vm.$_.trim(inputEnteredData[1]);

          vm.formData.emailReplyTo = vm.$_.trim(inputEnteredData[1]);
          vm.emailReplyToBinds.initial = vm.$_.trim(inputEnteredData[1]);
          break;
        case 'emailReplyTo':
          vm.formData.emailReplyTo = vm.$_.trim(inputEnteredData[1]);
          vm.emailReplyToBinds.initial = vm.$_.trim(inputEnteredData[1]);
          break;
        case 'emailCategory':
          vm.formData.emailCategory = vm.$_.trim(inputEnteredData[1]);
          vm.emailCategoryBinds.initial = vm.$_.trim(inputEnteredData[1]);
          break;
        case 'anyOtherInformation':
          vm.formData.anyOtherInformation = vm.$_.trim(inputEnteredData[1]);
          vm.anyOtherInformationBinds.initial = vm.formData.anyOtherInformation;
          break;
        case 'emailTags':
          try {
            let parsedTags = JSON.parse(inputEnteredData[1]);
            if (!vm.isArrayEqual(parsedTags, vm.formData.emailTags)) {
              vm.formData.emailTags = parsedTags;
              vm.emailTagsBinds.initial = parsedTags;
            }
          } catch (error) {
            console.error(error);
          }
          break;
        default:
          console.log(inputEnteredData);
          break;
      }
      console.log(vm.formData);
    },
    uploadDone() {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let reqHeader = {
        headers: {
          authorization: authorizationToken
        }
      };
      setTimeout(function () {
        api.operation(`fetchemailattachments/${vm.formData.emailDraftId}`).getAll(reqHeader)
            .then(({data}) => {
              console.log(data);
              if (data.success) {
                vm.formData.attachments = data.attachments;
              }
            });
      }, 1000)
    },
    removeAttachment(fileQquuid) {
      let vm = this,
          deletionPostDate = {qquuid: fileQquuid};
      let authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let reqHeader = {
        headers: {
          authorization: authorizationToken,
          filesource: `https://${window.location.hostname}`
        }
      };
      api.operation(`deleteemailattachment?auth=${authorizationToken}&logo=${logoUrl}&filesource=https://${window.location.hostname}&emailId=${vm.formData.emailDraftId}`).post(deletionPostDate, reqHeader)
          .then(({data}) => {
            console.log(data);
            vm.uploadDone();
          });
    },
    downloadAttachment(fileName, fileHumanName) {
      let vm = this;
      let authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let reqHeader = {
        headers: {
          authorization: authorizationToken,
          filesource: `https://${window.location.hostname}`
        },
        responseType: 'blob',
      };
      api.operation(`viewemailattachment/${fileName}`).download(reqHeader)
          .then((response) => {
            console.log(response.data);

            const href = window.URL.createObjectURL(response.data);

            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = fileHumanName;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);

          });
    }
  },
  watch: {
    dataSelected: {
      handler: function () {
        let vm = this;
        console.log(vm.dataSelected);
        vm.dataSelectedInternal = vm.dataSelected;
      },
      deep: true
    },
    formData: {
      handler: function () {
        let vm = this;
        console.log(vm.formData);
        vm.$session.set('emailFormData', vm.formData);
      },
      deep: true
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      console.log(vm.formsData);
      if (vm.$session.has('emailEditorDesignAutosave')) {
        vm.composerAutoSave = vm.$session.get('emailEditorDesignAutosave');
      }
      if (vm.$session.has('emailEditorDesignSaved')) {
        vm.composerSavedDesign = vm.$session.get('emailEditorDesignSaved');
      }
      if (vm.$session.has('emailFormData')) {
        vm.formData = vm.$session.get('emailFormData');
        console.log(vm.formsData);
        if (vm.$_.trim(vm.formData.emailDesignName) !== '') {
          vm.saveEditorType = {forTemplate: true};
          vm.editorChecksBinds.initial = {"forTemplate": true};
          vm.editorChecksBindsKey = `editorChecks_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;
        } else {
          vm.saveEditorType = {forTemplate: false};
        }

        vm.emailDesignNameBinds.initial = vm.formData.emailDesignName;
        // vm.emailDesignNameBindsKey = `emailDesignName_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.emailSubjectBinds.initial = vm.formData.emailSubject;
        // vm.emailSubjectBindsKey = `emailSubject_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.emailFromBinds.initial = vm.formData.emailFrom;
        // vm.emailFromBindsKey = `emailFrom_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.emailReplyToBinds.initial = vm.formData.emailReplyTo;
        // vm.emailReplyToBindsKey = `emailReplyTo_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.emailCategoryBinds.initial = vm.formData.emailCategory;
        // vm.emailCategoryBindsKey = `emailCategory_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.anyOtherInformationBinds.initial = vm.formData.anyOtherInformation;
        // vm.anyOtherInformationBindsKey = `anyOtherInformation_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.emailTagsBinds.initial = vm.formData.emailTags;
        // vm.emailTagsBindsKey = `emailTags_${vm.$DateTime.local().setZone("Africa/Nairobi").toMillis()}`;

        vm.receiverArrJoined = vm.formData.emailTo;
      }
    });
  }
};
</script>

<style scoped>
.blockbuilder-branding {
  display: none !important;
}

.email-composer-editor {
  height: 1080px !important;
}

.email-composer-tabs-editor {
  min-height: 1080px !important;
}

.email-composer-editor-padded {
  padding: 20px !important;
}

.form-wizard-class {
  width: 100% !important;
}

.bigger-text {
  font-size: larger;
}

.bolder-text {
  font-weight: bold;
}

.pad-top-20 {
  padding-top: 20px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>