<template>
    <section class="hero is-dark is-fullheight">
        <!-- Hero head: will stick at the top -->
        <div class="hero-head">

        </div>
        <!-- Hero content: will be in the middle -->
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Error 503
                </h1>
                <h2 class="subtitle">
                    Service Unavailable
                </h2>
                <h1 class="subtitle">
                    We have identified the problem you have encountered and we're working tirelessly
                    to restore the service.
                </h1>
                <img src="@/tumatext/themes/chadash/assets/img/503.gif">
                <h2 class="subtitle">
                    Please come back in a little while.
                </h2>
            </div>
        </div>

        <!-- Hero footer: will stick at the bottom -->
        <div class="hero-foot">
            <nav class="tabs is-boxed is-fullwidth">
                <div class="container">
                    <ul>
                        <li><a href=".">refresh page</a></li>
                        <li><a href="mailto:we@tumatext.co.ke?subject=Service is unavailable!">Send us an email</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>
    export default {
        name: "page503"
    }
</script>

<style scoped>

</style>