<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Name:</span> {{ formFieldsData.name.binds.initial }}
          </div>
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Number:</span> {{ formFieldsData.number.binds.initial }}
          </div>
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Signed Up on:</span> {{ formFieldsData.signupdate.binds.initial }}
          </div>
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Chanel:</span> {{ formFieldsData.chanel.binds.initial }}
          </div>
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Last Seen:</span> {{ formFieldsData.lastSeen.binds.initial }}
          </div>
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Will be purged on:</span> {{ formFieldsData.willBePurged.binds.initial }}
          </div>
          <div class="column is-full has-text-left break-long-lines no-bottom-pad">
            <span class="has-text-weight-bold">Sales Offer Properties:</span> {{
              formFieldsData.salesOfferProperties.binds.initial
            }}
          </div>
        </div>
        <div class="is-divider" data-content="Editable"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.blockedUser.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.testingUser.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
// import inputField from "./elements/inputField";
// import selectField from "./elements/selectField";
import checkRadioField from "./elements/checkRadioField";
// import textAreaField from "./elements/textAreaField";

export default {
  name: "salesoffersubscriberForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    // inputField,
    // selectField,
    checkRadioField,
    // textAreaField
  },
  data() {
    return {}
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}

.break-long-lines {
  line-break: anywhere;
}

.no-bottom-pad {
  padding-bottom: 0 !important;
}
</style>
