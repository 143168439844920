import { render, staticRenderFns } from "./cards.vue?vue&type=template&id=2b2d43ba&scoped=true&"
import script from "./cards.vue?vue&type=script&lang=js&"
export * from "./cards.vue?vue&type=script&lang=js&"
import style0 from "./cards.vue?vue&type=style&index=0&id=2b2d43ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2d43ba",
  null
  
)

export default component.exports