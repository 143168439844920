<template>
  <div>
    <p class="element-label" v-html="labelText"></p>
    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.inputValue.$invalid }">
      <select class="input is-primary is-rounded" v-model="inputValue"
              v-bind:class="{ 'is-danger' : $v.inputValue.$invalid }">
        <option disabled value="">Please select one</option>
        <option value="create" v-if="canCreateNew">Create New</option>
        <option value="none" v-if="canSelectNone">None</option>
        <option value="all" v-if="canSelectAll">All</option>
        <option v-for="(dataObj, key) in selectOptionsVisible" v-bind:key="key" v-bind:value="dataObj.key">
          {{ dataObj.value }}
        </option>
      </select>
      <span class="icon is-small is-left">
                <font-awesome-icon v-bind:icon="[inputIconCategory, inputIcon]"/>
            </span>
      <span class="icon is-small is-right redColored" v-if="$v.inputValue.$invalid">
                <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
            </span>
    </p>
    <p class="help is-danger bigger-text bolder-text" v-if="$v.inputValue.$invalid">{{
        mandatoryInputInvalidText
      }}</p>
  </div>
</template>

<script>
import formFunctions from "./../../../../api/mixins/formFunctions";
import fetchTableData from "./../../../../api/mixins/fetchTableData";

export default {
  name: "dataFetchingSelect",
  data() {
    let vm = this;
    console.log(vm.inputData);
    console.log((vm.$_.trim(vm.inputData.dataJson) === '') ? {} : require('./../../datas/' + vm.inputData.dataJson));
    console.log('All cool');
    return {
      inputValue: vm.inputData.initial,
      inputName: vm.inputData.elementName,
      inputType: vm.inputData.type,
      placeholderText: vm.inputData.placeholderText,
      mandatoryInputInvalidText: vm.inputData.invalidText,
      inputIconCategory: vm.inputData.iconCategory,
      inputIcon: vm.inputData.icon,
      isMandatory: vm.inputData.mandatory,
      validationRules: vm.inputData.validationRules,
      labelText: vm.inputData.labelText,
      disabledInput: vm.inputData.disabled,
      request: vm.inputData.dataRequest,
      jsonStructure: (vm.$_.trim(vm.inputData.dataJson) === '') ? {} : require('./../../datas/' + vm.inputData.dataJson),
      dataHeaderValue: vm.inputData.dataHeader,
      canCreateNew: vm.inputData.canCreateNew,
      canSelectNone: vm.inputData.canSelectNone,
      canSelectAll: vm.inputData.canSelectAll,
      showData: vm.inputData.showDataProvided,
      items: {},
      from: 'table',
    }
  },
  mixins: [fetchTableData, formFunctions],
  props: ['inputData'],
  watch: {
    inputData: {
      handler: function () {
        let vm = this;
        vm.inputValue = vm.inputData.initial;
        vm.inputName = vm.inputData.elementName;
        vm.inputType = vm.inputData.type;
        vm.placeholderText = vm.inputData.placeholderText;
        vm.mandatoryInputInvalidText = vm.inputData.invalidText;
        vm.inputIcon = vm.inputData.icon;
        vm.isMandatory = vm.inputData.mandatory;
        vm.validationRules = vm.inputData.validationRules;
        vm.labelText = vm.inputData.labelText;
        vm.disabledInput = vm.inputData.disabled;
        vm.request = vm.inputData.dataRequest;
        vm.jsonStructure = (vm.$_.trim(vm.inputData.dataJson) === '') ? {} : require('./../../datas/' + vm.inputData.dataJson);
        vm.dataHeaderValue = vm.inputData.dataHeader;
        vm.canCreateNew = vm.inputData.canCreateNew;
        vm.canSelectNone = vm.inputData.canSelectNone;
        vm.canSelectAll = vm.inputData.canSelectAll;
        vm.showData = vm.inputData.showDataProvided;

        console.log('changed');

        if (typeof vm.inputType !== 'undefined' && vm.$_.trim(vm.inputType) === 'datafetchingafresh') {
          console.log('datafetchingafresh');
          vm.loadApiModalData();
        }
      },
      deep: true
    },
    filterDataObjData: {
      handler: function () {
        let vm = this;
        console.log(vm.filterDataObjData);
        vm.$emit('filterDataReady', {filterData: vm.filterDataObjData});
      },
      deep: true
    },
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      console.log(vm.inputType);
      console.log(vm.inputData);
      if (typeof vm.inputType !== 'undefined' && (vm.$_.trim(vm.inputType) === 'datafetching' || vm.$_.trim(vm.inputType) === 'datafetchingafresh'))
      {
        console.log('datafetching');
        vm.loadApiModalData();
      }
    });
  }
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}
</style>