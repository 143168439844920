<template>
  <div class="columns is-mobile is-vcentered is-centered">
    <div class="column is-narrow">
      <div class="buttons">
        <button class="button" v-bind:class="{ 'is-success is-selected' : smsSelected, 'is-danger': !smsSelected }"
                v-on:click="toggleFilter('smsSelected')">
            <span class="icon">
              <font-awesome-icon v-bind:icon="['fas', 'comment']" v-bind:size="'lg'"/>
            </span>
        </button>
        <button class="button"
                v-bind:class="{ 'is-success is-selected' : whatsAppSelected,'is-danger': !whatsAppSelected }"
                v-on:click="toggleFilter('whatsAppSelected')">
            <span class="icon">
              <font-awesome-icon v-bind:icon="['fab', 'whatsapp']" v-bind:size="'lg'"/>
            </span>
        </button>
        <button class="button"
                v-bind:class="{ 'is-success is-selected' : telegramSelected,'is-danger': !telegramSelected }"
                v-on:click="toggleFilter('telegramSelected')">
            <span class="icon">
              <font-awesome-icon v-bind:icon="['fab', 'telegram-plane']" v-bind:size="'lg'"/>
            </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "channelSelector",
  props: ['requester'],
  data() {
    return {
      smsSelected: true,
      whatsAppSelected: true,
      telegramSelected: true,
    }
  },
  methods: {
    toggleFilter: function (filterName) {
      let vm = this;
      vm[filterName] = !vm[filterName];
      vm.$emit('channelsSelected', {
        requesterName: vm.requester,
        smsSelectedFilter: vm.smsSelected,
        whatsAppSelectedFilter: vm.whatsAppSelected,
        telegramSelectedFilter: vm.telegramSelected
      });
    }
  }
}
</script>

<style scoped>

</style>