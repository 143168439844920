import api from "./../api.js";
import queryString from "query-string";

export default {
 data() {
  return {
   requestid: 1,
   dashDataFilterDates: '',
   dashDataFilterMode: '',
   dashDataFilterScope: 'all',
   dashDataReturned: {
    dataTimeStamp: false,
    autoRefresh: false,

    cards: {
     todayTotal: {
      legend: 'Day:',
      tally: ''
     },
     weekTotal: {
      legend: 'Week:',
      tally: ''
     },
     monthTotal: {
      legend: 'Month:',
      tally: ''
     },
     yearTotal: {
      legend: 'Year:',
      tally: ''
     }
    },
    OneYearSummaryEpolicyHeatMap: [],
    OneYearSummaryRenewalHeatMap: [],

    DeliverySummaryDeliveryBars: {},
    DeliverySummaryDeliveryPie: {},

    ReadSummaryReadBars: {},
    ReadSummaryReadPie: {},

    distributionSummaryBars: {},
    distributionSummaryMonthYearPie: {},
    distributionSummaryDaysDaysToGoPie: {}
   }
  }
 },
 computed: {},
 methods: {
  getThisDashboardData: function () {
   let vm = this,
    authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   const formReqObj = {
     dashboard: vm.dashboardName,
     dateFilter: vm.dashDataFilterDates,
     dateMode: vm.dashDataFilterMode,
     dataScope: vm.dashDataFilterScope,
     session: vm.$session.id(),
     requestid: vm.requestid++,
    },
    getQueries = queryString.stringify(formReqObj);
   api.operation('dashdata?' + getQueries).getAll(reqHeader)
    .then(({data}) => {
     if (data.success) {
      vm.dashDataReturned = data.returnData.dataReturned;
      // console.log(vm.dashDataReturned.autoRefresh)
      if (vm.dashDataReturned.autoRefresh) {
       vm.countdownAutoRefresh();
      }
     }
    });
  }
 },
 watch: {
  // dashDataReturned: {
  //  handler: function () {
  //   let vm = this;
  //   // console.log(vm.dashDataReturned);
  //  },
  //  deep: true
  // },
 }
};