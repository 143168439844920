<template>
  <div>
    <div v-html="guide"></div>
    <div>
      <v-uploader
          v-on:done="uploadDone"
          v-bind:multiple="true"
          v-bind:language="'en'"
          v-bind:button-text="'Select File'"
          v-bind:file-type-exts="'csv'"
          v-bind:upload-file-obj-name="'datafiles'"
          v-bind:file-size-limit="'10MB'"
          v-bind:upload-file-url="uploadFileUrlWithAuthorization"
          v-bind:delete-file-url="deleteFileUrlWithAuthorization"
      ></v-uploader>
    </div>
  </div>

</template>

<script>
import api from "./../../../api/api.js";

const logoUrl = require('./../assets/img/Logo Full.png');

export default {
  name: "sectionUpload",
  data() {
    return {}
  },
  props: ['text', 'action', 'guide'],
  computed: {
    uploadFileUrlWithAuthorization: function () {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      return api.operation('uploadfile').returnUrl() + '?auth=' + authorizationToken + '&logo=' + logoUrl;
    },
    deleteFileUrlWithAuthorization: function () {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      return api.operation('deletefile').returnUrl() + '?auth=' + authorizationToken + '&logo=' + logoUrl;
    }
  },
  methods: {
    uploadDone(files) {
      if (files && Array.isArray(files) && files.length) {
        console.log(files);
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>