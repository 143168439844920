import { render, staticRenderFns } from "./dataCard.vue?vue&type=template&id=ab4e447a&scoped=true&"
import script from "./dataCard.vue?vue&type=script&lang=js&"
export * from "./dataCard.vue?vue&type=script&lang=js&"
import style0 from "./dataCard.vue?vue&type=style&index=0&id=ab4e447a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab4e447a",
  null
  
)

export default component.exports