<template>
    <form v-on:submit="checkForm">
        <div class="columns is-centered" v-if="showLogoutNotice">
            <div class="column is-one-third">
                <span class="black-text bolder-text">{{ logoutNotice }}</span>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : emailInvalid }">
                        <input class="input is-primary is-rounded" type="email" placeholder="Email"
                               v-model.trim="userEmail" v-bind:class="{ 'is-danger' : emailInvalid }">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'envelope']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="emailInvalid">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="emailInvalid">{{ emailInvalidText }}</p>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left"
                       v-bind:class="{ 'has-icons-right' : loginInvalid || passwordMissing}">
                        <input class="input is-primary is-rounded" type="password" placeholder="Password"
                               v-model.trim="userPassword"
                               v-bind:class="{ 'is-danger' : loginInvalid || passwordMissing}">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'lock']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="passwordMissing">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="loginInvalid">
                            <font-awesome-icon v-bind:icon="['fas', 'times-circle']"/>
                        </span>
                    </p>
                    <p class="help is-danger bigger-text bolder-text"
                       v-if="passwordMissing">{{ passwordMissingText }}</p>
                    <p class="help is-danger bigger-text bolder-text" v-if="loginInvalid">{{ loginInvalidText }}</p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">

                <div class="field">
                    <p class="control">
                        <button class="button is-primary is-inverted is-medium is-fullwidth">Login</button>
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <a href="/forgotpassword" class="black-text underlined_text">Forgotten credentials</a>
            </div>
        </div>
    </form>
</template>

<script>
import api from "./../../../api/api.js";

export default {
 name: "loginCredentials",
 data() {
  return {
   userEmail: '',
   userPassword: '',
   emailInvalid: false,
   emailInvalidText: 'Invalid Email',
   loginInvalid: false,
   loginInvalidText: 'Login failed',
   passwordMissing: false,
   passwordMissingText: 'Please enter a password',
   showLogoutNotice: false,
   logoutNotice: ''
  }
 },
 methods: {
  checkForm: function (event) {
   event.preventDefault();
   let vm = this,
    noError = true;
   if (vm.userEmail === '' || !vm.$validator.isEmail(vm.userEmail)) {
    vm.emailInvalid = true;
    noError = false;
   } else {
    vm.emailInvalid = false;
   }
   if (vm.userPassword === '') {
    vm.passwordMissing = true;
    noError = false;
   } else {
    vm.passwordMissing = false;
   }

   if (noError) {
    vm.submitform();
   }
  },
  submitform: function () {
   let vm = this;
   vm.loginInvalid = false;
   let authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   api.operation('authenticate').post({
    email: vm.userEmail,
    password: vm.userPassword,
    session: vm.$session.id()
   }, reqHeader)
    .then(({data}) => {
     if (data.success) {
      if (data.token.indexOf('loginsecureotp') === 0) {
       let tokenParts = vm.$_.split(data.token, ':');
       vm.loginInvalid = false;
       window.location.href = `/loginsecureotp/${tokenParts[1]}/${tokenParts[2]}`
      } else {
       vm.loginInvalid = false;
       vm.$session.set('jwt', data.token);
       window.location.href = '/account';
      }
     } else {
      if (data.msg === "Authentication failed.") {
       vm.loginInvalid = true;
      }
     }
    });
  }
 },
 mounted: function () {
  let vm = this,
   LogoutNotice = vm.$session.flash.get('logoff');
  if (LogoutNotice) {
   vm.showLogoutNotice = true;
   vm.logoutNotice = LogoutNotice;
  } else {
   vm.showLogoutNotice = false;
  }
 }
}
</script>

<style scoped>
.redColored {
    color: red !important;
}

.black-text {
    color: black !important;
}

.underlined_text {
    text-decoration: underline !important;
}

.bigger-text {
    font-size: larger;
}

.bolder-text {
    font-weight: bold;
}
</style>