module.exports = [
    {
        target: "#sections-dropdown-burger-button",
        content: "Use this button to help you navigate to the various sections of this page. The button also grants access for menus to: (1) manage your profile, (2) see the remaining credit, (3) buy more SMS if needed and (4) logout.",
        params: {
            placement: 'bottom',
            enableScrolling: false
        },
        scrollTo:false
    },
    {
        target: ".section-title-guide",
        content: "Section Title.",
        params: {
            placement: 'left',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".section-subtitle-guide",
        content: "Section subtitle.",
        params: {
            placement: 'left',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".cardx",
        content: "Subsections are displayed on cards.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".card-name-text",
        content: "The card name is displayed here.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".card-refresh",
        content: "The card can be refreshed and re-created by clicking on this button. This will reset the card to its original state.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".refresh-button",
        content: "Use this button to refresh the table.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".search-button",
        content: "Use this button to show or hide the search text box.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".export-button",
        content: "Use this button to show or hide the data export tools. You can export <span class='bold-white-text' style='color: white;font-weight: bold'>All</span> or <span class='bold-white-text' style='color: white;font-weight: bold'>Visible</span> table contents. Supported export formats are excel and pdf. A text box is also available for additional emails to which the report will be sent. <span class='bold-white-text' style='color: white;font-weight: bold'>Reports are prepared in the system and sent to the account email address. No reports are downloaded on the portal.</span>",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".pagination-button",
        content: "Use this button to show or hide the pagination options. You can change the number of records per page and scroll to other pages if a table is more than one page long.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".sort-button",
        content: "Use this button to show or hide the table sorting options. You may change the sorting order and priority.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".accordion-texts-guide",
        content: "Table data summary.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".accordion-collapse-item-guide",
        content: "Table data item. Click to open for more details.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".create-new-button",
        content: "Use this button to show the form applicable for adding new items to this table.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    },
    {
        target: ".delete-items-button",
        content: "Use this button to show the form applicable for deleting items from this table.",
        params: {
            placement: 'top',
            enableScrolling: false
        },
        scrollTo:'sectionParent'
    }
];