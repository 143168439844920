<template>
  <component
      v-bind:is="dataSelect"
      v-bind:datasData="datasData"
      v-on:selectedDatas="bubbleSelectedDatas"
  ></component>
</template>

<script>
import modalsSelectionData from './modalsSelectionData.vue';
import modalsTableData from './modalsTableData.vue';

export default {
  name: "modalsData",
  data() {
    return {}
  },
  props: ['datasData'],
  components: {
    selections: modalsSelectionData,
    tables: modalsTableData,
  },
  computed: {
    dataSelect: function () {
      try {
        let vm = this,
            modalName = '';
        switch (vm.datasData.type) {
          case 'radio':
            modalName = 'selections';
            break;
          case 'checkbox':
            modalName = 'selections';
            break;
          case 'table':
            modalName = 'tables';
            break;
          default:
            modalName = 'nodata';
            break;
        }
        return modalName;
      } catch (error) {
        return 'nodata';
      }
    }
  },
  methods: {
    bubbleSelectedDatas: function (emittedData) {
      let vm = this;
      vm.$emit('selectedDatas', emittedData);
    }
  },
  watch: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      console.log(vm.datasData);
    });
  }
}
</script>

<style scoped>
</style>