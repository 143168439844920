<template>
  <nav class="navbar is-fixed-top is-transparent" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" v-bind:href="homepageUrl">
        <img src="@/tumatext/themes/chadash/assets/img/Logo_w120.jpg" width="120">
      </a>

      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
         data-target="navbarBasicExample" v-on:click="showUserBurger = !showUserBurger"
         v-bind:class="{ 'is-active' : showUserBurger }" id="sections-dropdown-burger-button">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbarBasicExample" class="navbar-menu" v-on:click="showUserBurger = !showUserBurger"
         v-bind:class="{ 'is-active' : showUserBurger }">
      <div class="navbar-start">
        <div class="navbar-item has-dropdown" v-on:click="showSectionsDropdown = !showSectionsDropdown"
             v-bind:class="{ 'is-active' : showSectionsDropdown }">
          <a class="navbar-item">
            <div class="dropdown-trigger" id="sections-dropdown-button">
              <a class="button is-primary navbar-button-dropdown">
                                <span class="icon">
                                    <font-awesome-icon v-bind:icon="['fas', 'th-list']"/>
                                </span>
                <span>
                                    <strong>Sections</strong>
                                </span>
              </a>
            </div>
          </a>
          <div class="navbar-dropdown">
            <a class="navbar-item" v-for="(section, key) in mySections" v-bind:key="section.mykey"
               v-bind:href="'#'" v-scroll-to="'#'+section.mykey">
              {{ key }}
            </a>
          </div>
        </div>
      </div>
      <div class="navbar-end" v-if="pageStage.name==='account'">
        <div class="navbar-item has-dropdown" v-on:click="showUserDropdown = !showUserDropdown"
             v-bind:class="{ 'is-active' : showUserDropdown }">
          <a class="navbar-item">
            <div class="dropdown-trigger" id="myself-dropdown-button">
              <a v-if="isshadowing" class="button is-danger navbar-button-dropdown">
                                <span class="icon">
                                    <font-awesome-icon v-bind:icon="['fas', 'user-alt']"/>
                                </span>
                <span>
                                    <strong> Viewing as {{ myData.n }}</strong>
                                </span>
              </a>
              <a v-if="!isshadowing" class="button is-success navbar-button-dropdown">
                                <span class="icon">
                                    <font-awesome-icon v-bind:icon="['fas', 'user-alt']"/>
                                </span>
                <span>
                                    <strong>{{ myData.n }}</strong>
                                </span>
              </a>
            </div>
          </a>
          <div class="navbar-dropdown is-right">
            <a class="navbar-item" v-if="isshadowing" v-on:click="exitViewAs()">
              <font-awesome-icon v-bind:icon="['fas', 'door-open']"/>
              <span class="pad-10px-left">Exit</span>
            </a>
            <hr class="navbar-divider" v-if="isshadowing">
            <a class="navbar-item">
              <font-awesome-icon v-bind:icon="['fas', 'money-bill-alt']"/>
              <span class="pad-10px-left">{{ accCredit.remaining }} SMS Remaining</span>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item" v-on:click="emitFormAction('Top-up SMS','buymoresms-form')">
              <font-awesome-icon v-bind:icon="['fas', 'shopping-cart']"/>
              <span class="pad-10px-left">Buy more SMS</span>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item" v-on:click="showJoke = true">
              <font-awesome-icon v-bind:icon="['fas', 'grin-squint-tears']"/>
              <span class="pad-10px-left">LOL</span>
            </a>
            <!--                        <a class="navbar-item" v-on:click="showMyProfile = true">-->
            <!--                            My Profile-->
            <!--                        </a>-->
            <!--                        <a class="navbar-item" v-if="myData.r ==='mainadmin'" v-on:click="showAccountSetup = true">-->
            <!--                            Account Setup-->
            <!--                        </a>-->
            <hr class="navbar-divider">
            <a href="/logout" class="navbar-item">
              <font-awesome-icon v-bind:icon="['fas', 'sign-out-alt']"/>
              <span class="pad-10px-left">Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active' : showModal }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <article class="media" v-show="showJoke">
            <div class="media-left">
              <figure class="image">
                <font-awesome-icon v-bind:icon="['fas', 'grin-squint-tears']"/>
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Light Moment</strong>
                  <br>
                  {{ jokeOfTheMoment.text }}
                </p>
              </div>
              <nav class="level is-mobile">
                <div class="level-left">
                  <div class="buttons is-vcentered is-centered">
                    <a class="button is-medium" v-on:click="getJoke">
											<span class="icon is-medium">
												<font-awesome-icon v-bind:icon="['fas', 'retweet']"/>
											</span>
                    </a>
                    <!--                                        <a class="button is-medium">-->
                    <!--											<span class="icon is-medium">-->
                    <!--												<font-awesome-icon v-bind:icon="['fas', 'sms']"/>-->
                    <!--											</span>-->
                    <!--                                        </a>-->
                  </div>
                </div>
              </nav>
            </div>
          </article>
<!--          <article class="media" v-show="showMyProfile">-->
<!--            <div class="media-content">-->
<!--              <div class="content">-->
<!--                <myProfileForm-->
<!--                    v-if="fetchProfile"-->
<!--                    v-bind:profileData="myData"-->
<!--                    v-on:close-myprofile=closeModal-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </article>-->
          <!--          <article class="media" v-show="showBuyMoreSmsForm">-->
          <!--            <div class="media-content">-->
          <!--              <div class="content">-->
          <!--                <buyMoreSmsForm-->
          <!--                    v-if="fetchAccountSms"-->
          <!--                    v-bind:profileData="myData"-->
          <!--                    v-on:close-smstopup=closeModal-->
          <!--                />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </article>-->
          <article class="media" v-show="showAccountSetup">
            Account Setup
          </article>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" v-on:click="closeModal"></button>
    </div>
  </nav>
</template>

<script>
import api from "./../../api/api.js";
//import myProfileForm from "./forms/myProfileForm";
//import buyMoreSmsForm from "./forms/buyMoreSmsForm";

export default {
  name: "topNavBar",
  data() {
    let vm = this;
    return {
      //sections: {},
      homepageUrl: vm.baseUrlProvider(),
      showSectionsDropdown: false,
      showUserDropdown: false,
      showUserBurger: false,
      showJoke: false,
      fetchProfile: false,
      fetchAccountSms: false,
      showMyProfile: false,
      showBuyMoreSmsForm: false,
      showAccountSetup: false,
      jokeOfTheMoment: {text: ''},
      updatesMessage: null,
      //myRole: ""
      messages: [],
    }
  },
  props: ['mySections', 'myData', 'isAdmin', 'pageStage', 'isshadowing', 'accCredit'],
  components: {
    //myProfileForm,
    //buyMoreSmsForm
  },
  computed: {
    showModal: function () {
      let vm = this;
      return vm.showJoke || vm.showMyProfile || vm.showAccountSetup || vm.showBuyMoreSmsForm;
    }
  },
  methods: {
    baseUrlProvider: function () {
      // if (window.location.hostname === 'sms.tumatext.co.ke') {
      //   baseUrl = appConfig.production.baseDomain;
      // } else {
      //   baseUrl = appConfig.development.baseDomain;
      // }
      return `https://${window.location.hostname}`;
    },
    closeModal() {
      let vm = this;
      vm.showJoke = false;
      vm.showMyProfile = false;
      vm.showBuyMoreSmsForm = false;
      vm.showAccountSetup = false;
    },
    getJoke: function () {
      let vm = this;
      let reqJokeHeader = {
        headers: {
          Accept: 'application/json',
        }
      };
      api.operation('').getJoke(reqJokeHeader)
          .then(function ({data}) {
            if (parseInt(data.status) === 200) {
              vm.jokeOfTheMoment.text = data.joke;
            } else {
              vm.jokeOfTheMoment.text = 'Looks like our comedian is on a short break. Try again after a short while.';
            }
          })
          .catch(function (error) {
            console.error(error);
            vm.jokeOfTheMoment.text = 'Looks like our comedian is on a short break. Try again after a short while.';
          });
    },
    exitViewAs: function () {
      let vm = this,
          authorizationToken;
      if (vm.$session.has('jwt')) {
        authorizationToken = vm.$session.get('jwt');
      } else {
        authorizationToken = '';
      }
      let tableActions = {
            action: 'viewas:exit',
            formname: "accountmanagerusers",
            table: "accountmanagerusers-subsection"
          },
          reqHeader = {
            headers: {
              authorization: authorizationToken
            }
          };
      api.operation('tableaction').post(tableActions, reqHeader)
          .then(function ({data}) {
            if (data.success) {
              if (typeof data.returnData !== 'undefined') {
                if (typeof data.returnData.newtoken !== 'undefined') {
                  vm.$session.set('jwt', data.returnData.newtoken);
                  window.location.href = '/account'
                }
              }
            }
          })
          .catch(function (error) {
            console.error(error);
          });
    },
    emitFormAction: function (modalHead, subkey) {
      let vm = this,
          formData;
      switch (subkey) {
        case 'buymoresms-form':
          formData = {
            action: "smstopup:" + vm.myData.acc,
            formname: "buymoresms",
            table: "buymoresms-form"
          };
          break;
      }
      vm.$emit('formModal', {
        formTitle: modalHead,
        formData: formData,
        subkey: subkey,
        myRole: vm.myData.r,
        key: vm.$moment().valueOf()
      });
    }
  },
  mounted: function () {
    let vm = this;
    if (vm.pageStage.name === 'account') {
      vm.getJoke();
      vm.fetchProfile = true;
      vm.fetchAccountSms = true;
    }
  }
}
</script>

<style scoped>
.navbar-button-dropdown {
  float: none;
}

.navbar-item img {
  max-height: 2.5rem !important;
}

.pad-10px-left {
  padding-left: 10px !important;
}
</style>