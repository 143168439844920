<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-two-fifths-desktop is-two-fifths-widescreen is-two-fifths-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.accountName.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-two-fifths-desktop is-two-fifths-widescreen is-two-fifths-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.senderId.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.accountEnabled.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.email.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.mobile.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div
              class="column is-full-mobile is-full-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.package.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.type.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.smsRate.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div
              class="column is-full-mobile is-full-tablet is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.mainAccount.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.AccountVersion.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.shortCode.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.forwardSmsTo.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.whatsappChannel.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.whatsappChannelConfig.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.telegramChannel.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.telegramChannelConfig.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.signatureText.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.zohoAccount.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Account Features"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.accountBasicFeatures.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.accountPremiumFeatures.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Destination Gateway"></div>
        <div class="columns is-centered">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.sendDestination.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.queueType.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.sendDestinationCreds.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.sendDestinationId.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Quota Notification"></div>
        <div class="columns is-centered">
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.notificationQuota.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.notificationMobile.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.notificationEmail.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import selectField from "./elements/selectField";
import checkRadioField from "./elements/checkRadioField";
import textAreaField from "./elements/textAreaField";
import deletionForm from "./elements/deletionForm";

export default {
  name: "accountmanageraccountsForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField,
    selectField,
    deletionForm,
    checkRadioField,
    textAreaField
  },
  data() {
    return {}
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
