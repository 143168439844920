import axios from 'axios';


export default {
    operation(url) {
        let apiUrl=`https://${window.location.hostname}/apiv3/`;
        // if (window.location.hostname === 'sms.tumatext.co.ke') {
        //     apiUrl = appConfig.production.apiUrl;
        // } else {
        //     apiUrl = appConfig.development.apiUrl;
        // }
        const jokeUrl = 'https://icanhazdadjoke.com/',
            logoUrl = require('./../themes/chadash/assets/img/Logo Full.png');

        function addLogoUrl(objectToPost) {
            let finalObject = objectToPost;
            try {
                finalObject.logo = logoUrl;
            } catch (error) {
                console.log(error);
            }
            return finalObject;
        }

        return {
            getJoke: (headers) => axios.get(jokeUrl, headers),
            getMeData: ({id}, {page}, headers) => axios.get(apiUrl + `${url}/${id}/${page}`, headers),
            getOne: ({id}, headers) => axios.get(apiUrl + `${url}/${id}`, headers),
            getAll: (headers) => axios.get(apiUrl + `${url}`, headers),
            download: (headers) => axios.get(apiUrl + `${url}`, headers),
            post: (toPost, headers) => axios.post(apiUrl + `${url}`, addLogoUrl(toPost), headers),
            update: (toUpdate, headers) => axios.put(apiUrl + `${url}`, toUpdate, headers),
            create: (toCreate, headers) => axios.put(apiUrl + `${url}`, toCreate, headers),
            delete: ({id}, headers) => axios.delete(apiUrl + `${url}/${id}`, headers),
            returnUrl: () => {
                return apiUrl + `${url}`
            }
        }
    }
}