<script>
import VueCharts from 'vue-chartjs'

export default {
  extends: VueCharts.Line,
  mixins: [VueCharts.mixins.reactiveProp],
  props: ['chartData', 'options'],
  data() {
    return {}
  },
  watch: {
    options: {
      handler: function () {
        let vm = this;
        vm.renderChart(vm.chartData, vm.options);
      },
      deep: true
    }
  },
  mounted() {
    let vm = this;
    vm.$nextTick(function () {
      vm.renderChart(vm.chartData, vm.options);
    });
  }
}
</script>

<style scoped>
.legend-struck-through {
  text-decoration: line-through !important;
}
</style>