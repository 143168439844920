<template>
    <div>
        <p class="element-label" v-html="labelText"></p>
        <div class="columns is-mobile is-multiline has-text-left is-centered">
            <div class="column is-half">
                <p class="control has-icons-left" v-if="showsearchbox">
                    <input class="input is-primary is-rounded" type="text" placeholder="Search"
                           v-model.trim="searchModalValue"/>

                    <span class="icon is-small is-left">
                        <font-awesome-icon v-bind:icon="['fas', 'search']"/>
                    </span>
                </p>
            </div>
            <div class="column is-half" v-if="canCreateNew">
                <p class="control has-icons-left">
                    <input class="input is-primary is-rounded" type="text" placeholder="New Group"
                           v-model.trim="checkRadioSelected.typed"/>
                    <span class="icon is-small is-left">
                        <font-awesome-icon v-bind:icon="['fas', 'users']"/>
                    </span>
                </p>
            </div>
        </div>

        <div class="columns is-mobile is-multiline has-text-left is-centered">
            <div v-for="(dataObj, key) in showData" v-bind:key="key" class="column is-narrow">
                <input v-bind:type="type" name="selectionchecks" v-bind:id="dataObj.key"
                       v-model="checkRadioSelected[dataObj.key]" v-bind:value="true"
                       v-if="isCheckBox(dataObj.visible)"/>
                <input v-bind:type="type" name="selectionchecks" v-bind:id="dataObj.key"
                       v-model="checkRadioSelected['selected_radio']" v-bind:value="dataObj.value"
                       v-if="isRadio(dataObj.visible)"/>
                <label class="choice-label" v-bind:for="dataObj.key" v-if="dataObj.visible">{{ dataObj.value }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import fetchTableData from "./../../../../api/mixins/fetchTableData";
    import formFunctions from "./../../../../api/mixins/formFunctions";

    export default {
        name: "contactGroupsChecks",
        data() {
            let vm = this;
            return {
                items: {},
                checkRadioSelected: vm.inputData.initial,
                inputName: vm.inputData.elementName,
                groupsJson: vm.inputData.groupJson,
                groupType: vm.inputData.type,
                labelText: vm.inputData.labelText,
                dataHeader: vm.inputData.dataHeader,
                request: vm.inputData.dataRequest,
                from: vm.inputData.requestFrom,
                type: vm.inputData.type,
                canCreateNew: vm.inputData.canCreateNew,
                canSelectNone: vm.inputData.canSelectNone,
                canSelectAll: vm.inputData.canSelectAll,
                jsonStructure: require('./../../datas/' + vm.inputData.groupJson)
            }
        },
        methods: {
            isCheckBox: function (isVisible) {
                let vm = this;
                return !!(vm.type === 'checkbox' && isVisible);
            },
            isRadio: function (isVisible) {
                let vm = this;
                return !!(vm.type === 'radio' && isVisible);
            },
            textareaResize() {
                this.$refs.textinput.style.minHeight = this.$refs.textinput.scrollHeight + 'px';
            }
        },
        props: ['inputData'],
        watch: {
            inputData: {
                handler: function () {
                    let vm = this;
                    vm.checkRadioSelected = vm.inputData.initial;
                    vm.inputName = vm.inputData.elementName;
                    vm.groupsJson = vm.inputData.groupJson;
                    vm.groupType = vm.inputData.type;
                    vm.labelText = vm.inputData.labelText;
                    vm.dataHeader = vm.inputData.dataHeader;
                    vm.request = vm.inputData.dataRequest;
                    vm.from = vm.inputData.requestFrom;
                    vm.type = vm.inputData.type;
                    vm.canCreateNew = vm.inputData.canCreateNew;
                    vm.canSelectNone = vm.inputData.canSelectNone;
                    vm.canSelectAll = vm.inputData.canSelectAll;
                    vm.jsonStructure = require('./../../datas/' + vm.inputData.groupJson);
                },
                deep: true
            },
        },
        mixins: [fetchTableData, formFunctions],
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.loadApiModalData();
            });
        }
    }
</script>

<style scoped>
    .element-label {
        font-weight: bold;
    }

    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .choice-label {
        position: relative;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
        padding-left: 30px;
        padding-right: 10px;
        line-height: 40px;
        cursor: pointer;
    }

    .choice-label::before {
        content: " ";
        position: absolute;
        top: 6px;
        left: 0;
        display: block;
        width: 24px;
        height: 24px;
        /*border: 2px solid #8e44ad;*/
        border: 2px solid hsl(171, 100%, 41%);
        border-radius: 4px;
        /*z-index: -1;*/
    }

    input[type="radio"] + .choice-label::before {
        border-radius: 18px;
    }

    /* Checked */
    input[type="checkbox"]:checked + .choice-label,
    input[type="radio"]:checked + .choice-label {
        padding-left: 10px;
        color: #fff;
        z-index: 0;
    }

    input[type="checkbox"]:checked + .choice-label::before,
    input[type="radio"]:checked + .choice-label::before {
        top: 0;
        width: 100%;
        height: 100%;
        /*background: #8e44ad;*/
        background: hsl(171, 100%, 41%);
        z-index: -1;
    }

    /* Transition */
    .choice-label, .choice-label::before {
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
    }
</style>