<template>
  <vs-card actionable fixedHeight v-bind:class="subsection.cardclass" v-bind:key="cardkey">
    <div slot="header">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
          <h3 class="card-name-text">{{ subkey }}</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-lg="4" vs-sm="4" vs-xs="4">
          <div class="dropdown is-right is-hidden-mobile"
               v-bind:class="{
            'is-active' : activeHelpDropdown,
            'display-none' : activeCard==='inactive'}">
            <div class="dropdown-trigger" v-on:click="toggleHelp()">
              <button class="button card-caption" aria-haspopup="true" aria-controls="dropdown-help">
								<span>
									<font-awesome-icon v-bind:icon="['fas', 'question-circle']"/>
								</span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-help" role="menu">
              <div class="dropdown-content is-centered" v-html="subsection.caption"></div>
            </div>
          </div>

          <div class="dropdown is-right is-hidden-mobile"
               v-bind:class="{
            'is-active' : activeResizingDropdown,
            'display-none' : activeCard==='inactive' || inAccordion==='yes' || canResize==='no'}">
            <div class="dropdown-trigger" v-on:click="toggleResize()">
              <button class="button card-resize" aria-haspopup="true" aria-controls="dropdown-resize">
								<span>
									<font-awesome-icon v-bind:icon="['fas', 'exchange-alt']"/>
								</span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-resize" role="menu">
              <div class="dropdown-content buttons is-centered">
                <span class="button"
                      v-on:click="emitWidthChange('is-one-quarter')"><sup>1</sup>&frasl;<sub>4</sub></span>
                <span class="button" v-on:click="emitWidthChange('is-half')"><sup>1</sup>&frasl;<sub>2</sub></span>
                <span class="button" v-on:click="emitWidthChange('is-one-third')"><sup>1</sup>&frasl;<sub>3</sub></span>
                <span class="button"
                      v-on:click="emitWidthChange('is-three-quarters')"><sup>3</sup>&frasl;<sub>4</sub></span>
                <span class="button"
                      v-on:click="emitWidthChange('is-two-thirds')"><sup>2</sup>&frasl;<sub>3</sub></span>
                <span class="button" v-on:click="emitWidthChange('is-full')">1</span>
              </div>
            </div>
          </div>

          <button class="button card-refresh" v-on:click="refreshCard()">
						<span>
							<font-awesome-icon v-bind:icon="['fas', 'power-off']"/>
						</span>
          </button>

        </vs-col>
      </vs-row>
    </div>
    <div>
      <sectionCollapseHover
          v-if="subsection.bodytype==='hovercollapsetable'"
          v-bind:subsection="subsection"
          v-bind:subkey="subkey"
      />

      <sectionCollapseAccordion
          v-if="subsection.bodytype==='accordioncollapse'"
          v-bind:subsection="subsection"
          v-bind:subkey="subkey"
          v-bind:myRole="myRole"
          v-bind:refreshMe="refreshMe"
          v-bind:activeCard="activeCard"
          v-on:formModal="showFormModal"
          v-on:tabbedModal="showTabbedModal"
          v-on:dataModal="showDataModal"
          v-on:refreshTable="refreshModalTable"
          v-on:useThisData="useThisDataRow"
      />

      <sectionTabs
          v-if="subsection.bodytype==='tabbedsections'"
          v-bind:subsection="subsection"
          v-bind:subkey="subkey"
          v-bind:refreshMe="refreshMe"
          v-on:listAction="doListAction"
      />

      <sectionUpload
          v-if="subsection.bodytype==='multiplefileuploader'"
          v-bind:text="subsection.uploadtext"
          v-bind:action="subsection.uploadurl"
      />

      <sectionList
          v-if="subsection.bodytype==='listeddata'"
          v-bind:subsection="subsection"
          v-bind:subkey="subkey"
          v-bind:refreshMe="refreshMe"
          v-bind:refreshList="refreshList"
          v-on:listAction="doListAction"
      />
      <sectionForm
          v-if="subsection.bodytype==='form'"
          v-on:dataModal="showDataModal"
          v-on:closeModal="closeDataModal"
          v-on:refreshTable="refreshModalTable"
          v-bind:formsData="formsData"
          v-bind:dataSelected="dataSelected"
      />

      <sectionCharts
          v-if="subsection.bodytype==='chartcanvas'"
          v-bind:subsection="subsection"
      />

      <infoPanel
          v-if="subsection.bodytype==='infopanel'"
          v-bind:panelData="subsection"
      />

      <sectionDashboard
          v-if="subsection.bodytype==='dashboard'"
          v-bind:dashData="subsection"
      />

    </div>
    <div slot="footer">
      <vs-row
          v-bind:vs-justify="subsection.footerbuttons.justify"
          v-if="subsection.hasButtons"
      >
        <vs-button
            v-for="(attribs, text) in subsection.footerbuttons.buttondata"
            v-bind:key="attribs.mykey"
            v-bind:color="attribs.color"
            v-bind:type="attribs.type"
        >{{ text }}
        </vs-button>
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import sectionCollapseHover from './collapseHover.vue';
import sectionCollapseAccordion from './collapseAccordion.vue';
import infoPanel from './infoPanel.vue';
import sectionCharts from './charts.vue';
import sectionUpload from './upload.vue';
import sectionTabs from './tabs.vue';
import sectionList from './lists.vue';
import sectionDashboard from './dashboards.vue';
import sectionForm from './sectionsForm.vue';

export default {
  name: "sectionCard",
  data() {
    let vm = this;
    return {
      cardkey: vm.$moment().valueOf(),
      activeResizingDropdown: false,
      activeHelpDropdown: false,
      formsData: {
        subsection: vm.subsection,
        subkey: vm.subkey,
        myRole: vm.myRole,
        dataSelected: vm.dataSelected,
        refreshList: ''
      }
    }
  },
  props: ['subsection', 'subkey', 'myRole', 'dataSelected', 'refreshMe', 'activeCard', 'inAccordion', 'canResize'],
  computed: {},
  methods: {
    showFormModal: function (emittedData) {
      let vm = this;
      vm.$emit('formModal', emittedData);
    },
    showTabbedModal: function (emittedData) {
      let vm = this;
      vm.$emit('tabbedModal', emittedData);
    },
    showDataModal: function (emittedData) {
      let vm = this;
      vm.$emit('dataModal', emittedData);
    },
    closeDataModal: function (emittedData) {
      let vm = this;
      vm.$emit('closeModal', emittedData);
    },
    refreshModalTable: function (emittedData) {
      let vm = this;
      vm.$emit('refreshTable', emittedData);
    },
    useThisDataRow: function (emittedData) {
      let vm = this;
      vm.$emit('useThisData', emittedData);
    },
    doListAction: function (emittedData) {
      let vm = this;
      vm.$emit('listAction', emittedData);
    },
    toggleResize: function () {
      let vm = this;
      vm.activeResizingDropdown = !vm.activeResizingDropdown;
      if (vm.activeResizingDropdown) {
        vm.activeHelpDropdown = !vm.activeResizingDropdown;
      }
    },
    toggleHelp: function () {
      let vm = this;
      vm.activeHelpDropdown = !vm.activeHelpDropdown;
      if (vm.activeHelpDropdown) {
        vm.activeResizingDropdown = !vm.activeHelpDropdown;
      }
    },
    emitWidthChange: function (newSize) {
      let vm = this;
      vm.$emit('changeColWidth', {newSize: newSize, myKey: vm.subsection.mykey});
      vm.activeResizingDropdown = false;
    },
    refreshCard: function () {
      let vm = this;
      vm.cardkey = vm.$moment().valueOf();
    }
  },
  components: {
    sectionCollapseHover,
    sectionCollapseAccordion,
    sectionCharts,
    sectionUpload,
    sectionTabs,
    sectionList,
    sectionForm,
    infoPanel,
    sectionDashboard
  },
  watch: {}
}
</script>

<style scoped>
.vs-inputx {
  margin: 6px !important;
}

.no-padding {
  padding: 0px !important;
}

.hovered-card {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
}
</style>