<template>
  <div>
    <div v-if="scheduleBoxesLoaded">
      <div class="columns is-centered is-multiline"
           v-for="(boxObj,boxIndex) in scheduleBoxes"
           v-bind:key="boxObj.scheduleKey">
        <div class="column is-full">
          <div class="card">
            <header class="card-header box-header-div">
              <div class="button is-danger card-header-icon card-close-button"
                   v-on:click="removeBox(boxIndex,$event)"
                   v-if="scheduleBoxes.length > 1">
                <span class="icon is-small">
                <font-awesome-icon v-bind:icon="['fas', 'minus-square']"/>
                </span>
              </div>
            </header>
            <div class="card-content">
              <div class="content">
                <div class="columns is-centered is-mobile is-multiline ">
                  <div
                      class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                    <div class="field">
                      <selectField
                          v-bind:inputData=boxObj.itemScheduleType
                          v-on:dataEvent="scheduleDataEvent"
                      />
                    </div>
                  </div>
                  <div
                      class="column is-half-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                    <p class="element-label">Time</p>
                    <vue-timepicker v-bind:minute-interval="5"
                                    close-on-complete
                                    hide-clear-button
                                    drop-direction="up"
                                    v-bind:input-class="{ 'display-time': false, 'input': true, 'is-primary': true, 'is-rounded': true, 'is-primary-forced': true, 'is-normal-input-forced': true}"
                                    v-bind:format="boxObj.timePickerFormat"
                                    v-model="boxObj.timePickerPicked"
                                    v-on:change="timePicked(boxObj.scheduleKey)">
                      <template v-slot:icon>
                        <font-awesome-icon v-bind:icon="['fas', 'clock']"/>
                      </template>
                    </vue-timepicker>
                  </div>
                  <div
                      class="column is-half-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                    <div class="field">
                      <inputField
                          v-bind:inputData=boxObj.itemScheduleDate
                          v-on:dataEvent="scheduleDataEvent"
                      />
                    </div>
                  </div>
                </div>
                <div class="columns is-centered is-mobile is-multiline ">
                  <div
                      class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                    <div class="field">
                      <selectField
                          v-bind:inputData=boxObj.itemScheduleDay
                          v-on:dataEvent="scheduleDataEvent"
                      />
                    </div>
                  </div>
                  <div
                      class="column is-half-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                    <div class="field">
                      <selectField
                          v-bind:inputData=boxObj.itemScheduleMonth
                          v-on:dataEvent="scheduleDataEvent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer class="card-footer" v-show="showBoxAddButton">
              <div class="button is-success is-fullwidth card-footer-item"
                   v-on:click="addBox($event)"
                   v-if="boxIndex === (scheduleBoxes.length - 1)">
                <font-awesome-icon v-bind:icon="['fas', 'plus-square']"/>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!scheduleBoxesLoaded"> Loading Schedule Maker...</div>
  </div>
</template>

<script>

import formFunctions from "./../../../../api/mixins/formFunctions";
import selectField from "./selectField";
import inputField from "./inputField";

export default {
  name: "scheduleMaker",
  mixins: [formFunctions],
  data() {
    return {
      scheduleBoxesLoaded: false,
      scheduleBoxes: [],
    }
  },
  props: ['scheduleData', 'scheduleFor', 'maxScheduled'],
  components: {
    selectField,
    inputField,
  },
  computed: {
    showBoxAddButton: function () {
      let vm = this;
      return (parseInt(vm.maxScheduled) === -1) ? true : vm.scheduleBoxes.length < parseInt(vm.maxScheduled);
    }
  },
  methods: {
    returnScheduleObj: function (scheduleObject) {
      let vm = this,
          scheduleId = vm.$DateTime.local().setZone("UTC").valueOf(),
          itemScheduleType = typeof scheduleObject.itemScheduleType !== 'undefined' ? scheduleObject.itemScheduleType : '',
          itemScheduleDay = typeof scheduleObject.itemScheduleDay !== 'undefined' ? scheduleObject.itemScheduleDay : '',
          itemScheduleMonth = typeof scheduleObject.itemScheduleMonth !== 'undefined' ? scheduleObject.itemScheduleMonth : '',
          itemScheduleDate = typeof scheduleObject.itemScheduleDate !== 'undefined' ? scheduleObject.itemScheduleDate : '',
          itemScheduleTime = typeof scheduleObject.itemScheduleTime !== 'undefined' ? vm.$_.split(scheduleObject.itemScheduleTime, ':') : ['', ''];


      let itemScheduleTimeData;
      if (vm.$_.trim(itemScheduleType) !== '') {
        itemScheduleTimeData = {
          inputEntered: `itemScheduleType_${scheduleId}` + '==' + `${itemScheduleType}`,
          inputInvalidity: `itemScheduleType_${scheduleId}` + '== false'
        }
        console.log(itemScheduleTimeData);
        vm.$emit('scheduleEvent', itemScheduleTimeData);
      }
      if (vm.$_.trim(itemScheduleDay) !== '') {
        itemScheduleTimeData = {
          inputEntered: `itemScheduleDay_${scheduleId}` + '==' + `${itemScheduleDay}`,
          inputInvalidity: `itemScheduleDay_${scheduleId}` + '== false'
        }
        console.log(itemScheduleTimeData);
        vm.$emit('scheduleEvent', itemScheduleTimeData);
      }
      if (vm.$_.trim(itemScheduleMonth) !== '') {
        itemScheduleTimeData = {
          inputEntered: `itemScheduleMonth_${scheduleId}` + '==' + `${itemScheduleMonth}`,
          inputInvalidity: `itemScheduleMonth_${scheduleId}` + '== false'
        }
        console.log(itemScheduleTimeData);
        vm.$emit('scheduleEvent', itemScheduleTimeData);
      }
      if (vm.$_.trim(itemScheduleDate) !== '') {
        itemScheduleTimeData = {
          inputEntered: `itemScheduleDate_${scheduleId}` + '==' + `${itemScheduleDate}`,
          inputInvalidity: `itemScheduleDate_${scheduleId}` + '== false'
        }
        console.log(itemScheduleTimeData);
        vm.$emit('scheduleEvent', itemScheduleTimeData);
      }
      if (vm.$_.trim(itemScheduleTime[0]) !== '') {
        itemScheduleTimeData = {
          inputEntered: `itemScheduleTime_${scheduleId}` + '==' + `${itemScheduleTime[0]}:${itemScheduleTime[1]}`,
          inputInvalidity: `itemScheduleTime_${scheduleId}` + '== false'
        }
        console.log(itemScheduleTimeData);
        vm.$emit('scheduleEvent', itemScheduleTimeData);
      }


      return {
        scheduleKey: `${scheduleId}`,
        timePickerFormat: 'HH:mm',
        timePickerPicked: {
          HH: itemScheduleTime[0],
          mm: itemScheduleTime[1],
        },
        itemScheduleType: {
          element: 'select',
          elementKey: '',
          elementName: `itemScheduleType_${scheduleId}`,
          initial: `${itemScheduleType}`,
          labelText: 'Type',
          placeholderText: 'Type',
          invalidText: 'Please select the schedule type',
          iconCategory: 'fas',
          icon: 'bullseye',
          type: 'dataprovided',
          showDataProvided: [
            {key: 'once', visible: true, value: 'Once'},
            {key: 'daily', visible: true, value: 'Daily'},
            {key: 'weekly', visible: true, value: 'Weekly'},
            {key: 'monthly', visible: true, value: 'Monthly'},
            {key: 'annually', visible: true, value: 'Annually'},
          ],
          dataJson: '',
          dataRequest: '',
          dataHeader: `itemScheduleType_${scheduleId}`,
          mandatory: 'no',
          canCreateNew: false,
          canSelectNone: false,
          canSelectAll: false,
          validationRules: '',
          disabled: false
        },
        itemScheduleDay: {
          element: 'select',
          elementKey: '',
          elementName: `itemScheduleDay_${scheduleId}`,
          initial: `${itemScheduleDay}`,
          labelText: 'Day',
          placeholderText: 'Day',
          invalidText: 'Please select the repetition day',
          iconCategory: 'fas',
          icon: 'bullseye',
          type: 'dataprovided',
          showDataProvided: [
            {key: 'monday', visible: true, value: 'Monday'},
            {key: 'tuesday', visible: true, value: 'Tuesday'},
            {key: 'wednesday', visible: true, value: 'Wednesday'},
            {key: 'thursday', visible: true, value: 'Thursday'},
            {key: 'friday', visible: true, value: 'Friday'},
            {key: 'saturday', visible: true, value: 'Saturday'},
            {key: 'sunday', visible: true, value: 'Sunday'},
          ],
          dataJson: '',
          dataRequest: '',
          dataHeader: `itemScheduleDay_${scheduleId}`,
          mandatory: 'no',
          canCreateNew: false,
          canSelectNone: true,
          canSelectAll: false,
          validationRules: '',
          disabled: false
        },
        itemScheduleMonth: {
          element: 'select',
          elementKey: '',
          elementName: `itemScheduleMonth_${scheduleId}`,
          initial: `${itemScheduleMonth}`,
          labelText: 'Month',
          placeholderText: 'Month',
          invalidText: 'Please select the repetition month',
          iconCategory: 'fas',
          icon: 'bullseye',
          type: 'dataprovided',
          showDataProvided: [
            {key: 'january', visible: true, value: 'January'},
            {key: 'february', visible: true, value: 'February'},
            {key: 'march', visible: true, value: 'March'},
            {key: 'april', visible: true, value: 'April'},
            {key: 'may', visible: true, value: 'May'},
            {key: 'june', visible: true, value: 'June'},
            {key: 'july', visible: true, value: 'July'},
            {key: 'august', visible: true, value: 'August'},
            {key: 'september', visible: true, value: 'September'},
            {key: 'october', visible: true, value: 'October'},
            {key: 'november', visible: true, value: 'November'},
            {key: 'december', visible: true, value: 'December'},
          ],
          dataJson: '',
          dataRequest: '',
          dataHeader: `itemScheduleMonth_${scheduleId}`,
          mandatory: 'no',
          canCreateNew: false,
          canSelectNone: true,
          canSelectAll: false,
          validationRules: '',
          disabled: false
        },
        itemScheduleDate: {
          element: 'input',
          elementKey: '',
          elementName: `itemScheduleDate_${scheduleId}`,
          initial: `${itemScheduleDate}`,
          labelText: 'Date',
          placeholderText: 'Date',
          invalidText: 'Please enter a schedule date for this item',
          iconCategory: 'fas',
          icon: 'mobile-alt',
          type: 'number',
          mandatory: 'no',
          validationRules: 'integer,true|minValue,1|maxValue,31',
          disabled: false
        }
      };
    },
    addBox: function (event) {
      if (event) {
        event.preventDefault()
      }
      let vm = this;
      vm.scheduleBoxes.push(vm.returnScheduleObj({}));
    },
    removeBox: function (boxIndexToRemove, event) {
      let vm = this;
      if (event) {
        event.preventDefault()
      }
      if (vm.scheduleBoxes.length > 1) {
        console.log(`Remove ${boxIndexToRemove}`);
        console.log(vm.scheduleBoxes[boxIndexToRemove]);
        let itemScheduleTimeData = {
          inputEntered: `itemScheduleDelete_${vm.scheduleBoxes[boxIndexToRemove].scheduleKey}` + '==' + `delete`,
          inputInvalidity: `itemScheduleTime_${vm.scheduleBoxes[boxIndexToRemove].scheduleKey}` + '==false'
        };
        console.log(itemScheduleTimeData);
        vm.$emit('scheduleEvent', itemScheduleTimeData);
        vm.scheduleBoxes.splice(boxIndexToRemove, 1);
      }
    },
    timePicked: function (scheduleKey) {
      console.log(scheduleKey);
      let vm = this,
          timeValid = true;
      vm.$_.forEach(vm.scheduleBoxes, function (scheduleItemValue) {
        if (vm.$_.trim(scheduleItemValue.scheduleKey) === vm.$_.trim(scheduleKey)) {
          console.log(scheduleItemValue.timePickerPicked);
          vm.$_.forEach(scheduleItemValue.timePickerPicked, function (timePickerPickedItem) {
            if (vm.$_.trim(timePickerPickedItem) === '') {
              timeValid = false;
            }
          });
          let itemScheduleTimeData = {
            inputEntered: `itemScheduleTime_${scheduleKey}` + '==' + `${scheduleItemValue.timePickerPicked.HH}:${scheduleItemValue.timePickerPicked.mm}`,
            inputInvalidity: `itemScheduleTime_${scheduleKey}` + '==' + !timeValid
          };
          console.log(itemScheduleTimeData);
          vm.$emit('scheduleEvent', itemScheduleTimeData);
        }
      });
    }
  },
  watch: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      if (vm.$_.trim(vm.scheduleData) === '') {
        vm.scheduleBoxes.push(vm.returnScheduleObj({}));
        vm.scheduleBoxesLoaded = true;
      } else {
        let scheduleDataObject = JSON.parse(vm.scheduleData);
        vm.$_.forEach(scheduleDataObject, function (scheduleDataObjectItem) {
          console.log(scheduleDataObjectItem);
          vm.scheduleBoxes.push(vm.returnScheduleObj(scheduleDataObjectItem));
        });
        console.log(vm.scheduleBoxes);
        vm.scheduleBoxesLoaded = true;
      }
    });
  }
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}

.box-header-div {
  background-color: hsl(171, 100%, 96%);
}

.card-close-button {
  position: absolute;
  right: 0;
}


</style>