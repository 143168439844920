import api from "./../api.js";
import queryString from "query-string";
import {required, email, minValue, maxValue, integer, helpers, requiredIf} from 'vuelidate/lib/validators';

const isDivisibleBy = function (divisor) {
 return function (dividend) {
  return helpers.req(dividend) && dividend % divisor === 0
 }
}

const requiredDependingOn = function (formModel, trueValue) {
 return function (inputValue) {
  console.log(formModel);
  console.log(trueValue);
  console.log(inputValue);
  return true;
 }
}

const hasAtLeastOneSelected = function () {
 return function (dataHere) {
  return helpers.req(dataHere)
 }
}

export default {
 data() {
  let vm = this;
  return {
   formLoadingText: 'Loading Form Elements',
   elementsLoaded: false,
   formFields: {},
   filterItemsArray: {},
   filterItemsId: '',
   formFieldsInvalidity: {},
   filterFormProcessed: {},
   formLoading: true,
   displayForm: true,
   deleteFormSelect: '',
   alwaysTrue: true,
   alwaysFalse: false,
   contactKeysMapper: require('./../../themes/chadash/assets/js/contactKeysMapper.js'),
   dataUpdateActivity: vm.$DateTime.local().setZone("UTC").valueOf()
  }
 },
 computed: {
  dateInputInvalid: function () {
   return false;
  },
  selectOptionsVisible: function () {
   let vm = this,
    filteredData = [];
   vm.$_.forEach(vm.showData, function (dataValue) {
    if (dataValue.visible) {
     filteredData.push(dataValue);
    }
   });
   return filteredData;
  },
  displayingForm: function () {
   let vm = this;
   return vm.elementsLoaded && vm.displayForm;
  },
  notDisplayingForm: function () {
   let vm = this;
   return vm.elementsLoaded && !vm.displayForm;
  },
  deletePageOrAllForm: function () {
   let vm = this;
   return vm.$_.trim(vm.deleteFormSelect) === 'PageOrAll' && vm.elementsLoaded;
  },
  deleteSingleRecordForm: function () {
   let vm = this;
   return vm.$_.trim(vm.deleteFormSelect) === 'SingleRecord' && vm.elementsLoaded;
  },
  nodeRolePicked: function () {
   let vm = this,
    nodeRolesFields = ['processingNodes', 'presentationNodes'],
    nodeRoleHasBeenPicked = false;
   try {
    if (typeof vm.formFields.nodeRoles !== 'undefined') {
     let selectedRoles = JSON.parse(vm.formFields.nodeRoles);
     vm.$_.forEach(nodeRolesFields, function (nodeRolesField) {
      if (typeof selectedRoles[nodeRolesField] !== 'undefined' && vm.$_.trim(selectedRoles[nodeRolesField]) !== '' && !vm.$_.isEmpty(selectedRoles[nodeRolesField]) && selectedRoles[nodeRolesField]) {
       nodeRoleHasBeenPicked = true;
      }
     });
    }
   } catch (error) {
    console.log(error);
   }
   return nodeRoleHasBeenPicked;
  },
  actionTypedbuser: function () {
   let vm = this,
    returnBool = vm.$_.trim(vm.formFields.actionType) === 'dbuser';
   return returnBool;
  },
  actionTypedbloader: function () {
   let vm = this,
    returnBool = vm.$_.trim(vm.formFields.actionType) === 'dbloader';
   return returnBool;
  }
 },
 methods: {
  clearInputValue: function () {
   let vm = this;
   vm.inputValue = '';
   //vm.$emit('inputEntered', {inputEntered: vm.inputName + '==' + vm.inputValue});
   vm.$emit('dataEvent', {
    inputEntered: vm.inputName + '==' + vm.inputValue,
    inputInvalidity: vm.inputName + '==' + vm.$v.inputValue.$invalid
   });
  },
  getMyFormData: function (userId) {
   let vm = this,
    authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   const formReqObj = {
     uid: userId,
     form: vm.formName
    },
    getQueries = queryString.stringify(formReqObj);
   api.operation('formdata?' + getQueries).getAll(reqHeader)
    .then(({data}) => {
     if (data.success) {
      vm.profileDataReturned = data.returnData;
     } else {
      console.log(data);
     }
    });
  },
  getMyAccountRates: function (accountId) {
   let vm = this,
    authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   const formReqObj = {
     acc: accountId,
     form: vm.formName
    },
    getQueries = queryString.stringify(formReqObj);
   api.operation('formdata?' + getQueries).getAll(reqHeader)
    .then(({data}) => {
     if (data.success) {
      vm.accountDataReturned = data.returnData;
     } else {
      console.log(data);
     }
    });
  },
  getThisFormData: function () {
   let vm = this,
    fetchData = false;
   vm.tableName = vm.formsData.data.table;
   vm.formName = vm.formsData.data.formname;
   vm.formAction = vm.formsData.data.action;
   let formDisplayBool = vm.$_.split(vm.$_.trim(vm.formAction), ':'),
    formActionName = vm.$_.trim(formDisplayBool[0]).toString().toLowerCase(),
    formActionScope = vm.$_.trim(formDisplayBool[1]).toString().toLowerCase();
   switch (formActionName) {
    case 'smstopup':
     vm.displayForm = true;
     fetchData = true;
     break;
    case 'create':
     vm.displayForm = true;
     fetchData = false;
     break;
    case 'edit':
     vm.displayForm = true;
     fetchData = true;
     break;
    case 'delete':
     vm.displayForm = false;
     if (formActionScope === 'data') {
      vm.deleteFormSelect = 'PageOrAll';
     } else {
      fetchData = true;
      vm.deleteFormSelect = 'SingleRecord';
     }
     break;
   }
   vm.dataForForm = fetchData;
   if (fetchData) {
    vm.loadApiFormData()
     .then((data) => {
      if (data) {
       if (vm.$_.isEmpty(data.returnData.form)) {
        vm.formLoadingText = 'This form has no elements';
       } else {
        if (data.returnData.form.f === vm.formsData.data.formname) {
         vm.formFieldsData = data.returnData.form.e;
         vm.formDataObjectPopulate();
         vm.formActionRequirementsLoading();
         vm.elementsLoaded = true;
         vm.formLoading = false;
        }
       }
      } else {
       console.log('no data returned');
      }
     });
   } else {
    vm.fetchApiTableFormData()
     .then((data) => {
      if (data) {
       if (vm.$_.isEmpty(data.returnData)) {
        vm.formLoadingText = 'This form has no elements';
       } else {
        if (data.returnData.f === vm.formsData.data.formname) {
         vm.formFieldsData = data.returnData.e;
         vm.formActionRequirementsLoading();
         vm.elementsLoaded = true;
         vm.formLoading = false;
        }
       }
      }
     }, (failure) => {
      console.log(failure)
     });
   }
  },
  getDeletionFormData: function () {
   let vm = this;
   vm.formLoading = true;
   vm.tableName = 'notable';
   vm.formName = 'deletionform';
   vm.formAction = vm.deletionAction;
   let formDisplayBool = vm.$_.split(vm.$_.trim(vm.formAction), ':'),
    formActionScope = vm.$_.trim(formDisplayBool[1]).toString().toLowerCase();
   vm.displayForm = false;
   if (formActionScope === 'data') {
    vm.deleteFormSelect = 'PageOrAll';
   } else {
    vm.deleteFormSelect = 'SingleRecord';
   }
   vm.fetchApiTableFormData()
    .then((data) => {
     if (data) {
      if (vm.$_.isEmpty(data.returnData)) {
       vm.formLoadingText = 'This form has no elements';
      } else {
       if (data.returnData.f === 'deletionform') {
        vm.deletionFormFieldsData = data.returnData.e;
        vm.elementsLoaded = true;
        vm.formLoading = false;
       }
      }
     }
    }, (failure) => {
     console.log(failure)
    });

  },
  formDataObjectPopulate: function () {
   let vm = this;
   vm.$_.forEach(vm.formFieldsData, function (dataItemObject, dataItemObjectKey) {
    if (typeof dataItemObject.binds !== 'undefined') {
     vm.formFields[dataItemObjectKey] = dataItemObject.binds.initial;
    }
   });
  },
  formActionRequirementsLoading: function () {
   let vm = this;
   vm.formFields.formActions = vm.formFieldsData.formActions;
   //vm.formFields.deleteRequired = vm.formFieldsData.formActions.delete.required;
   //vm.formFields.saveRequired = vm.formFieldsData.formActions.save.required;
  },
  formDataPopulate: function (dataType, sourceData, topKey, objectKey, valueType) {
   let vm = this,
    returnValue,
    returnValueString = '',
    workingObject = {},
    workingArray = [];
   switch (dataType) {
    case 'string':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      returnValue = sourceData[topKey];
     }
     returnValueString = returnValue;
     break;
    case 'number':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      if (!isNaN(sourceData[topKey])) {
       returnValue = parseInt(sourceData[topKey]);
      }
     }
     returnValueString = returnValue;
     break;
    case 'float2points':
     returnValue = '1.00';
     if (typeof sourceData[topKey] !== 'undefined') {
      if (!isNaN(sourceData[topKey])) {
       returnValue = vm.$numbro(sourceData[topKey]).format({
        thousandSeparated: false,
        mantissa: 2
       });
      }
     }
     returnValueString = returnValue;
     break;
    case 'autopopulatedstring':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      workingObject = sourceData[topKey];
      if (workingObject !== null) {
       if (typeof workingObject[objectKey] !== 'undefined') {
        returnValue = workingObject[objectKey];
       }
      }
     }
     returnValueString = returnValue;
     break;
    case 'stringfromarray':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      returnValue = vm.$_.join(sourceData[topKey], ', ');
     }
     returnValueString = returnValue;
     break;
    case 'contactsmapped':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      returnValue = vm.$_.join(sourceData[topKey], ', ');
     }
     returnValueString = returnValue;
     break;
    case 'selectvaluefromidsarray':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      if (sourceData[topKey].length > 1) {
       returnValue = objectKey.toString()
      } else {
       returnValue = sourceData[topKey][0]._id;
      }
     }
     returnValueString = returnValue;
     break;
    case 'stringfromjson':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      returnValue = JSON.stringify(sourceData[topKey]);
     }
     returnValueString = returnValue;
     break;
    case 'stringfromobject':
     returnValue = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      vm.$_.forEach(sourceData[topKey], function (dataItemObjectValue, dataItemObjectKey) {
       workingArray.push(`${dataItemObjectKey}=${dataItemObjectValue}`);
      });
      returnValue = vm.$_.join(workingArray, ', ');
     }
     returnValueString = returnValue;
     break;
    case 'namedobject':
     returnValue = {};
     returnValue[valueType] = '';
     if (typeof sourceData[topKey] !== 'undefined') {
      returnValue[valueType] = sourceData[topKey];
     }
     returnValueString = JSON.stringify(returnValue);
     break;
    case 'openobject':
     returnValue = {};
     if (typeof sourceData[topKey] !== 'undefined') {
      vm.$_.forEach(sourceData[topKey], function (dataObjectValue) {
       if (typeof dataObjectValue[objectKey] !== 'undefined') {
        switch (valueType) {
         case 'boolean':
          returnValue[dataObjectValue[objectKey]] = true;
          break;
         case 'integers':
          returnValue[dataObjectValue[objectKey]] = 1;
          break;
         default:
          break;
        }
       }
      });
     }
     returnValueString = JSON.stringify(returnValue);
     break;
    case 'opennamedobject':
     returnValue = {};
     if (typeof sourceData[topKey] !== 'undefined') {
      switch (valueType) {
       case 'boolean':
        returnValue[objectKey] = sourceData[topKey];
        break;
       case 'integers':
        returnValue[objectKey] = parseInt(sourceData[topKey]);
        break;
       case 'value':
        returnValue[objectKey] = sourceData[topKey];
        break;
       default:
        break;
      }
     }
     returnValueString = JSON.stringify(returnValue);
     break;
    default:
     break;
   }

   return returnValueString;
  },
  postFormAction: function () {
   let vm = this;
   if (!vm.$session.exists()) {
    vm.$session.start();
   }
   let authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   let strippedFormFields = vm.$_.omit(vm.formFields, ['formActions']);
   api.operation('formposting').post(strippedFormFields, reqHeader)
    .then(({data}) => {
     if (data.success) {
      vm.$emit('refreshTable', {table: data.returnData.table});
      vm.$emit('closeModal', {});
     } else {
      console.log('keep open! there was an error');
     }
    })
    .catch(error => {
     console.log(error);
    })
    .then(() => {
    });
  },
  isEmptyObjArray: function (containerObj, containerKey) {
   let vm = this,
    isArrayObject,
    VariableData = {
     isArrayObject: false,
     isEmpty: false
    };
   if (typeof containerObj[containerKey] !== 'undefined') {
    isArrayObject = vm.$_.isObject(containerObj[containerKey]) || vm.$_.isArray(containerObj[containerKey]);
    if (isArrayObject) {
     VariableData.isEmpty = vm.$_.isEmpty(containerObj[containerKey]);
    }
    VariableData.isArrayObject = isArrayObject;
   }
   return VariableData;
  },
  checkFormFields: function () {
   let vm = this,
    proceedToPost = true,
    errorsArray = [],
    isAnEmptyObjArray;
   vm.$_.forEach(vm.formFields.formActions[vm.formFields.formAction].required, function (requiredFieldObject) {
    isAnEmptyObjArray = vm.isEmptyObjArray(vm.formFields, requiredFieldObject.fieldVar);
    if ((
     typeof vm.formFields[requiredFieldObject.fieldVar] === 'undefined' ||
     vm.$_.trim(vm.formFields[requiredFieldObject.fieldVar]) === '' ||
     (isAnEmptyObjArray.isArrayObject && isAnEmptyObjArray.isEmpty)
    ) && vm[requiredFieldObject.ifTrue]) {
     proceedToPost = false;
     errorsArray.push('Please provide the ' + requiredFieldObject.fieldName);
    }
    if (vm.$_.trim(requiredFieldObject.mustBeValue) !== '' && vm.formFields[requiredFieldObject.fieldVar] !== vm.$_.trim(requiredFieldObject.mustBeValue)) {
     proceedToPost = false;
     errorsArray.push(requiredFieldObject.fieldName + ' value must be ' + vm.$_.trim(requiredFieldObject.mustBeValue));
    }
   });

   vm.$_.forEach(vm.formFieldsInvalidity, function (inputInvalid, inputName) {
    if (vm.$_.trim(inputInvalid.toString().toLowerCase()) === 'true') {
     vm.$_.forEach(vm.formFieldsData, function (inputFieldData, inputFieldName) {
      if (inputFieldName === inputName) {
       proceedToPost = false;
       errorsArray.push(inputFieldData.binds.labelText + ' has invalid data');
      }
     });
    }
   });

   vm.$emit('missingFormData', {errors: errorsArray});
   return proceedToPost;
  },
  saveForm: function () {
   let vm = this;
   vm.formFields['formAction'] = 'save';
   vm.formFields['action'] = vm.formsData.data;
   let proceedToPost = vm.checkFormFields();
   if (proceedToPost) {
    vm.postFormAction();
   }
  },
  deleteForm: function () {
   let vm = this;
   vm.formFields['formAction'] = 'delete';
   vm.formFields['action'] = vm.formsData.data;
   let proceedToPost = vm.checkFormFields();
   if (proceedToPost) {
    vm.postFormAction();
   }
  },
  payForm: function () {
   let vm = this;
   vm.formFields['formAction'] = 'pay';
   vm.formFields['action'] = vm.formsData.data;
   let proceedToPost = vm.checkFormFields();
   if (proceedToPost) {
    vm.postFormAction();
   }
  },
  dataEventTracked: function (emittedData) {
   let vm = this,
    inputEnteredData = vm.$_.split(emittedData.inputEntered, '=='),
    inputInvalidityData = vm.$_.split(emittedData.inputInvalidity, '==');
   vm.formFields[inputEnteredData[0]] = inputEnteredData[1];
   vm.formFieldsInvalidity[inputInvalidityData[0]] = inputInvalidityData[1];
   let moneyAmount,
    taxRate,
    smsRate,
    smsCreditWorth = 0,
    smsUnits = 0,
    taxAmount = 0,
    totalPayment = 0;
   switch (inputEnteredData[0]) {
    case 'payingAmount':
     // totalPaymentBeforeTax = parseInt(inputEnteredData[1]) * parseFloat(vm.formFieldsData.smsRate.binds.initial);
     // totalPaymentAfterTax = totalPaymentBeforeTax + (totalPaymentBeforeTax * (vm.formFieldsData.vatPercentage.binds.initial / 100));
     // vm.totalPayment = vm.roundNumber(vm.$numbro(totalPaymentAfterTax).format({
     //     thousandSeparated: false,
     //     mantissa: 2
     // }), 1);
     moneyAmount = parseInt(inputEnteredData[1]);
     taxRate = vm.formFieldsData.vatPercentage.binds.initial;
     smsRate = parseFloat(vm.formFieldsData.smsRate.binds.initial);

     smsCreditWorth = vm.smsCreditWorthFromMoney(moneyAmount, taxRate);
     smsUnits = vm.smsCountFromMoney(smsCreditWorth, smsRate);
     taxAmount = vm.taxDueFromMoney(smsCreditWorth, taxRate);
     totalPayment = smsCreditWorth + taxAmount;

     vm.smsCreditWorth = (isNaN(smsCreditWorth)) ? 0 : smsCreditWorth;
     vm.smsUnits = (isNaN(smsUnits)) ? 0 : smsUnits;
     vm.taxAmount = (isNaN(taxAmount)) ? 0 : taxAmount;
     vm.totalPayment = (isNaN(totalPayment)) ? 0 : totalPayment;
     break;
   }
  },
  roundNumber: function (value, precision) {
   let multiplier = Math.pow(10, precision || 0);
   return Math.round(value * multiplier) / multiplier;
  },
  smsCountFromMoney: function (moneyAmount, smsRate) {
   let smsCount = moneyAmount / smsRate;
   return Math.floor(smsCount);
  },
  smsCreditWorthFromMoney: function (moneyAmount, taxRate) {
   let vm = this,
    smsCreditWorth = (100 * moneyAmount) / (100 + parseFloat(taxRate));
   return vm.roundNumber(smsCreditWorth, 2);
  },
  taxDueFromMoney: function (moneyAmount, taxRate) {
   let vm = this;
   let taxCount = (taxRate * moneyAmount) / 100;
   return vm.roundNumber(taxCount, 2);
  },
  dataEvent: function (emittedData) {
   let vm = this,
    inputEnteredData = vm.$_.split(emittedData.inputEntered, '=='),
    inputInvalidityData = vm.$_.split(emittedData.inputInvalidity, '==');
   vm.formFields[inputEnteredData[0]] = inputEnteredData[1];
   vm.formFieldsInvalidity[inputInvalidityData[0]] = inputInvalidityData[1];
   vm.dataUpdateActivity = vm.$DateTime.local().setZone("UTC").valueOf();
   // console.log(vm.dataUpdateActivity);
   // console.log(inputEnteredData);
   // console.log(JSON.stringify(vm.formFields));
   switch (inputEnteredData[0]) {
    case 'salesOfferInteractionType':
     vm.interactionType = inputEnteredData[1];
     break;
    case 'autoReportType':
     vm.filterItemsId = inputEnteredData[1];
     break;
    default:
     vm.dataEventPostProcess();
     break;
   }
  },
  filtersInvalidate: function () {

   let vm = this,
    filterFields = ['itemFilterColumn', 'itemFilterOperation', 'itemFilterValue', 'itemFilterDelete', 'itemFilterBoxDelete'],
    deletedElements = {};

   console.log(vm.filterFormProcessed);
   console.log(vm.filterItemsId);

   if (typeof vm.filterFormProcessed.activeFilterId !== 'undefined' && typeof vm.filterItemsId !== 'undefined') {
    if (vm.filterFormProcessed.activeFilterId !== vm.filterItemsId) {
     vm.$_.forEach(vm.formFields, function (formFieldValue, formFieldKey) {
      vm.$_.forEach(filterFields, function (filterField) {
       if (vm.$_.startsWith(formFieldKey, filterField)) {
        deletedElements[formFieldKey] = 'InvalidatedByFilterId';
       }
      });
     });

     console.log(deletedElements);

     vm.formFields = vm.$_.assign({}, vm.formFields, deletedElements);
     vm.filterFormProcessed = vm.$_.assign({}, vm.filterFormProcessed, {
      activeFilterId: vm.filterItemsId,
      activeFilterElements: [],
      activeFilterElementsDelete: [],
      activeFilterElementsSource: []
     });
    }
   } else {
    vm.filterFormProcessed = vm.$_.assign({}, vm.filterFormProcessed, {
     activeFilterId: vm.filterItemsId,
     activeFilterElements: [],
     activeFilterElementsDelete: [],
     activeFilterElementsSource: []
    });
   }

   console.log(vm.filterFormProcessed);
   console.log(vm.formFields);
  },
  dataEventPostProcess: function () {
   let vm = this,
    filterFields = ['itemFilterColumn', 'itemFilterOperation', 'itemFilterValue', 'itemFilterDelete', 'itemFilterBoxDelete'],
    filterFormFields = [];
   // console.log(vm.formFields);
   // console.log(vm.formFieldsInvalidity);

   vm.$_.forEach(vm.formFields, function (formFieldValue, formFieldKey) {
    vm.$_.forEach(filterFields, function (filterField) {
     if (vm.$_.startsWith(formFieldKey, filterField)) {
      if (vm.formFieldsInvalidity[formFieldKey] === 'false' && formFieldValue !== 'InvalidatedByFilterId') {
       filterFormFields.push({fieldKey: formFieldKey, fieldValue: formFieldValue});
      }
     }
    });
   });
   if (!vm.$_.isEmpty(filterFormFields)) {
    console.log(filterFormFields);
    vm.filterTexter(filterFormFields);
   }

  },
  filterTexter: function (filterFormFields) {
   console.log(filterFormFields);
   let vm = this,
    deletionKeys = ['itemFilterDelete', 'itemFilterBoxDelete'],
    deletedObj = {itemFilterDelete: [], itemFilterBoxDelete: []},
    filterTextParts = [],
    filterTextMap = [];


   console.log(vm.filterFormProcessed);


   vm.$_.forEach(filterFormFields, function (filterFormField) {
    vm.$_.forEach(deletionKeys, function (deletionKey) {
     if (vm.$_.startsWith(filterFormField.fieldKey, deletionKey)) {
      filterTextParts = vm.$_.split(filterFormField.fieldKey, '_');
      switch (deletionKey) {
       case 'itemFilterDelete':
        deletedObj.itemFilterDelete.push(filterTextParts[2]);
        break;
       case 'itemFilterBoxDelete':
        deletedObj.itemFilterBoxDelete.push(filterTextParts[1]);
        break;

      }
     }
    });
   });

   console.log(deletedObj);

   vm.$_.forEach(filterFormFields, function (filterFormField) {
    filterTextParts = vm.$_.split(filterFormField.fieldKey, '_');
    filterTextMap.push(vm.$_.assign({}, {
     DataType: filterTextParts[0],
     boxId: filterTextParts[1],
     fieldId: filterTextParts[2]
    }, filterFormField));
   });

   console.log(filterTextMap);

   filterTextMap = vm.$_.uniqBy(vm.$_.orderBy(filterTextMap, ['boxId', 'fieldId'], ['asc', 'asc']), 'fieldKey');

   console.log(filterTextMap);

   vm.filterFormProcessed = vm.$_.assign({}, vm.filterFormProcessed, {
    activeFilterElements: filterTextMap,
    activeFilterElementsDelete: deletedObj,
    activeFilterElementsSource: vm.filterItemsArray[vm.filterItemsId]
   });

   console.log(vm.filterItemsArray);
   console.log(vm.filterItemsId);
   console.log(vm.filterItemsArray[vm.filterItemsId]);

   console.log(vm.filterFormProcessed);
  },
  filterDataReady: function (emittedData) {
   console.log(emittedData);
   let vm = this;
   console.log(vm.formFields);
   if (typeof emittedData.filterData !== 'undefined' && !vm.$_.isEmpty(emittedData.filterData)) {
    vm.filterItemsArray = emittedData.filterData;
   }
  },
  scheduleDataEvent: function (emittedData) {
   let vm = this;
   vm.$emit('scheduleEvent', emittedData);
  },
  filteringDataEvent: function (emittedData) {
   let vm = this;
   console.log(emittedData);
   vm.$emit('filterEvent', vm.$_.assign({}, emittedData, {filtersId: vm.filterItemsIdReceived}));
  },
  complexDateSet: function (emittedData) {
   //let vm = this;
   console.log(emittedData);
  },
  bubbleDataEvent: function (emittedData) {
   let vm = this;
   vm.$emit('dataEvent', emittedData);
  },
  closeDataModal: function () {
   let vm = this;
   vm.$emit('closeModal', {});
  },
  refreshModalTable: function (emittedData) {
   let vm = this;
   vm.$emit('refreshTable', emittedData);
  },
  boxEvent: function (emittedData) {
   let vm = this,
    inputEnteredData = vm.$_.split(emittedData.inputEntered, '=='),
    inputInvalidityData = vm.$_.split(emittedData.inputInvalidity, '==');
   vm.formFields[inputEnteredData[0]] = inputEnteredData[1];
   vm.formFieldsInvalidity[inputInvalidityData[0]] = inputInvalidityData[1];
  }
 },
 watch: {
  inputValue: {
   handler: function () {
    let vm = this;
    if (typeof vm.inputValue !== 'undefined') {
     //vm.$emit('inputEntered', {inputEntered: vm.inputName + '==' + vm.inputValue});
     //vm.$emit('inputInvalidity', {inputInvalidity: vm.inputName + '==' + vm.$v.inputValue.$invalid});

     let EmitValue;
     if (typeof vm.dateData !== 'undefined' && vm.$_.trim(vm.dateData) !== '') {
      EmitValue = vm.dateData;
     } else {
      EmitValue = vm.inputValue;
     }
     vm.$emit('dataEvent', {
      inputEntered: vm.inputName + '==' + EmitValue,
      inputInvalidity: vm.inputName + '==' + vm.$v.inputValue.$invalid
     });
    }
   },
   deep: true
  },
  inputValues: {
   handler: function () {
    let vm = this;
    if (typeof vm.inputValues !== 'undefined') {
     let EmitValue= vm.inputValues;
     vm.$emit('dataEvent', {
      inputEntered: vm.inputName + '==' + JSON.stringify(EmitValue),
      inputInvalidity: vm.inputName + '==false'
     });
    }
   },
   deep: true
  },
  checkRadioSelected: {
   handler: function () {
    let vm = this,
     checkRadioInvalid = false;
    if (typeof vm.checkRadioSelected !== 'undefined' && !vm.$_.isEmpty(vm.checkRadioSelected)) {
     if (typeof vm.$v.checkRadioSelected.hasAtLeastOneSelected !== 'undefined') {
      vm.$_.forEach(vm.checkRadioSelected, function (checkValue, checkKey) {
       if (!checkValue) {
        vm.checkRadioSelected = vm.$_.omit(vm.checkRadioSelected, [checkKey]);
       }
      });
      checkRadioInvalid = vm.$v.checkRadioSelected.$invalid
     }
     vm.$emit('dataEvent', {
      inputEntered: vm.inputName + '==' + JSON.stringify(vm.checkRadioSelected),
      inputInvalidity: vm.inputName + '==' + checkRadioInvalid
     });
    }
   },
   deep: true
  },
  formActionButton: {
   handler: function () {
    let vm = this,
     actionTime = vm.$_.split(vm.$_.trim(vm.formActionButton), ':'),
     action = actionTime[0];
    switch (action) {
     case 'save':
      vm.saveForm();
      break;
     case 'delete':
      vm.deleteForm();
      break;
     case 'pay':
      vm.payForm();
      break;
     default:
      console.log('Unknown action: ' + action);
    }
   },
   deep: true
  }
 },
 validations() {
  let vm = this,
   validationObj,
   mustBeWord;
  switch (vm.$_.trim(vm.isMandatory)) {
   case 'yes':
    validationObj = {
     inputValue: {
      required
     },
     checkRadioSelected: {
      required
     }
    };
    break;
   case 'no':
    validationObj = {
     inputValue: {},
     checkRadioSelected: {}
    };
    break;
   default:
    mustBeWord = (value) => value.indexOf(vm.$_.trim(vm.isMandatory)) >= 0;
    vm.mustBeWordInputInvalidText = 'The text must be: ' + vm.$_.trim(vm.isMandatory);
    validationObj = {
     inputValue: {
      required,
      mustBeWord
     },
     checkRadioSelected: {
      required,
      mustBeWord
     }
    };
    break;
  }

  if (vm.$_.trim(vm.inputType) === 'email') {
   validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {email});
   validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {email});
   vm.mustBeEmailInputInvalidText = 'The text must be an email';
  }

  if (vm.$_.trim(vm.validationRules) !== '') {
   let validationParts = vm.$_.split(vm.$_.trim(vm.validationRules), '|'),
    requiredParams,
    requiredIfObject = {};
   vm.$_.forEach(validationParts, function (validationPart) {
    let validationParts = vm.$_.split(vm.$_.trim(validationPart), ',');
    switch (validationParts[0]) {
     case 'minValue':
      validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {minValue: minValue(parseInt(validationParts[1]))});
      validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {minValue: minValue(parseInt(validationParts[1]))});
      vm.minValueInputInvalidText = 'The minimum value acceptable is ' + validationParts[1];
      break;
     case 'maxValue':
      validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {maxValue: maxValue(parseInt(validationParts[1]))});
      validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {maxValue: maxValue(parseInt(validationParts[1]))});
      vm.maxValueInputInvalidText = 'The maximum value acceptable is ' + validationParts[1];
      break;
     case 'integer':
      if (validationParts[1].toString() === 'true') {
       validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {integer});
       validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {integer});
       vm.integerInputInvalidText = 'The field only accepts integers';
      }
      break;
     case 'requiredIf':
      requiredParams = vm.$_.split(vm.$_.trim(validationParts[1].toString()), '=');
      requiredIfObject = {
       required: requiredIf(`'${vm.$_.trim(requiredParams[0].toString())}${vm.$_.trim(requiredParams[1].toString())}'`)
      };
      validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, requiredIfObject);
      validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, requiredIfObject);
      vm.requiredIfInvalidText = 'The field is required';
      break;
     case 'isDivisibleBy':
      validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {isDivisibleBy: isDivisibleBy(parseInt(validationParts[1]))});
      validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {isDivisibleBy: isDivisibleBy(parseInt(validationParts[1]))});
      vm.isDivisibleByInvalidText = 'The value must be divisible by ' + validationParts[1];
      break;
     case 'requiredDependingOn':
      requiredParams = vm.$_.split(vm.$_.trim(validationParts[1].toString()), '=');
      validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {requiredDependingOn: requiredDependingOn(requiredParams[0], requiredParams[1])});
      validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {requiredDependingOn: requiredDependingOn(requiredParams[0], requiredParams[1])});
      vm.requiredDependingOnInvalidText = 'Blas blas';
      break;
     case 'hasAtLeastOneSelected':
      validationObj.inputValue = vm.$_.assign({}, validationObj.inputValue, {hasAtLeastOneSelected: hasAtLeastOneSelected()});
      validationObj.checkRadioSelected = vm.$_.assign({}, validationObj.checkRadioSelected, {hasAtLeastOneSelected: hasAtLeastOneSelected()});
      vm.hasAtLeastOneSelectedInputInvalidText = 'At least one option must be selected'
      break;
     default:
      break;
    }
   });
  }
  return validationObj;
 }
};