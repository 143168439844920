<template>
    <div>
        <span v-show="false" v-if="idleTiming">
            <v-idle v-on:idle="onIdleTimeLapse"
                    v-on:remind="onIdleTimeReminder"
                    v-bind:reminders="idleReminders"
                    v-bind:loop="idleLoopTime"
                    v-bind:events="idleResetEvents"
                    v-bind:wait="idleWaitTime"
                    v-bind:duration="idleDurationTime"/>
        </span>
        <section v-for="(section, key) in mySections"
                 v-bind:class="section.class"
                 v-bind:key="section.mykey"
                 v-bind:id="section.mykey">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title" v-if="section.imageTitle===''">
                        <span class="section-title-guide">{{ key }}</span>
                    </h1>
                    <h1 class="title" v-else>
                        <img v-bind:src="getImgUrl(section.imageTitle)" class="section-title-image section-title-guide">
                    </h1>
                    <h2 class="subtitle section-subtitle" v-if="getJokeTrigger(section.caption)">
						<span v-if="jokeOfTheMoment.text===''" class="section-subtitle-guide">
							<font-awesome-icon v-bind:icon="['fas', 'grin']"/>
						</span>
                        <span v-else class="section-subtitle-guide">
							{{ jokeOfTheMoment.text }}
						</span>
                    </h2>
                    <h2 class="subtitle section-subtitle" v-else>
                        <span class="section-subtitle-guide">{{ section.caption }}</span>
                    </h2>
                    <div class="columns is-multiline">
                        <!--Standalone modules here-->
                        <div v-for="(subsection, subkey) in section.v4subsections.standalone" class="column"
                             v-bind:class="columnClass(subsection)" v-bind:key="subsection.mykey"
                             v-bind:id="subsection.mykey">
                            <sectionCard
                                    v-if="subsection.type==='card'"
                                    v-bind:subsection="subsection"
                                    v-bind:subkey="subkey"
                                    v-bind:myRole="myRole"
                                    v-bind:refreshMe="refreshMe"
                                    v-bind:activeCard="'active'"
                                    v-bind:inAccordion="'no'"
                                    v-bind:canResize="columnCanResize(subsection)"
                                    v-on:formModal="showFormModal"
                                    v-on:tabbedModal="showTabbedModal"
                                    v-on:dataModal="showDataModal"
                                    v-on:closeModal="closeDataModal"
                                    v-on:refreshTable="refreshModalTable"
                                    v-on:useThisData="useThisDataRow"
                                    v-on:listAction="doListAction"
                                    v-bind:dataSelected="dataSelected"
                                    v-on:changeColWidth="setColumnwidth"
                            />
                            <sectionCollapseAccordion
                                    v-if="subsection.type==='accordioncollapse'"
                                    v-bind:subsection="subsection"
                                    v-bind:subkey="subkey"
                                    v-bind:myRole="myRole"
                                    v-bind:activeCard="'active'"
                                    v-bind:dataSelected="dataSelected"
                            />
                            <sectionForm
                                    v-if="subsection.type==='form'"
                                    v-on:dataModal="showDataModal"
                                    v-on:closeModal="closeDataModal"
                                    v-on:refreshTable="refreshModalTable"
                                    v-bind:formsData="{subsection:subsection, subkey:subkey, myRole:myRole, dataSelected:dataSelected}"
                            />
                        </div>
                        <!--inAccordion modules here -->
                        <div v-for="(subsection) in section.v4subsections.inAccordion" class="column"
                             v-bind:class="columnClass(subsection)" v-bind:key="subsection.mykey"
                             v-bind:id="subsection.mykey">
                            <h3>
                <span class="accordion-title">
                      {{ subsection.accordionTitle }}
                </span>
                            </h3>

                            <ul class="geAcc">
                                <li v-for="(tab,index) in subsection.accordionTabs" v-bind:key="tab.tabTitle"
                                    v-bind:class="['geAcc__tile', {'geAccT--active': tabActivation[`${subsection.mykey}_${index}`] === 'active'}]"
                                    v-on:click="activateTab(subsection.mykey,index)">
                                    <div class="accordion-content">
                                        <sectionCard
                                                v-if="tab.type==='card'"
                                                v-bind:subsection="tab"
                                                v-bind:subkey="tab.tabTitle"
                                                v-bind:myRole="myRole"
                                                v-bind:refreshMe="refreshMe"
                                                v-bind:activeCard="tabActivation[`${subsection.mykey}_${index}`]"
                                                v-bind:inAccordion="'yes'"
                                                v-bind:canResize="columnCanResize(subsection)"
                                                v-on:formModal="showFormModal"
                                                v-on:tabbedModal="showTabbedModal"
                                                v-on:dataModal="showDataModal"
                                                v-on:closeModal="closeDataModal"
                                                v-on:refreshTable="refreshModalTable"
                                                v-on:useThisData="useThisDataRow"
                                                v-on:listAction="doListAction"
                                                v-bind:dataSelected="dataSelected"
                                                v-on:changeColWidth="setColumnwidth"
                                        />
                                        <sectionCollapseAccordion
                                                v-if="tab.type==='accordioncollapse'"
                                                v-bind:subsection="tab"
                                                v-bind:subkey="tab.tabTitle"
                                                v-bind:myRole="myRole"
                                                v-bind:activeCard="tabActivation[`${subsection.mykey}_${index}`]"
                                                v-bind:dataSelected="dataSelected"
                                        />
                                        <sectionForm
                                                v-if="tab.type==='form'"
                                                v-on:dataModal="showDataModal"
                                                v-on:closeModal="closeDataModal"
                                                v-on:refreshTable="refreshModalTable"
                                                v-bind:formsData="{
                        subsection:tab,
                        subkey:tab.tabTitle,
                        myRole:myRole,
                        dataSelected:dataSelected
                      }"
                                        />
                                    </div>
                                    <div class="geAcc__tile__bar" v-bind:class="tab.tabTitleColor">
                                        <span>{{ tab.tabTitle }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <a class="button is-info tour-button" v-on:click="startPageTour" v-if="showTour" v-show="showTour">
      <span class="icon">
      <font-awesome-icon v-bind:icon="['fas', 'map-signs']"/>
      </span>
            <span>Guide</span>
        </a>
        <span v-if="showTour" v-show="showTour">
      <v-tour name="nonMobileTour" :steps="nonMobileTourSteps" :callbacks="myCallbacks"></v-tour>
      <v-tour name="mobileTour" :steps="mobileTourSteps" :callbacks="myCallbacks"></v-tour>
    </span>
    </div>
</template>

<script>
//import axios from 'axios'
import sectionCard from './partials/cards.vue';
import sectionCollapseAccordion from './partials/collapseAccordion.vue';
import sectionForm from './partials/sectionsForm.vue';
import api from "./../../api/api.js";
import jokes from "./assets/js/jokeStarters.js";

export default {
 name: "accountSections",
 data() {
  let vm = this;
  return {
   selectedColumnWidthDefault: {},
   customColumnWidth: false,
   jokeOfTheMoment: {text: '', fetched: false},
   nonMobileTourSteps: require('./assets/js/tour-guide-nonmobile'),
   mobileTourSteps: require('./assets/js/tour-guide-mobile'),
   myCallbacks: {
    onPreviousStep: vm.myCustomPreviousStepCallback,
    onNextStep: vm.myCustomNextStepCallback
   },
   tabActivation: {},
   idleTiming: false,
   idleReminders: [5, 10, 20, 60],
   idleWaitTime: 100,
   idleDurationTime: 1800,
   idleResetEvents: ['mousemove', 'keypress'],
   idleLoopTime: true
  }
 },
 props: ['mySections', 'myRole', 'isAdmin', 'dataSelected', 'refreshMe', 'showTour'],
 computed: {},
 methods: {
  setColumnwidth: function (emittedData) {
   let vm = this;
   vm.customColumnWidth = true;
   vm.$_.forEach(vm.mySections, function (sectionData, sectionIndex) {

    vm.$_.forEach(sectionData.v4subsections.standalone, function (subSectionData, subSectionIndex) {
     if (subSectionData.mykey === emittedData.myKey) {
      if (vm.isAdmin) {
       vm.mySections[sectionIndex].v4subsections.standalone[subSectionIndex].admincolumnclass = emittedData.newSize;
      } else {
       vm.mySections[sectionIndex].v4subsections.standalone[subSectionIndex].columnclass = emittedData.newSize;
      }
     }
    });

    vm.$_.forEach(sectionData.v4subsections.inAccordion, function (subSectionData, subSectionIndex) {
     if (subSectionData.mykey === emittedData.myKey) {
      if (vm.isAdmin) {
       vm.mySections[sectionIndex].v4subsections.inAccordion[subSectionIndex].admincolumnclass = emittedData.newSize;
      } else {
       vm.mySections[sectionIndex].v4subsections.inAccordion[subSectionIndex].columnclass = emittedData.newSize;
      }
     }
    });

   });
  },
  columnClass: function (subsectionData) {
   let vm = this;
   if (vm.isAdmin) {
    return subsectionData.admincolumnclass;
   } else {
    return subsectionData.columnclass;
   }
  },
  columnCanResize: function (subsectionData) {
   // console.log(subsectionData.canresize);

   if (subsectionData.canresize) {
    return 'yes';
   } else {
    return 'no';
   }
  },
  getImgUrl: function (imageName) {
   return require('./assets/img/' + imageName);
  },
  getJoke: function () {
   let vm = this;
   let reqJokeHeader = {
    headers: {
     Accept: 'application/json',
    }
   };
   api.operation('').getJoke(reqJokeHeader)
    .then(function ({data}) {
     if (parseInt(data.status) === 200) {
      vm.jokeOfTheMoment.text = data.joke;
      vm.jokeOfTheMoment.fetched = true;
     } else {
      vm.jokeOfTheMoment.text = vm.$_.sample(jokes);
      vm.jokeOfTheMoment.fetched = true;
     }
    })
    .catch(function (error) {
     console.error(error);
     vm.jokeOfTheMoment.text = vm.$_.sample(jokes);
     vm.jokeOfTheMoment.fetched = true;
    });
  },
  getJokeTrigger: function (caption) {
   let vm = this;
   if (caption === 'JOTD') {
    if (!vm.jokeOfTheMoment.fetched) {
     vm.getJoke();
    }
    return true;
   } else {
    return false
   }
  },
  showFormModal: function (emittedData) {
   let vm = this;
   vm.$emit('formModal', emittedData);
  },
  showTabbedModal: function (emittedData) {
   let vm = this;
   vm.$emit('tabbedModal', emittedData);
  },
  showDataModal: function (emittedData) {
   let vm = this;
   vm.$emit('dataModal', emittedData);
  },
  closeDataModal: function (emittedData) {
   let vm = this;
   vm.$emit('closeModal', emittedData);
  },
  refreshModalTable: function (emittedData) {
   let vm = this;
   vm.$emit('refreshTable', emittedData);
  },
  useThisDataRow: function (emittedData) {
   let vm = this;
   vm.$emit('useThisData', emittedData);
  },
  doListAction: function (emittedData) {
   let vm = this;
   vm.$emit('listAction', emittedData);
  },
  startPageTour: function () {
   let vm = this;
   if (vm.$mobileDeviceDetect.isMobile) {
    vm.$tours['mobileTour'].start();
   } else {
    vm.$tours['nonMobileTour'].start();
   }
  },
  myCustomPreviousStepCallback: function (currentStep) {
   let vm = this,
    activeSteps,
    parentSection;
   if (vm.$mobileDeviceDetect.isMobile) {
    activeSteps = vm.mobileTourSteps;
   } else {
    activeSteps = vm.nonMobileTourSteps;
   }
   if (typeof activeSteps[currentStep - 1] !== 'undefined') {
    if (activeSteps[currentStep - 1].scrollTo) {
     switch (activeSteps[currentStep - 1].scrollTo) {
      case 'sectionParent':
       parentSection = document.querySelector(activeSteps[currentStep - 1].target).closest("section.section-element");
       vm.$scrollTo(parentSection);
       break;
     }
    }
   }
  },
  myCustomNextStepCallback: function (currentStep) {
   let vm = this,
    activeSteps,
    parentSection;
   if (vm.$mobileDeviceDetect.isMobile) {
    activeSteps = vm.mobileTourSteps;
   } else {
    activeSteps = vm.nonMobileTourSteps;
   }
   if (typeof activeSteps[currentStep + 1] !== 'undefined') {
    if (activeSteps[currentStep + 1].scrollTo) {
     switch (activeSteps[currentStep + 1].scrollTo) {
      case 'sectionParent':
       parentSection = document.querySelector(activeSteps[currentStep + 1].target).closest("section.section-element");
       vm.$scrollTo(parentSection);
       break;
     }
    }
   }
  },
  activateTab: function (accordionTitle, accordionIndex) {
   let vm = this,
    splitTabId;
    console.log(vm.tabActivation);
    console.log(accordionTitle);
    console.log(accordionIndex);
   vm.tabActivation[`${accordionTitle}_${accordionIndex}`] = 'active';
   vm.$_.forEach(vm.tabActivation, function (tabActivationValue, tabActivationKey) {
    splitTabId = vm.$_.split(tabActivationKey, '_');
    if (
     (vm.$_.trim(splitTabId[0]) === vm.$_.trim(accordionTitle)) &&
     (vm.$_.trim(splitTabId[1]) !== vm.$_.trim(accordionIndex.toString()))
    ) {
     vm.tabActivation[tabActivationKey] = 'inactive';
    }
   });
   console.log(vm.tabActivation);
   vm.tabActivation = JSON.parse(JSON.stringify(vm.tabActivation));
  },
  onIdleTimeLapse: function () {
   let vm = this;
   vm.$notify({
    group: 'idle-logoff-notification',
    title: `Idle time logoff`,
    text: `Due to inactivity, you have been automatically logged out now.`,
    duration: -1,
    max: 1,
    position: 'top center'
   });
   setTimeout(function () {
    window.location.href = '/logout'
   }, 1000);
  },
  onIdleTimeReminder: function (time) {
   let vm = this;
   vm.$notify({
    group: 'idle-warning-notification',
    title: `Idle time logoff warning`,
    text: `Due to inactivity, you will automatically be logged out in ${time} seconds if you remain inactive.`,
    duration: -1,
    max: 1,
    position: 'bottom left'
   });
  }
 },
 components: {
  sectionCard,
  sectionCollapseAccordion,
  sectionForm
 },
 mounted: function () {
  let vm = this;
  vm.$nextTick(function () {
   // console.log(vm.$session.get('jwt'));
   // console.log(window.location.pathname);
   vm.idleTiming = window.location.pathname === '/account' && typeof vm.$session.get('jwt') !== 'undefined';
   vm.$_.forEach(vm.mySections, function (sectionData) {
    vm.$_.forEach(sectionData.v4subsections.inAccordion, function (subSectionData) {
     vm.$_.forEach(subSectionData.accordionTabs, function (subsection, subsectionIndex) {
      vm.tabActivation[`${subSectionData.mykey}_${subsectionIndex}`] = 'inactive'
     });
    });
   });
   vm.tabActivation = JSON.parse(JSON.stringify(vm.tabActivation));
  });
 }
}
</script>

<style scoped>
.card-text {
    color: black;
}

.section-title-image {
    width: 300px;
}

.tour-button {
    position: fixed !important;
    bottom: 10px !important;
    right: 10px !important;
}

.bold-white-text {
    color: white !important;
    font-weight: bold !important;
}
</style>