<template>
  <div>
    <div v-if="parentBoxesLoaded">
      <div v-if="interactionType===''">
        Select an interaction type
      </div>
      <div v-if="interactionType==='buttons'">
        <div
            class="columns is-centered is-multiline"
            v-for="(parentBoxObj,parentBoxIndex) in parentBoxes"
            v-bind:key="parentBoxObj.buttonId">
          <div class="column is-full">
            <div class="field has-addons">
              <div class="control is-expanded">
                <input type="text"
                       v-bind:placeholder="'Button Title'"
                       class="input is-primary is-rounded"
                       v-model.trim="parentBoxes[parentBoxIndex].buttonTitle"
                />
              </div>
              <div class="control">
                <a class="button is-info is-static">
                  {{ textCounts[`Button${parentBoxIndex}buttonTitle`] }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="interactionType==='list'">
        <div class="columns is-centered is-multiline">
          <div class="column is-full">
            <div class="field has-addons">
              <div class="control is-expanded">
                <input type="text"
                       v-bind:placeholder="'Button Text'"
                       class="input is-primary is-rounded"
                       ref="sectionTitle"
                       v-model.trim="parentBoxes[0].parentBoxTitle"
                />
              </div>
              <div class="control">
                <a class="button is-info is-static">
                  {{ textCounts[`parentBoxTitle`] }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-centered is-multiline"
             v-for="(boxObj,boxIndex) in parentBoxes[1]"
             v-bind:key="boxObj.parentBoxKey">
          <div class="column is-full">
            <div class="card">
              <header class="card-header box-header-div">
                <div class="card-header-title box-header-div">
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input type="text"
                             v-bind:placeholder="'Section Title'"
                             class="input is-primary is-rounded"
                             ref="sectionTitle"
                             v-model.trim="parentBoxes[1][boxIndex].parentBoxName"
                      />
                    </div>
                    <div class="control">
                      <a class="button is-info is-static">
                        {{ textCounts[`Box${boxIndex}parentBoxName`] }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="button is-danger card-header-icon"
                     v-on:click="removeBox(boxIndex,$event)"
                     v-if="parentBoxes[1].length > 1">
                  <span class="icon is-small">
                  <font-awesome-icon v-bind:icon="['fas', 'minus-square']"/>
                  </span>
                </div>
              </header>
              <div class="card-content">
                <div class="content">
                  <div
                      class="columns is-centered is-multiline"
                      v-for="(rowObj,rowIndex) in parentBoxes[1][boxIndex].parentBoxRows"
                      v-bind:key="rowObj.parentBoxRowKey">
                    <div class="column is-4">
                      <div class="field has-addons">
                        <div class="control is-expanded">
                          <input type="text"
                                 v-bind:placeholder="'Option Title'"
                                 class="input is-primary is-rounded"
                                 v-model.trim="parentBoxes[1][boxIndex].parentBoxRows[rowIndex].parentBoxRowTitle"
                          />
                        </div>
                        <div class="control">
                          <a class="button is-info is-static">
                            {{ textCounts[`Box${boxIndex}Row${rowIndex}parentBoxRowTitle`] }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field has-addons">
                        <div class="control is-expanded">
                      <textarea
                          v-bind:placeholder="'Option Description'"
                          class="textarea is-primary is-rounded"
                          rows="2"
                          v-model.trim="parentBoxes[1][boxIndex].parentBoxRows[rowIndex].parentBoxRowDesc"
                          v-on:input="textareaResize(`Box${boxIndex}Row${rowIndex}`)"
                          v-bind:ref="`Box${boxIndex}Row${rowIndex}`"></textarea>
                        </div>
                        <div class="control">
                          <a class="button is-info is-static is-tall-button">
                            {{ textCounts[`Box${boxIndex}Row${rowIndex}parentBoxRowDesc`] }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="column is-2">
                      <div class="field has-addons">
                        <div class="control is-expanded"
                             v-if="parentBoxes[1][boxIndex].parentBoxRows.length > 1">
                          <div class="button is-danger is-small"
                               v-on:click="removeRow(boxIndex,rowIndex,$event)"
                               v-bind:class="{'is-fullwidth':rowIndex !== (parentBoxes[1][boxIndex].parentBoxRows.length - 1)}">
                            <font-awesome-icon v-bind:icon="['fas', 'minus-square']"/>
                          </div>
                        </div>
                        <div class="control is-expanded"
                             v-if="rowIndex === (parentBoxes[1][boxIndex].parentBoxRows.length - 1)">
                          <div class="button is-success is-small"
                               v-on:click="addRow(boxIndex,rowIndex,$event)">
                            <font-awesome-icon v-bind:icon="['fas', 'plus-square']"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <div class="button is-success is-fullwidth card-footer-item"
                     v-on:click="addBox($event)"
                     v-if="boxIndex === (parentBoxes[1].length - 1)">
                  <font-awesome-icon v-bind:icon="['fas', 'plus-square']"/>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!parentBoxesLoaded"> Loading Options...</div>
  </div>
</template>

<script>
export default {
  name: "boxSection",
  props: ['boxFor', 'interactionType', 'boxData'],
  mixins: [],
  components: {},
  data() {
    // let vm = this;
    return {
      parentBoxesLoaded: false,
      parentBoxes: [],
      textCounts: {},
      textLimits: {
        buttonTitle: 20,
        parentBoxTitle: 20,
        parentBoxName: 24,
        parentBoxRowTitle: 24,
        parentBoxRowDesc: 72
      }
    }
  },
  methods: {
    addBox: function (event) {
      let vm = this,
          boxId = vm.$moment().valueOf();
      if (event) {
        event.preventDefault()
      }

      vm.parentBoxes[1].push({
        parentBoxName: ``,
        parentBoxKey: `section${boxId}`,
        parentBoxRows: [{parentBoxRowTitle: '', parentBoxRowDesc: '', parentBoxRowKey: `section${boxId}row${boxId}`}]
      });
    },
    removeBox: function (boxIndexToRemove, event) {
      let vm = this;
      if (event) {
        event.preventDefault()
      }
      if (vm.parentBoxes[1].length > 1) {
        vm.parentBoxes[1].splice(boxIndexToRemove, 1);
      }
    },
    addRow: function (boxIndex, rowIndexAfter, event) {
      let vm = this,
          rowId = vm.$moment().valueOf();
      if (event) {
        event.preventDefault()
      }
      vm.parentBoxes[1][boxIndex].parentBoxRows.push({
        parentBoxRowTitle: ``,
        parentBoxRowDesc: ``,
        parentBoxRowKey: `section${rowId}row${rowId}`
      });
    },
    removeRow: function (boxIndex, rowIndexToRemove, event) {
      let vm = this;
      if (event) {
        event.preventDefault()
      }
      if (vm.parentBoxes[1][boxIndex].parentBoxRows.length > 1) {
        vm.parentBoxes[1][boxIndex].parentBoxRows.splice(rowIndexToRemove, 1);
      }
    },
    textareaResize: function (textAreaRef) {
      this.$refs[textAreaRef][0].style.minHeight = this.$refs[textAreaRef][0].scrollHeight + 'px';
    },
  },
  computed: {},
  watch: {
    parentBoxes: {
      handler: function (newVal) {
        let vm = this,
            countsBoxItemKey,
            countsBoxKey,
            countsRowKey,
            countBoxItemText,
            countBoxText,
            countRowText,
            characterBoxItemCount = 0,
            characterBoxCount = 0,
            characterRowCount = 0;

        switch (vm.interactionType) {
          case 'list':
            vm.$_.forEach(newVal[0], function (boxItemValue, boxItemKey) {
              countsBoxItemKey = `${boxItemKey}`;
              if (typeof vm.textLimits[boxItemKey] !== "undefined") {
                characterBoxItemCount = (parseInt(vm.textLimits[boxItemKey]) - parseInt(vm.$_.trim(boxItemValue).length));
                if (characterBoxItemCount >= 0) {
                  countBoxItemText = characterBoxItemCount;
                } else {
                  vm.parentBoxes[0][boxItemKey] = vm.parentBoxes[0][boxItemKey].substring(0, vm.textLimits[boxItemKey]);
                  countBoxItemText = '0';
                }
              } else {
                countBoxItemText = vm.$_.trim(boxItemValue).length;
              }
              vm.textCounts[countsBoxItemKey] = countBoxItemText
            });
            vm.$_.forEach(newVal[1], function (parentBox, boxIndex) {
              vm.$_.forEach(parentBox, function (boxValue, boxKey) {
                countsBoxKey = `Box${boxIndex}${boxKey}`;
                if (typeof vm.textLimits[boxKey] !== "undefined") {
                  characterBoxCount = (parseInt(vm.textLimits[boxKey]) - parseInt(vm.$_.trim(boxValue).length));
                  if (characterBoxCount >= 0) {
                    countBoxText = characterBoxCount;
                  } else {
                    vm.parentBoxes[1][boxIndex][boxKey] = vm.parentBoxes[1][boxIndex][boxKey].substring(0, vm.textLimits[boxKey]);
                    countBoxText = 0;
                  }
                } else {
                  countBoxText = vm.$_.trim(boxValue).length;
                }
                vm.textCounts[countsBoxKey] = countBoxText
              });
              vm.$_.forEach(parentBox.parentBoxRows, function (parentBoxRow, rowIndex) {
                vm.$_.forEach(parentBoxRow, function (rowValue, rowKey) {
                  countsRowKey = `Box${boxIndex}Row${rowIndex}${rowKey}`;
                  if (typeof vm.textLimits[rowKey] !== "undefined") {
                    characterRowCount = (parseInt(vm.textLimits[rowKey]) - parseInt(vm.$_.trim(rowValue).length));
                    if (characterRowCount >= 0) {
                      countRowText = characterRowCount;
                    } else {
                      vm.parentBoxes[1][boxIndex].parentBoxRows[rowIndex][rowKey] = vm.parentBoxes[1][boxIndex].parentBoxRows[rowIndex][rowKey].substring(0, vm.textLimits[rowKey]);
                      countRowText = 0;
                    }
                  } else {
                    countRowText = vm.$_.trim(rowValue).length;
                  }
                  vm.textCounts[countsRowKey] = countRowText
                });
              });
            });
            break;
          case 'buttons':
            vm.$_.forEach(newVal, function (boxItemValue, boxItemKey) {
              vm.$_.forEach(boxItemValue, function (boxButtonItemValue, boxButtonItemKey) {
                countsBoxItemKey = `Button${boxItemKey}${boxButtonItemKey}`;
                if (typeof vm.textLimits[boxButtonItemKey] !== "undefined") {
                  characterBoxItemCount = (parseInt(vm.textLimits[boxButtonItemKey]) - parseInt(vm.$_.trim(boxButtonItemValue).length));
                  if (characterBoxItemCount >= 0) {
                    countBoxItemText = characterBoxItemCount;
                  } else {
                    vm.parentBoxes[boxItemKey][boxButtonItemKey] = vm.parentBoxes[boxItemKey][boxButtonItemKey].substring(0, vm.textLimits[boxButtonItemKey]);
                    countBoxItemText = '0';
                  }
                } else {
                  countBoxItemText = vm.$_.trim(boxButtonItemValue).length;
                }
                vm.textCounts[countsBoxItemKey] = countBoxItemText
              });
            });
            break;
        }


        vm.$emit('boxEvent', {
          inputEntered: vm.boxFor + '==' + JSON.stringify(vm.parentBoxes),
          inputInvalidity: vm.boxFor + '==false'
        });
      },
      deep: true
    },
    interactionType: {
      handler: function (newVal) {
        let vm = this,
            boxDataObject = vm.boxData;
        if (vm.$_.isEmpty(boxDataObject)) {
          switch (newVal) {
            case 'list':
              vm.parentBoxes = [
                {
                  parentBoxTitle: ''
                },
                [{
                  parentBoxName: '',
                  parentBoxKey: 'section0',
                  parentBoxRows: [{parentBoxRowTitle: '', parentBoxRowDesc: '', parentBoxRowKey: 'section0row0'}]
                }]
              ];
              break;
            case 'buttons':
              vm.parentBoxes = [
                {
                  buttonTitle: '',
                  buttonId: 'btn0',
                },
                {
                  buttonTitle: '',
                  buttonId: 'btn1',
                },
                {
                  buttonTitle: '',
                  buttonId: 'btn2',
                },
              ];
              break;
            default:
              break;
          }
          vm.parentBoxesLoaded = true;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      let boxDataObject = vm.boxData,
          rowsArray = [];
      if (vm.$_.isEmpty(boxDataObject)) {
        switch (vm.interactionType) {
          case 'list':
            vm.parentBoxes = [
              {
                parentBoxTitle: ''
              },
              [{
                parentBoxName: '',
                parentBoxKey: 'section0',
                parentBoxRows: [{parentBoxRowTitle: '', parentBoxRowDesc: '', parentBoxRowKey: 'section0row0'}]
              }]
            ];
            break;
          case 'buttons':
            vm.parentBoxes = [
              {
                buttonTitle: '',
                buttonId: 'btn0',
              },
              {
                buttonTitle: '',
                buttonId: 'btn1',
              },
              {
                buttonTitle: '',
                buttonId: 'btn2',
              },
            ];
            break;
          default:
            break;
        }
        vm.parentBoxesLoaded = true;
      } else {
        switch (vm.interactionType) {
          case 'list':
            vm.parentBoxes = [{parentBoxTitle: boxDataObject.button}, []];
            vm.$_.forEach(boxDataObject.sections, function (sectionValue, sectionIndex) {
              rowsArray = [];
              vm.$_.forEach(sectionValue.rows, function (sectionRowValue, sectionRowIndex) {
                rowsArray.push({
                  parentBoxRowTitle: sectionRowValue.title,
                  parentBoxRowDesc: sectionRowValue.description,
                  parentBoxRowKey: `section${sectionIndex}${vm.$DateTime.local().setZone("UTC").valueOf()}Row${sectionRowIndex}${vm.$DateTime.local().setZone("UTC").valueOf()}`
                })
              });
              vm.parentBoxes[1].push({
                parentBoxName: sectionValue.title,
                parentBoxKey: `section${sectionIndex}${vm.$DateTime.local().setZone("UTC").valueOf()}`,
                parentBoxRows: rowsArray
              });
            });
            break;
          case 'buttons':
            vm.$_.forEach(boxDataObject.buttons, function (sectionValue, sectionIndex) {
              vm.parentBoxes.push({
                buttonTitle: sectionValue.reply.title,
                buttonId: `btn${sectionIndex}`,
              })
            });
            break;
          default:
            break;
        }
        vm.parentBoxesLoaded = true;
      }
    });
  }
}
</script>

<style scoped>
.box-header-div {
  background-color: hsl(171, 100%, 96%);
  padding: 0;
}

.is-tall-button {
  height: 100%;
}

.full-height-card-close-button {
  height: auto !important;
}
</style>