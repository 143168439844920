<template>
    <form v-on:submit="checkForm">
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : otpNotGiven }">
                        <input class="input is-primary is-rounded" type="text" placeholder="OTP" v-model.trim="userOTP"
                               v-bind:class="{ 'is-danger' : otpNotGiven }">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'key']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="otpNotGiven">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
                    </p>
                    <p><strong>The OTP has been sent to your email</strong></p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control">
                        <button class="button is-primary is-inverted is-medium is-fullwidth">Submit OTP</button>
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <a href="/login" class="black-text underlined_text">Return to Login</a>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column">
                <p class="help is-danger bigger-text bolder-text" v-if="otpInvalid">{{ otpInvalidText }}</p>
            </div>
        </div>
    </form>
</template>

<script>
import api from "./../../../api/api.js";

export default {
 name: "SubmitOTP",
 data() {
  return {
   userOTP: '',
   otpNotGiven: false,
   otpInvalid: false,
   otpInvalidText: 'The OTP is invalid. You need to login again to receive a valid OTP.'
  }
 },
 methods: {
  checkForm: function (event) {
   event.preventDefault();
   let vm = this,
    noError = true;
   if (vm.userOTP === '') {
    vm.otpNotGiven = true;
    noError = false;
   } else {
    vm.otpNotGiven = false;
   }

   if (noError) {
    vm.submitform();
   }
  },
  submitform: function () {
   let vm = this;
   vm.otpNotGiven = false;
   let authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   api.operation('submitotp').post({
    userOTP: vm.userOTP,
    session: vm.$session.id(),
    sessiondata: JSON.stringify(vm.$session.getAll())
   }, reqHeader)
    .then(({data}) => {
     if (data.success) {
      vm.otpInvalid = false;
      vm.$session.set('jwt', data.token);
      window.location.href = '/account';
     } else {
      vm.otpInvalidText = data.msg;
      vm.otpInvalid = true;
     }
    });
  }
 },
 mounted: function () {
 }
}
</script>

<style scoped>
.redColored {
    color: red !important;
}

.black-text {
    color: black !important;
}

.underlined_text {
    text-decoration: underline !important;
}

.bigger-text {
    font-size: larger;
}

.bolder-text {
    font-weight: bold;
}
</style>