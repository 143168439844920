<template>
    <div>
        <form v-if="deletePageOrAllForm">
            <div class="columns is-centered is-vcentered">
                <div class="column is-full">
                    <div class="field">
                        <div class="field-body">
                            <div class="field">
                                <checkRadioField
                                        v-bind:inputData=deletionFormFieldsData.deleteScope.binds
                                        v-on:dataEvent="bubbleDataEvent"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-full">
                    <div class="field-body">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=deletionFormFieldsData.deleteConfirm.binds
                                    v-on:dataEvent="bubbleDataEvent"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <form v-if="deleteSingleRecordForm">
            <div class="columns is-centered" v-if="formLoading">
                <div class="column is-full">
                    <div class="notification is-info padded-3-px">
                        Loading...
                    </div>
                </div>
            </div>
            <div class="columns is-centered is-vcentered">
                <div class="column is-full">
                    Delete
                </div>
            </div>

            <div
                    class="columns is-centered is-vcentered"
                    v-for="(itemValue, itemName) in formFieldsVal.FormData"
                    v-bind:key="itemName"
            >
                <div class="column is-full">
                    <strong>{{ itemName }}:</strong> {{ itemValue }}
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-full">
                    <div class="field-body">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=deletionFormFieldsData.deleteConfirm.binds
                                    v-on:dataEvent="bubbleDataEvent"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import formFunctions from "./../../../../api/mixins/formFunctions";
    import fetchTableData from "./../../../../api/mixins/fetchTableData";
    import checkRadioField from "./checkRadioField";
    import inputField from "./inputField";

    export default {
        name: "deletionForm",
        data() {
            return {}
        },
        mixins: [fetchTableData, formFunctions],
        components: {
            checkRadioField,
            inputField
        },
        props: ['formFieldsVal', 'deletionAction'],
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.getDeletionFormData();
            });
        }
    }
</script>

<style scoped>
    .padded-3-px {
        padding: 3px !important;
    }
</style>