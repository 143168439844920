// import Vue from 'vue/dist/vue.js'
import Vue from 'vue'
import App from './App.vue'
import api from "./tumatext/api/api.js";
//import Buefy from 'buefy'
// import bulma from '../node_modules/bulma/bulma.sass'
// import bulma from 'bulma'
// import bulma from '@/assets/main.scss'
import bulmaDivider from 'bulma-divider';
import bulmaSwitch from 'bulma-switch';
import axios from 'axios'
import Vuesax from 'vuesax'
import VueSession from 'vue-session'
import _ from 'lodash'
import numbro from 'numbro'
import VueScrollTo from 'vue-scrollto'
import validator from 'validator';
import VueTextareaAutosize from 'vue-textarea-autosize'
import momentLib from 'moment'
import xdateLib from 'xdate'
import Q from 'q'
import vUploader from 'v-uploader'
import vDialog from 'v-dialogs'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import Vuelidate from 'vuelidate'
import VueSSE from 'vue-sse'
import md5 from 'js-md5'
import VCalendar from 'v-calendar'
import VueTour from 'vue-tour'
import mobileDeviceDetect from 'mobile-device-detect';
import {DateTime} from 'luxon';
import VueTimepicker from 'vue2-timepicker'
import VuejsHeatmap from 'vuejs-heatmap'
import AsyncComputed from 'vue-async-computed'
import Vidle from 'v-idle'
import VueStepWizard from 'vue-step-wizard'
import VueObserveVisibility from 'vue-observe-visibility'


import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

//import 'buefy/dist/buefy.css'
import './tumatext/themes/chadash/assets/css/collapsingtabs.css'
import './tumatext/themes/chadash/assets/css/horizontalAccordion.css'
import './tumatext/themes/chadash/assets/css/tumatextStyle.css'
import 'bulma-divider/dist/css/bulma-divider.min.css'
import 'bulma-switch/dist/css/bulma-switch.min.css'
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css'
import 'css-toggle-switch/dist/toggle-switch.css'
import 'bulma/css/bulma.css'
import 'vue-tour/dist/vue-tour.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import './../node_modules/cal-heatmap/dist/cal-heatmap.css';
import 'vue-step-wizard/dist/vue-step-wizard.css'


Object.defineProperty(Vue.prototype, '$_', {value: _});
Object.defineProperty(Vue.prototype, '$numbro', {value: numbro});
Object.defineProperty(Vue.prototype, '$validator', {value: validator});
Object.defineProperty(Vue.prototype, '$moment', {value: momentLib});
Object.defineProperty(Vue.prototype, '$DateTime', {value: DateTime});
Object.defineProperty(Vue.prototype, '$xdate', {value: xdateLib});
Object.defineProperty(Vue.prototype, '$Q', {value: Q});
Object.defineProperty(Vue.prototype, '$md5', {value: md5});
Object.defineProperty(Vue.prototype, '$mobileDeviceDetect', {value: mobileDeviceDetect});
Vue.prototype.$http = axios;
// Vue.use(bulma);
Vue.use(bulmaDivider);
Vue.use(bulmaSwitch);
Vue.use(Vuesax);
Vue.use(VueSSE);
Vue.use(VueTour);
Vue.use(VuejsHeatmap);
Vue.use(AsyncComputed);
Vue.use(Vidle)

Vue.use(VueScrollTo, {
 container: "body",
 duration: 500,
 easing: "ease",
 offset: -50,
 force: true,
 cancelable: true,
 onStart: false,
 onDone: false,
 onCancel: false,
 x: false,
 y: true
});
Vue.use(VueSession, {
 persist: true
});
Vue.use(VueTextareaAutosize);

Vue.use(VCalendar, {
 componentPrefix: 'vc'
});

//Vue.use(Buefy);

library.add(fas, fab, far);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-timepicker', VueTimepicker);
Vue.component('vuejs-heatmap', VuejsHeatmap)

Vue.use(vDialog, {});
Vue.use(Notifications, {velocity});
Vue.use(Vuelidate);
Vue.use(VueStepWizard);
Vue.use(VueObserveVisibility)

const uploaderConfig = {
 uploadFileUrl: api.operation('uploadfile').returnUrl(),
 deleteFileUrl: api.operation('deletefile').returnUrl(),
};
Vue.use(vUploader, uploaderConfig);


Vue.config.productionTip = false;
new Vue({
 render: h => h(App),
}).$mount('#app');