<template>
	<div>
		<div class="buttons has-addons is-centered">
			<a class="button is-normal" v-on:click="$emit('refresh-chart')" v-if="refreshAble">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'redo-alt']"/>
					</span>
				<span>Refresh</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('make-line')" v-if="lineAble">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'chart-line']"/>
					</span>
				<span>Line</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('make-bar')" v-if="barAble">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'chart-bar']"/>
					</span>
				<span>Bar</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('toggle-stacking')" v-if="stackAble" v-bind:class="{ 'is-success' : isStacked }">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'layer-group']"/>
					</span>
				<span>Stacked</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('scope-today')" v-if="todayScope" v-bind:class="{ 'is-success' : selectedScope==='today' }">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'calendar-day']"/>
					</span>
				<span>Today</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('scope-week')" v-if="weekScope" v-bind:class="{ 'is-success' : selectedScope==='week' }">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'calendar-week']"/>
					</span>
				<span>This Week</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('scope-month')" v-if="monthScope" v-bind:class="{ 'is-success' : selectedScope==='month' }">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'calendar']"/>
					</span>
				<span>This Month</span>
			</a>
			<a class="button is-normal" v-on:click="$emit('scope-year')" v-if="yearScope" v-bind:class="{ 'is-success' : selectedScope==='year' }">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', 'calendar-alt']"/>
					</span>
				<span>This Year</span>
			</a>
		</div>
	</div>
</template>

<script>
    export default {
        name: "chartsButtons",
        data() {
            return {}
        },
        props: ['refreshAble', 'lineAble', 'barAble', 'todayScope', 'weekScope', 'monthScope', 'yearScope', 'selectedScope','stackAble','isStacked'],
        components: {},
        methods: {}
    }
</script>

<style scoped>

</style>