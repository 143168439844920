<template>
	<form>
		<div class="columns is-centered" v-if="hasTextInput">
			<div class="column">
				<div class="field">
					<div class="field-label is-normal has-text-left form-padded-labels">
						<label class="label">{{ textinputLabel }}</label>
					</div>
					<div class="field-body">
						<div class="field">
							<p class="control">
								<textarea class="textarea has-fixed-size is-primary" placeholder="Type here" v-model.lazy.trim="selecteditems.typed" v-on:input="textareaResize" ref="textinput" rows="1"></textarea>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="columns is-centered is-vcentered">
			<div class="column">
				<div class="field">
					<div class="field-label is-normal has-text-left form-padded-labels">
						<label class="label">{{ dataTitle }}</label>
					</div>
					<div class="field-body">
						<div class="field">
							<p class="control has-icons-left" v-if="showsearchbox">
								<input class="input is-primary is-rounded margin-bottom" type="text" placeholder="Search" v-model.trim="searchModalValue">

								<span class="icon is-small is-left">
                                    <font-awesome-icon v-bind:icon="['fas', 'search']"/>
                                </span>
							</p>
							<div class="columns is-mobile is-multiline has-text-left">
								<div v-for="(dataObj, key) in showData" v-bind:key="key" class="column is-narrow">
									<input v-bind:type="type" name="selectionchecks" v-bind:id="dataObj.key" v-model="selecteditems[dataObj.key]" v-bind:value=" dataObj.value" v-if="isCheckBox(dataObj.visible)"/>
									<input v-bind:type="type" name="selectionchecks" v-bind:id="dataObj.key" v-model="selecteditems['selected_radio']" v-bind:value=" dataObj.value" v-if="isRadio(dataObj.visible)"/>
									<label class="choice-label" v-bind:for="dataObj.key" v-if="dataObj.visible">{{ dataObj.value }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
    import dataFetcher from "./../../../api/mixins/fetchTableData";

    export default {
        name: "modalsSelectionData",
        data() {
            let vm = this;
            return {
                items: vm.datasData.items,
                dataHeader: vm.datasData.dataHeader,
                request: vm.datasData.request,
                from: vm.datasData.from,
                type: vm.datasData.type,
                jsonStructure: require('./../datas/' + vm.datasData.request)
            }
        },
        mixins: [dataFetcher],
        props: ['datasData'],
        computed: {},
        methods: {
            isCheckBox: function (isVisible) {
                let vm = this;
                return !!(vm.type === 'checkbox' && isVisible);
            },
            isRadio: function (isVisible) {
                let vm = this;
                return !!(vm.type === 'radio' && isVisible);
            },
            textareaResize() {
                this.$refs.textinput.style.minHeight = this.$refs.textinput.scrollHeight + 'px';
            }
        },
        watch: {
            emission: {
                handler: function () {
                    let vm = this;
                    vm.$emit('selectedDatas', {items: vm.selecteditems, selection: vm.emission, from: vm.request});
                },
                deep: true
            }
        },
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.loadApiModalData();
                vm.showrefreshnoticebox = false;
            });
        }
    }
</script>

<style scoped>
	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		opacity: 0;
		z-index: -1;
	}

	.choice-label {
		position: relative;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;
		padding-left: 30px;
		padding-right: 10px;
		line-height: 40px;
		cursor: pointer;
	}

	.choice-label::before {
		content: " ";
		position: absolute;
		top: 6px;
		left: 0;
		display: block;
		width: 24px;
		height: 24px;
		/*border: 2px solid #8e44ad;*/
		border: 2px solid hsl(171, 100%, 41%);
		border-radius: 4px;
		/*z-index: -1;*/
	}

	input[type="radio"] + .choice-label::before {
		border-radius: 18px;
	}

	/* Checked */
	input[type="checkbox"]:checked + .choice-label,
	input[type="radio"]:checked + .choice-label {
		padding-left: 10px;
		color: #fff;
		z-index: 0;
	}

	input[type="checkbox"]:checked + .choice-label::before,
	input[type="radio"]:checked + .choice-label::before {
		top: 0;
		width: 100%;
		height: 100%;
		/*background: #8e44ad;*/
		background: hsl(171, 100%, 41%);
		z-index: -1;
	}

	/* Transition */
	.choice-label, .choice-label::before {
		-webkit-transition: .25s all ease;
		-o-transition: .25s all ease;
		transition: .25s all ease;
	}

	.margin-bottom {
		margin-bottom: 20px !important;
	}
</style>