<template>
  <div>
    <p class="element-label" v-html="labelText"></p>
    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.inputValue.$invalid }">
      <input
          class="input is-primary is-rounded"
          v-bind:type="inputType"
          v-model.trim="inputValue"
          v-bind:placeholder="placeholderText"
          v-bind:class="{ 'is-danger' : $v.inputValue.$invalid }"
          v-bind:disabled="disabledInput"
      />
      <span class="icon is-small is-left">
                <font-awesome-icon v-bind:icon="[inputIconCategory, inputIcon]"/>
            </span>
      <span class="icon is-small is-right redColored" v-if="$v.inputValue.$invalid">
                <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
            </span>
    </p>
    <p class="help is-danger bigger-text bolder-text" v-if="$v.inputValue.$invalid">{{
        mandatoryInputInvalidText
      }}</p>
    <p class="help is-danger bigger-text bolder-text" v-if="!$v.inputValue.email">{{
        mustBeEmailInputInvalidText
      }}</p>
    <p class="help is-danger bigger-text bolder-text" v-if="!$v.inputValue.mustBeWord">{{
        mustBeWordInputInvalidText
      }}</p>
    <p class="help is-danger bigger-text bolder-text" v-if="!$v.inputValue.minValue">{{
        minValueInputInvalidText
      }}</p>
    <p class="help is-danger bigger-text bolder-text" v-if="!$v.inputValue.integer">{{
        integerInputInvalidText
      }}</p>
    <p class="help is-danger bigger-text bolder-text" v-if="!$v.inputValue.isDivisibleBy">{{
        isDivisibleByInvalidText
      }}</p>
    <p class="help is-danger bigger-text bolder-text" v-if="!$v.inputValue.maxValue">{{
        maxValueInputInvalidText
      }}</p>
  </div>
</template>

<script>
import formFunctions from "./../../../../api/mixins/formFunctions";

export default {
  name: "inputField",
  mixins: [formFunctions],
  data() {
    let vm = this;
    return {
      inputValue: vm.inputData.initial,
      inputName: vm.inputData.elementName,
      inputType: vm.inputData.type,
      placeholderText: vm.inputData.placeholderText,
      mandatoryInputInvalidText: vm.inputData.invalidText,
      inputIconCategory: vm.inputData.iconCategory,
      inputIcon: vm.inputData.icon,
      isMandatory: vm.inputData.mandatory,
      validationRules: vm.inputData.validationRules,
      labelText: vm.inputData.labelText,
      disabledInput: vm.inputData.disabled,
      mustBeWordInputInvalidText: '',
      mustBeEmailInputInvalidText: '',
      minValueInputInvalidText: '',
      integerInputInvalidText: '',
      isDivisibleByInvalidText: '',
      maxValueInputInvalidText: '',
      beenUpdated: false
    }
  },
  props: ['inputData']
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}
</style>