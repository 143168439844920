<template>
    <section class="hero is-dark is-fullheight">
        <!-- Hero head: will stick at the top -->
        <div class="hero-head">

        </div>
        <!-- Hero content: will be in the middle -->
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Error 404
                </h1>
                <h2 class="subtitle">
                    Not Found
                </h2>
                <h1 class="subtitle">
                    Sorry, the page you are looking for does not exist.
                </h1>
                <img src="@/tumatext/themes/chadash/assets/img/404.gif">
                <h2 class="subtitle">
                    <a class="button is-success is-inverted" href="/account">
                        <span class="icon">
                           <font-awesome-icon v-bind:icon="['fas', 'home']"/>
                        </span>
                        <span>Take me home</span>
                    </a>
                </h2>
            </div>
        </div>

        <!-- Hero footer: will stick at the bottom -->
        <div class="hero-foot">
            <nav class="tabs is-boxed is-fullwidth">
                <div class="container">
                    <ul>
                        <li><a href=".">refresh page</a></li>
                        <li><a href="mailto:we@tumatext.co.ke?subject=Service is unavailable!">Send us an email</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>
    export default {
        name: "page404"
    }
</script>

<style scoped>

</style>