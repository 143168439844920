<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.canSendAs.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.sendingAccount.binds
                  v-on:dataEvent="dataEvent"
                  v-on:filterDataReady="filterDataReady"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-vcentered">
          <div class="column">
            <div class="field">
              <checkRadioGroup
                  v-bind:inputData=formFieldsData.categoriesAllowed.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import selectField from "./elements/selectField";
import textAreaField from "./elements/textAreaField";
import checkRadioGroup from "@/tumatext/themes/chadash/forms/elements/checkRadioGroup.vue";

export default {
  name: "emailusersForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    checkRadioGroup,
    selectField,
    textAreaField
  },
  data() {
    // let vm = this;
    return {}
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
