<template>
  <div>
    <div v-bind:class="{
            'display-none' : activeCard==='inactive'}">
      <div class="columns is-vcentered is-centered" v-if="subsection.istable">
        <div class="column is-vcentered is-centered" v-if="showToolbar">
          <div class="buttons is-vcentered is-centered">
            <a class="button refresh-button" v-if="subsection.refreshable" v-on:click="triggertablerefresh">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fas', 'sync-alt']"/>
                            </span>
            </a>
            <a class="button search-button" v-if="subsection.isfilterable" v-on:click="showfilterbox = !showfilterbox"
               v-bind:class="{ 'is-primary': showfilterbox }">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fas', 'filter']"/>
                            </span>
            </a>
            <a class="button search-button" v-if="subsection.issearchable" v-on:click="showsearchbox = !showsearchbox"
               v-bind:class="{ 'is-primary': showsearchbox }">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fas', 'search']"/>
                            </span>
            </a>
            <a class="button pagination-button" v-if="subsection.ispaginated"
               v-on:click="showpaginationbox = !showpaginationbox"
               v-bind:class="{ 'is-primary': showpaginationbox }">
                            <span class="icon">
                                <font-awesome-icon v-bind:icon="['fas', 'exchange-alt']"/>
                            </span>
            </a>
            <a class="button sort-button" v-if="sortableTable" v-on:click="showsortingbox = !showsortingbox"
               v-bind:class="{ 'is-primary': showsortingbox }">
                            <span class="icon">
                                <font-awesome-icon v-bind:icon="['fas', 'sort']"/>
                            </span>
            </a>
            <a class="button refresh-button" v-if="subsection.hasdatefilter"
               v-on:click="showdatefilter = !showdatefilter"
               v-bind:class="{ 'is-primary': showdatefilter }">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fas', 'calendar-alt']"/>
                            </span>
            </a>
            <a class="button export-button" v-if="exportingTable" v-on:click="showexportbox = !showexportbox"
               v-bind:class="{ 'is-primary': showexportbox }">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fas', 'file-export']"/>
                            </span>
            </a>
            <a class="button create-new-button" v-if="checkVisibility(subsection.cancreate)" v-on:click="createNew">
                            <span class="icon">
                                <font-awesome-icon v-bind:icon="['fas', 'plus']"/>
                            </span>
            </a>
            <a class="button delete-items-button" v-if="checkVisibility(subsection.candelete)" v-on:click="deleteData">
                            <span class="icon">
                                <font-awesome-icon v-bind:icon="['fas', 'trash-alt']"/>
                            </span>
            </a>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered is-centered" v-if="showpaginationbox" v-bind:class="'toggled-row'">
        <div class="column is-vcentered is-centered">
          <div class="field">
            <div class="control">
              <div class="select is-rounded is-primary">
                <select v-model="selectedPageSize" v-on:change="changetablepageSize">
                  <option v-for="(size,index) in pageSize" v-bind:key="index" v-bind:value="size.value">{{
                      size.text
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-vcentered is-centered">
          <nav class="pagination is-centered is-rounded" role="navigation" aria-label="pagination">
            <a class="pagination-previous" v-on:click="goToPage('prev')" v-bind:disabled="prevDisabled">
              <font-awesome-icon v-bind:icon="['fas', 'chevron-left']"/>
            </a>
            <a class="pagination-next" v-on:click="goToPage('next')" v-bind:disabled="nextDisabled">
              <font-awesome-icon v-bind:icon="['fas', 'chevron-right']"/>
            </a>
            <ul class="pagination-list">
              <li v-for="(number,index) in pagesAvailable" v-bind:key="index"
                  v-on:click="goToPage(number.digit)">
                <a class="pagination-link" v-bind:aria-label="'Goto page' + number.digit"
                   v-bind:class="{ 'is-current' : number.current }">{{ number.digit }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns is-vcentered is-centered" v-if="showsearchbox" v-bind:class="'toggled-row'">
        <div class="column is-vcentered is-centered">
          <div class="field has-addons">
            <div class="control has-icons-left is-expanded">
              <input class="input" type="text" placeholder="Search" v-model.trim="searchTableValue"
                     v-on:input="triggertablesearch" v-focus/>
              <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'search']"/>
                        </span>
            </div>
            <div class="control">
						<span class="button" v-on:click="clearSearchValue()">
							<font-awesome-icon v-bind:icon="['fas', 'times']"/>
						</span>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered is-centered" v-if="showfilterbox" v-bind:class="'toggled-row'">
        <div class="column is-vcentered is-centered">
          <component
              v-bind:is="filterforSelect"
              v-on:channelsSelected="channelsSelected"
          ></component>
        </div>
      </div>
      <div class="columns is-vcentered is-centered is-multiline" v-if="showexportbox" v-bind:class="'toggled-row'">
        <div class="column is-vcentered is-centered">
          <vs-radio color="success" v-model="exportScope" vs-value="visible">Visible</vs-radio>
        </div>
        <div class="column is-vcentered is-centered">
          <vs-radio color="success" v-model="exportScope" vs-value="all">All</vs-radio>
        </div>
        <div class="column is-vcentered is-centered">
          <div class="buttons is-vcentered is-centered">
            <a v-bind:disabled="processingReport" class="button" v-if="subsection.isexportablepdf"
               v-on:click="exportpdf">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fas', 'file-pdf']"/>
                            </span>
            </a>
            <a v-bind:disabled="processingReport" class="button" v-if="subsection.isexportableexcel"
               v-on:click="exportexcel">
          <span class="icon">
            <font-awesome-icon v-bind:icon="['fas', 'file-excel']"/>
          </span>
            </a>
          </div>
        </div>
        <div class="column is-full is-vcentered is-centered">
          <div class="field">
            <p class="control has-icons-left">
              <input v-bind:disabled="processingReport" class="input" type="text" placeholder="Copy to..."
                     v-model.trim="emailcc" v-focus/>
              <span class="icon is-small is-left">
              <font-awesome-icon v-bind:icon="['fas', 'at']"/>
            </span>
            </p>
          </div>
        </div>
        <div class="column is-full is-vcentered is-centered" v-if="emailSendingStatusNotEmpty">
          <span class="activity-status">{{ emailSendingStatus }}</span>
        </div>
      </div>
      <div class="columns is-vcentered is-centered" v-if="showsortingbox" v-bind:class="'toggled-row'">
        <div class="column is-vcentered is-centered">
          <div class="field">
            <!--<p class="control has-icons-left">-->
            <div class="buttons">
              <draggable v-model="sortArray">
                <transition-group>
                  <div v-for="element in sortArray" :key="element.col">
                    <a class="button is-link is-fullwidth is-outlined"
                       v-on:click="element.order=!element.order">
                      <span>{{ element.name }}</span>
                      <span class="icon">
											<font-awesome-icon v-if="element.order"
                                         v-bind:icon="['fas', 'sort-amount-up']"/>
											<font-awesome-icon v-else v-bind:icon="['fas', 'sort-amount-down']"/>
										</span>
                    </a>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered is-centered" v-if="showdatefilter" v-bind:class="'toggled-row'">
        <div class="column is-vcentered is-centered">
          <dateField
              v-bind:inputData=datePickerData
              v-bind:dateFieldType="'complex'"
              v-bind:isExpanded="true"
              v-on:dataEvent="dataEvent"
              v-on:complexDateSet="complexDateSet"
          />
        </div>
      </div>
    </div>
    <div class="columns is-vcentered is-centered" v-if="showrefreshnoticebox" v-bind:class="'toggled-row'">
      <div class="column is-vcentered is-centered">
        <font-awesome-icon v-bind:icon="['fas', 'cog']" spin/>
        Loading
      </div>
    </div>
    <div class="columns is-vcentered is-centered" v-if="subsection.ispaginated">
      <div class="column is-vcentered is-centered bolded-text accordion-texts-guide" v-html="tableTexts"></div>
    </div>
    <vs-collapse accordion>
      <vs-collapse-item v-for="item in tableData" v-bind:key="item.key">
        <div slot="header" class="is-centered accordion-collapse-item-guide">
          <div class="columns is-mobile is-centered">
            <div class="column is-9-mobile is-9-tablet is-10-desktop is-10-widescreen is-10-fullhd"
                 v-bind:style="item.headerBackground">
              <p class="has-text-justified wrap-text" v-html="item.header"></p>
            </div>
          </div>
        </div>
        <div class="has-text-justified wrap-text">
          <span v-html="item.body" class="wrap-text"></span>
        </div>
        <accordionButtons
            v-if="item.hasButtons"
            v-bind:editAble="item.editable"
            v-bind:deleteAble="item.deleteable"
            v-bind:approveAble="item.approveable"
            v-bind:rejectAble="item.rejectable"
            v-bind:detailsAble="item.detailsable"
            v-bind:cancelAble="item.cancelable"
            v-bind:useAble="item.useable"
            v-bind:viewAble="item.viewable"
            v-bind:updateAble="item.updateable"
            v-bind:toggleAble="item.toggleable"
            v-bind:sendAble="item.sendable"
            v-bind:voidAble="item.voidable"
            v-bind:viewasAble="item.viewasable"
            v-bind:startstopAble="item.startstopable"
            v-bind:shawmappingAble="item.showmappingable"
            v-bind:loadAble="item.loadable"
            v-bind:hasMessagesContent="item.hasmessagescontent"
            v-on:edit-record="editItem(item.id)"
            v-on:delete-record="deleteItem(item.id)"
            v-on:approve-record="approveItem(item.id)"
            v-on:reject-record="rejectItem(item.id)"
            v-on:details-record="detailsItem(item.id)"
            v-on:cancel-record="cancelItem(item.id)"
            v-on:use-record="useItem(item)"
            v-on:update-record="updateItem(item.id)"
            v-on:toggle-record="updateItem(item.id)"
            v-on:send-record="sendItem(item.id)"
            v-on:view-record="viewItem(item.id)"
            v-on:void-record="voidItem(item.id)"
            v-on:load-record="loadItem(item.id)"
            v-on:startstop-record="startstopItem(item.id)"
            v-on:view-as="viewAs(item.id)"
            v-on:view-messages="viewMessages(item,subsection.mykey)"
        />
      </vs-collapse-item>
    </vs-collapse>
  </div>
</template>

<script>

import dataFetcher from "./../../../api/mixins/fetchTableData";
import accordionButtons from './accordionButtons.vue';
import draggable from 'vuedraggable';
import dateField from "./../forms/elements/dateField";
import sentMessageReportFilter from "./../forms/elements/sentMessageReportFilter";
import receivedMessageReportFilter from "./../forms/elements/receivedMessageReportFilter";

export default {
  name: "sectionCollapseAccordion",
  mixins: [dataFetcher],
  data() {
    let vm = this;
    return {
      datePickerData: {
        elementName: 'dataFilterDates'
      },
      showexportbox: false,
      showpaginationbox: false,
      showrefreshnoticebox: true,
      showsortingbox: false,
      showdatefilter: false,
      showsearchbox: false,
      showfilterbox: false,
      sortArrayLoaded: false,
      pagebuttons: 3,
      prevDisabled: false,
      nextDisabled: false,
      emailcc: '',
      pageSize: [
        {text: '10 per page', value: 10},
        {text: '50 per page', value: 50},
        {text: '100 per page', value: 100},
      ],
      pagesAvailable: [],
      tableData: [],
      formData: {
        table: vm.$_.trim(vm.subsection.mykey),
        formname: vm.$_.trim(vm.$_.split(vm.subsection.mykey, '-')[0]),
        action: ''
      },
      sortingTable: false,
      tableTexts: '',
      emailSendingStatus: '',
      processingReport: false,
      exportScope: 'visible',
      contactKeysMapper: require('./../assets/js/contactKeysMapper.js'),
      scrollOptions: {
        container: "body",
        duration: 500,
        easing: "ease",
        offset: -50,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      },
      showEmail: false,
      showHtml: false,
      showText: false
      //jsonStructure: vm.getJsonStructure() //require('./../tables/' + vm.subsection.mykey)
    }
  },
  components: {
    accordionButtons,
    draggable,
    dateField,
    sentMessageReport: sentMessageReportFilter,
    receivedMessageReport: receivedMessageReportFilter,
  },
  props: ['subsection', 'subkey', 'myRole', 'refreshMe', 'activeCard'],
  computed: {
    filterforSelect: function () {
      try {
        let vm = this;
        return vm.subsection.filterfor;
      } catch (error) {
        return '';
      }
    },
    sortableTable: function () {
      let vm = this,
          sortingTable;
      if (vm.$_.has(vm.subsection, 'issortable')) {
        sortingTable = vm.subsection.issortable.length > 0;

      } else {
        sortingTable = false;
      }
      vm.sortingTable = sortingTable;
      if (!vm.sortArrayLoaded) {
        if (sortingTable) {
          vm.sortArray = vm.subsection.issortable;
        } else {
          vm.sortArray = [];
        }
        vm.sortArrayLoaded = true;
      }
      return sortingTable;
    },
    emailSendingStatusNotEmpty: function () {
      let vm = this;
      return vm.$_.trim(vm.emailSendingStatus) !== '';
    },
    exportingTable: function () {
      let vm = this;
      return vm.subsection.isexportablepdf || vm.subsection.isexportableexcel;
    },
    showToolbar: function () {
      let vm = this;
      return vm.subsection.issearchable ||
          vm.subsection.isexportable ||
          vm.exportingTable() ||
          vm.subsection.refreshable ||
          vm.subsection.ispaginated ||
          vm.sortableTable() ||
          vm.checkVisibility(vm.subsection.cancreate) ||
          vm.checkVisibility(vm.subsection.candelete);
    }
  },
  methods: {
    closeEmailModal() {
      let vm = this;
      vm.showEmail = false;
      vm.showHtml = false;
      vm.showText = false;
    },
    dataEvent: function (emittedData) {
      let vm = this,
          inputEnteredData = vm.$_.split(emittedData.inputEntered, '==');
      //inputInvalidityData = vm.$_.split(emittedData.inputInvalidity, '==');
      vm.tableDataFilterDates = inputEnteredData[1];
    },
    complexDateSet: function (emittedData) {
      let vm = this;
      if (vm.$_.trim(emittedData.dateData) !== '') {
        vm.tableDataFilterDates = vm.$_.trim(emittedData.dateData);
      } else {
        vm.tableDataFilterDates = vm.$_.trim(emittedData.inputValue);
      }
      vm.tableDataFilterMode = vm.$_.trim(emittedData.dateMode);
      vm.triggertablerefresh();
    },
    triggertablerefresh: function () {
      let vm = this;
      //vm.showrefreshnoticebox = true;
      vm.loadApiTableData(true);
    },
    triggertablesearch: function () {
      let vm = this;
      //vm.showrefreshnoticebox = true;
      vm.loadApiTableData(true);
    },
    changetablepageSize: function () {
      let vm = this;
      //vm.showrefreshnoticebox = true;
      if (vm.currentpage === 1) {
        vm.loadApiTableData(true);
      } else {
        vm.goToPage(1);
      }
    },
    checkVisibility: function (subsectionData) {
      let vm = this;
      return !!vm.$_.includes(subsectionData, vm.myRole) || subsectionData[0] === 'everyone'
    },
    goToPage: function (pageRequest) {
      let vm = this,
          topNumber,
          numberSteps,
          pagesObj = [];
      if (vm.$_.isNumber(pageRequest)) {
        if (pageRequest > vm.maxpages) {
          vm.currentpage = vm.maxpages;
        } else if (pageRequest < 1) {
          vm.currentpage = 1;
        } else {
          vm.currentpage = pageRequest;
        }
      } else if (vm.$_.trim(pageRequest) === 'prev') {
        if (vm.currentpage-- <= 1) {
          vm.currentpage = 1;
        }
      } else if (vm.$_.trim(pageRequest) === 'next') {
        if (vm.currentpage++ >= vm.maxpages) {
          vm.currentpage = vm.maxpages;
        }
      } else {
        vm.currentpage = 1;
      }
      vm.prevDisabled = vm.currentpage === 1;
      vm.nextDisabled = vm.currentpage === vm.maxpages;
      topNumber = vm.$_.ceil(vm.currentpage / vm.pagebuttons) * vm.pagebuttons;
      numberSteps = vm.pagebuttons - 1;
      vm.$_.forEach(vm.$_.range(topNumber - numberSteps, topNumber + 1), function (pageNumber) {
        if (pageNumber >= 1 && pageNumber <= vm.maxpages) {
          pagesObj.push({digit: pageNumber, current: pageNumber === vm.currentpage});
        }
      });
      vm.pagesAvailable = pagesObj;
    },
    emitFormAction: function () {
      let vm = this;
      vm.$emit('formModal', {
        formTitle: vm.jsonStructure.modalHead,
        formData: vm.formData,
        subkey: vm.subkey,
        myRole: vm.myRole,
        key: vm.$moment().valueOf()
      });
    },
    emitDataAction: function () {
      let vm = this,
          tableData = {
            mymodal: vm.subsection.mykey + '-details',
            mykey: vm.subsection.mykey + '-' + vm.formData.action,
            istable: vm.subsection.istable,
            refreshable: vm.subsection.refreshable,
            issearchable: vm.subsection.issearchable,
            issortable: vm.subsection.issortable,
            ispaginated: vm.subsection.ispaginated,
            cancreate: vm.subsection.cancreate,
            candelete: vm.subsection.candelete,
            isexportable: vm.subsection.isexportable,
            isexportablepdf: vm.subsection.isexportablepdf,
            isexportableexcel: vm.subsection.isexportableexcel
          };
      vm.$emit('dataModal', {
        dataHead: 'Details - ' + vm.subkey,
        dataReq: tableData,
        from: vm.formData.table,
        type: 'table',
        formData: vm.formData,
        key: vm.$moment().valueOf(),
        items: {}
      });
    },
    performUpdateAction: function () {
      let vm = this;
      vm.actionApiTableData(vm.formData)
          .then(function (data) {
            if (typeof data.returnData !== 'undefined') {
              if (typeof data.returnData.newtoken !== 'undefined') {
                vm.$session.set('jwt', data.returnData.newtoken);
                window.location.href = '/account'
              }
            }
            vm.loadApiTableData(false);
          }, function (failed) {
            console.log(failed);
            vm.loadApiTableData(false);
          });

    },
    createNew: function () {
      let vm = this;
      vm.formData.action = 'create:new';
      vm.emitFormAction();
    },
    deleteData: function () {
      let vm = this;
      vm.formData.action = 'delete:data:page==' + vm.currentpage + ':search==' + vm.searchTableValue + ':pagesize==' + vm.selectedPageSize + ':sorting==' + vm.$_.replace(JSON.stringify(vm.sortArray), new RegExp(':', 'g'), '!');
      vm.emitFormAction();
    },
    editItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'edit:' + itemId;
      vm.emitFormAction();
    },
    deleteItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'delete:' + itemId;
      vm.emitFormAction();
      //vm.$emit('formModal', {formData: vm.formData, subkey: vm.subkey, myRole: vm.myRole});
      //vm.showForm = true;
    },
    exportpdf: function () {
      let vm = this;
      if (!vm.processingReport) {
        vm.processingReport = true;
        vm.emailSendingStatus = 'Sending PDF report';
        vm.exportApiTableData(vm.exportScope, 'pdf');
      }
    },
    exportexcel: function () {
      let vm = this;
      if (!vm.processingReport) {
        vm.processingReport = true;
        vm.emailSendingStatus = 'Sending Excel report';
        vm.exportApiTableData(vm.exportScope, 'xls');
      }
    },
    approveItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'approve:' + itemId;
      vm.performUpdateAction();
    },
    rejectItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'reject:' + itemId;
      vm.performUpdateAction();
    },
    detailsItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'details:' + itemId;
      vm.emitDataAction();
    },
    cancelItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'cancel:' + itemId;
      vm.performUpdateAction();
    },
    useItem: function (inputObj) {
      let vm = this;
      vm.$emit('useThisData', {from: vm.formData.table, rowData: inputObj.rowData});
    },
    updateItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'update:' + itemId;
      vm.performUpdateAction();
    },
    sendItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'send:' + itemId;
      vm.performUpdateAction();
    },
    viewItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'view:' + itemId;
      vm.performUpdateAction();
    },
    voidItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'void:' + itemId;
      vm.performUpdateAction();
    },
    loadItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'load:' + itemId;
      vm.performUpdateAction();
    },
    startstopItem: function (itemId) {
      let vm = this;
      vm.formData.action = 'startstop:' + itemId;
      console.log(vm.formData);
      //vm.performUpdateAction();
    },
    viewAs: function (itemId) {
      let vm = this;
      vm.formData.action = 'viewas:' + itemId;
      console.log(vm.formData);
      vm.performUpdateAction();
    },
    viewMessages: function (item, mykey) {
      let vm = this;

      console.log(mykey);
      console.log(item);

      switch (mykey) {
        case 'emailorders-subsection':
          vm.$emit('tabbedModal', {
            tabsData: {
              0: {
                title: "Email HTML",
                data: item.rowData.sf.emailHtml,
                key: `${item.key}_emailhtml`
              }
            },
            key: vm.$moment().valueOf(),
            showhelp: true,
            dataHead: 'Email Preview Ordered',
            dataReq: 'tabbed-messages'
          });
          break;
        case 'emaildispatch-subsection':
          vm.$emit('tabbedModal', {
            tabsData: {
              0: {
                title: "Email HTML",
                data: item.rowData.eh,
                key: `${item.key}_emailhtml`
              }
            },
            key: vm.$moment().valueOf(),
            showhelp: true,
            dataHead: 'Email Preview Sent',
            dataReq: 'tabbed-messages'
          });
          break;
        default:
          vm.$emit('tabbedModal', {
            tabsData: {
              0: {
                title: "Email HTML",
                data: item.rowData.ed.mailObj.html,
                key: `${item.key}_emailhtml`
              },
              1: {
                title: "Email Text",
                data: vm.$_.replace(item.rowData.ed.mailObj.text, /(?:\r\n|\r|\n)/gm, "<br>"),
                key: `${item.key}_emailtext`
              },
              2: {
                title: "SMS Text",
                data: item.rowData.m,
                key: `${item.key}_smstext`
              }
            },
            key: vm.$moment().valueOf(),
            showhelp: true,
            dataHead: 'Messages Sent',
            dataReq: 'tabbed-messages'
          });
          break;
      }

    },
    clearSearchValue: function () {
      let vm = this;
      vm.searchTableValue = '';
      vm.triggertablesearch();
    },
    channelsSelected: function (emittedData) {
      // let vm = this;
      console.log(emittedData);
    }
  },
  watch: {
    currentpage: {
      handler: function () {
        let vm = this;
        vm.loadApiTableData(false);
      },
      deep: true
    },
    sortArray: {
      handler: function () {
        let vm = this;
        vm.loadApiTableData(true);
      },
      deep: true
    },
    showsearchbox: {
      handler: function () {
        let vm = this;
        if (!vm.showsearchbox) {
          vm.clearSearchValue();
        }
      },
      deep: true
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.loadApiTableData(true);
      //vm.showrefreshnoticebox = false;
      vm.setRefreshAlso(vm.subsection.mykey);
    });
  },
  beforeMount: function () {
    let vm = this,
        jsonStrut = {};
    vm.$nextTick(function () {
      if (vm.subsection.mykey.indexOf(':') !== -1) {
        let strutName = vm.subsection.mykey.toString().split(":");
        jsonStrut = require('./../tables/' + strutName[0]);
      } else {
        jsonStrut = require('./../tables/' + vm.subsection.mykey);
      }
      vm.jsonStructure = jsonStrut;
    });
  }
}
</script>

<style scoped>
.toggled-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.table-modal {
  position: absolute !important;
}

.close-btn-columns {
  position: absolute;
  right: 20px;
}

.modal-content-in-card {
  width: 100% !important;
}

.bolded-text {
  font-weight: bold;
}

.div-10-margin {
  margin: 10px !important;
}

.activity-status {
  font-weight: bold;
  font-size: larger;
}

.wrap-text {
  line-break: anywhere !important;
}
</style>