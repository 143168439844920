<template>
	<div class="columns is-multiline is-centered">
		<div class="column is-full">
			<div class="columns is-multiline is-centered">
				<div class="column is-full">
					<chartsButtons
							v-bind:refreshAble="subsection.refreshAble"
							v-bind:lineAble="showLineButton"
							v-bind:barAble="showBarButton"
							v-bind:stackAble="chartStackable"
							v-bind:isStacked="chartStacked"
							v-bind:todayScope="subsection.todayScope"
							v-bind:weekScope="subsection.weekScope"
							v-bind:monthScope="subsection.monthScope"
							v-bind:yearScope="subsection.yearScope"
							v-bind:selectedScope="selectedChartScope"
							v-on:refresh-chart="refreshChart()"
							v-on:make-line="convertChart('lineChart')"
							v-on:make-bar="convertChart('barChart')"
							v-on:toggle-stacking="toggleStacking()"
							v-on:scope-today="scopeChart('today')"
							v-on:scope-week="scopeChart('week')"
							v-on:scope-month="scopeChart('month')"
							v-on:scope-year="scopeChart('year')"
					/>
				</div>
				<div class="column is-full">
					<div class="columns is-multiline is-centered">
						<div v-for="(dataObj, key) in legends" v-bind:key="key" class="column is-narrow">
							<input v-bind:type="dataObj.type" v-bind:name="dataObj.idname" v-bind:id="dataObj.idname" v-bind:value=" dataObj.key" v-if="dataObj.visible" v-model="selectedSeries[dataObj.key]"/>
							<label class="choice-label" v-bind:for="dataObj.idname" v-if="dataObj.visible">{{ dataObj.value }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="column is-full">
			<component
					v-if="chartReady"
					class="height-300-chart"
					v-bind:is="chartSelect"
					v-bind:chartData="chartData"
					v-bind:options="options"
			></component>
		</div>
	</div>
</template>

<script>

    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import barChart from './../charts/barChart';
    import lineChart from './../charts/lineChart';
    import chartsButtons from './chartsButtons';

    export default {
        name: "charts",
        mixins: [dataFetcher],
        props: ['subsection'],
        data() {
            let vm = this;
            return {
                chartDataLoaded: false,
                selectedChartType: '',
                selectedChartScope: 'today',
                request: vm.subsection.mykey,
                jsonStructure: require('./../charts/data/' + vm.subsection.mykey),
                exportScope: 'chart',
                chartReady: true,
                apiData: {},
                selectedSeries: {},
                chartData: {},
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                config: {
                    stackAble: false,
                    stacked: false
                },
                legends: {}
            }
        },
        components: {
            barChart,
            lineChart,
            chartsButtons
        },
        computed: {
            chartStackable: function () {
                try {
                    let vm = this;
                    return vm.config.stackAble
                } catch (error) {
                    return false;
                }
            },
            chartStacked: function () {
                try {
                    let vm = this;
                    return vm.config.stacked
                } catch (error) {
                    return false;
                }
            },
            chartSelect: function () {
                try {
                    let vm = this;
                    if (vm.selectedChartType === '') {
                        return vm.subsection.charttype;
                    } else {
                        return vm.selectedChartType;
                    }
                } catch (error) {
                    return '';
                }
            },
            showLineButton: function () {
                try {
                    let vm = this;
                    if (vm.selectedChartType === '') {
                        return vm.subsection.charttype === 'barChart' && vm.subsection.lineAble;
                    } else {
                        return vm.selectedChartType === 'barChart';
                    }

                } catch (error) {
                    return false;
                }
            },
            showBarButton: function () {
                try {
                    let vm = this;
                    if (vm.selectedChartType === '') {
                        return vm.subsection.charttype === 'lineChart' && vm.subsection.barAble;
                    } else {
                        return vm.selectedChartType === 'lineChart';
                    }
                } catch (error) {
                    return false;
                }
            }
        },
        methods: {
            refreshChart: function () {
                let vm = this;
                vm.loadApiChartData();
            },
            convertChart: function (chartType) {
                let vm = this;
                vm.selectedChartType = chartType;
            },
            toggleStacking: function () {
                let vm = this;
                vm.config.stacked = !vm.config.stacked;
                vm.options.scales.xAxes.stacked = vm.config.stacked;
                vm.options.scales.yAxes.stacked = vm.config.stacked;
                vm.loadApiChartData();
            },
            scopeChart: function (chartScope) {
                let vm = this;
                vm.selectedChartScope = chartScope;
                vm.loadApiChartData();
            }
        },
        watch: {
            apiData: {
                handler: function () {
                    let vm = this;
                    vm.chartData = vm.apiData.data;
                    vm.options = vm.apiData.options;
                    vm.legends = vm.apiData.legends;
                    vm.config = vm.apiData.config;
                    vm.$nextTick(function () {
                        vm.chartDataLoaded = true;
                    });
                },
                deep: true
            },
            selectedSeries: {
                handler: function () {
                    let vm = this;
                    if (vm.chartDataLoaded) {
                        vm.loadApiChartData();
                    }
                },
                deep: true
            },
        },
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.loadApiChartData();
            });
        }
    }
</script>

<style scoped>
	.height-300-chart {
		height: 300px;
	}

	.legend-struck-through {
		text-decoration: line-through !important;
	}

	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		opacity: 0;
		z-index: -1;
	}

	.choice-label {
		position: relative;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;
		padding-left: 30px;
		padding-right: 10px;
		line-height: 40px;
		cursor: pointer;
	}

	.choice-label::before {
		content: " ";
		position: absolute;
		top: 6px;
		left: 0;
		display: block;
		width: 24px;
		height: 24px;
		/*border: 2px solid #8e44ad;*/
		border: 2px solid hsl(171, 100%, 41%);
		border-radius: 4px;
		/*z-index: -1;*/
	}

	input[type="radio"] + .choice-label::before {
		border-radius: 18px;
	}

	/* Checked */
	input[type="checkbox"]:checked + .choice-label,
	input[type="radio"]:checked + .choice-label {
		padding-left: 10px;
		color: #fff;
		z-index: 0;
	}

	input[type="checkbox"]:checked + .choice-label::before,
	input[type="radio"]:checked + .choice-label::before {
		top: 0;
		width: 100%;
		height: 100%;
		/*background: #8e44ad;*/
		background: hsl(171, 100%, 41%);
		z-index: -1;
	}

	/* Transition */
	.choice-label, .choice-label::before {
		-webkit-transition: .25s all ease;
		-o-transition: .25s all ease;
		transition: .25s all ease;
	}

	.margin-bottom {
		margin-bottom: 20px !important;
	}
</style>