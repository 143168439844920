<template>
  <channelSelector
      v-bind:requester="'sentMessageReport'"
      v-on:channelsSelected="channelsSelected"
  ></channelSelector>
</template>

<script>

import channelSelector from './channelSelector';

export default {
  name: "sentMessageReportFilter",
  components: {
    channelSelector
  },
  methods: {
    channelsSelected: function (emittedData) {
      let vm = this;
      vm.$emit('channelsSelected', emittedData);
    }
  }
}
</script>

<style scoped>

</style>