<template>
    <div class="columns is-centered">
        <div class="column is-full" v-if="!elementsLoaded">
            {{ formLoadingText }}
        </div>
        <div class="column is-full" v-if="displayingForm">
            <form>
                <div class="columns is-centered" v-if="formLoading">
                    <div class="column is-full">
                        <div class="notification is-info padded-3-px">
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.actionName.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.mpesaActionEnabled.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.numberAccount.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.successActionSMS.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.successActionPostUrl.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.failActionSMS.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.failActionPostUrl.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-full" v-if="notDisplayingForm">
            <deletionForm
                    v-bind:formFieldsVal="formFieldsData.formActions.delete"
                    v-bind:deletionAction="formsData.data.action"
                    v-on:dataEvent="dataEvent"
            />
        </div>
    </div>
</template>

<script>

    import formFunctions from "./../../../api/mixins/formFunctions";
    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import inputField from "./elements/inputField";
    import checkRadioField from "./elements/checkRadioField";
    import selectField from "./elements/selectField";
    import deletionForm from "./elements/deletionForm";
    import textAreaField from "./elements/textAreaField";

    export default {
        name: "mpesamanageractionsForm",
        mixins: [dataFetcher, formFunctions],
        components: {
            inputField,
            checkRadioField,
            deletionForm,
            selectField,
            textAreaField
        },
        data() {
            return {}
        },
        props: ['formsData', 'formActionButton'],
        methods: {},
        watch: {},
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.getThisFormData();
            });
        }
    }
</script>

<style scoped>
    .padded-3-px {
        padding: 3px !important;
    }
</style>
