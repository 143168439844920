<template>
	<vs-list v-bind:class="'break-long-words'">
		<vs-list-item v-if="hasCommonButtons">
			<listCommonButtons
					v-bind:buttonData="subsection.listbuttons"
					v-on:import-all="commonAction('import-all')"
					v-on:delete-all="commonAction('delete-all')"
					v-on:stop-all="commonAction('stop-all')"

			/>
		</vs-list-item>
		<vs-list-item v-for="item in listItems" v-bind:key="item.key" v-bind:title="item.title" v-bind:subtitle="item.subtitle">
			<listItemButtons
					v-if="item.buttons.hasButtons"
					v-bind:downloadAble="item.buttons.downloadable"
					v-bind:importAble="item.buttons.importable"
					v-bind:deleteAble="item.buttons.deleteable"
					v-on:download-item="downloadItem(item.key)"
					v-on:import-item="importItem(item.key)"
					v-on:delete-item="deleteItem(item.key)"
			/>
		</vs-list-item>
		<vs-list-item v-if="noItemsLoaded" title="Loading List..." subtitle="In one moment">
		</vs-list-item>
	</vs-list>
</template>

<script>

    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import listItemButtons from './listItemButtons.vue';
    import listCommonButtons from './listCommonButtons.vue';

    export default {
        name: "sectionList",
        mixins: [dataFetcher],
        data() {
            let vm = this;
            return {
                request: vm.subsection.mykey,
                listButtons: vm.subsection.listbuttons,
                jsonStructure: require('./../lists/' + vm.subsection.mykey),
                listItems: [],
                listData: {},
                refreshTs: ''
            }
        },
        methods: {
            emitListAction: function () {
                let vm = this;
                vm.$emit('listAction', {listIdentifier: vm.subsection.mykey, listData: vm.listData, subkey: vm.subkey, key: vm.$moment().valueOf(), refreshScope: vm.subsection.mykey + ':list'});
            },
            downloadItem: function (itemId) {
                let vm = this;
                vm.listData.action = 'download:' + itemId;
                vm.emitListAction();
            },
            importItem: function (itemId) {
                let vm = this;
                vm.listData.action = 'import:' + itemId;
                vm.emitListAction();
            },
            deleteItem: function (itemId) {
                let vm = this;
                vm.listData.action = 'delete:' + itemId;
                vm.emitListAction();
            },
            commonAction: function (functionType) {
                console.log(functionType);
            }
        },
        props: ['subsection', 'subkey', 'refreshMe', 'refreshList'],
        components: {
            listItemButtons,
            listCommonButtons
        },
        computed: {
            noItemsLoaded: function () {
                let vm = this;
                return vm.listItems.length === 0;
            },
            hasCommonButtons: function () {
                let vm = this;
                return !(typeof vm.subsection.listbuttons === 'undefined' || vm.$_.isEmpty(vm.subsection.listbuttons));
            }
        },
        watch: {
            refreshList: {
                handler: function () {
                    let vm = this,
                        changeData = vm.$_.split(vm.refreshList, ':');
                    if (vm.$_.trim(changeData[0]) === vm.$_.trim(vm.subkey)) {
                        if (vm.$_.trim(vm.refreshTs) !== vm.$_.trim(changeData[1])) {
                            vm.refreshTs = vm.$_.trim(changeData[1]);
                            vm.loadApiListData();
                        }
                    }
                },
                deep: true
            }
        },
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.loadApiListData();
            });
        }
    }
</script>

<style scoped>
	.break-long-words {
		word-break: break-word !important;
	}
</style>