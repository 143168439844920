<template>
  <component
      v-bind:is="dashSelect"
  ></component>
</template>

<script>
import proxymanagerdashboard from '../dashboards/proxymanagerdashboard-subsection';

export default {
  name: "sectionDashboard",
  data() {
    return {}
  },
  props: ['dashData'],
  components: {
    proxymanagerdashboard: proxymanagerdashboard,
  },
  computed: {
    dashSelect: function () {
      try {
        let vm = this;
        return vm.dashData.dashname;
      } catch (error) {
        return '';
      }
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
    });
  },
}
</script>

<style scoped>

</style>