<template>
	<div class="buttons">
		<a v-for="(btnData,btnName) in buttonData" class="button" v-bind:key="btnName" v-bind:class="btnData.class" v-on:click="$emit(btnData.emit)">
					<span class="icon is-small">
						<font-awesome-icon v-bind:icon="['fas', btnData.icon]"/>
					</span>
			<span v-html="btnName"></span>
		</a>
		<!--		<a class="button is-danger" v-on:click="$emit('delete-all')">-->
		<!--					<span class="icon is-small">-->
		<!--						<font-awesome-icon v-bind:icon="['fas', 'trash-alt']"/>-->
		<!--					</span>-->
		<!--			<span>Delete All</span>-->
		<!--		</a>-->
		<!--		<a class="button is-info" v-on:click="$emit('stop-all')">-->
		<!--					<span class="icon is-small">-->
		<!--						<font-awesome-icon v-bind:icon="['fas', 'hand-paper']"/>-->
		<!--					</span>-->
		<!--			<span>Stop All</span>-->
		<!--		</a>-->
	</div>
</template>

<script>
    export default {
        name: "listCommonButtons",
        data() {
            return {}
        },
        props: ['buttonData'],
        components: {},
        methods: {}
    }
</script>

<style scoped>

</style>