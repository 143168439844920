<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-four-fifths-desktop is-four-fifths-widescreen is-four-fifths-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.name.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.itemEnabled.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.itemTargetGroup.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.itemMessageType.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.itemInteractive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.chanel.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <!--        <div class="columns is-centered is-mobile is-multiline ">-->
        <!--          <div-->
        <!--              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">-->
        <!--            <div class="field">-->
        <!--              <selectField-->
        <!--                  v-bind:inputData=formFieldsData.itemInteractive.binds-->
        <!--                  v-on:dataEvent="dataEvent"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="columns is-centered is-mobile is-multiline ">-->
        <!--          <div-->
        <!--              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">-->
        <!--            <div class="field">-->
        <!--              <selectField-->
        <!--                  v-bind:inputData=formFieldsData.responseInteractive.binds-->
        <!--                  v-on:dataEvent="dataEvent"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="is-divider" data-content="Message"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.itemMessage.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.itemCaption.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.itemFooter.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Period"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <dateField
                  v-bind:inputData=formFieldsData.itemStartDate.binds
                  v-bind:dateFieldType="'basic'"
                  v-bind:isExpanded="false"
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <dateField
                  v-bind:inputData=formFieldsData.itemEndDate.binds
                  v-bind:dateFieldType="'basic'"
                  v-bind:isExpanded="false"
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Scheduling"></div>
        <scheduleMaker
            v-bind:scheduleFor="'salesofferitem'"
            v-bind:scheduleData=formFieldsData.itemSchedule.binds.initial
            v-bind:maxScheduled="'-1'"
            v-on:scheduleEvent="dataEvent"
        ></scheduleMaker>
      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import selectField from "./elements/selectField";
import dateField from "./elements/dateField";
import checkRadioField from "./elements/checkRadioField";
import textAreaField from "./elements/textAreaField";
import deletionForm from "./elements/deletionForm";
import scheduleMaker from "./elements/scheduleMaker";

export default {
  name: "salesofferactionForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField,
    selectField,
    dateField,
    deletionForm,
    checkRadioField,
    textAreaField,
    scheduleMaker
  },
  data() {
    return {}
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
