<template>
  <div>
    <div v-if="filterBoxesLoaded">
      <div class="columns is-centered is-multiline"
           v-for="(boxObj,boxIndex) in filterBoxes"
           v-bind:key="boxObj.filterKey">
        <div class="column is-full">
          <div class="card">
            <header class="card-header box-header-div">
              <div class="button is-danger card-header-icon card-close-button"
                   v-on:click="removeBox(boxIndex,$event)"
                   v-if="filterBoxes.length > 1">
                <span class="icon is-small">
                <font-awesome-icon v-bind:icon="['fas', 'minus-square']"/>
                </span>
              </div>
            </header>
            <div class="card-content">
              <div class="content">
                <div
                    class="columns is-centered is-mobile is-multiline is-variable is-0-mobile is-0-tablet is-1-desktop is-1-widescreen is-1-fullhd"
                    v-for="(boxObjItems,boxObjItemsIndex) in boxObj.filterData"
                    v-bind:key="boxObjItems.elementId">
                  <div
                      class="column is-narrow is-full-mobile is-full-tablet is-1-desktop is-1-widescreen is-1-fullhd">
                    <div class="field">
                      <p class="element-label">&#8203;</p>
                      <div class="button is-danger is-outlined"
                           v-if="boxObj.filterData.length >1"
                           v-on:click="removeFilter(boxIndex,boxObjItemsIndex,$event)">
                        <span class="icon is-small">
                          <font-awesome-icon v-bind:icon="['fas', 'minus-square']"/>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                      class="column is-narrow is-full-mobile is-full-tablet is-4-desktop is-4-widescreen is-4-fullhd">
                    <div class="field">
                      <selectField
                          v-bind:inputData=boxObjItems.itemFilterColumn
                          v-on:dataEvent="filteringDataEvent"
                      />
                    </div>
                  </div>
                  <div
                      class="column is-narrow is-full-mobile is-full-tablet is-3-desktop is-3-widescreen is-3-fullhd">
                    <div class="field">
                      <selectField
                          v-bind:inputData=boxObjItems.itemFilterOperation
                          v-on:dataEvent="filteringDataEvent"
                      />
                    </div>
                  </div>
                  <div
                      class="column is-narrow is-full-mobile is-full-tablet is-3-desktop is-3-widescreen is-3-fullhd">
                    <div class="field">
                      <inputField
                          v-bind:inputData=boxObjItems.itemFilterValue
                          v-on:dataEvent="filteringDataEvent"
                      />
                    </div>
                  </div>
                  <div
                      class="column is-narrow is-full-mobile is-full-tablet is-1-desktop is-1-widescreen is-1-fullhd">
                    <div class="field">
                      <p class="element-label">&#8203;</p>
                      <div class="button is-success is-outlined"
                           v-if="boxObjItemsIndex===(boxObj.filterData.length-1)"
                           v-on:click="addFilter(boxIndex,$event)">
                        <span class="icon is-small">
                          <font-awesome-icon v-bind:icon="['fas', 'plus-square']"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer class="card-footer" v-show="showBoxAddButton">
              <div class="button is-success is-fullwidth card-footer-item"
                   v-on:click="addBox($event)"
                   v-if="boxIndex === (filterBoxes.length - 1)">
                OR
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filterBoxesLoaded" class="columns is-centered is-multiline">
      <div class="column is-full">{{ groupedTextFinalText }}</div>
    </div>
    <div v-if="!filterBoxesLoaded" class="columns is-centered is-multiline">
      <div class="column is-full">{{ returnFiltersText }}</div>
    </div>
  </div>
</template>

<script>

import formFunctions from "./../../../../api/mixins/formFunctions";
import selectField from "./selectField";
import inputField from "./inputField";

export default {
  name: "filterMaker",
  mixins: [formFunctions],
  data() {
    return {
      showBoxAddButton: false,
      filterBoxesLoaded: false,
      filterBoxes: [],
      // filterSummary: [],
      loadingFiltersText: '',
      groupedTextFinalText: ''
    }
  },
  props: ['filterData', 'filterFor', 'maxFilters', 'filterItemsIdReceivedInit', 'filterItemsArrayReceived', 'filterItemsIdReceived', 'noFilterMessage', 'filterFormProcessedReceived', 'dataUpdateActivityTimeStamp'],
  components: {
    selectField,
    inputField,
  },
  computed: {
    returnFiltersText: function () {
      let vm = this;
      if (vm.$_.trim(vm.loadingFiltersText) !== '') {
        return vm.loadingFiltersText;
      } else {
        return vm.noFilterMessage;
      }
    }
  },
  methods: {
    returnWorkingFilterItemsId: function () {
      let vm = this;
      if (vm.$_.trim(vm.filterItemsIdReceived.toString()) !== '') {
        return vm.$_.trim(vm.filterItemsIdReceived.toString());
      } else if (vm.$_.trim(vm.filterItemsIdReceivedInit.toString()) !== '') {
        return vm.$_.trim(vm.filterItemsIdReceivedInit.toString());
      } else {
        return '';
      }
    },
    returnFilterElements: function (filterItemsArray, filterItemsId, parentFilterId, caller) {
      let vm = this,
          filterId = parentFilterId ? parentFilterId : vm.$DateTime.local().setZone("UTC").valueOf(),
          columnsArray = {},
          selectDataProvided = [],
          elementId;
      if (filterItemsId !== '') {
        columnsArray = filterItemsArray[filterItemsId];
        elementId = vm.$DateTime.local().setZone("UTC").valueOf();
        vm.$_.forEach(columnsArray, function (columnsArrayObj) {
          selectDataProvided.push({
            key: columnsArrayObj.key,
            visible: true,
            value: columnsArrayObj.item,
            type: columnsArrayObj.type
          });
        });
        console.log(vm.filterData);
        console.log(caller);
        if (caller === 'filterItemsArrayReceived') {
          if (vm.$_.trim(vm.filterData.toString()) !== '') {
            let filterDataObj = {},
                filterDataObjMinimized = {},
                filterDataObjArr = [];
            vm.$_.forEach(JSON.parse(vm.filterData.toString()), function (filterDataBoxValue, filterDataBoxKey) {
              vm.$_.forEach(filterDataBoxValue, function (filterDataBoxItemValue, filterDataBoxItemKey) {
                vm.$_.forEach(columnsArray, function (column) {
                  if (vm.$_.trim(column.key) === vm.$_.trim(filterDataBoxItemValue.itemFilterColumn)) {
                    if (typeof filterDataObj[filterDataBoxKey] === 'undefined') {
                      filterDataObj[filterDataBoxKey] = {};
                    }
                    if (typeof filterDataObj[filterDataBoxKey][filterDataBoxItemKey] === 'undefined') {
                      filterDataObj[filterDataBoxKey][filterDataBoxItemKey] = [
                        {
                          elementId: filterDataBoxItemKey,
                          itemFilterColumn: {
                            element: 'select',
                            elementKey: '',
                            elementName: `itemFilterColumn_${filterDataBoxKey}_${filterDataBoxItemKey}`,
                            initial: `${filterDataBoxItemValue.itemFilterColumn}`,
                            labelText: 'Column',
                            placeholderText: 'Column',
                            invalidText: 'Please select the filter column',
                            iconCategory: 'fas',
                            icon: 'bullseye',
                            type: 'dataprovided',
                            showDataProvided: selectDataProvided,
                            dataJson: '',
                            dataRequest: '',
                            dataHeader: `itemFilterColumn_${filterDataBoxKey}_${filterDataBoxItemKey}`,
                            mandatory: 'no',
                            canCreateNew: false,
                            canSelectNone: false,
                            canSelectAll: false,
                            validationRules: '',
                            disabled: false
                          },
                          itemFilterOperation: {
                            element: 'select',
                            elementKey: '',
                            elementName: `itemFilterOperation_${filterDataBoxKey}_${filterDataBoxItemKey}`,
                            initial: `${filterDataBoxItemValue.itemFilterOperation}`,
                            labelText: 'Compare',
                            placeholderText: 'Compare',
                            invalidText: 'Please select the filter comparing',
                            iconCategory: 'fas',
                            icon: 'bullseye',
                            type: 'dataprovided',
                            showDataProvided: [
                              {key: 'lessthan', visible: true, value: 'Less Than'},
                              {key: 'equalto', visible: true, value: 'Equal To'},
                              {key: 'greaterthan', visible: true, value: 'Greater Than'},
                              {key: 'notequalto', visible: true, value: 'Not Equal To'}
                            ],
                            dataJson: '',
                            dataRequest: '',
                            dataHeader: `itemFilterOperation_${filterDataBoxKey}_${filterDataBoxItemKey}`,
                            mandatory: 'no',
                            canCreateNew: false,
                            canSelectNone: false,
                            canSelectAll: false,
                            validationRules: '',
                            disabled: false
                          },
                          itemFilterValue: {
                            element: 'input',
                            elementKey: '',
                            elementName: `itemFilterValue_${filterDataBoxKey}_${filterDataBoxItemKey}`,
                            initial: `${filterDataBoxItemValue.itemFilterValue}`,
                            labelText: 'Value',
                            placeholderText: 'Value',
                            invalidText: 'Please enter a value for this filter',
                            iconCategory: 'fas',
                            icon: 'mobile-alt',
                            type: 'text',
                            mandatory: 'no',
                            validationRules: '',
                            disabled: false
                          }
                        }
                      ];
                      let itemFilterColumnData = {
                            inputEntered: `itemFilterColumn_${filterDataBoxKey}_${filterDataBoxItemKey}` + '==' + filterDataBoxItemValue.itemFilterColumn,
                            inputInvalidity: `itemFilterColumn_${filterDataBoxKey}_${filterDataBoxItemKey}` + '==false',
                            filtersId: vm.filterItemsIdReceived
                          },
                          itemFilterOperationData = {
                            inputEntered: `itemFilterOperation_${filterDataBoxKey}_${filterDataBoxItemKey}` + '==' + filterDataBoxItemValue.itemFilterOperation,
                            inputInvalidity: `itemFilterOperation_${filterDataBoxKey}_${filterDataBoxItemKey}` + '==false',
                            filtersId: vm.filterItemsIdReceived
                          },
                          itemFilterValueData = {
                            inputEntered: `itemFilterValue_${filterDataBoxKey}_${filterDataBoxItemKey}` + '==' + filterDataBoxItemValue.itemFilterValue,
                            inputInvalidity: `itemFilterValue_${filterDataBoxKey}_${filterDataBoxItemKey}` + '==false',
                            filtersId: vm.filterItemsIdReceived
                          };
                      vm.$emit('filterEvent', itemFilterColumnData);
                      vm.$emit('filterEvent', itemFilterOperationData);
                      vm.$emit('filterEvent', itemFilterValueData);
                    }
                  }
                });
              });
            });
            console.log(filterDataObj);
            vm.$_.forEach(filterDataObj, function (filterDataObjValue, filterDataObjKey) {
              console.log(filterDataObjValue);
              console.log(filterDataObjKey);
              if (typeof filterDataObjMinimized[filterDataObjKey] === 'undefined') {
                filterDataObjMinimized[filterDataObjKey] = {filterKey: filterDataObjKey, filterData: []};
              }
              vm.$_.forEach(filterDataObjValue, function (filterDataObjItemValue, filterDataObjItemKey) {
                console.log(filterDataObjItemValue);
                console.log(filterDataObjItemKey);
                filterDataObjMinimized[filterDataObjKey].filterData.push(filterDataObjItemValue[0]);
                // filterDataObjArr.push({filterKey: filterDataObjKey, filterData: filterDataObjItemValue})
              });
            });
            vm.$_.forEach(filterDataObjMinimized, function (filterDataObjMinimizedValue) {
              filterDataObjArr.push(filterDataObjMinimizedValue);
            });
            console.log(filterDataObjArr);
            return {
              returnType: 'load',
              returnData: filterDataObjArr
            };
          } else {
            return {
              returnType: 'single',
              returnData: {
                filterKey: filterId,
                filterData: [
                  {
                    elementId: elementId,
                    itemFilterColumn: {
                      element: 'select',
                      elementKey: '',
                      elementName: `itemFilterColumn_${filterId}_${elementId}`,
                      initial: ``,
                      labelText: 'Column',
                      placeholderText: 'Column',
                      invalidText: 'Please select the filter column',
                      iconCategory: 'fas',
                      icon: 'bullseye',
                      type: 'dataprovided',
                      showDataProvided: selectDataProvided,
                      dataJson: '',
                      dataRequest: '',
                      dataHeader: `itemFilterColumn_${filterId}_${elementId}`,
                      mandatory: 'no',
                      canCreateNew: false,
                      canSelectNone: false,
                      canSelectAll: false,
                      validationRules: '',
                      disabled: false
                    },
                    itemFilterOperation: {
                      element: 'select',
                      elementKey: '',
                      elementName: `itemFilterOperation_${filterId}_${elementId}`,
                      initial: ``,
                      labelText: 'Compare',
                      placeholderText: 'Compare',
                      invalidText: 'Please select the filter comparing',
                      iconCategory: 'fas',
                      icon: 'bullseye',
                      type: 'dataprovided',
                      showDataProvided: [
                        {key: 'lessthan', visible: true, value: 'Less Than'},
                        {key: 'equalto', visible: true, value: 'Equal To'},
                        {key: 'greaterthan', visible: true, value: 'Greater Than'},
                        {key: 'notequalto', visible: true, value: 'Not Equal To'}
                      ],
                      dataJson: '',
                      dataRequest: '',
                      dataHeader: `itemFilterOperation_${filterId}_${elementId}`,
                      mandatory: 'no',
                      canCreateNew: false,
                      canSelectNone: false,
                      canSelectAll: false,
                      validationRules: '',
                      disabled: false
                    },
                    itemFilterValue: {
                      element: 'input',
                      elementKey: '',
                      elementName: `itemFilterValue_${filterId}_${elementId}`,
                      initial: ``,
                      labelText: 'Value',
                      placeholderText: 'Value',
                      invalidText: 'Please enter a value for this filter',
                      iconCategory: 'fas',
                      icon: 'mobile-alt',
                      type: 'text',
                      mandatory: 'no',
                      validationRules: '',
                      disabled: false
                    }
                  }
                ]
              }
            };
          }
        } else {
          console.log(filterId);
          console.log(elementId);
          return {
            filterKey: filterId,
            filterData: [
              {
                elementId: elementId,
                itemFilterColumn: {
                  element: 'select',
                  elementKey: '',
                  elementName: `itemFilterColumn_${filterId}_${elementId}`,
                  initial: ``,
                  labelText: 'Column',
                  placeholderText: 'Column',
                  invalidText: 'Please select the filter column',
                  iconCategory: 'fas',
                  icon: 'bullseye',
                  type: 'dataprovided',
                  showDataProvided: selectDataProvided,
                  dataJson: '',
                  dataRequest: '',
                  dataHeader: `itemFilterColumn_${filterId}_${elementId}`,
                  mandatory: 'no',
                  canCreateNew: false,
                  canSelectNone: false,
                  canSelectAll: false,
                  validationRules: '',
                  disabled: false
                },
                itemFilterOperation: {
                  element: 'select',
                  elementKey: '',
                  elementName: `itemFilterOperation_${filterId}_${elementId}`,
                  initial: ``,
                  labelText: 'Compare',
                  placeholderText: 'Compare',
                  invalidText: 'Please select the filter comparing',
                  iconCategory: 'fas',
                  icon: 'bullseye',
                  type: 'dataprovided',
                  showDataProvided: [
                    {key: 'lessthan', visible: true, value: 'Less Than'},
                    {key: 'equalto', visible: true, value: 'Equal To'},
                    {key: 'greaterthan', visible: true, value: 'Greater Than'},
                    {key: 'notequalto', visible: true, value: 'Not Equal To'}
                  ],
                  dataJson: '',
                  dataRequest: '',
                  dataHeader: `itemFilterOperation_${filterId}_${elementId}`,
                  mandatory: 'no',
                  canCreateNew: false,
                  canSelectNone: false,
                  canSelectAll: false,
                  validationRules: '',
                  disabled: false
                },
                itemFilterValue: {
                  element: 'input',
                  elementKey: '',
                  elementName: `itemFilterValue_${filterId}_${elementId}`,
                  initial: ``,
                  labelText: 'Value',
                  placeholderText: 'Value',
                  invalidText: 'Please enter a value for this filter',
                  iconCategory: 'fas',
                  icon: 'mobile-alt',
                  type: 'text',
                  mandatory: 'no',
                  validationRules: '',
                  disabled: false
                }
              }
            ]
          }
        }
      } else {
        vm.filterBoxesLoaded = false;
        return {};
      }
    },
    addBox: function (event) {
      if (event) {
        event.preventDefault()
      }
      let vm = this,
          workingFilterItemsIdReceived = vm.returnWorkingFilterItemsId();
      vm.filterBoxes.push(vm.returnFilterElements(vm.filterItemsArrayReceived, workingFilterItemsIdReceived, false, 'addBox'));
      // vm.filterSummary.push([{done: false, column: '', compare: '', value: ''}]);
      console.log(vm.filterBoxes);
      // console.log(vm.filterSummary);
    },
    removeBox: function (boxIndexToRemove, event) {
      let vm = this;
      if (event) {
        event.preventDefault()
      }
      if (vm.filterBoxes.length > 1) {
        console.log(`Remove ${boxIndexToRemove}`);
        console.log(JSON.parse(JSON.stringify(vm.filterBoxes)));
        console.log(vm.filterBoxes[boxIndexToRemove]);
        let filterBoxDeletionData = {
          inputEntered: `itemFilterBoxDelete_${vm.filterBoxes[boxIndexToRemove].filterKey}_${vm.filterBoxes[boxIndexToRemove].filterKey}` + '==' + `delete`,
          inputInvalidity: `itemFilterBoxDelete_${vm.filterBoxes[boxIndexToRemove].filterKey}_${vm.filterBoxes[boxIndexToRemove].filterKey}` + '==false',
          filtersId: vm.filterItemsIdReceived
        };
        console.log(filterBoxDeletionData);
        console.log(vm.filterBoxes);
        // console.log(vm.filterSummary);
        vm.$emit('filterEvent', filterBoxDeletionData);
        vm.filterBoxes.splice(boxIndexToRemove, 1);
        // vm.filterSummary.splice(boxIndexToRemove, 1);
        console.log(vm.filterBoxes);
      }
    },
    addFilter: function (boxIndex, event) {
      if (event) {
        event.preventDefault()
      }
      let vm = this,
          workingFilterItemsIdReceived = vm.returnWorkingFilterItemsId();
      console.log(vm.returnFilterElements(vm.filterItemsArrayReceived, workingFilterItemsIdReceived, vm.filterBoxes[boxIndex].filterKey, 'addFilter'));
      console.log(vm.filterBoxes);
      vm.filterBoxes[boxIndex].filterData.push(vm.returnFilterElements(vm.filterItemsArrayReceived, workingFilterItemsIdReceived, vm.filterBoxes[boxIndex].filterKey, 'addFilter').filterData[0]);
      // vm.filterSummary[boxIndex].push([{done: false, column: '', compare: '', value: ''}]);
      console.log(vm.filterBoxes);
      // console.log(vm.filterSummary);
    },
    removeFilter: function (boxIndex, boxObjItemsIndex, event) {
      if (event) {
        event.preventDefault()
      }
      let vm = this;
      if (vm.filterBoxes[boxIndex].filterData.length > 1) {
        console.log(`Remove ${boxIndex}->${boxObjItemsIndex}`);
        console.log(JSON.parse(JSON.stringify(vm.filterBoxes)));
        console.log(vm.filterBoxes[boxIndex]);
        let filterItemBoxDeletionData = {
          inputEntered: `itemFilterDelete_${vm.filterBoxes[boxIndex].filterKey}_${vm.filterBoxes[boxIndex].filterData[boxObjItemsIndex].elementId}` + '==' + `delete`,
          inputInvalidity: `itemFilterDelete_${vm.filterBoxes[boxIndex].filterKey}_${vm.filterBoxes[boxIndex].filterData[boxObjItemsIndex].elementId}` + '==false',
          filtersId: vm.filterItemsIdReceived
        };
        console.log(filterItemBoxDeletionData);
        console.log(vm.filterBoxes);
        // console.log(vm.filterSummary);
        vm.$emit('filterEvent', filterItemBoxDeletionData);
        vm.filterBoxes[boxIndex].filterData.splice(boxObjItemsIndex, 1);
        // vm.filterSummary[boxIndex].splice(boxObjItemsIndex, 1);
        console.log(vm.filterBoxes);
      }
    },
    returnSummaryText: function () {
      let vm = this,
          activeFilterElementsSourceObjMaker = {},
          compareObj = {equalto: '=', notequalto: '!=', lessthan: '<', greaterthan: '>'},
          filterDataObj = {}, fieldKeyArr, filterDataObjFinal = {},
          itemDeleteOmit = [], groupedText = {}, columnValue, ComparisonValue, valueValue,
          groupedTextFinal = [], groupedTextFinalText = '', filterTextData,
          useFilterFormProcessedReceived = false, useActiveFilterBoxDelete = false, useActiveFilterItemDelete = false;
      console.log(vm.filterFormProcessedReceived);
      console.log(vm.filterFormProcessedReceived.activeFilterElements);
      if (typeof vm.filterFormProcessedReceived.activeFilterElements !== 'undefined') {
        if (vm.filterFormProcessedReceived.activeFilterElements.length > 0) {
          useFilterFormProcessedReceived = true;
        }
      }
      if (useFilterFormProcessedReceived) {
        vm.$_.forEach(vm.filterFormProcessedReceived.activeFilterElements, function (activeFilterElement) {
          if (typeof filterDataObj[activeFilterElement.boxId] === 'undefined') {
            filterDataObj[activeFilterElement.boxId] = {}
          }
          if (typeof filterDataObj[activeFilterElement.boxId][activeFilterElement.fieldId] === 'undefined') {
            filterDataObj[activeFilterElement.boxId][activeFilterElement.fieldId] = {}
          }
          fieldKeyArr = vm.$_.split(activeFilterElement.fieldKey, '_');
          filterDataObj[activeFilterElement.boxId][activeFilterElement.fieldId][fieldKeyArr[0]] = activeFilterElement.fieldValue;
        });
      } else {
        console.log(vm.filterData);
        if (vm.$_.trim(vm.filterData) !== '') {
          filterDataObj = JSON.parse(vm.filterData);
        }
      }

      if (typeof vm.filterFormProcessedReceived.activeFilterElementsDelete !== 'undefined') {
        if (typeof vm.filterFormProcessedReceived.activeFilterElementsDelete.itemFilterBoxDelete !== 'undefined') {
          if (vm.filterFormProcessedReceived.activeFilterElementsDelete.itemFilterBoxDelete.length > 0) {
            useActiveFilterBoxDelete = true;
          }
        }

        if (typeof vm.filterFormProcessedReceived.activeFilterElementsDelete.itemFilterDelete !== 'undefined') {
          if (vm.filterFormProcessedReceived.activeFilterElementsDelete.itemFilterDelete.length > 0) {
            useActiveFilterItemDelete = true;
          }
        }
      }

      if (useActiveFilterBoxDelete) {
        itemDeleteOmit = vm.filterFormProcessedReceived.activeFilterElementsDelete.itemFilterBoxDelete;
      }
      filterDataObjFinal = vm.$_.omit(filterDataObj, itemDeleteOmit);
      if (useActiveFilterItemDelete) {
        itemDeleteOmit = vm.filterFormProcessedReceived.activeFilterElementsDelete.itemFilterDelete;
      }
      vm.$_.forEach(filterDataObjFinal, function (filterDataObjFinalValue, filterDataObjFinalKey) {
        filterDataObjFinal[filterDataObjFinalKey] = vm.$_.omit(filterDataObjFinal[filterDataObjFinalKey], itemDeleteOmit);
      });
      vm.$_.forEach(vm.filterItemsArrayReceived, function (filterItemsArrayReceivedArr) {
        vm.$_.forEach(filterItemsArrayReceivedArr, function (activeFilterElementsSourceObj) {
          activeFilterElementsSourceObjMaker[activeFilterElementsSourceObj.key] = activeFilterElementsSourceObj.item
        });
      });
      vm.$_.forEach(filterDataObjFinal, function (filterDataObjFinalGroup, filterDataObjFinalGroupKey) {
        vm.$_.forEach(filterDataObjFinalGroup, function (filterDataObjFinalGroupObj) {
          columnValue = activeFilterElementsSourceObjMaker[filterDataObjFinalGroupObj.itemFilterColumn];
          ComparisonValue = compareObj[filterDataObjFinalGroupObj.itemFilterOperation];
          valueValue = filterDataObjFinalGroupObj.itemFilterValue;
          if (typeof columnValue !== 'undefined' && typeof ComparisonValue !== 'undefined' && typeof valueValue !== 'undefined') {
            if (typeof groupedText[filterDataObjFinalGroupKey] === 'undefined') {
              groupedText[filterDataObjFinalGroupKey] = [];
            }
            groupedText[filterDataObjFinalGroupKey].push(`(${columnValue} ${ComparisonValue} ${valueValue})`);
          }
        });
      });
      vm.$_.forEach(groupedText, function (groupedTextArr) {
        groupedTextFinal.push(`(${vm.$_.join(groupedTextArr, ' AND ')})`);
      });
      groupedTextFinalText = vm.$_.join(groupedTextFinal, ' OR ');
      filterTextData = {
        inputEntered: `filterText` + '==' + groupedTextFinalText,
        inputInvalidity: `filterText` + '==false'
      };
      if(vm.groupedTextFinalText !== groupedTextFinalText){
        vm.groupedTextFinalText = groupedTextFinalText;
        vm.$emit('dataEvent', filterTextData);
      }
    },
  },
  watch: {
    filterItemsArrayReceived: {
      handler: function () {
        let vm = this,
            workingFilterItemsIdReceived = vm.returnWorkingFilterItemsId(),
            boxesDataBack;
        if (vm.$_.trim(vm.filterItemsArrayReceived) !== '' && workingFilterItemsIdReceived !== '') {
          boxesDataBack = vm.returnFilterElements(vm.filterItemsArrayReceived, workingFilterItemsIdReceived, false, 'filterItemsArrayReceived');
          console.log(boxesDataBack);

          if (boxesDataBack.returnType === 'single') {
            vm.filterBoxes = [JSON.parse(JSON.stringify(boxesDataBack.returnData))];
          } else if (boxesDataBack.returnType === 'load') {
            console.log(JSON.parse(JSON.stringify(boxesDataBack.returnData)));
            vm.filterBoxes = JSON.parse(JSON.stringify(boxesDataBack.returnData));
          } else {
            vm.filterBoxes = [];
          }
          // vm.filterSummary = [[{done: false, column: '', compare: '', value: ''}]];
          vm.filterBoxesLoaded = true;
          vm.showBoxAddButton = true;
          vm.$emit('filtersInvalidate', {});
        } else {
          vm.filterBoxesLoaded = false;
          vm.showBoxAddButton = false;
        }
      },
      deep: true
    },
    filterItemsIdReceived: {
      handler: function () {
        let vm = this,
            workingFilterItemsIdReceived = vm.returnWorkingFilterItemsId();
        if (vm.$_.trim(vm.filterItemsArrayReceived) !== '' && workingFilterItemsIdReceived !== '') {
          vm.filterBoxes = [vm.returnFilterElements(vm.filterItemsArrayReceived, workingFilterItemsIdReceived, false, 'filterItemsIdReceived')];
          // vm.filterSummary = [[{done: false, column: '', compare: '', value: ''}]];
          vm.filterBoxesLoaded = true;
          vm.showBoxAddButton = true;
          vm.$emit('filtersInvalidate', {});
        } else {
          vm.filterBoxesLoaded = false;
          vm.showBoxAddButton = false;
        }
      },
      deep: true
    },
    dataUpdateActivityTimeStamp: {
      handler: function () {
        let vm = this;
        console.log(vm.dataUpdateActivityTimeStamp);
        vm.returnSummaryText();
      },
      deep: true
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.filterBoxesLoaded = false;
      console.log(vm.filterData);
      if (vm.$_.trim(vm.filterItemsIdReceivedInit) !== '') {
        vm.loadingFiltersText = 'The filters will be loaded soon.'
      }
    });
  }
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}

.box-header-div {
  background-color: hsl(171, 100%, 96%);
}

.card-close-button {
  position: absolute;
  right: 0;
}


</style>