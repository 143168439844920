<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div
              class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.name.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.proxyActive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.ip.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.description.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-full">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.location.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>

import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import checkRadioField from "./elements/checkRadioField";
import textAreaField from "./elements/textAreaField";
import deletionForm from "./elements/deletionForm";


export default {
  name: "nodesManagerProxyForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField,
    checkRadioField,
    textAreaField,
    deletionForm
  },
  data() {
    return {
      nodeRoles: {presentationNodes: false, processingNodes: false}
    }
  },
  props: ['formsData', 'formActionButton'],
  methods: {},
  watch: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
