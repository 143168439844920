<template>
    <form v-on:submit="checkForm">
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.userEmail.$invalid }">
                        <input class="input is-primary is-rounded" type="email" placeholder="Email"
                               v-model.trim="userEmail"
                               v-bind:class="{ 'is-danger' : $v.userEmail.$invalid }">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'envelope']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="$v.userEmail.$invalid">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="!$v.userEmail.required">
                        {{ emailInvalidText }}
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="!$v.userEmail.email">
                        {{ emailInvalidEmailText }}
                    </p>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.userMobile.$invalid }">
                        <input class="input is-primary is-rounded" type="text" placeholder="Mobile Number"
                               v-model.trim="userMobile"
                               v-bind:class="{ 'is-danger' : $v.userMobile.$invalid }">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'mobile-alt']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="$v.userMobile.$invalid">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="$v.userMobile.$invalid">
                        {{ mobileMissingText }}
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.userPassword.$invalid }">
                        <input class="input is-primary is-rounded" type="password" placeholder="Password"
                               v-model.trim="userPassword"
                               v-bind:class="{ 'is-danger' : $v.userPassword.$invalid }">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'key']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="$v.userPassword.$invalid">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="!$v.userPassword.required">
                        {{passwordInvalidText }}
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="!$v.userPassword.minLength">
                        {{passwordInvalidLengthText }}
                    </p>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="field">
                    <p class="control has-icons-left"
                       v-bind:class="{ 'has-icons-right' : $v.userPasswordConfirm.$invalid }">
                        <input class="input is-primary is-rounded" type="password" placeholder="Password Confirmation"
                               v-model.trim="userPasswordConfirm"
                               v-bind:class="{ 'is-danger' : $v.userPasswordConfirm.$invalid }">
                        <span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'key']"/>
                        </span>
                        <span class="icon is-small is-right redColored" v-if="$v.userPasswordConfirm.$invalid">
                            <font-awesome-icon v-bind:icon="['fas', 'key']"/>
                        </span>
                    </p>
                    <p class="help is-danger bigger-text bolder-text" v-if="!$v.userPasswordConfirm.sameAsUserPassword">
                        {{passwordConfirmNotMatchingText }}
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <div class="field">
                    <p class="control">
                        <button class="button is-primary is-inverted is-medium is-fullwidth">Reset Password</button>
                    </p>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-one-third">
                <a href="/login" class="black-text underlined_text">Return to Login</a>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column">
                <p class="help is-danger bigger-text bolder-text" v-if="resetInvalid">{{ resetInvalidText }}</p>
            </div>
        </div>
    </form>
</template>

<script>
    import api from "./../../../api/api.js";
    import {required, minLength, sameAs, email} from "vuelidate/lib/validators";

    export default {
        name: "resetCredentials",
        data() {
            return {
                userEmail: '',
                userMobile: '',
                userPassword: '',
                userPasswordConfirm: '',
                emailInvalid: false,
                emailInvalidText: 'Please enter your valid Email',
                emailInvalidEmailText: 'Not a valid Email',
                mobileMissing: false,
                mobileMissingText: 'Please enter your mobile number',
                passwordInvalid: false,
                passwordInvalidText: 'Please enter the new password',
                passwordInvalidLengthText: 'The password must be at least 6 characters long',
                passwordConfirmInvalid: false,
                passwordConfirmNotMatchingText: 'The passwords are not matching',
                resetInvalid: false,
                resetInvalidText: 'The information you gave did not match'
            }
        },
        validations() {
            return {
                userEmail: {required, email},
                userMobile: {required},
                userPassword: {required, minLength: minLength(6)},
                userPasswordConfirm: {sameAsUserPassword: sameAs('userPassword')}
            };
        },
        methods: {
            checkForm: function (event) {
                event.preventDefault();
                let vm = this,
                    noError = true;
                if (vm.userEmail === '' || !vm.$validator.isEmail(vm.userEmail)) {
                    vm.emailInvalid = true;
                    noError = false;
                } else {
                    vm.emailInvalid = false;
                }
                if (vm.userMobile === '') {
                    vm.mobileMissing = true;
                    noError = false;
                } else {
                    vm.mobileMissing = false;
                }
                if (vm.userPassword === '') {
                    vm.passwordInvalid = true;
                    noError = false;
                } else {
                    vm.passwordInvalid = false;
                }
                if (vm.userPasswordConfirm === '') {
                    vm.passwordConfirmInvalid = true;
                    noError = false;
                } else {
                    vm.passwordConfirmInvalid = false;
                }

                if (noError && !vm.$v.$invalid) {
                    vm.submitform();
                }
            },
            submitform: function () {
                let vm = this;
                vm.resetInvalid = false;
                let authorizationToken;
                if (vm.$session.has('jwt')) {
                    authorizationToken = vm.$session.get('jwt');
                } else {
                    authorizationToken = '';
                }
                let reqHeader = {
                    headers: {
                        authorization: authorizationToken,
                    }
                };
                api.operation('resetpassword').post({
                    email: vm.userEmail,
                    mobile: vm.userMobile,
                    password: vm.userPassword,
                    passwordConfirm: vm.userPasswordConfirm,
                    requestFrom: window.location.href,
                    session: vm.$session.id(),
                    sessiondata: JSON.stringify(vm.$session.getAll())
                }, reqHeader)
                    .then(({data}) => {
                        if (data.success) {
                            vm.resetInvalid = false;
                            vm.$session.flash.set('logoff', 'Your password reset was successful.');
                            window.location.href = '/login'
                        } else {
                            if (data.msg === "Authentication failed.") {
                                vm.resetInvalid = true;
                            }
                        }
                    });
            }
        },
        mounted: function () {
        }
    }
</script>

<style scoped>
    .redColored {
        color: red !important;
    }

    .black-text {
        color: black !important;
    }

    .underlined_text {
        text-decoration: underline !important;
    }

    .bigger-text {
        font-size: larger;
    }

    .bolder-text {
        font-weight: bold;
    }
</style>