<template>
    <div class="columns is-centered">
        <div class="column is-full" v-if="!elementsLoaded">
            {{ formLoadingText }}
        </div>
        <div class="column is-full" v-if="displayingForm">
            <form>
                <div class="columns is-centered" v-if="formLoading">
                    <div class="column is-full">
                        <div class="notification is-info padded-3-px">
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.name.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.userEnabled.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.userAccount.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.email.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.mobile.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.idNumber.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <dateField
                                    v-bind:inputData=formFieldsData.birthday.binds
                                    v-bind:dateFieldType="'basic'"
                                    v-bind:isExpanded="false"
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.role.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.password.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.userGender.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.userActivate.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.activationNotification.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-full" v-if="notDisplayingForm">
            <deletionForm
                    v-bind:formFieldsVal="formFieldsData.formActions.delete"
                    v-bind:deletionAction="formsData.data.action"
                    v-on:dataEvent="dataEvent"
            />
        </div>
    </div>
</template>

<script>

import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import checkRadioField from "./elements/checkRadioField";
import selectField from "./elements/selectField";
import deletionForm from "./elements/deletionForm";
import dateField from "./elements/dateField";

export default {
 name: "accountmanagerusersForm",
 mixins: [dataFetcher, formFunctions],
 components: {
  inputField,
  checkRadioField,
  deletionForm,
  selectField,
  dateField
 },
 data() {
  return {}
 },
 props: ['formsData', 'formActionButton'],
 methods: {},
 watch: {},
 mounted: function () {
  let vm = this;
  vm.$nextTick(function () {
   vm.getThisFormData();
  });
 }
}
</script>

<style scoped>
.padded-3-px {
    padding: 3px !important;
}
</style>
