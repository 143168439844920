<template>
	<form v-on:submit="checkForm">
		<div class="columns is-centered">
			<div class="column is-one-third">
				<div class="field">
					<p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : emailInvalid }">
						<input class="input is-primary is-rounded" type="email" placeholder="Email" v-model.trim="userEmail" v-bind:class="{ 'is-danger' : emailInvalid }">
						<span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'envelope']"/>
                        </span>
						<span class="icon is-small is-right redColored" v-if="emailInvalid">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
					</p>
					<p class="help is-danger bigger-text bolder-text" v-if="emailInvalid">{{ emailInvalidText }}</p>
				</div>
			</div>
			<div class="column is-one-third">
				<div class="field">
					<p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : mobileMissing }">
						<input class="input is-primary is-rounded" type="text" placeholder="Mobile Number" v-model.trim="userMobile" v-bind:class="{ 'is-danger' : mobileMissing }">
						<span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'mobile-alt']"/>
                        </span>
						<span class="icon is-small is-right redColored" v-if="mobileMissing">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
					</p>
					<p class="help is-danger bigger-text bolder-text" v-if="mobileMissing">{{ mobileMissingText }}</p>
				</div>
			</div>
		</div>
		<div class="columns is-centered">
			<div class="column is-one-third">
				<div class="field">
					<p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : passwordMissing}">
						<input class="input is-primary is-rounded" type="password" placeholder="Password" v-model.trim="userPassword" v-bind:class="{ 'is-danger' :  passwordMissing}">
						<span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'lock']"/>
                        </span>
						<span class="icon is-small is-right redColored" v-if="passwordMissing">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
					</p>
					<p class="help is-danger bigger-text bolder-text" v-if="passwordMissing">{{ passwordMissingText }}</p>
				</div>
			</div>
			<div class="column is-one-third">
				<div class="field">
					<p class="control has-icons-left" v-bind:class="{ 'has-icons-right' :  passwordNotMatching}">
						<input class="input is-primary is-rounded" type="password" placeholder="Password Confirmation" v-model.trim="userPasswordConfirm" v-bind:class="{ 'is-danger' :  passwordNotMatching}">
						<span class="icon is-small is-left">
                            <font-awesome-icon v-bind:icon="['fas', 'lock']"/>
                        </span>
						<span class="icon is-small is-right redColored" v-if="passwordNotMatching">
                            <font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
                        </span>
					</p>
					<p class="help is-danger bigger-text bolder-text" v-if="passwordNotMatching">{{ passwordNotMatchingText }}</p>
				</div>
			</div>
		</div>
		<div class="columns is-centered">
			<div class="column is-one-third">

				<div class="field">
					<p class="control">
						<button class="button is-primary is-inverted is-medium is-fullwidth">Activate & Login</button>
					</p>
				</div>
			</div>
		</div>
		<div class="columns is-centered">
			<div class="column is-one-third">
				<a href="/login" class="black-text underlined_text">Go to Login</a>
			</div>
		</div>
	</form>
</template>

<script>
    import api from "./../../../api/api.js";

    export default {
        name: "activateAccount",
        data() {
            return {
                userEmail: '',
                userMobile: '',
                userPassword: '',
                userPasswordConfirm: '',
                emailInvalid: false,
                emailInvalidText: 'Invalid Email',
                mobileMissing: false,
                mobileMissingText: 'Please enter a mobile number',
                passwordMissing: false,
                passwordMissingText: 'Please enter a password',
                passwordNotMatching: false,
                passwordNotMatchingText: 'The two password are not matching',
                activationInvalid: false,
                activationInvalidText: 'The information you gave did not match'
            }
        },
        methods: {
            checkForm: function (event) {
                event.preventDefault();
                let vm = this,
                    noError = true;
                if (vm.userEmail === '' || !vm.$validator.isEmail(vm.userEmail)) {
                    vm.emailInvalid = true;
                    noError = false;
                } else {
                    vm.emailInvalid = false;
                }
                if (vm.userMobile === '') {
                    vm.mobileMissing = true;
                    noError = false;
                } else {
                    vm.mobileMissing = false;
                }
                if (vm.userPassword === '') {
                    vm.passwordMissing = true;
                    noError = false;
                } else {
                    vm.passwordMissing = false;
                }
                if (vm.userPassword !== vm.userPasswordConfirm) {
                    vm.passwordNotMatching = true;
                    noError = false;
                } else {
                    vm.passwordNotMatching = false;
                }

                if (noError) {
                    vm.submitform();
                }
            },
            submitform: function () {
                let vm = this;
                vm.reminderInvalid = false;
                let authorizationToken;
                if (vm.$session.has('jwt')) {
                    authorizationToken = vm.$session.get('jwt');
                } else {
                    authorizationToken = '';
                }
                let reqHeader = {
                    headers: {
                        authorization: authorizationToken,
                    }
                };
                api.operation('activateaccount').post({
                    email: vm.userEmail,
                    mobile: vm.userMobile,
                    password: vm.userPassword,
                    session: vm.$session.id(),
                    sessiondata: JSON.stringify(vm.$session.getAll())
                }, reqHeader)
                    .then(({data}) => {
                        if (data.success) {
                            vm.reminderInvalid = false;
                            vm.$session.flash.set('logoff', 'Your account has been activated. You may login now.');
                            window.location.href = '/login'
                        } else {
                            if (data.msg === "Authentication failed.") {
                                vm.reminderInvalid = true;
                            }
                        }
                    });
            }
        },
        mounted: function () {}
    }
</script>

<style scoped>
	.redColored {
		color: red !important;
	}

	.black-text {
		color: black !important;
	}

	.underlined_text {
		text-decoration: underline !important;
	}

	.bigger-text {
		font-size: larger;
	}

	.bolder-text {
		font-weight: bold;
	}
</style>