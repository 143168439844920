<template>
    <div class="columns is-centered">
        <div class="column is-full" v-if="!elementsLoaded">
            {{ formLoadingText }}
        </div>
        <div class="column is-full" v-if="displayingForm">
            <form>
                <div class="columns is-centered" v-if="formLoading">
                    <div class="column is-full">
                        <div class="notification is-info padded-3-px">
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div
                            class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.name.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div
                            class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.proxyActionEnabled.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <selectField
                                v-bind:inputData=formFieldsData.actionType.binds
                                v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.sourceTable.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <selectField
                                v-bind:inputData=formFieldsData.destinationTable.binds
                                v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.dataFilter.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.actionSms.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.actionMobile.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div
                            class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.actionEmail.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div
                            class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.emailPrerequisite.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.actionGroup.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.actionContactName.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div
                            class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.fileLocations.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div
                            class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.allFiles.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.fileName.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.fileCheck.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.fileGenerator.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.fileSuccessResponse.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.fileFormat.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.filePassword.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.emailHtmlFile.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.emailHtml.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.emailText.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.emailFrom.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.emailReply.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.emailSubject.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div
                            class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.emailCopy.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div
                            class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.blindCopyEmail.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div
                            class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.errorsEmail.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div
                            class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.errorsEmailSuccess.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.trackDelivery.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.trackOpens.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.triggerHour.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-full" v-if="notDisplayingForm">
            <deletionForm
                    v-bind:formFieldsVal="formFieldsData.formActions.delete"
                    v-bind:deletionAction="formsData.data.action"
                    v-on:dataEvent="dataEvent"
            />
        </div>
    </div>
</template>

<script>

import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import checkRadioField from "./elements/checkRadioField";
import selectField from "./elements/selectField";
import deletionForm from "./elements/deletionForm";
import textAreaField from "./elements/textAreaField";

export default {
 name: "proxymanageractionsForm",
 mixins: [dataFetcher, formFunctions],
 components: {
  inputField,
  checkRadioField,
  deletionForm,
  selectField,
  textAreaField
 },
 data() {
  return {}
 },
 props: ['formsData', 'formActionButton'],
 methods: {},
 watch: {},
 mounted: function () {
  let vm = this;
  vm.$nextTick(function () {
   vm.getThisFormData();
  });
 }
}
</script>

<style scoped>
.padded-3-px {
    padding: 3px !important;
}
</style>
