<template>
    <div class="section no-padding">
        <div class="container is-fluid content-container no-padding">
            <div class="columns is-vcentered is-centered proxyboard-top-buttons-container">
                <div class="buttons has-addons is-centered proxyboard-top-buttons">
                    <button class="button" v-bind:class="{ 'is-success is-selected' : scopeBtn.isAllScope }"
                            v-on:click="setDashBoardScope('isAllScope')">All
                    </button>
                    <button class="button" v-bind:class="{ 'is-success is-selected' : scopeBtn.isEpolicyScope }"
                            v-on:click="setDashBoardScope('isEpolicyScope')">E-policy
                    </button>
                    <button class="button"
                            v-bind:class="{ 'is-success is-selected' : scopeBtn.isMedRenewalsScope }"
                            v-on:click="setDashBoardScope('isMedRenewalsScope')">Medical Renewals
                    </button>
                    <button class="button"
                            v-bind:class="{ 'is-success is-selected' : scopeBtn.isNonMedRenewalsScope }"
                            v-on:click="setDashBoardScope('isNonMedRenewalsScope')">Non-Medical Renewals
                    </button>
                </div>
            </div>
            <div class="columns is-vcentered is-centered">
                <div
                        class="column is-full-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                    <dataCard
                            v-bind:dropCapLetter="'D'"
                            v-bind:boxWord="'Day'"
                            v-bind:boxData="dashDataReturned.cards.todayTotal"
                    ></dataCard>
                </div>
                <div
                        class="column is-full-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                    <dataCard
                            v-bind:dropCapLetter="'W'"
                            v-bind:boxWord="'Week'"
                            v-bind:boxData="dashDataReturned.cards.weekTotal"
                    ></dataCard>
                </div>
                <div
                        class="column is-full-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                    <dataCard
                            v-bind:dropCapLetter="'M'"
                            v-bind:boxWord="'Month'"
                            v-bind:boxData="dashDataReturned.cards.monthTotal"
                    ></dataCard>
                </div>
                <div
                        class="column is-full-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                    <dataCard
                            v-bind:dropCapLetter="'Y'"
                            v-bind:boxWord="'Year'"
                            v-bind:boxData="dashDataReturned.cards.yearTotal"
                    ></dataCard>
                </div>
            </div>
            <div class="columns is-vcentered is-centered">
                <div class="column is-full">
                    <div class="card">
                        <header class="card-header">
                            <div class="card-header-title">
                                <div class="columns is-vcentered container">
                                    <div class="column is-narrow dash-head-column">
                                        {{ chartTitle }}
                                    </div>
                                    <div class="column is-narrow dash-head-column"
                                         v-show="!calendarVisible">
                                        {{ dataFilterDatesValue }}
                                    </div>
                                    <div class="column is-narrow dash-head-column"
                                         v-show="calendarVisible">
                                        <dateField
                                                v-bind:inputData=datePickerData
                                                v-bind:dateFieldType="'dashboard'"
                                                v-bind:isExpanded="true"
                                                v-on:dataEvent="dataEvent"
                                                v-on:complexDateSet="complexDateSet"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="card-header-icon card-header-dashboard" aria-label="Date Filter"
                                 v-on:click="showCalendar()">
                <span class="icon">
                  <font-awesome-icon v-bind:icon="['fas', 'calendar-alt']"/>
                </span>
                            </div>
                        </header>
                        <div class="card-content">
                            <div class="content">
                                <div class="columns is-vcentered is-centered" v-show="chartActive.deliverySummary">
                                    <div
                                            class="column is-full-mobile is-two-thirds-tablet is-thee-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                                        <!--                    <div class="chart-container-500h">-->
                                        <barChart
                                                v-bind:chartData="dashDataReturned.DeliverySummaryDeliveryBars"
                                                v-bind:options="barOptions"
                                                v-bind:activationTime="activationTimeDelivery">
                                        </barChart>
                                        <!--                    </div>-->
                                    </div>
                                    <div
                                            class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                                        <!--        <div class="chart-container-200h">-->
                                        <pieChart
                                                v-bind:chartData="dashDataReturned.DeliverySummaryDeliveryPie"
                                                v-bind:options="pieOptions"
                                                v-bind:activationTime="activationTimeDelivery">
                                        </pieChart>
                                        <!--        </div>-->
                                    </div>
                                </div>
                                <div class="columns is-vcentered is-centered" v-show="chartActive.readSummary">
                                    <div
                                            class="column is-full-mobile is-two-thirds-tablet is-thee-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                                        <!--                    <div class="chart-container-500h">-->
                                        <barChart
                                                v-bind:chartData="dashDataReturned.ReadSummaryReadBars"
                                                v-bind:options="barOptions"
                                                v-bind:activationTime="activationTimeDelivery">
                                        </barChart>
                                        <!--                    </div>-->
                                    </div>
                                    <div
                                            class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                                        <!--        <div class="chart-container-200h">-->
                                        <pieChart
                                                v-bind:chartData="dashDataReturned.ReadSummaryReadPie"
                                                v-bind:options="pieOptions"
                                                v-bind:activationTime="activationTimeDelivery">
                                        </pieChart>
                                        <!--        </div>-->
                                    </div>
                                </div>
                                <div class="columns is-vcentered is-centered" v-show="chartActive.distributionSummary">
                                    <!--                <div class="columns is-vcentered is-centered">-->
                                    <div
                                            class="column is-full-mobile is-one-third-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                                        <!--                    <div class="chart-container-500h">-->
                                        <barChart
                                                v-bind:chartData="dashDataReturned.distributionSummaryBars"
                                                v-bind:options="barOptions"
                                                v-bind:activationTime="activationTimeDistribution">
                                        </barChart>
                                        <!--                    </div>-->
                                    </div>
                                    <div
                                            class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                                        <pieChart
                                                v-bind:chartData="dashDataReturned.distributionSummaryMonthYearPie"
                                                v-bind:options="pieOptions"
                                                v-bind:activationTime="activationTimeDistribution">
                                        </pieChart>
                                    </div>
                                    <div
                                            class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                                        <pieChart
                                                v-bind:chartData="dashDataReturned.distributionSummaryDaysDaysToGoPie"
                                                v-bind:options="pieOptions"
                                                v-bind:activationTime="activationTimeDistribution">
                                        </pieChart>
                                    </div>
                                </div>
                                <div class="columns is-vcentered is-centered is-mobile is-multiline"
                                     v-show="chartActive.oneYearSummary">
                                    <div class="column is-full">
                                        <span class="is-size-4">E-Policies Dispatched</span>
                                        <calHeatmap
                                                v-bind:heatmapSelector="'heatmapepolicy'"
                                                v-bind:heatmapEntries="dashDataReturned.OneYearSummaryEpolicyHeatMap"
                                                v-bind:heatmapColorRange="['#acd5f2', '#254e77']"></calHeatmap>
                                    </div>
                                    <div class="column is-full">
                                        <span class="is-size-4">Renewals Dispatched</span>
                                        <calHeatmap
                                                v-bind:heatmapSelector="'heatmaprenewal'"
                                                v-bind:heatmapEntries="dashDataReturned.OneYearSummaryRenewalHeatMap"
                                                v-bind:heatmapColorRange="['#d6e685', '#1e6823']"></calHeatmap>
                                    </div>
                                </div>
                                <div class="columns is-vcentered has-text-right"
                                     v-show="dashDataReturned.dataTimeStamp">
                                    <div class="column is-full">
                                        <span><strong>Data time:</strong> {{ dashDataReturned.dataTimeStamp }}</span>
                                        <span v-show="dashDataReturned.autoRefresh"> (Refreshing in {{ refreshCountdownSeconds }}s)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a class="card-footer-item"
                               v-bind:class="{ 'has-background-light' : chartActive.deliverySummary }"
                               v-on:click="setActiveChart('deliverySummary')">Delivery Summary</a>
                            <a class="card-footer-item"
                               v-bind:class="{ 'has-background-light' : chartActive.readSummary }"
                               v-on:click="setActiveChart('readSummary')">Read Summary</a>
                            <a class="card-footer-item"
                               v-bind:class="{ 'has-background-light' : chartActive.distributionSummary }"
                               v-on:click="setActiveChart('distributionSummary')">Distribution Summary</a>
                            <a class="card-footer-item"
                               v-bind:class="{ 'has-background-light' : chartActive.oneYearSummary }"
                               v-on:click="setActiveChart('oneYearSummary')">One Year Summary</a>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dashboardFunctions from "./../../../api/mixins/dashboardFunctions";
import formFunctions from "./../../../api/mixins/formFunctions";
import barChart from '../charts/barChart'
import pieChart from '../charts/pieChart'
import calHeatmap from '../charts/calHeatmap'
import dateField from "./../forms/elements/dateField";
import dataCard from "./elements/dataCard";


export default {
 name: "proxymanagerDashboard",
 mixins: [dashboardFunctions, formFunctions],
 components: {
  barChart,
  pieChart,
  calHeatmap,
  dateField,
  dataCard
 },
 data() {
  return {
   datePickerData: {
    elementName: 'dataFilterDates',
    inlineElement: false,
   },
   dashboardName: 'proxymanagerdashboard',
   barOptions: {
    segmentShowStroke: false,
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
     mode: 'index',
     intersect: false
    },
    scales: {
     xAxes: [
      {
       stacked: true
      }
     ],
     yAxes: [
      {
       stacked: true
      }
     ]
    }
   },
   pieOptions: {
    cutoutPercentage: 0,
    responsive: true,
    maintainAspectRatio: false,
   },
   scopeBtn: {isAllScope: true, isEpolicyScope: false, isMedRenewalsScope: false, isNonMedRenewalsScope: false},
   chartTitle: 'Delivery Summary',
   chartActive: {deliverySummary: true, readSummary: false, distributionSummary: false, oneYearSummary: false},
   activationTimeDelivery: 0,
   activationTimeDistribution: 0,
   calendarVisible: false,
   dataFilterDatesValue: '',
   refreshCountdownSeconds: 300
  }
 },
 computed: {},
 props: [],
 methods: {
  complexDateSet: function (emittedData) {
   let vm = this;
   // console.log(emittedData);
   if (vm.$_.trim(emittedData.dateData) !== '') {
    vm.dashDataFilterDates = vm.$_.trim(emittedData.dateData);
   } else {
    vm.dashDataFilterDates = vm.$_.trim(emittedData.inputValue);
   }
   if (vm.$_.trim(vm.dashDataFilterDates) !== '') {
    vm.dataFilterDatesValue = `:: ${vm.$_.trim(vm.dashDataFilterDates)}`;
   } else {
    vm.dataFilterDatesValue = ``;
   }
   vm.calendarVisible = false;

   vm.dashDataFilterMode = vm.$_.trim(emittedData.dateMode);
   vm.getThisDashboardData();
  },
  setDashBoardScope: function (scopeKey) {
   let vm = this;
   vm.scopeBtn = {isAllScope: false, isEpolicyScope: false, isMedRenewalsScope: false, isNonMedRenewalsScope: false};
   vm.scopeBtn[scopeKey] = true;
   vm.dashDataFilterScope = scopeKey;
   vm.getThisDashboardData();
  },
  setActiveChart: function (chartName) {
   let vm = this;
   switch (chartName) {
    case 'deliverySummary':
     vm.chartActive = {
      deliverySummary: true,
      readSummary: false,
      distributionSummary: false,
      oneYearSummary: false
     };
     vm.chartTitle = 'Delivery Summary';
     vm.activationTimeDelivery = vm.$DateTime.local().setZone("UTC").valueOf();
     break;
    case 'readSummary':
     vm.chartActive = {
      deliverySummary: false,
      readSummary: true,
      distributionSummary: false,
      oneYearSummary: false
     };
     vm.chartTitle = 'Read Summary';
     vm.activationTimeDelivery = vm.$DateTime.local().setZone("UTC").valueOf();
     break;
    case 'distributionSummary':
     vm.chartActive = {
      deliverySummary: false,
      readSummary: false,
      distributionSummary: true,
      oneYearSummary: false
     };
     vm.chartTitle = 'Distribution Summary';
     vm.activationTimeDistribution = vm.$DateTime.local().setZone("UTC").valueOf();
     break;
    case 'oneYearSummary':
     vm.chartActive = {
      deliverySummary: false,
      readSummary: false,
      distributionSummary: false,
      oneYearSummary: true
     };
     vm.chartTitle = 'One Year Summary';
     vm.activationTimeDistribution = vm.$DateTime.local().setZone("UTC").valueOf();
     break;
   }
  },
  showCalendar: function () {
   let vm = this;
   vm.calendarVisible = !vm.calendarVisible;
  },
  countdownAutoRefresh: function () {
   let vm = this;
   if (vm.dashDataReturned.autoRefresh) {
    setTimeout(function () {
     if (vm.refreshCountdownSeconds > 0) {
      vm.refreshCountdownSeconds -= 1;
      vm.countdownAutoRefresh();
     } else {
      vm.getThisDashboardData();
      vm.refreshCountdownSeconds = 300;
     }
    }, 1000)
   } else {
    vm.refreshCountdownSeconds = 300;
   }
  }
 },
 watch: {},
 mounted: function () {
  let vm = this;
  vm.$nextTick(function () {
   vm.getThisDashboardData();
  });
 }
}
</script>

<style scoped>
.chart-container-500h {
    position: relative;
    /*height: 500px !important;*/
}

.proxyboard-top-buttons-container {
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
}

.proxyboard-top-buttons {
    margin-bottom: -9px !important;
}

.card-header-dashboard {
    padding: 5px !important;
    font-size: 20px !important;
}

.dash-head-column {
    padding: 0 5px !important;
}

.no-padding {
    padding: 0 !important;
}
</style>