<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-four-fifths-desktop is-four-fifths-widescreen is-four-fifths-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.name.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.configActive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.autoReportType.binds
                  v-on:dataEvent="dataEvent"
                  v-on:filterDataReady="filterDataReady"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.reportFile.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.reportTo.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.sendEmail.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <dateField
                  v-bind:inputData=formFieldsData.startDate.binds
                  v-bind:dateFieldType="'basic'"
                  v-bind:isExpanded="false"
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <dateField
                  v-bind:inputData=formFieldsData.endDate.binds
                  v-bind:dateFieldType="'basic'"
                  v-bind:isExpanded="false"
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Filtering"></div>
        <filterMaker
            v-bind:filterFor="'reportconfig'"
            v-bind:filterData=formFieldsData.reportFilter.binds.initial
            v-bind:maxFilters="'-1'"
            v-bind:filterItemsArrayReceived="filterItemsArray"
            v-bind:filterItemsIdReceivedInit=formFieldsData.autoReportType.binds.initial
            v-bind:filterItemsIdReceived="filterItemsId"
            v-bind:filterFormProcessedReceived="filterFormProcessed"
            v-bind:noFilterMessage="noFilterMessage"
            v-bind:dataUpdateActivityTimeStamp="dataUpdateActivity"
            v-on:filterEvent="dataEvent"
            v-on:dataEvent="dataEvent"
            v-on:filtersInvalidate="filtersInvalidate"
        ></filterMaker>
        <div class="is-divider" data-content="Scheduling"></div>
        <scheduleMaker
            v-bind:scheduleFor="'reportconfig'"
            v-bind:scheduleData=formFieldsData.reportSchedule.binds.initial
            v-bind:maxScheduled="'-1'"
            v-on:scheduleEvent="dataEvent"
        ></scheduleMaker>
      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import selectField from "./elements/selectField";
import dateField from "./elements/dateField";
import checkRadioField from "./elements/checkRadioField";
import textAreaField from "./elements/textAreaField";
import deletionForm from "./elements/deletionForm";
import scheduleMaker from "./elements/scheduleMaker";
import filterMaker from "./elements/filterMaker";

export default {
  name: "reportconfigurationForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField,
    selectField,
    dateField,
    deletionForm,
    checkRadioField,
    textAreaField,
    scheduleMaker,
    filterMaker
  },
  data() {
    // let vm = this;
    return {
      noFilterMessage: 'Select the report type to activate filtering'
    }
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
