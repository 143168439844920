<template>
    <div class="columns is-centered">
        <div class="column is-full" v-if="!elementsLoaded">
            {{ formLoadingText }}
        </div>
        <div class="column is-full" v-if="displayingForm">
            <form>
                <div class="columns is-centered" v-if="formLoading">
                    <div class="column is-full">
                        <div class="notification is-info padded-3-px">
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.seriesName.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full-mobile is-full-tablet is-three-quarters-desktop is-three-quarters-widescreen is-three-quarters-fullhd">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.questionName.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                    <div class="column is-full-mobile is-full-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.surveyQuestion.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.questionSms.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.questionAnswer.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <selectField
                                    v-bind:inputData=formFieldsData.mappedTo.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.questionOrder.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.questionCorrectSms.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <textAreaField
                                    v-bind:inputData=formFieldsData.questionFailSms.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.successPostUrl.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.failPostUrl.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.questionRetries.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <inputField
                                    v-bind:inputData=formFieldsData.postRetries.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-full" v-if="notDisplayingForm">
            <deletionForm
                    v-bind:formFieldsVal="formFieldsData.formActions.delete"
                    v-bind:deletionAction="formsData.data.action"
                    v-on:dataEvent="dataEvent"
            />
        </div>
    </div>
</template>

<script>

    import formFunctions from "./../../../api/mixins/formFunctions";
    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import selectField from "./elements/selectField";
    import inputField from "./elements/inputField";
    import textAreaField from "./elements/textAreaField";
    import checkRadioField from "./elements/checkRadioField";
    import deletionForm from "./elements/deletionForm";

    export default {
        name: "datacollectorquestionsForm",
        mixins: [dataFetcher, formFunctions],
        components: {
            inputField,
            textAreaField,
            checkRadioField,
            selectField,
            deletionForm
        },
        data() {
            return {}
        },
        props: ['formsData', 'formActionButton'],
        watch: {},
        methods: {},
        computed: {},
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.getThisFormData();
            });
        }
    }
</script>

<style scoped>

</style>
