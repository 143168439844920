<template>
    <div class="columns is-vcentered is-centered">
        <div class="column is-vcentered is-centered">
            <nav class="panel is-primary">
                <p class="panel-tabs">
                    <a v-for="(panelData, key) in panelStructure" v-bind:key="key"
                       v-bind:class="{ 'is-active' : activeTab === panelData.headId }"
                       v-on:click="setActiveTab(panelData.headId)">
                        {{ key }}
                    </a>
                </p>
                <div v-for="(panelDataItems, key) in panelBlockData" v-bind:key="key"
                     v-bind:class="{ 'is-active' : activeTab === panelDataItems.headId }"
                     class="panel-block">
                    <div class="field">
                        <input v-if="panelDataItems.element === 'switch'"
                               v-bind:id="panelDataItems.id"
                               type="checkbox"
                               v-bind:name="panelDataItems.name"
                               v-bind:class="panelDataItems.elementClass"
                               v-on:change="switchToggled(panelDataItems.id)"
                               checked="checked">
                        <label v-bind:for="panelDataItems.id" class="is-size-5">{{ panelDataItems.itemLabel }}</label>
                        <div v-if="panelDataItems.element === 'button'"
                             v-bind:class="panelDataItems.elementClass"
                             v-on:click="buttonClicked(panelDataItems.id)"
                             class="panel-button">10000
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>

    import dataFetcher from "./../../../api/mixins/fetchTableData";

    export default {
        name: "infoPanel",
        data() {
            let vm = this;
            return {
                panelStructure: require('../infopanels/' + vm.panelData.mykey + '.json'),
                panelDataFetched: [],
                activeTab: 'account-tab'
            }
        },
        props: ['panelData'],
        mixins: [dataFetcher],
        components: {},
        computed: {
            panelBlockData: function () {
                let panelBlockDataObj = [];
                try {
                    let vm = this;
                    vm.$_.forEach(vm.panelStructure, function (panelData) {
                        vm.$_.forEach(panelData.bodyItems, function (panelDataItems) {
                            if (vm.activeTab === panelDataItems.headId) {
                                panelBlockDataObj.push(panelDataItems);
                            }
                        });
                    });
                    return panelBlockDataObj;
                } catch (error) {
                    return [];
                }
            }
        },
        methods: {
            setActiveTab: function (thisTabId) {
                let vm = this;
                vm.activeTab = thisTabId;
            },
            switchToggled: function (switchId) {
                console.log(switchId);
            },
            buttonClicked: function (buttonId) {
                console.log(buttonId);
            }
        },
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                console.log(vm.panelData);
                console.log(vm.panelStructure);
                //vm.loadPanelData(vm.panelData.mykey);
            });
        },
        watch: {}
    }
</script>

<style scoped>
    .panel-button {
        margin: 0px 10px !important;
    }
</style>