<template>
  <div>
    <div class="container">
      <div class="tabs is-centered is-boxed">
        <ul>
          <li v-for="(section,key) in tabsData" v-bind:key="`head_${section.key}`" v-bind:id="`head_${section.key}`"
              v-bind:class="{ 'is-active' : key===activeTab }" v-on:click="activateTab(key)">
            <a>{{ section.title }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container is-fluid content-container">
      <div v-for="(section,key) in tabsData" v-bind:key="`head_${section.key}`"
           v-bind:id="`head_${section.key}`"
           v-show="key===activeTab" v-html="section.data">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "modalsTabbed",
  data() {
    return {
      activeTab: '0'
    }
  },
  props: ['tabsData'],
  components: {},
  computed: {},
  methods: {
    activateTab: function (tabKey) {
      let vm = this;
      vm.activeTab = tabKey;
    }
  },
  watch: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.activeTab = '0';
    });
  }
}
</script>

<style scoped>
.content-container{
  overflow: scroll;
  height: 500px;
  text-align: left;
}
</style>