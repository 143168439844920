<template>
  <div class="columns is-centered">
    <div class="column is-full" v-if="!elementsLoaded">
      {{ formLoadingText }}
    </div>
    <div class="column is-full" v-if="displayingForm">
      <form>
        <div class="columns is-centered" v-if="formLoading">
          <div class="column is-full">
            <div class="notification is-info padded-3-px">
              Loading...
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-four-fifths-desktop is-four-fifths-widescreen is-four-fifths-fullhd">
            <div class="field">
              <inputField
                  v-bind:inputData=formFieldsData.name.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.actionEnabled.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.saveActionTrigger.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.responseType.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.responseInteractive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Question/Message"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.responseQuestionMessage.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.responseMessageCaption.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-four-fifths-desktop is-four-fifths-widescreen is-four-fifths-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.responseAnswer.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-fullhd">
            <div class="field">
              <checkRadioField
                  v-bind:inputData=formFieldsData.sendAnswerWithQuestion.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Question Passed"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.onQuestionPassAction.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.onQuestionPassSave.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.onQuestionPassInteractive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.onQuestionPassText.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.onQuestionPassCaption.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="is-divider" data-content="Question Failed"></div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.onQuestionFailAction.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
            <div class="field">
              <selectField
                  v-bind:inputData=formFieldsData.onQuestionFailInteractive.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>
        <div class="columns is-centered is-mobile is-multiline ">
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.onQuestionFailText.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
          <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
            <div class="field">
              <textAreaField
                  v-bind:inputData=formFieldsData.onQuestionFailCaption.binds
                  v-on:dataEvent="dataEvent"
              />
            </div>
          </div>
        </div>


      </form>
    </div>
    <div class="column is-full" v-if="notDisplayingForm">
      <deletionForm
          v-bind:formFieldsVal="formFieldsData.formActions.delete"
          v-bind:deletionAction="formsData.data.action"
          v-on:dataEvent="dataEvent"
      />
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../api/mixins/formFunctions";
import dataFetcher from "./../../../api/mixins/fetchTableData";
import inputField from "./elements/inputField";
import selectField from "./elements/selectField";
import checkRadioField from "./elements/checkRadioField";
import textAreaField from "./elements/textAreaField";
import deletionForm from "./elements/deletionForm";

export default {
  name: "salesofferactionForm",
  mixins: [dataFetcher, formFunctions],
  components: {
    inputField,
    selectField,
    deletionForm,
    checkRadioField,
    textAreaField
  },
  data() {
    return {}
  },
  props: ['formsData', 'formActionButton'],
  watch: {},
  methods: {},
  computed: {},
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      vm.getThisFormData();
    });
  }
}
</script>

<style scoped>
.padded-3-px {
  padding: 3px !important;
}
</style>
