<template>
	<div class="columns is-multiline is-centered">
		<div class="column is-full">
			<div class="tabs is-centered is-boxed">
				<ul class="tab-heads">
					<li
							class="tab-head"
							v-for="(attribs, heading) in subsection.tabsdata"
							v-bind:key="attribs.tabheadingkey"
							v-bind:data-tab="attribs.tabkey"
							v-bind:class="{ 'is-active' : activeTab===attribs.tabkey }"
					>
						<a v-on:click="activateTab(attribs.tabkey)">{{ heading }}</a>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<p
						v-for="(attribs) in subsection.tabsdata"
						v-bind:key="attribs.tabcontentkey"
						v-bind:data-content="attribs.tabkey"
						v-bind:class="{ 'is-active' : activeTab===attribs.tabkey }"
				>
					<sectionUpload
							v-if="attribs.tabtype==='multiplefileuploader'"
							v-bind:text="attribs.uploadtext"
							v-bind:action="attribs.uploadurl"
							v-bind:guide="attribs.tabguide"
					/>
					<sectionList
							v-if="attribs.tabtype==='listeddata'"
							v-bind:subsection="attribs"
							v-bind:subkey="attribs.tabkey"
							v-bind:refreshMe="refreshMe"
							v-bind:refreshList="refreshList"
							v-on:listAction="doListAction"
					/>

					<sectionCharts
							v-if="attribs.tabtype==='chartcanvas'"
							v-bind:subsection="attribs"
					/>
				</p>
			</div>
		</div>
	</div>

</template>

<script>
    import sectionUpload from './upload.vue';
    import sectionList from './lists.vue';
    import sectionCharts from './charts.vue';

    export default {
        name: "sectionTabs",
        data() {
            let vm = this;
            return {
                colorx: 'primary',
                activeTab: vm.subsection.inittab,
                refreshList: ''
            }
        },
        computed: {},
        methods: {
            doListAction: function (emittedData) {
                let vm = this;
                vm.$emit('listAction', emittedData);
            },
            activateTab(tabIdentifier) {
                let vm = this;
                vm.activeTab = tabIdentifier;
                vm.refreshList = tabIdentifier + ':' + vm.$moment().valueOf();
            }
        },
        props: ['subsection', 'subkey', 'refreshMe'],
        components: {
            sectionUpload,
            sectionList,
            sectionCharts
        }
    }
</script>

<style scoped>
	.tab-head a {
		color: black !important;
	}

	.tab-head.is-active a {
		color: hsl(217, 71%, 53%) !important;
	}

	.tab-content p {
		display: none;
	}

	.tab-content p.is-active {
		display: block;
	}
</style>