<template>
    <div class="columns is-centered">
        <div class="column is-full" v-if="!elementsLoaded">
            {{ formLoadingText }}
        </div>
        <div class="column is-full" v-if="displayingForm">
            <form>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            Message Here
                        </div>
                    </div>
                </div>
                <div class="columns is-centered">
                    <div class="column is-full">
                        <div class="field">
                            <checkRadioField
                                    v-bind:inputData=formFieldsData.messageApproval.binds
                                    v-on:dataEvent="dataEvent"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-full" v-if="notDisplayingForm">
            Delete
        </div>
    </div>
</template>

<script>

    import formFunctions from "./../../../api/mixins/formFunctions";
    import dataFetcher from "./../../../api/mixins/fetchTableData";
    import checkRadioField from "./elements/checkRadioField";

    export default {
        name: "approveForm",
        mixins: [dataFetcher, formFunctions],
        components: {
            checkRadioField
        },
        data() {
            return {}
        },
        props: ['formsData', 'formActionButton'],
        methods: {},
        watch: {},
        mounted: function () {
            let vm = this;
            vm.$nextTick(function () {
                vm.getThisFormData();
            });
        }
    }
</script>

<style scoped>
</style>
