<template>
    <div id="app" v-bind:class="{ 'top-margin-60' : hasTopBar }" v-cloak>
        <page503
                v-if="is503Response"
        />
        <page404
                v-if="is404Response"
        />
        <topNavBar
                v-bind:mySections="computedSections"
                v-bind:myData="mydata"
                v-bind:isAdmin="isadmin"
                v-bind:pageStage="pagestage"
                v-bind:isshadowing="isshadowing"
                v-bind:accCredit="acccredit"
                v-on:formModal="showFormModal"
                v-if="isReadyPage"/>
        <section class="hero is-warning" v-if="isEmptyPage && isReadyPage">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        Page is M.I.A.
                    </h1>
                    <h2 class="subtitle">
                        <font-awesome-icon v-bind:icon="['fas', 'flushed']"/>
                        Looks like the
                        <font-awesome-icon v-bind:icon="['fas', 'dog']"/>
                        ate this page
                        <font-awesome-icon v-bind:icon="['fas', 'hand-point-right']"/>&nbsp;
                        <strong>{{ pageUrl }}</strong>
                    </h2>
                    <div>
                        <a href="/login" class="button">
    <span class="icon">
      <font-awesome-icon v-bind:icon="['fas', 'home']"/>
    </span>
                            <span>Go to Login</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <accountSections
                v-bind:mySections="computedSections"
                v-bind:myRole="myrole"
                v-bind:isAdmin="isadmin"
                v-bind:refreshMe="refreshme"
                v-bind:showTour="showtour"
                v-if="isReadyPage"
                v-on:formModal="showFormModal"
                v-on:tabbedModal="showTabbedModal"
                v-on:dataModal="showDataModal"
                v-on:listAction="doListAction"
                v-on:refreshTable="refreshModalTable"
                v-on:useThisData="updateDatasObj"
                v-bind:dataSelected="datasObj"
        />
        <div class="modal table-modal" v-bind:class="{ 'is-active' : showModal }">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <button class="button" v-on:click="showHelp(modalDatas.helper)" v-show="modalDatas.showhelp">
                        <span class="icon is-small">
                         <font-awesome-icon v-bind:icon="['fas', 'life-ring']"/>
                        </span>
                    </button>
                    <p class="modal-card-title">{{ modalDatas.header }}</p>
                    <button class="delete" aria-label="close" v-on:click="showModal = false"></button>
                </header>
                <section class="modal-card-body relative-position-body">
                    <modalForm
                            v-if="isFormModal && (modalType==='form')"
                            v-bind:key="formsData.key"
                            v-bind:formsData="formsData"
                            v-on:selectedForms="formDatas"
                            v-on:closeModal="closeDataModal"
                            v-on:refreshTable="refreshModalTable"
                            v-on:missingFormData="missingFormData"
                            v-bind:formActionButton="formActionName"
                    />
                    <modalData v-if="isFormModal && (modalType==='datas')" v-bind:key="datasData.key"
                               v-bind:datasData="datasData"
                               v-on:selectedDatas="PropDatas"/>
                    <modalTabbed v-if="isTabbedModal" v-bind:tabsData="tabsData"></modalTabbed>
                </section>
                <footer class="modal-card-foot center-justified-footer">
                    <div class="columns is-multiline is-centered" v-if="isFormModal">
                        <div class="column is-full" v-if="missingFormErrorsExist">
                            <p class="help is-danger justify-text-alignment"
                               v-for="(missingFormError,index) in missingFormErrors" v-bind:key="index">
                                {{ missingFormError }}
                            </p>
                        </div>
                        <div class="column is-full">
                            <button class="button is-success" v-on:click="formPayAction" v-if="showPayButton">Pay
                            </button>
                            <button class="button is-success" v-on:click="formSaveAction" v-if="showSaveButton">Save
                            </button>
                            <button class="button is-danger" v-on:click="formDeleteAction" v-if="showDeleteButton">
                                Delete
                            </button>
                            <button class="button is-normal" v-on:click="showModal = false">Done</button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
        <notifications
                group="success-notification"
                position="bottom left"
                animation-type="velocity"
                v-bind:max=1
                v-bind:animation="NotificationsAnimation">
            <article class="message is-success" slot="body" slot-scope="props">
                <div class="message-header">
                    <p>{{ props.item.title }}</p>
                    <a class="delete" v-on:click="props.close">
                        <font-awesome-icon v-bind:icon="['fas', 'window-close']"/>
                    </a>
                </div>
                <div class="message-body justify-text-alignment" v-html="props.item.text"></div>
            </article>
        </notifications>
        <notifications
                group="danger-notification"
                position="bottom left"
                animation-type="velocity"
                v-bind:max=1
                v-bind:animation="NotificationsAnimation">
            <article class="message is-danger" slot="body" slot-scope="props">
                <div class="message-header">
                    <p>{{ props.item.title }}</p>
                    <a class="delete" v-on:click="props.close">
                        <font-awesome-icon v-bind:icon="['fas', 'window-close']"/>
                    </a>
                </div>
                <div class="message-body justify-text-alignment" v-html="props.item.text"></div>
            </article>
        </notifications>
        <notifications
                group="warning-notification"
                position="top center"
                animation-type="velocity"
                v-bind:max=1
                v-bind:duration=3
                v-bind:animation="NotificationsAnimation">
            <article class="message is-warning" slot="body" slot-scope="props">
                <div class="message-header">
                    <p>{{ props.item.title }}</p>
                    <a class="delete" v-on:click="props.close">
                        <font-awesome-icon v-bind:icon="['fas', 'window-close']"/>
                    </a>
                </div>
                <div class="message-body justify-text-alignment" v-html="props.item.text"></div>
            </article>
        </notifications>
        <notifications
                group="info-notification"
                position="bottom right"
                animation-type="velocity"
                v-bind:max=1
                v-bind:animation="NotificationsAnimation">
            <article class="message is-info" slot="body" slot-scope="props">
                <div class="message-header">
                    <p>{{ props.item.title }}</p>
                    <a class="delete" v-on:click="props.close">
                        <font-awesome-icon v-bind:icon="['fas', 'window-close']"/>
                    </a>
                </div>
                <div class="message-body left-text-alignment is-size-7" v-html="props.item.text"></div>
            </article>
        </notifications>
        <notifications
                group="idle-warning-notification"
                position="bottom left"
                animation-type="velocity"
                v-bind:close-on-click="true"
                v-bind:max=1
                v-bind:duration=3
                v-bind:animation="NotificationsAnimation">
            <article class="message is-warning" slot="body" slot-scope="props" v-on:click="props.close">
                <div class="message-header">
                    <p>{{ props.item.title }}</p>
                </div>
                <div class="message-body justify-text-alignment is-size-5" v-html="props.item.text"></div>
            </article>
        </notifications>
        <notifications
                group="idle-logoff-notification"
                position="top center"
                animation-type="velocity"
                v-bind:close-on-click="true"
                v-bind:max=1
                v-bind:duration=3
                v-bind:animation="NotificationsAnimation">
            <article class="message is-danger" slot="body" slot-scope="props" v-on:click="props.close">
                <div class="message-header">
                    <p>{{ props.item.title }}</p>
                </div>
                <div class="message-body justify-text-alignment is-size-5" v-html="props.item.text"></div>
            </article>
        </notifications>
    </div>
</template>

<script>
import topNavBar from "./tumatext/themes/chadash/topNavBar.vue";
import accountSections from "./tumatext/themes/chadash/accountSections.vue";
import page503 from "./tumatext/themes/chadash/503.vue";
import page404 from "./tumatext/themes/chadash/404.vue";
import modalForm from './tumatext/themes/chadash/partials/modalsForm.vue';
import modalData from './tumatext/themes/chadash/partials/modalsData.vue';
import modalTabbed from './tumatext/themes/chadash/partials/modalsTabbed.vue';
// import basicSections from './tumatext/themes/chadash/sections/basic_layout.json';
// import featureSections from './tumatext/themes/chadash/sections/features_layout.json';
import api from "./tumatext/api/api.js";

let eventSource = {};

export default {
 name: 'app',
 data() {
  return {
   myrole: "",
   refreshme: '',
   mydata: {},
   computedSections: {},
   isEmptyPage: false,
   isReadyPage: false,
   isshadowing: false,
   pageErrorpath: '',
   is503Response: false,
   is404Response: false,
   pageUrl: window.location.href,
   isadmin: false,
   addonfeatures: [],
   pagestage: {
    name: "login"
   },
   showModal: false,
   isFormModal: false,
   isTabbedModal: false,
   modalDatas: {},
   tabsData: {},
   formData: {},
   datasData: {},
   listsData: {},
   formsData: {},
   formSubkey: '',
   formRole: '',
   modalType: 'form',
   datasObj: {},
   formsObj: {},
   formActionName: '',
   NotificationsAnimation: {
    enter(element) {
     /*
*  "element" - is a notification element
*    (before animation, meaning that you can take it's initial height, width, color, etc)
*/
     let height = element.clientHeight;

     return {
      // Animates from 0px to "height"
      height: [height, 0],

      // Animates from 0 to random opacity (in range between 0.5 and 1)
      opacity: [1, 0]
     }
    },
    leave: {
     height: 0,
     opacity: 0
    }
   },
   showSaveButton: false,
   showPayButton: false,
   showDeleteButton: false,
   missingFormErrors: [],
   missingFormErrorsExist: false,
   hasTopBar: true,
   showtour: false,
   acccredit: {
    provisioned: 'Loading',
    remaining: 'Loading',
    used: 'Loading'
   }
  }
 },
 components: {
  topNavBar,
  accountSections,
  page503,
  page404,
  modalForm,
  modalData,
  modalTabbed
 },
 computed: {
  //timeNow: function () {
  //    let vm = this;
  //    return vm.$moment().valueOf();
  //}
 },
 methods: {
  loadPage: function (pageToLoad, reqHeader) {
   let vm = this;
   vm.pagestage.name = pageToLoad;
   api.operation('userstatus').getMeData({id: 'me'}, {page: pageToLoad}, reqHeader)
    .then(function ({data}) {
      if (!data.success) {
       let authPages = ['login', 'forgotpassword', 'resetpassword', 'activateaccount', 'loginsecureotp'];
       if (!vm.$_.includes(authPages, pageToLoad)) {
        vm.$session.destroy();
        vm.$session.start();
        let authorizationTokenRefresh = '';
        let reqHeaderRefresh = {
         headers: {
          authorization: authorizationTokenRefresh,
         }
        };
        if (typeof data.flash !== 'undefined') {
         vm.$session.flash.set('logoff', data.flash.toString());
        } else {
         vm.$session.flash.set('logoff', 'Please login to proceed');
        }
        let pageToLoadRefresh = 'login';
        vm.loadPage(pageToLoadRefresh, reqHeaderRefresh, authorizationTokenRefresh);
       } else {
        vm.computedSections = data.page;
        vm.isReadyPage = true;
       }
      } else {
       vm.mydata = data.me;
       vm.myrole = data.me.r;
       vm.mypackage = data.me.p;
       vm.addonfeatures = data.me.ft;
       vm.isadmin = data.me.r === 'mainadmin' || data.me.r === 'superuser';
       vm.isshadowing = data.me.vw;
       vm.$session.set('myself', data.me);
       vm.computedSections = data.page;
       vm.isReadyPage = true;
       vm.showtour = true;
       if (vm.$_.trim(pageToLoad) === 'account') {
        vm.setupSseStream();
       }
      }
     }
    )
    .catch(function (error) {
     console.error(error);
     switch (vm.$_.trim(vm.pageErrorpath)) {
      case 'notfound':
       vm.is404Response = true;
       vm.hasTopBar = false;
       break;
      case 'unavailable':
       vm.is503Response = true;
       vm.hasTopBar = false;
       break;
      default:
       vm.is404Response = true;
       vm.hasTopBar = false;
       break;
     }
    });
  },
  apiPostAction: function (apiOperation, postData, refreshScope) {
   let vm = this;
   if (!vm.$session.exists()) {
    vm.$session.start();
   }
   let authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   api.operation(apiOperation).post(postData, reqHeader)
    .then(function () {
    })
    .catch(function (error) {
     console.error(error);
    })
    .then(function () {
     vm.refreshme = refreshScope + ':' + vm.$moment().valueOf();
    });
  },
  showFormModal: function (emittedData) {
   let vm = this;
   vm.formsData = {
    data: emittedData.formData,
    subkey: emittedData.subkey,
    role: emittedData.myRole,
    key: emittedData.key
   };
   let showHelpButton = (typeof emittedData.formData.formname !== 'undefined');
   vm.modalDatas = {
    header: emittedData.formTitle,
    helper: emittedData.formData.formname,
    showhelp: showHelpButton
   };
   vm.modalType = 'form';
   vm.missingFormErrors = [];
   vm.missingFormErrorsExist = false;
   vm.showModal = true;
   vm.isFormModal = true;
   vm.isTabbedModal = false;
   vm.showSaveButtonFn();
   vm.showPayButtonFn();
   vm.showDeleteButtonFn();
  },
  showTabbedModal: function (emittedData) {
   let vm = this;
   vm.tabsData = emittedData.tabsData;
   vm.modalDatas = {
    header: emittedData.dataHead,
    helper: emittedData.dataReq,
    showhelp: emittedData.showhelp
   };
   vm.showModal = true;
   vm.isFormModal = false;
   vm.isTabbedModal = true;
  },
  showDataModal: function (emittedData) {
   let vm = this;
   vm.datasData = {
    items: emittedData.items,
    dataHeader: emittedData.dataHead,
    request: emittedData.dataReq,
    from: emittedData.from,
    type: emittedData.type,
    key: emittedData.key,
    formData: emittedData.formData,
   };
   let showHelpButton = (typeof emittedData.dataReq !== 'undefined');
   vm.modalDatas = {
    header: emittedData.dataHead,
    helper: emittedData.dataReq,
    showhelp: showHelpButton
   };
   vm.modalType = 'datas';
   //vm.timeNow = vm.$moment().valueOf();
   vm.missingFormErrors = [];
   vm.missingFormErrorsExist = false;
   vm.showModal = true;
   vm.isFormModal = true;
   vm.isTabbedModal = false;
   vm.showSaveButtonFn();
   vm.showPayButtonFn();
   vm.showDeleteButtonFn();
  },
  closeDataModal: function () {
   let vm = this;
   vm.showModal = false;
   vm.isFormModal = false;
   vm.isTabbedModal = false;
  },
  refreshModalTable: function (emittedData) {
   let vm = this;
   if (typeof emittedData.refreshAlso !== 'undefined') {
    if (emittedData.refreshAlso) {
     vm.refreshme = emittedData.table + ':tablerefresh:' + vm.$moment().valueOf();
    }
   } else {
    vm.refreshme = emittedData.table + ':table:' + vm.$moment().valueOf();
   }
  },
  missingFormData: function (emittedData) {
   let vm = this;
   vm.missingFormErrors = emittedData.errors;
   vm.missingFormErrorsExist = vm.missingFormErrors.length > 0;
  },
  doListAction: function (emittedData) {
   let vm = this;
   vm.listsData = {
    listIdentifier: emittedData.listIdentifier,
    listData: emittedData.listData,
    subkey: emittedData.subkey,
    key: emittedData.key,
   };
   vm.apiPostAction('listaction', vm.listsData, emittedData.refreshScope);
  },
  formSaveAction: function () {
   let vm = this;
   vm.formActionName = 'save:' + vm.$moment().valueOf();
  },
  formPayAction: function () {
   let vm = this;
   vm.formActionName = 'pay:' + vm.$moment().valueOf();
  },
  formDeleteAction: function () {
   let vm = this;
   vm.formActionName = 'delete:' + vm.$moment().valueOf();
  },
  PropDatas: function (emittedData) {
   let vm = this;
   vm.datasObj = emittedData;
  },
  updateDatasObj: function (emittedData) {
   let vm = this;
   vm.datasObj = emittedData;
  },
  formDatas: function (emittedData) {
   let vm = this;
   vm.formsObj = emittedData;
  },
  showSaveButtonFn: function () {
   let vm = this,
    showSaveButtonBool = false;
   try {
    if (typeof vm.formsData.data !== 'undefined') {
     if (typeof vm.formsData.data.action !== 'undefined') {
      let formsData = vm.formsData.data.action,
       formDisplayBool = vm.$_.split(vm.$_.trim(formsData), ':'),
       formActionName = vm.$_.trim(formDisplayBool[0]).toString().toLowerCase(),
       isAForm = vm.modalType === 'form';
      showSaveButtonBool = (formActionName === 'create' || formActionName === 'edit') && isAForm;
     }
    }
   } catch (error) {
    console.error(error);
   }
   vm.showSaveButton = showSaveButtonBool;
  },
  showPayButtonFn: function () {
   let vm = this,
    showPayButtonBool = false;
   try {
    if (typeof vm.formsData.data !== 'undefined') {
     if (typeof vm.formsData.data.action !== 'undefined') {
      let formsData = vm.formsData.data.action,
       formDisplayBool = vm.$_.split(vm.$_.trim(formsData), ':'),
       formActionName = vm.$_.trim(formDisplayBool[0]).toString().toLowerCase(),
       isAForm = vm.modalType === 'form';
      showPayButtonBool = (formActionName === 'smstopup') && isAForm;
     }
    }
   } catch (error) {
    console.error(error);
   }
   vm.showPayButton = showPayButtonBool;
  },
  showDeleteButtonFn: function () {
   let vm = this,
    showDeleteButtonBool = false;
   try {
    if (typeof vm.formsData.data !== 'undefined') {
     if (typeof vm.formsData.data.action !== 'undefined') {
      let formsData = vm.formsData.data.action,
       formDisplayBool = vm.$_.split(vm.$_.trim(formsData), ':'),
       formActionName = vm.$_.trim(formDisplayBool[0]).toString().toLowerCase(),
       isAForm = vm.modalType === 'form';
      showDeleteButtonBool = (formActionName === 'delete') && isAForm;
     }
    }
   } catch (error) {
    console.error(error);
   }
   vm.showDeleteButton = showDeleteButtonBool;
  },
  showHelp: function (jsonFileName) {
   let vm = this,
    jsonHelpData = require('./tumatext/themes/chadash/forms/helptext/' + jsonFileName);
   vm.$notify({
    group: 'info-notification',
    title: jsonHelpData.NotificationHeading,
    text: jsonHelpData.NotificationText,
    duration: -1,
    position: 'bottom right',
    max: 1
   });
  },
  hideHelp: function () {
   let vm = this;
   vm.$notify({
    group: 'info-notification',
    clean: true
   });
  },
  closeStream: function () {
   let vm = this;
   if (eventSource.length > 0) {
    vm.$_.forEach(eventSource, function (channelName, channelId) {
     eventSource[channelId].close();
    });
    eventSource = {};
   }
  },
  setupSseStream: function () {
   let vm = this;
   eventSource = {};
   if (typeof vm.mydata !== 'undefined') {
    if (typeof vm.mydata.acc !== 'undefined') {
     let eventChannels = {
      importEventsChannel: vm.$md5('importEvents' + vm.mydata.acc),
      accountCreditChannel: vm.$md5('accountCredit' + vm.mydata.acc)
     };
     vm.$_.forEach(eventChannels, function (channelName, channelId) {
      vm.$sse('/updates/' + channelName, {format: 'json'})
       .then(function (sse) {
        eventSource[channelId] = sse;
        eventSource[channelId].onError(function (e) {
         console.error('event lost connection;', e);
        });
        eventSource[channelId].subscribe(channelName, function (message) {
         vm.propagateUpdate(channelId, message)
        });
       })
       .catch(function (err) {
        console.error('Failed to connect to server', err);
       });
     });
    }
   }
  },
  propagateUpdate: function (channelId, jsonObject) {
   let vm = this
   switch (channelId) {
    case 'importEventsChannel':
     console.log(jsonObject.importData);
     break;
    case 'accountCreditChannel':
     vm.acccredit = jsonObject.smsUnits;
     break;
   }
  }
 },
 watch: {
  showModal: function () {
   let vm = this;
   //document.documentElement.style.overflow = vm.showModal ? 'hidden' : '';
   if (!vm.showModal) {
    vm.hideHelp();
   }
  }
 },
 created() {
  let vm = this;
  window.addEventListener('beforeunload', vm.closeStream())
 },
 mounted: function () {
  let vm = this,
   requestedPath = vm.$_.replace(window.location.pathname, '/', '');
  vm.$nextTick(function () {
   vm.$http.interceptors.response.use(function (response) {
    return response
   }, function (err) {
    console.error(err);
    if (err.response && err.response.status === 401) {
     vm.$session.destroy();
     vm.$session.start();
     vm.$session.flash.set('logoff', 'You need to log in to proceed.');
    }
    if (err.response && err.response.status === 404) {
     vm.$session.destroy();
     vm.$session.start();
     vm.$session.flash.set('logoff', 'Welcome back to earth. You need to log in to proceed.');
     vm.pageErrorpath = 'notfound';
    }
    if (err.response && err.response.status === 503) {
     vm.$session.destroy();
     vm.$session.start();
     vm.$session.flash.set('logoff', 'Service has been restored. You need to log in to proceed.');
     vm.pageErrorpath = 'unavailable';
    }
    if (err.response && err.response.status === 502) {
     console.error(err.response);
    }
    return Promise.reject(err)
   });
   if (!vm.$session.exists()) {
    vm.$session.start();
   }
   let authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   let pageToLoad = '';
   const knownPaths = ['login', 'forgotpassword', 'resetpassword', 'activateaccount', 'loginsecureotp', 'logout', 'account'],
    loginRelated = ['forgotpassword', 'resetpassword', 'activateaccount', 'loginsecureotp'],
    requestPathIdentifier = vm.$_.split(requestedPath, '/');
   let requestedPathName = requestPathIdentifier[0].toString().toLowerCase();
   if (vm.$_.trim(requestedPathName) === '') {
    requestedPathName = 'account';
   }
   if (vm.$_.includes(knownPaths, requestedPathName)) {
    if (vm.$_.includes(loginRelated, requestedPathName)) {
     vm.$session.destroy();
     vm.$session.start();
     vm.$session.set('sessionData', requestedPath);
     pageToLoad = requestedPathName;
     vm.loadPage(pageToLoad, reqHeader, authorizationToken);
    } else if (requestedPathName === 'logout') {
     api.operation('logout').post(null, reqHeader)
      .then(function () {
       vm.$session.destroy();
       vm.$session.start();
       authorizationToken = '';
       reqHeader.headers.authorization = authorizationToken;
       vm.$session.flash.set('logoff', 'You have been successfully logged off');
       pageToLoad = 'login';
       vm.loadPage(pageToLoad, reqHeader, authorizationToken);
      })
      .catch(function (error) {
       console.error(error);
       pageToLoad = 'login';
       vm.loadPage(pageToLoad, reqHeader, authorizationToken);
      });
    } else {
     pageToLoad = requestedPathName;
     if (vm.$_.trim(pageToLoad) === '') {
      pageToLoad = 'login';
     }
     vm.loadPage(pageToLoad, reqHeader, authorizationToken);
    }
   } else {
    vm.is404Response = true;
    vm.hasTopBar = false;
   }
  });
 },
 beforeDestroy() {
  let vm = this;
  vm.closeStream();
 }
}
</script>

<style scoped>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif, 'AngloText';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}

.top-margin-60 {
    margin-top: 60px !important;
}

[v-cloak] {
    display: none;
}

.no-scrolling {
    overflow: hidden !important;
}

.relative-position-body {
    position: relative !important;
}

.center-justified-footer {
    justify-content: center !important;
}

.justify-text-alignment {
    text-align: justify !important;
}

.left-text-alignment {
    text-align: left !important;
}


@font-face {
    font-family: "AngloText";
    src: local("AngloText"),
    url(./tumatext/themes/chadash/assets/fonts/AngloText.ttf) format("truetype");
}
</style>
