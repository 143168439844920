import api from "./../api.js";
import queryString from 'query-string';

export default {
 data() {
  return {
   searchTableValue: '',
   tableDataFilterDates: '',
   tableDataFilterMode: '',
   searchModalValue: '',
   selectedChartScope: 'today',
   refreshTs: '',
   refreshAlso: [],
   sortArray: [],
   selectedPageSize: 10,
   currentpage: 1,
   requestid: 1,
   showData: [],
   filterDataObjData: {},
   dataTitle: 'Loading',
   showsearchbox: false,
   hasTextInput: false,
   dataForForm: false,
   textinputLabel: '',
   selecteditems: {typed: ''},
   exportScope: 'everything',
   jsonStructure: {},
   iterateData: [],
   emission: []
  }
 },
 methods: {
  loadStructure: function () {
   let vm = this;
   return vm.jsonStructure;
  },
  fetchApiTableFormData: function () {
   let vm = this,
    deferredFn = vm.$Q.defer(),
    formElementsFetch = {
     formname: vm.formName,
     tablename: vm.tableName,
     session: vm.$session.id()
    };
   if (typeof formElementsFetch.formname !== 'undefined') {
    let authorizationToken;
    if (vm.$session.has('jwt')) {
     authorizationToken = vm.$session.get('jwt');
    } else {
     authorizationToken = '';
    }
    let reqHeader = {
     headers: {
      authorization: authorizationToken,
     }
    };
    const getQueries = queryString.stringify(formElementsFetch);
    api.operation('formelements?' + getQueries).getAll(reqHeader)
     .then(({data}) => {
      if (data.success) {
       deferredFn.resolve(data);
      } else {
       deferredFn.reject(false);
      }
     });
   } else {
    deferredFn.reject(false);
   }
   return deferredFn.promise;
  },
  fetchApiTableData: function (tableFetch) {
   let vm = this,
    deferredFn = vm.$Q.defer();

   if (typeof tableFetch.table !== 'undefined') {
    let authorizationToken;
    if (vm.$session.has('jwt')) {
     authorizationToken = vm.$session.get('jwt');
    } else {
     authorizationToken = '';
    }
    let reqHeader = {
     headers: {
      authorization: authorizationToken,
     }
    };
    const getQueries = queryString.stringify(tableFetch);
    api.operation('tabledata?' + getQueries).getAll(reqHeader)
     .then(({data}) => {
      if (data.success) {
       if (typeof data.returnData === 'undefined') {
        deferredFn.resolve(data);
       } else {
        if (parseInt(data.returnData.req) === parseInt(vm.requestid - 1) || (parseInt(data.returnData.req) === -1)) {
         deferredFn.resolve(data);
        } else {
         deferredFn.reject(false);
        }
       }
      } else {
       // console.log(data);
       if (data.msg === 'Overdue Session') {
        vm.$session.flash.set('logoff', data.flash.toString());
        window.location.href = '/logout';
       }
       deferredFn.reject(false);
      }
     });
   } else {
    deferredFn.reject(false);
   }
   return deferredFn.promise;
  },
  actionApiTableData: function (tableActions) {
   let vm = this,
    deferredFn = vm.$Q.defer();
   if (typeof tableActions.table !== 'undefined') {
    let authorizationToken;
    if (vm.$session.has('jwt')) {
     authorizationToken = vm.$session.get('jwt');
    } else {
     authorizationToken = '';
    }
    let reqHeader = {
     headers: {
      authorization: authorizationToken,
     }
    };
    api.operation('tableaction').post(tableActions, reqHeader)
     .then(({data}) => {
      if (data.success) {
       deferredFn.resolve(data);
      } else {
       deferredFn.reject(false);
      }
     });
   } else {
    deferredFn.reject(false);
   }
   return deferredFn.promise;
  },
  fetchModalTableStructure: function (modalTableFormData) {
   let vm = this,
    deferredFn = vm.$Q.defer();
   let authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   const getQueries = queryString.stringify(modalTableFormData);
   api.operation('modaltable?' + getQueries).getAll(reqHeader)
    .then(({data}) => {
     if (data.success) {
      deferredFn.resolve({
       struct: data.returnData,
       fetch: data.fetchScope
      });
     } else {
      deferredFn.reject(false);
     }
    });
   return deferredFn.promise;
  },
  getItemValue: function (dataItemValue, dataItem) {
   let vm = this,
    dataValue = '',
    nestedKeys,
    keyparts = vm.$_.split(dataItemValue, '.');
   if (keyparts.length === 1) {
    if (vm.$_.has(dataItem, keyparts[0])) {
     dataValue = dataItem[keyparts[0]];
    }

   } else {
    if (vm.$_.has(dataItem, keyparts[0])) {
     dataValue = dataItem[keyparts[0]];
    }
    for (nestedKeys = 1; nestedKeys < keyparts.length; nestedKeys++) {
     if (dataValue !== null) {
      dataValue = dataValue[keyparts[nestedKeys]];
     }
    }
   }
   if (dataValue === null || dataValue === '') {
    dataValue = '-';
   }
   if (typeof dataValue === 'string') {
    dataValue = vm.$_.replace(dataValue, /(?:\r\n|\r|\n)/gm, "<br>");
   }
   return dataValue;
  },
  getTaggedValue: function (dataItemValue, dataItem, tagType) {
   let vm = this,
    returnData,
    replaceMatches;
   switch (tagType) {
    //     case 'headerString':
    //         returnData = vm.getItemValue(dataItemValue, dataItem);
    //         break;
    default:
     replaceMatches = vm.getReplaceMatches(dataItemValue);
     returnData = dataItemValue;
     vm.$_.forEach(replaceMatches, function (replaceMatchesArr) {
      returnData = vm.$_.replace(returnData, replaceMatchesArr[0], vm.getItemValue(replaceMatchesArr[1], dataItem));
     });
     break;
   }
   return returnData;
  },
  formatDateValue: function (rawDataValue, requiredDateFormat) {
   let vm = this,
    dateValue = vm.$moment(rawDataValue, vm.$moment.ISO_8601),
    dataReturned;
   if (dateValue.isValid()) {
    dataReturned = dateValue.format(requiredDateFormat);
   } else {
    dataReturned = rawDataValue;
   }
   return dataReturned;
  },
  getReplaceMatches: function (valueStructure) {
   const regex = /%([A-Za-z0–9_.]+)%/gm;
   let m,
    replaceArr = [],
    replaceMatches = [];

   while ((m = regex.exec(valueStructure)) !== null) {
    if (m.index === regex.lastIndex) {
     regex.lastIndex++;
    }
    replaceArr = [];
    m.forEach((match) => {
     replaceArr.push(match);
    });
    replaceMatches.push(replaceArr);
   }
   return replaceMatches;
  },
  formatTableDataTypes: function (dataItemValue, dataItemKey, dataItem, returnPlainValue) {
   let vm = this,
    bodyText = '',
    dataValue = '',
    dateVal,
    joinedText,
    bodyTextArr = [],
    objectText = '',
    booleanStatus,
    dateTimeValue,
    involvedInts,
    dividend,
    divisor,
    quotient,
    addendA,
    addendB,
    minuend,
    subtrahend,
    sum,
    difference,
    factorA,
    factorB,
    product,
    returnVal = '-',
    finalArray = [];
   switch (vm.$_.trim(dataItemValue.type)) {
    case 'string':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     returnVal = dataValue;
     break;
    case 'colorheaderstring':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     returnVal = dataValue;
     break;
    case 'colorheadercolor':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     returnVal = dataValue;
     break;
    case 'stringfromjson':
     dataValue = '-';
     if (typeof dataItem[dataItemValue.key] !== 'undefined') {
      dataValue = JSON.stringify(dataItem[dataItemValue.key]);
     }
     returnVal = dataValue;
     break;
    case 'linktonewtab':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     returnVal = `${dataValue}`;
     break;
    case 'modalview':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     returnVal = `<span v-on:click="console.log('hey!')">${dataValue.markup}</span>`;
     break;
    case 'stringwithhtml':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem).replace(/</g, "&lt;").replace(/>/g, "&gt;");
     returnVal = dataValue;
     break;
    case 'invoiceurl':
     if (dataItemValue.key.indexOf('%') !== -1) {
      dataValue = vm.getTaggedValue(dataItemValue.key, dataItem, 'invoiceUrl');
     } else {
      dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     }
     // dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     dataValue = `<a href="https://ext.tumatext.co.ke/ext/invoice/${dataValue}" target="_blank">https://ext1.tumatext.co.ke/ext/invoice/${dataValue}</a>`;
     returnVal = dataValue;
     break;
    case 'number':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (isNaN(dataValue)) {
      returnVal = dataItemValue.missing;
     } else {
      returnVal = dataValue;
     }
     break;
    case 'formatednumber':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (isNaN(dataValue)) {
      returnVal = dataItemValue.missing;
     } else {
      returnVal = vm.$numbro(dataValue).format({
       thousandSeparated: dataItemValue.thousandcomma,
       mantissa: dataItemValue.decimalpoints
      });
     }
     break;
    case 'money':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (isNaN(dataValue)) {
      returnVal = dataItemValue.missing;
     } else {
      returnVal = vm.$numbro(dataValue).format({thousandSeparated: true, mantissa: 2});
     }
     break;
    case 'date':
     dataValue = vm.formatDateValue(vm.getItemValue(dataItemValue.key, dataItem), "YYYY-MM-DD");
     returnVal = dataValue;
     break;
    case 'datetime':
     dateTimeValue = vm.getItemValue(dataItemValue.key, dataItem);
     dateVal = new vm.$xdate(dateTimeValue);
     //if (vm.$moment(dateTimeValue).isValid()) {
     if (dateVal.valid()) {
      dataValue = vm.$moment(dateTimeValue).format("YYYY-MM-DD HH:mm:ss");
     } else {
      dataValue = '-';
     }
     returnVal = dataValue;
     break;
    case 'array':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (vm.$_.trim(dataItemValue.join) === 'elements') {
      joinedText = vm.$_.trim(vm.$_.join(dataValue, ', '));
     }
     if (joinedText === '') {
      joinedText = '-';
     }
     returnVal = joinedText;
     break;
    case 'arrayofelements':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (vm.$_.trim(dataItemValue.join) === 'divs') {
      joinedText = '<div>' + vm.$_.join(dataValue, '</div><div>') + '</div>';
     }
     returnVal = joinedText;
     break;
    case 'arrayofnamedelements':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     vm.$_.forEach(dataValue, function (dataItemValue) {
      switch (dataItemValue.element) {
       case 'div':
        bodyTextArr.push('<div class="' + dataItemValue.class + '" style="' + dataItemValue.style + '">' + dataItemValue.text + '</div>');
        break;
       case 'container':
        bodyTextArr.push('<div class="container"><div class="' + dataItemValue.class + '" style="' + dataItemValue.style + '">' + dataItemValue.text + '</div></div>');
        break;
       case 'fluidcontainer':
        bodyTextArr.push('<div class="container is-fluid"><div class="' + dataItemValue.class + '" style="' + dataItemValue.style + '">' + dataItemValue.text + '</div></div>');
        break;
      }
     });
     joinedText = vm.$_.join(bodyTextArr, '');
     returnVal = joinedText;
     break;
    case 'arrayofobjects':
     joinedText = '';
     bodyTextArr = [];
     if (dataItemValue.parentkey.indexOf('.') !== -1) {
      let objectKeys = vm.$_.split(dataItemValue.parentkey, '.');
      finalArray = dataItem[objectKeys[0]];
      for (let arrayIndex = 1; arrayIndex < objectKeys.length; arrayIndex++) {
       finalArray = finalArray[objectKeys[arrayIndex]];
      }
     } else {
      finalArray = dataItem[dataItemValue.parentkey];
     }
     for (let arrayIndex = 0; arrayIndex < finalArray.length; arrayIndex++) {
      if (finalArray[arrayIndex]) {
       bodyTextArr.push(vm.getItemValue(dataItemValue.objectkey, finalArray[arrayIndex]));
      }
     }
     joinedText = vm.$_.join(bodyTextArr, ', ');
     returnVal = joinedText;
     break;
    case 'boolean':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (vm.$_.trim(dataValue) === '-') {
      booleanStatus = '-';
     } else {
      if (dataValue) {
       booleanStatus = vm.$_.trim(dataItemValue.true);
      } else {
       booleanStatus = vm.$_.trim(dataItemValue.false);
      }
     }
     returnVal = booleanStatus;
     break;
    case 'positivecolorboolean':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (vm.$_.trim(dataValue) === '-') {
      booleanStatus = '-';
     } else {
      if (dataValue) {
       booleanStatus = vm.$_.trim(dataItemValue.true);
      } else {
       booleanStatus = vm.$_.trim(dataItemValue.false);
      }
     }
     returnVal = booleanStatus;
     break;
    case 'negativecolorboolean':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (vm.$_.trim(dataValue) === '-') {
      booleanStatus = '-';
     } else {
      if (dataValue) {
       booleanStatus = vm.$_.trim(dataItemValue.true);
      } else {
       booleanStatus = vm.$_.trim(dataItemValue.false);
      }
     }
     returnVal = booleanStatus;
     break;
    case 'object':
     returnVal = '-'
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (dataValue !== '-') {
      objectText = [];
      vm.$_.forEach(dataValue, function (dataItemObjectValue, dataItemObjectKey) {
       objectText.push(`${dataItemObjectKey}=${dataItemObjectValue}`);
      });
      returnVal = vm.$_.join(objectText, ', ');
     }
     break;
    case 'objectobjectified':
     returnVal = '-'
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (dataValue !== '-') {
      objectText = '';
      bodyTextArr = [];
      vm.$_.forEach(dataValue, function (dataItemObjectValue, dataItemObjectKey) {
       bodyTextArr.push(`${dataItemObjectKey}:${dataItemObjectValue}`);
      });
      objectText = vm.$_.join(bodyTextArr, ', ');
      returnVal = `{${objectText}}`;
     }
     break;
    case 'objectcomma':
     returnVal = '-'
     if (typeof dataItem[dataItemValue.key] !== 'undefined') {
      dataValue = vm.getItemValue(dataItemValue.key, dataItem);
      if (dataValue !== '-') {
       objectText = '';
       bodyTextArr = [];
       vm.$_.forEach(dataValue, function (dataItemObjectValue, dataItemObjectKey) {
        bodyTextArr.push(`${dataItemObjectKey}=${JSON.stringify(dataItemObjectValue)}`);
       });
       objectText = vm.$_.join(bodyTextArr, ', ');
       returnVal = `{${objectText}}`;
      }
     }
     break;
    case 'mappedstring':
     dataValue = vm.getItemValue(dataItemValue.key, dataItem);
     if (vm.$_.trim(dataItemValue.mapping) === 'contacts') {
      if (vm.$_.includes(vm.$_.keys(vm.contactKeysMapper), dataValue)) {
       returnVal = vm.contactKeysMapper[dataValue];
      } else {
       returnVal = `No mapping`;
      }
     }
     break;
    case 'calculate':
     involvedInts = vm.$_.split(dataItemValue.key, '|');
     switch (dataItemValue.calcType) {
      case 'add':
       addendA = parseInt(vm.getItemValue(involvedInts[0], dataItem));
       addendB = parseInt(vm.getItemValue(involvedInts[1], dataItem));
       if (isNaN(addendA) || isNaN(addendB)) {
        returnVal = `-`;
       } else {
        sum = addendA + addendB;
        returnVal = sum;
       }
       break;
      case 'subtract':
       minuend = parseInt(vm.getItemValue(involvedInts[0], dataItem));
       subtrahend = parseInt(vm.getItemValue(involvedInts[1], dataItem));
       if (isNaN(minuend) || isNaN(subtrahend)) {
        returnVal = `-`;
       } else {
        difference = minuend - subtrahend;
        returnVal = difference;
       }
       break;
      case 'divide':
       dividend = parseInt(vm.getItemValue(involvedInts[0], dataItem));
       divisor = parseInt(vm.getItemValue(involvedInts[1], dataItem));
       if (divisor === 0 || isNaN(divisor)) {
        returnVal = dataItemValue.missing;
       } else {
        if (dividend === '' || isNaN(dividend)) {
         returnVal = `-`;
        } else {
         quotient = vm.$numbro((dividend / divisor) * 100).format({mantissa: 2});
         returnVal = quotient;
        }
       }
       break;
      case 'multiply':
       factorA = parseInt(vm.getItemValue(involvedInts[0], dataItem));
       factorB = parseInt(vm.getItemValue(involvedInts[1], dataItem));
       if (isNaN(factorA) || isNaN(factorB)) {
        returnVal = `-`;
       } else {
        product = factorA * factorB;
        returnVal = product;
       }
       break;
      case 'percentage':
       dividend = parseInt(vm.getItemValue(involvedInts[0], dataItem));
       divisor = parseInt(vm.getItemValue(involvedInts[1], dataItem));
       if (divisor === 0 || isNaN(divisor)) {
        returnVal = dataItemValue.missing;
       } else {
        if (dividend === '' || isNaN(dividend)) {
         returnVal = dataItemValue.missing;
        } else {
         quotient = vm.$_.ceil(vm.$numbro((dividend / divisor) * 100).format({mantissa: 2}));
         returnVal = `${quotient}%`;
        }
       }
       break;
     }
     break;
    default:
     bodyText += '';
     break;
   }
   if (returnPlainValue) {
    bodyText = returnVal;
   } else {
    if (vm.$_.trim(dataItemKey) === '') {
     bodyText += `${returnVal}`;
    } else {
     bodyText += `<strong>${dataItemKey}:</strong> ${returnVal}<br>`;
    }
   }
   return bodyText;
  },
  formatModalDataTypes: function (valuetypeStructure, replaceMatches, valueStructure, dataItemObjectValue, keyStructure) {
   let vm = this,
    replacementVal = '',
    dataVal,
    returnObj = {};
   switch (valuetypeStructure) {
    case 'keysobject':
     dataVal = valueStructure;
     vm.$_.forEach(replaceMatches, function (replaceMatchesValue) {
      replacementVal = vm.getItemValue(replaceMatchesValue[1], dataItemObjectValue);
      dataVal = dataVal.replace(replaceMatchesValue[0], replacementVal);
     });
     returnObj = {
      key: dataItemObjectValue[keyStructure],
      value: dataVal,
      visible: true
     };
     break;
    case 'stringsarray':
     vm.$_.forEach(dataItemObjectValue[valueStructure], function (stringVal, stringKey) {
      returnObj = {
       key: 'item_' + stringKey,
       value: stringVal,
       visible: true
      };
     });
     break;
    default:
     returnObj = {
      key: 'key',
      value: 'Empty',
      visible: true
     };
     break;
   }
   return returnObj;
  },
  createSupportingData: function (elementName, dataReturned) {
   let vm = this,
    returnObjArray = [],
    returnObj,
    showText;
   switch (elementName) {
    case 'fetables-reporting-subsection':
     vm.$_.forEach(dataReturned.l.content, function (contentObjectValue, contentObjectKey) {
      showText = (typeof contentObjectValue.show !== 'undefined') ? contentObjectValue.show : true;
      if (showText) {
       returnObjArray.push({
        item: contentObjectKey,
        type: contentObjectValue.type,
        key: contentObjectValue.key
       });
      }
     });
     returnObj = {
      itemId: dataReturned._id.toString(),
      itemData: returnObjArray
     };
     break;
    default:
     returnObj = {};
     break;
   }
   return returnObj;
  },
  formatListDataTypes: function (valuetypeStructure, titleReplaceMatches, titleStructure, subtitleReplaceMatches, subtitleStructure, dataItemObjectValue, keyStructure, listButtons, dateformatStructure) {
   let vm = this,
    replacementVal = '',
    titleVal,
    subtitleVal,
    returnObj = {};
   switch (valuetypeStructure) {
    case 'objectsarray':
     titleVal = titleStructure;
     subtitleVal = subtitleStructure;
     vm.$_.forEach(titleReplaceMatches, function (replaceMatchesValue) {
      replacementVal = vm.getItemValue(replaceMatchesValue[1], dataItemObjectValue);
      titleVal = titleVal.replace(replaceMatchesValue[0], replacementVal);
     });
     vm.$_.forEach(subtitleReplaceMatches, function (replaceMatchesValue) {
      replacementVal = vm.formatDateValue(vm.getItemValue(replaceMatchesValue[1], dataItemObjectValue), dateformatStructure);
      subtitleVal = subtitleVal.replace(replaceMatchesValue[0], replacementVal);
     });
     returnObj = {
      key: dataItemObjectValue[keyStructure],
      title: titleVal,
      subtitle: subtitleVal,
      buttons: listButtons,
     };
     break;
    default:
     returnObj = {
      key: '',
      title: 'Title',
      subtitle: 'Subtitle',
      buttons: {}
     };
     break;
   }
   return returnObj;
  },
  formatChartDataTypes: function (dataReturned, xAxis, yAxis, chartScope, chartConfig) {
   let vm = this,
    chartData = {},
    datasetObject = {},
    datasetArray = [],
    timeFormatObj,
    yIndex = 0,
    yIndexTracker = 0,
    yIndexKey = 0,
    yLegendObj = {};
   switch (chartScope) {
    case 'today':
     timeFormatObj = {
      unit: 'hour',
      displayFormats: {
       hour: 'HH'
      }
     };
     break;
    case 'week':
     timeFormatObj = {
      unit: 'day',
      displayFormats: {
       day: 'MMM Do'
      }
     };
     break;
    case 'month':
     timeFormatObj = {
      unit: 'week',
      displayFormats: {
       week: 'YYYY - ww'
      }
     };
     break;
    case 'year':
     timeFormatObj = {
      unit: 'month',
      displayFormats: {
       month: 'MMM'
      }
     };
     break;
    default:
     timeFormatObj = {
      unit: 'day',
      displayFormats: {
       day: 'MMM Do'
      }
     };
     break;
   }

   yIndex = 0;
   vm.$_.forEach(dataReturned.data.y, function (xyValue, xvKey) {
    yIndexTracker = yIndex++;
    yIndexKey = 'legend_' + yIndexTracker;
    yLegendObj[yIndexTracker] = {
     value: xvKey,
     visible: true,
     type: 'checkbox',
     key: yIndexKey,
     idname: yIndexKey
    };
    if (typeof vm.selectedSeries[yIndexKey] === 'undefined' || vm.selectedSeries[yIndexKey]) {
     if (chartConfig.stacked) {
      datasetObject = {
       label: xvKey,
       backgroundColor: yAxis.colors[xvKey],
       fill: false,
       stack: yAxis.stack[xvKey],
       data: xyValue
      };
     } else {
      datasetObject = {
       label: xvKey,
       backgroundColor: yAxis.colors[xvKey],
       fill: false,
       data: xyValue
      };
     }

     datasetArray.push(datasetObject);
     if (typeof vm.selectedSeries[yIndexKey] === 'undefined') {
      vm.$set(vm.selectedSeries, yIndexKey, true)
     }
    } else {
     vm.$set(vm.selectedSeries, yIndexKey, false)
    }
   });
   chartData.config = chartConfig;
   chartData.legends = yLegendObj;
   chartData.data = {
    labels: dataReturned.data.x,
    datasets: datasetArray
   };

   chartData.options = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
     text: 'Chart.js Time Scale'
    },
    scales: {
     xAxes: [
      {
       type: 'time',
       distribution: 'linear',
       bounds: 'data',
       time: timeFormatObj,
       stacked: chartConfig.stacked,
       gridLines: {
        offsetGridLines: true
       }
      }
     ],
     yAxes: [
      {
       stacked: chartConfig.stacked,
      }
     ]
    },
    legend: {
     display: true,
     usePointStyle: true
    }
   };
   vm.apiData = chartData;
  },
  loadApiTableData: function (gotToPageOne) {
   let vm = this,
    dataStructure = vm.loadStructure(),
    tableFetchRequest = {
     table: vm.subsection.mykey,
     search: vm.searchTableValue,
     dateFilter: vm.tableDataFilterDates,
     dateMode: vm.tableDataFilterMode,
     sort: JSON.stringify(vm.sortArray),
     pagesize: vm.selectedPageSize,
     pagenumber: vm.currentpage,
     requestid: vm.requestid++,
     session: vm.$session.id(),
     exportscope: 'table',
     dataStructure: JSON.stringify(dataStructure),
     contactKeysMap: JSON.stringify(vm.contactKeysMapper),
     reportType: '',
     reportCc: vm.emailcc
    },
    bodyTextHolder,
    showText = true,
    returnPlainValue = false,
    percentageHolder = [],
    booleanHolder = [];
   vm.showrefreshnoticebox = true;
   vm.fetchApiTableData(tableFetchRequest)
    .then((data) => {
     if (data) {
      let newRow = {},
       bodyText = '',
       tableDataRaw = [];
      vm.$_.forEach(data.returnData.data, function (dataItem) {
       newRow = {};
       if (dataStructure.headText.indexOf('%') !== -1) {
        newRow.header = vm.getTaggedValue(dataStructure.headText, dataItem, 'headerString');
       } else {
        newRow.header = vm.getItemValue(dataStructure.headText, dataItem);
       }
       newRow.key = dataItem[dataStructure.key];
       newRow.headerBackground = '';
       bodyText = '';
       vm.$_.forEach(dataStructure.content, function (dataItemValue, dataItemKey) {
        if (typeof dataItemValue.show !== 'undefined') {
         showText = dataItemValue.show;
        } else {
         showText = true
        }
        if (typeof dataItemValue.plainValue !== 'undefined') {
         returnPlainValue = dataItemValue.plainValue;
        } else {
         returnPlainValue = false
        }
        bodyTextHolder = vm.formatTableDataTypes(dataItemValue, dataItemKey, dataItem, returnPlainValue);
        if (showText) {
         bodyText += bodyTextHolder;
        }
        if (vm.$_.trim(dataItemValue.type) === 'calculate' &&
         vm.$_.trim(dataItemValue.calcType) === 'percentage') {
         percentageHolder = vm.$_.split(bodyTextHolder, ':');
         newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 ' + vm.$_.trim(percentageHolder[1].replace(/\D/gm, '')) + '%, #F08080 0);';
        }
        if (vm.$_.trim(dataItemValue.type) === 'positivecolorboolean') {
         booleanHolder = vm.$_.split(bodyTextHolder, ':');
         if (vm.$_.trim(booleanHolder[1]).indexOf(' Yes') >= 0) {
          newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 100%, #F08080 0);';
         } else {
          newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 0%, #F08080 0);';
         }
        }
        if (vm.$_.trim(dataItemValue.type) === 'colorheaderstring') {
         booleanHolder = vm.$_.split(bodyTextHolder, ':');
         if (vm.$_.trim(booleanHolder[1]).indexOf(' Delivered') >= 0) {
          newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 100%, #F08080 0);';
         } else {
          newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 0%, #F08080 0);';
         }

        }
        if (vm.$_.trim(dataItemValue.type) === 'colorheadercolor') {
         newRow.headerBackground = `background: linear-gradient(90deg, ${bodyTextHolder} 100%, #FFFFFF 0);`;
        }
        if (vm.$_.trim(dataItemValue.type) === 'negativecolorboolean') {
         booleanHolder = vm.$_.split(bodyTextHolder, ':');
         if (vm.$_.trim(booleanHolder[1]).indexOf(' No') >= 0) {
          newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 100%, #F08080 0);';
         } else {
          newRow.headerBackground = 'background: linear-gradient(90deg, #90EE90 0%, #F08080 0);';
         }

        }
       });
       newRow.body = bodyText;
       newRow.hasButtons = dataItem.hasbuttons;
       if (dataItem.hasbuttons) {
        newRow.editable = (typeof dataItem.editable !== 'undefined') ? dataItem.editable : false;
        newRow.deleteable = (typeof dataItem.deleteable !== 'undefined') ? dataItem.deleteable : false;
        newRow.approveable = (typeof dataItem.approveable !== 'undefined') ? dataItem.approveable : false;
        newRow.rejectable = (typeof dataItem.rejectable !== 'undefined') ? dataItem.rejectable : false;
        newRow.detailsable = (typeof dataItem.detailsable !== 'undefined') ? dataItem.detailsable : false;
        newRow.cancelable = (typeof dataItem.cancelable !== 'undefined') ? dataItem.cancelable : false;
        newRow.useable = (typeof dataItem.useable !== 'undefined') ? dataItem.useable : false;
        newRow.viewable = (typeof dataItem.viewable !== 'undefined') ? dataItem.viewable : false;
        newRow.updateable = (typeof dataItem.updateable !== 'undefined') ? dataItem.updateable : false;
        newRow.toggleable = (typeof dataItem.toggleable !== 'undefined') ? dataItem.toggleable : false;
        newRow.sendable = (typeof dataItem.sendable !== 'undefined') ? dataItem.sendable : false;
        newRow.voidable = (typeof dataItem.voidable !== 'undefined') ? dataItem.voidable : false;
        newRow.viewasable = (typeof dataItem.viewasable !== 'undefined') ? dataItem.viewasable : false;
        newRow.startstopable = (typeof dataItem.startstopable !== 'undefined') ? dataItem.startstopable : false;
        newRow.showmappingable = (typeof dataItem.showmappingable !== 'undefined') ? dataItem.showmappingable : false;
        newRow.loadable = (typeof dataItem.loadable !== 'undefined') ? dataItem.loadable : false;
        newRow.hasmessagescontent = (typeof dataItem.hasmessagescontent !== 'undefined') ? dataItem.hasmessagescontent : false;
       }
       newRow.id = dataItem._id;
       newRow.rowData = dataItem;
       tableDataRaw.push(newRow);
      });
      vm.tableData = vm.$_.uniq(tableDataRaw);
      vm.totalRecords = data.returnData.recordsTotal;
      vm.filteredRecords = data.returnData.recordsFiltered;
      vm.maxpages = vm.$_.ceil(parseInt(data.returnData.recordsFiltered) / parseInt(vm.selectedPageSize));

      let startPageIndex = (((vm.currentpage - 1) * vm.selectedPageSize) + 1);
      let endPageIndex = (vm.currentpage * vm.selectedPageSize);
      if (endPageIndex > vm.totalRecords) {
       endPageIndex = vm.totalRecords;
      }

      let tableTextsArr = [],
       totalCountDesc = '',
       filteredCountDesc = '',
       countingText = '',
       sortingText = '',
       sortingTextArr = [],
       searchingText = '',
       dateFilteringText = '';

      if (parseInt(vm.totalRecords) === 1) {
       totalCountDesc = ' record';
      } else {
       totalCountDesc = ' records';
      }
      if (parseInt(vm.filteredRecords) === 1) {
       filteredCountDesc = ' record';
      } else {
       filteredCountDesc = ' records';
      }
      if (parseInt(vm.totalRecords) === parseInt(vm.filteredRecords)) {
       countingText = vm.totalRecords + totalCountDesc;

      } else {
       countingText = vm.filteredRecords + filteredCountDesc + ' filtered from ' + vm.totalRecords + totalCountDesc;
      }
      let pagingText = 'Page ' + vm.currentpage + '( ' + startPageIndex + '-' + endPageIndex + ' )';
      if (vm.$_.trim(vm.searchTableValue) !== '') {
       searchingText = '<br>Results of search for "' + vm.$_.trim(vm.searchTableValue) + '"';
      }

      if (vm.$_.trim(vm.tableDataFilterDates) !== '') {
       dateFilteringText = '<br>Filtering results for "' + vm.$_.trim(vm.tableDataFilterDates) + '"';
      }
      if (!vm.$_.isEmpty(vm.sortArray)) {
       vm.$_.forEach(vm.sortArray, function (sortObject) {
        if (sortObject.order) {
         sortingTextArr.push(sortObject.name + ' Ascending');
        } else {
         sortingTextArr.push(sortObject.name + ' Descending');
        }
       });
      }
      if (!vm.$_.isEmpty(vm.$_.compact(sortingTextArr))) {
       sortingText = '<br>Sorted by ' + vm.$_.compact(sortingTextArr).join(', then ');
      }

      tableTextsArr.push(countingText + ' | ' + pagingText, sortingText, searchingText, dateFilteringText);
      vm.tableTexts = vm.$_.compact(tableTextsArr).join("");
      vm.showrefreshnoticebox = false;
      if (gotToPageOne) {
       vm.goToPage(1);
      }
     }
    });
  },
  loadApiModalData: function () {
   let vm = this,
    dataStructure = vm.loadStructure(),
    modalFetchRequest = {
     table: vm.request,
     search: vm.searchModalValue,
     sort: JSON.stringify(vm.sortArray),
     pagesize: vm.selectedPageSize,
     pagenumber: vm.currentpage,
     requestid: vm.requestid++,
     session: vm.$session.id(),
     exportscope: vm.exportScope,
     dataStructure: JSON.stringify({}),
     contactKeysMap: JSON.stringify({}),
     reportType: '',
     reportCc: ''
    };
   vm.fetchApiTableData(modalFetchRequest)
    .then((data) => {
     if (data) {
      const keyStructure = dataStructure.key,
       valueStructure = dataStructure.value,
       valuetypeStructure = dataStructure.valuetype;
      let replaceMatches = vm.getReplaceMatches(valueStructure),
       readyData = [],
       filterData = [],
       filterDataObj = {};
      vm.$_.forEach(data.returnData.data, function (dataItemObjectValue) {
       readyData.push(vm.formatModalDataTypes(valuetypeStructure, replaceMatches, valueStructure, dataItemObjectValue, keyStructure));
       filterData.push(vm.createSupportingData(modalFetchRequest.table, dataItemObjectValue));
      });
      vm.$_.forEach(filterData, function (filterDataItem) {
       filterDataObj[filterDataItem.itemId] = filterDataItem.itemData
      });
      vm.filterDataObjData = filterDataObj;
      vm.showData = vm.iterateData = readyData;
      vm.dataTitle = dataStructure.dataTitle;
      vm.showsearchbox = dataStructure.searchable;
      if (vm.$_.trim(dataStructure.textinputLabel) !== '') {
       vm.hasTextInput = true;
       vm.textinputLabel = vm.$_.trim(dataStructure.textinputLabel);
      }
      if (vm.items) {
       vm.selecteditems = vm.items;
      }
     }
    });
  },
  loadTagsData: function () {
   let vm = this,
    modalFetchRequest = {
     table: vm.request,
     search: vm.searchModalValue,
     sort: JSON.stringify(vm.sortArray),
     pagesize: vm.selectedPageSize,
     pagenumber: vm.currentpage,
     requestid: vm.requestid++,
     session: vm.$session.id(),
     exportscope: vm.exportScope,
     dataStructure: JSON.stringify({}),
     contactKeysMap: JSON.stringify({}),
     reportType: '',
     reportCc: ''
    };

   if (typeof modalFetchRequest.table !== 'undefined') {
    let authorizationToken;
    if (vm.$session.has('jwt')) {
     authorizationToken = vm.$session.get('jwt');
    } else {
     authorizationToken = '';
    }
    let reqHeader = {
     headers: {
      authorization: authorizationToken,
     }
    };
    const getQueries = queryString.stringify(modalFetchRequest);
    api.operation('tabledata?' + getQueries).getAll(reqHeader)
     .then(({data}) => {
      console.log(data);
      if (data.success) {
       if (parseInt(data.returnData.req) === parseInt(vm.requestid - 1) || (parseInt(data.returnData.req) === -1)) {
        console.log(data);
       } else {
        //failed
       }
      } else {
       if (data.msg === 'Overdue Session') {
        vm.$session.flash.set('logoff', data.flash.toString());
        window.location.href = '/logout';
       }
      }
     });
   } else {
    // No Table
   }
  },
  loadApiListData: function () {
   let vm = this,
    dataStructure = vm.loadStructure(),
    listFetchRequest = {
     table: vm.request,
     search: vm.searchModalValue,
     sort: JSON.stringify(vm.sortArray),
     pagesize: vm.selectedPageSize,
     pagenumber: vm.currentpage,
     requestid: vm.requestid++,
     session: vm.$session.id(),
     exportscope: vm.exportScope,
     dataStructure: JSON.stringify(dataStructure),
     contactKeysMap: JSON.stringify({}),
     reportType: '',
     reportCc: ''
    };
   vm.fetchApiTableData(listFetchRequest)
    .then((data) => {
     if (data) {
      const keyStructure = dataStructure.key,
       titleStructure = dataStructure.title,
       subtitleStructure = dataStructure.subtitle,
       listButtons = dataStructure.buttons,
       valuetypeStructure = dataStructure.valuetype,
       dateformatStructure = dataStructure.dateformat;
      let titleReplaceMatches = vm.getReplaceMatches(titleStructure),
       subtitleReplaceMatches = vm.getReplaceMatches(subtitleStructure),
       readyData = [];

      vm.$_.forEach(data.returnData.data, function (dataItemObjectValue) {
       readyData.push(vm.formatListDataTypes(valuetypeStructure, titleReplaceMatches, titleStructure, subtitleReplaceMatches, subtitleStructure, dataItemObjectValue, keyStructure, listButtons, dateformatStructure));
      });
      if (readyData.length > 0) {
       vm.listItems = readyData;
      } else {
       vm.listItems = [
        {
         key: '_',
         title: 'There are no items',
         subtitle: '',
         buttons: {}
        }
       ];
      }
     }
    });
  },
  loadApiFormData: function () {
   let vm = this,
    deferredFn = vm.$Q.defer(),
    dataStructure = vm.loadStructure(),
    tableFetchRequest = {
     table: vm.tableName,
     formname: vm.formName,
     dataforform: vm.dataForForm,
     search: vm.formAction,
     sort: JSON.stringify(vm.sortArray),
     pagesize: 1,
     pagenumber: 1,
     requestid: -1,
     session: vm.$session.id(),
     exportscope: vm.exportScope,
     dataStructure: JSON.stringify(dataStructure),
     contactKeysMap: JSON.stringify({}),
     reportType: '',
     reportCc: ''
    };
   vm.fetchApiTableData(tableFetchRequest)
    .then((data) => {
     if (data) {
      deferredFn.resolve(data);
     }
    });
   return deferredFn.promise;
  },
  loadApiChartData: function () {
   let vm = this,
    dataStructure = vm.loadStructure(),
    chartFetchRequest = {
     table: vm.request,
     search: vm.selectedChartScope,
     sort: JSON.stringify(vm.sortArray),
     pagesize: 1,
     pagenumber: 1,
     requestid: -1,
     session: vm.$session.id(),
     exportscope: vm.exportScope,
     dataStructure: JSON.stringify(dataStructure),
     contactKeysMap: JSON.stringify({}),
     reportType: '',
     reportCc: ''
    };
   vm.chartDataLoaded = false;
   vm.fetchApiTableData(chartFetchRequest)
    .then((data) => {
     if (data) {
      const chartXAxis = dataStructure.xAxis,
       chartYAxis = dataStructure.yAxis,
       chartConfig = dataStructure.config;
      vm.formatChartDataTypes(data.returnData.chart, chartXAxis, chartYAxis, vm.selectedChartScope, chartConfig);
     }
    });
  },
  loadModalTableData: function () {
   let vm = this;
   vm.fetchModalTableStructure(vm.datasData.formData)
    .then((data) => {
     if (data) {
      vm.$_.forEach(data.struct, function (subsection, subkey) {
       vm.tableData = {
        subsection: subsection,
        subkey: subkey,
        myRole: '',
        refreshMe: ''
       };
       vm.tableStructureReady = true;
      });
     }
    });
  },
  loadPanelData: function (panelname) {
   let vm = this,
    authorizationToken;
   if (vm.$session.has('jwt')) {
    authorizationToken = vm.$session.get('jwt');
   } else {
    authorizationToken = '';
   }
   let reqHeader = {
    headers: {
     authorization: authorizationToken,
    }
   };
   const getQueries = queryString.stringify({panel: panelname});
   api.operation('paneldata?' + getQueries).getAll(reqHeader)
    .then(({data}) => {
     if (data.success) {
      vm.panelDataFetched = data.returnData;
     }
    });
  },
  setRefreshAlso: function (tableName) {
   let vm = this;
   switch (vm.$_.trim(tableName)) {
    case 'groups-subsection':
     vm.refreshAlso = [
      'contacts-subsection'
     ];
     break;
    case 'contacts-subsection':
     vm.refreshAlso = [
      'groups-subsection'
     ];
     break;
    case 'proxymanageractions-subsection':
     vm.refreshAlso = [
      'proxymanagertables-subsection'
     ];
     break;
    default:
     break;
   }
  },
  exportApiTableData: function (exportscope, reportType) {
   let vm = this,
    dataStructure = vm.loadStructure(),
    tableFetchRequest = {
     table: vm.subsection.mykey,
     search: vm.searchTableValue,
     dateFilter: vm.tableDataFilterDates,
     dateMode: vm.tableDataFilterMode,
     sort: JSON.stringify(vm.sortArray),
     pagesize: vm.selectedPageSize,
     pagenumber: vm.currentpage,
     requestid: vm.requestid++,
     session: vm.$session.id(),
     exportscope: exportscope,
     dataStructure: JSON.stringify(dataStructure),
     contactKeysMap: JSON.stringify(vm.contactKeysMapper),
     reportType: reportType,
     reportCc: vm.emailcc
    };
   vm.fetchApiTableData(tableFetchRequest)
    .then((data) => {
     if (data) {
      if (data.success) {
       vm.processingReport = false;
       vm.emailcc = '';
       vm.emailSendingStatus = vm.emailSendingStatus + ' successful';
       setTimeout(function () {
        vm.emailSendingStatus = '';
        vm.showexportbox = false;
       }, 3000);
      } else {
       vm.processingReport = false;
       vm.emailSendingStatus = vm.emailSendingStatus + ' failed';
      }
     }
    });
  }
 },
 watch: {
  refreshMe: {
   handler: function () {
    let vm = this,
     changeData = vm.$_.split(vm.refreshMe, ':');
    if (vm.$_.trim(changeData[0]) === vm.$_.trim(vm.subsection.mykey)) {
     if (vm.$_.trim(vm.refreshTs) !== vm.$_.trim(changeData[2])) {
      vm.refreshTs = vm.$_.trim(changeData[2]);
      if (vm.$_.trim(changeData[1]) === 'list') {
       vm.loadApiListData();
      } else {
       vm.loadApiTableData(false);
      }
      if (vm.$_.trim(changeData[1]) !== 'tablerefresh') {
       vm.$_.forEach(vm.refreshAlso, function (refreshTable) {
        vm.$emit('refreshTable', {table: refreshTable, refreshAlso: true});
       });
      }
     }
    }

   },
   deep: true
  },
  searchModalValue: {
   handler: function () {
    let vm = this,
     re = new RegExp(vm.searchModalValue, "gi");
    vm.showData = [];
    vm.$_.forEach(vm.iterateData, function (dataValue) {
     if (vm.$_.trim(vm.searchModalValue) === '') {
      vm.showData.push(dataValue);
     } else {
      if (re.test(dataValue.value)) {
       vm.showData.push(dataValue);
      }
     }
    });
   },
   deep: true
  },
  selecteditems: {
   handler: function () {
    let vm = this;
    vm.emission = [];
    vm.emission[0] = vm.$_.trim(vm.selecteditems.typed);
    switch (vm.type) {
     case 'radio':
      vm.emission[0] = vm.$_.trim(vm.selecteditems.selected_radio);
      break;
     case 'checkbox':
      vm.$_.forEach(vm.iterateData, function (dataValue) {
       vm.$_.forEach(vm.selecteditems, function (selectedValue, selectedKey) {
        if ((dataValue.key === selectedKey) && selectedValue) {
         vm.emission.push(dataValue.value);
        }
       });
      });
      break;
     default:
      vm.emission[0] = '';
      break;
    }

    vm.emission = vm.$_.uniq(vm.$_.compact(vm.emission));
   },
   deep: true
  }
 }
}