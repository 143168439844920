<template>
  <div>
    <p class="element-label" v-html="labelText"></p>
    <p class="control has-icons-left" v-bind:class="{ 'has-icons-right' : $v.inputValue.$invalid }">
      <vue-tags-input
          class="textarea is-primary is-rounded text-area-with-icon"
          v-bind:class="{ 'is-danger' : $v.inputValue.$invalid }"
          v-model="inputValue"
          v-bind:tags="inputValues"
          v-bind:placeholder="placeholderText"
          v-bind:autocomplete-items="filteredItems"
          v-on:tags-changed="newTags => inputValues = newTags"
      />
      <span class="icon is-small is-left">
                <font-awesome-icon v-bind:icon="[inputIconCategory, inputIcon]"/>
            </span>
      <span class="icon is-small is-right redColored" v-if="$v.inputValue.$invalid">
				<font-awesome-icon v-bind:icon="['fas', 'exclamation-triangle']"/>
			</span>
    </p>
    <p class="help is-danger bigger-text bolder-text" v-if="$v.inputValue.$invalid">{{
        mandatoryInputInvalidText
      }}</p>
  </div>
</template>

<script>
import formFunctions from "./../../../../api/mixins/formFunctions";
import fetchTableData from "./../../../../api/mixins/fetchTableData";
import api from "./../../../../api/api.js";
import VueTagsInput from '@johmun/vue-tags-input';
import queryString from "query-string";

export default {
  name: "tagsInputField",
  mixins: [formFunctions, fetchTableData],
  data() {
    let vm = this;
    return {
      inputValue: '',
      inputValues: vm.inputData.initial,
      autocompleteItems: [],
      inputName: vm.inputData.elementName,
      inputType: vm.inputData.type,
      placeholderText: vm.inputData.placeholderText,
      mandatoryInputInvalidText: vm.inputData.invalidText,
      inputIconCategory: vm.inputData.iconCategory,
      inputIcon: vm.inputData.icon,
      isMandatory: vm.inputData.mandatory,
      validationRules: vm.inputData.validationRules,
      labelText: vm.inputData.labelText,
      disabledInput: vm.inputData.disabled,
      mustBeWordInputInvalidText: '',
      mustBeEmailInputInvalidText: '',
      minValueInputInvalidText: ''
    }
  },
  props: ['inputData'],
  components: {
    VueTagsInput
  },
  watch: {
    inputData: {
      handler: function () {
        let vm = this;
        vm.inputValues = vm.inputData.initial;
        vm.inputName = vm.inputData.elementName;
        vm.inputType = vm.inputData.type;
        vm.placeholderText = vm.inputData.placeholderText;
        vm.mandatoryInputInvalidText = vm.inputData.invalidText;
        vm.inputIcon = vm.inputData.icon;
        vm.isMandatory = vm.inputData.mandatory;
        vm.validationRules = vm.inputData.validationRules;
        vm.labelText = vm.inputData.labelText;
        vm.disabledInput = vm.inputData.disabled;
      },
      deep: true
    },
    inputValue: {
      handler: function () {
        let vm = this;
        vm.initItems();
      },
      deep: true
    }

  },
  methods: {
    initItems() {
      let vm = this;
      console.log(vm.inputValue);
      if (vm.inputValue.length < 2) return;
      // const url = `https://itunes.apple.com/search?term=
      //   ${this.inputValue}&entity=allArtist&attribute=allArtistTerm&limit=6`;

      clearTimeout(vm.debounce);
      vm.debounce = setTimeout(() => {
        let authorizationToken;
        if (vm.$session.has('jwt')) {
          authorizationToken = vm.$session.get('jwt');
        } else {
          authorizationToken = '';
        }
        let reqHeader = {
          headers: {
            authorization: authorizationToken,
          }
        };
        let tableFetchRequest = {
          table: 'emailtags-subsection',
          search: vm.inputValue,
          sort: JSON.stringify([]),
          pagesize: -1,
          pagenumber: -1,
          requestid: -1,
          session: vm.$session.id(),
          exportscope: 'everything',
          dataStructure: JSON.stringify({}),
          contactKeysMap: JSON.stringify({}),
          reportType: '',
          reportCc: ''
        };

        console.log(tableFetchRequest);
        let tagsReturned = [];
        const getQueries = queryString.stringify(tableFetchRequest);
        api.operation('tabledata?' + getQueries).getAll(reqHeader)
            .then(function (response) {
              console.log(response.data);
              if (response.data.success) {
                tagsReturned = [];
                vm.$_.forEach(response.data.returnData.data, function (tagObject) {
                  tagsReturned.push({text: tagObject.n})
                });
              }
              vm.autocompleteItems = tagsReturned;
              console.log(vm.autocompleteItems);
            }).catch(function () {
          console.warn('Oh. Something went wrong')
        } );
      }, 600);
    }
  },
  computed: {
    filteredItems() {
      let vm = this;
      return vm.$_.filter(vm.autocompleteItems, function (wordObj) {
        return wordObj.text.toLowerCase().indexOf(vm.inputValue.toLowerCase()) !== -1;
      });
    }
  },
  mounted: function () {
    let vm = this;
    vm.$nextTick(function () {
      console.log(vm.inputType);
      console.log(vm.inputData);
      if (typeof vm.inputType !== 'undefined' && vm.$_.trim(vm.inputType) === 'datafetching') {
        console.log('datafetching');
        vm.loadTagsData();
      }
    });
  }
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}

.text-area-with-icon {
  padding-left: 30px !important;
}

</style>