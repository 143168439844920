<template>
  <div class="box">
    <!--    <article class="media">-->
    <!--      <div class="media-left">-->
    <!--        <figure class="image is-64x64">-->
    <!--          &lt;!&ndash;          <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">&ndash;&gt;-->
    <!--         -->
    <!--        </figure>-->
    <!--      </div>-->
    <!--      <div class="media-content">-->
    <!--        <div class="content">-->

    <div class="columns is-mobile is-vcentered is-centered">
      <div class="column is-one-quarter short-div"><span class="drop-cap-text">{{ dropCapLetter }}</span></div>
      <div class="column is-three-quarters short-div">
        <div class="columns">
          <div class="column is-full short-div">
            <strong>{{ boxWord }}:</strong> <small>{{ boxData.legend }}</small>
          </div>
        </div>
        <div class="columns">
          <!--          <div class="column is-half short-div">summary</div>-->
          <div class="column is-half short-div tally-text">{{ boxData.tally }}</div>
        </div>
      </div>
    </div>


    <!--        -->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </article>-->
  </div>
</template>

<script>

export default {
  name: "datacards",
  components: {},
  props: ['dropCapLetter', 'boxWord', 'boxData'],
}
</script>

<style scoped>
.drop-cap-text {
  font-family: "AngloText", Helvetica, Arial, sans-serif;
  font-size: 75px;
}

.short-div {
  padding-top: 0;
  padding-bottom: 0;
}

.tally-text {
  font-size: 70px;
}
</style>