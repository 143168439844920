<template>
  <div v-cloak>
    <p class="element-label" v-html="labelText"></p>
    <div class="columns is-mobile is-multiline has-text-left">
      <div class="column is-full">
        <div class="columns is-mobile is-multiline has-text-left is-centered">
          <div class="column is-narrow" v-for="(pickItem,index) in pickItems" v-bind:key="index">
            <input
                v-bind:type="pickItem.type"
                v-bind:name="pickItem.name"
                v-bind:id="pickItem.id"
                v-model="checkRadioSelected[pickItem.model]"
                v-bind:value="pickItem.value"/>
            <label class="choice-label"
                   v-bind:for="pickItem.id">{{ pickItem.label }}</label>
          </div>
          <div class="column is-full no-padding-top no-padding-bottom no-margin-top">
            <p class="help is-danger bigger-text bolder-text" v-if="$v.checkRadioSelected.$invalid">{{
                mandatoryInputInvalidText
              }}</p>
            <p class="help is-danger bigger-text bolder-text" v-if="!$v.checkRadioSelected.hasAtLeastOneSelected">{{
                hasAtLeastOneSelectedInputInvalidText
              }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formFunctions from "./../../../../api/mixins/formFunctions";

export default {
  name: "checkRadioField",
  mixins: [formFunctions],
  data() {
    let vm = this;
    return {
      checkRadioSelected: vm.inputData.initial,
      inputName: vm.inputData.elementName,
      inputType: vm.inputData.type,
      placeholderText: vm.inputData.placeholderText,
      mandatoryInputInvalidText: vm.inputData.invalidText,
      hasAtLeastOneSelectedInputInvalidText: '',
      inputIcon: vm.inputData.icon,
      pickItems: vm.inputData.pickItemsArray,
      isMandatory: vm.inputData.mandatory,
      validationRules: vm.inputData.validationRules,
      labelText: vm.inputData.labelText,
      disabledInput: vm.inputData.disabled,
      mustBeWordInputInvalidText: '',
      mustBeEmailInputInvalidText: '',
      minValueInputInvalidText: '',
      requiredUnlessInputInvalidText: ''
    }
  },
  props: ['inputData']
}
</script>

<style scoped>
.element-label {
  font-weight: bold;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.choice-label {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 10px;
  line-height: 40px;
  cursor: pointer;
}

.choice-label::before {
  content: " ";
  position: absolute;
  top: 6px;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  /*border: 2px solid #8e44ad;*/
  border: 2px solid hsl(171, 100%, 41%);
  border-radius: 4px;
  /*z-index: -1;*/
}

input[type="radio"] + .choice-label::before {
  border-radius: 18px;
}

/* Checked */
input[type="checkbox"]:checked + .choice-label,
input[type="radio"]:checked + .choice-label {
  padding-left: 10px;
  color: #fff;
  z-index: 0;
}

input[type="checkbox"]:checked + .choice-label::before,
input[type="radio"]:checked + .choice-label::before {
  top: 0;
  width: 100%;
  height: 100%;
  /*background: #8e44ad;*/
  background: hsl(171, 100%, 41%);
  z-index: -1;
}

/* Transition */
.choice-label, .choice-label::before {
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

[v-cloak] {
  display: none;
}

</style>